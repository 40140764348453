import React from 'react'

import { Client } from '@foods-n-goods/server/generated/schema'
import TableTypes from '@stage-ui/core/data/Table/types'
import { ColorText } from 'components/ColorText'
import { rub } from 'utils/rub'

export function DebtCell(ctx: TableTypes.TableCellContext<Client>) {
  const { row } = ctx
  const creditDebt = row.markets[0]?.creditDebt

  if (!creditDebt) return null

  return (
    <ColorText
      weight={500}
      color="orange500"
      size="xs"
      p="xs s"
      backgroundColor="orange50"
      w="max-content"
    >
      {rub(Number(creditDebt))}
    </ColorText>
  )
}
