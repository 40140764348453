export default `mutation clientDeliveryAddressDelete($id: String!, $clientId: String) {
    clientDeliveryAddressDelete(clientId: $clientId, id: $id) {
        id
        name
        address
        entrance
        floor
        apartment
        intercom
        lat
        lng
        isDefault
    }
}`
