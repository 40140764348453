import React from 'react'

import { Block, Flexbox } from '@stage-ui/core'

import useSelector from 'hooks/useSelector'

import { objectEqual } from 'utils/objectEqual'

import { LogisticsCoordinator } from './Coordinator'
import { LogisticsCouriers } from './Couriers'
import { LogisticsCouriersFilter } from './Couriers/Filter'
import { LogisticsMenu } from './Menu'
import { LogisticsOrdersFilter } from './Orders/Filter'
import { LogisticsOrders } from './Orders/Table'

export function SideView() {
  const section = useSelector((state) => state.delivery.filter.section, objectEqual)

  return (
    <Block
      px="l"
      w="28rem"
      style={{
        '@media print': {
          width: '100%',
        },
      }}
    >
      <LogisticsMenu
        pb="s"
        style={{
          '@media print': {
            display: 'none',
          },
        }}
      />
      {section === 'orders' && (
        <>
          <Flexbox flex={1} wrap="nowrap">
            <LogisticsOrdersFilter />
          </Flexbox>
          <LogisticsOrders />
        </>
      )}
      {section === 'couriers' && (
        <>
          <LogisticsCouriersFilter />
          <LogisticsCouriers />
        </>
      )}
      {section === 'coordinator' && <LogisticsCoordinator />}
    </Block>
  )
}
