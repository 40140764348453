import React, { forwardRef, useImperativeHandle } from 'react'

import { Placemark } from 'react-yandex-maps'

interface AddressModalPlacemarkProps {
  updateByCoords: (coords: number[]) => Promise<void>
  lng: string
  lat: string
  address: string
}

function AddressModal(props: AddressModalPlacemarkProps, ref: any) {
  const { updateByCoords, lng, lat, address } = props

  useImperativeHandle(ref, () => ({
    geometry: ref.geometry,
  }))

  return (
    <Placemark
      instanceRef={(r: any) => (ref = r)}
      onDragEnd={() => {
        if (ref) {
          const coords = ref.geometry.getCoordinates()
          updateByCoords(coords)
        }
      }}
      geometry={[parseFloat(lat), parseFloat(lng)]}
      options={{
        preset: 'islands#violetDotIconWithCaption',
        draggable: true,
      }}
      properties={{
        balloonContent: address,
      }}
    />
  )
}

export default forwardRef(AddressModal)
