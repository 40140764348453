import React from 'react'

import { Flexbox, Grid, Text } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import { AlertTriangle, ShoppingBag, Weight } from '@stage-ui/icons'
import { useMouseDrop } from 'components/MouseDrop'

import { rub } from 'utils/rub'

import { OrderedPosition } from '..'

enum ChangedCodeStatus {
  NOT_CHANGED = 0,
  CHANGED_BY_MARKET = 1,
  CHANGED_BY_CLIENT = 2,
  CLIENT_NOT_ACCEPTED = 3,
  CHANGED_BY_WAREHOUSE = 4,
}

export function OrderPositionQuantityAfterCell(
  ctx: TableTypes.TableCellContext<OrderedPosition>,
) {
  const { row } = ctx

  const {
    quantityAfter,
    wishfulUnitName,
    wishfulUnitQuantity,
    quantityBeforeAcceptance,
    deleteDate,
    orderContainerLabels,
    amountAfter,
    changed,
    unit,
    lineDate,
    scaleable,
    tareWeight,
  } = row

  let warningText = ''

  if (changed === ChangedCodeStatus.CHANGED_BY_MARKET) {
    warningText = 'Исправлено оператором'
  }

  if (changed === ChangedCodeStatus.CHANGED_BY_WAREHOUSE) {
    warningText = 'Исправлено сборщиком'
  }

  if (!!deleteDate && orderContainerLabels) {
    warningText = `Освободите паллеты ${orderContainerLabels}`
  }

  const dropWarning = useMouseDrop(warningText)
  const fixedDateWarning = useMouseDrop('Нет даты фиксации')
  const tareWeightHint = useMouseDrop(
    tareWeight && `Тара ${tareWeight} ${scaleable ? 'кг' : unit.text} `,
  )

  const quantityBefore = quantityBeforeAcceptance
    ? ` (было ${quantityBeforeAcceptance?.toLocaleString()}) `
    : ' '

  const quantityValue =
    (quantityAfter || '0').toLocaleString() +
    (quantityBefore || '0') +
    (scaleable ? 'кг' : unit.text)

  const amountValue = rub(amountAfter || 0)

  if (!quantityAfter && !quantityBeforeAcceptance) return null

  return (
    <Grid autoFlow="row" alignItems="start" gap="0.125rem">
      <Flexbox alignItems="center" ml="-3.375rem">
        <Grid
          gap="0.0625rem"
          autoFlow="column"
          mr="xs"
          w="3.125rem"
          justifyContent="end"
          alignItems="center"
        >
          {!!quantityAfter && !lineDate && (
            <AlertTriangle color="red500" {...fixedDateWarning} />
          )}
          {!!warningText && <Weight color="blue500" {...dropWarning} />}
          {!!tareWeight && <ShoppingBag color="gray400" {...tareWeightHint} />}
        </Grid>
        <Text size="s" color="gray900" style={{ whiteSpace: 'nowrap' }}>
          {quantityValue}
        </Text>
        {wishfulUnitQuantity && wishfulUnitName && (
          <Text size="s" color="gray900" style={{ whiteSpace: 'nowrap' }}>
            ({wishfulUnitQuantity} {wishfulUnitName})
          </Text>
        )}
      </Flexbox>
      <Text size="s" color="gray900" style={{ whiteSpace: 'nowrap' }}>
        {amountValue}
      </Text>
    </Grid>
  )
}
