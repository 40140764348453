import ClientSystem, { OrderStatusCode } from '@foods-n-goods/client/system/types'
import {
  ChartAvarageValue,
  Client,
  ClientPage,
  DebtStatus,
  MarketStatus,
  OrderPage,
} from '@foods-n-goods/server/generated/schema'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type ClientFilter = {
  // Common
  section?: string
  registerDateStart?: string
  registerDateEnd?: string
  debt?: DebtStatus
  status?: MarketStatus
  page: number
  pageSize: number
  search?: string

  // Client Details
  detailsSection?: string
  detailsOrderStatus?: OrderStatusCode
  detailsOrderCreateDateStart?: string
  detailsOrderCreateDateEnd?: string
  detailsOrderPage: number
  detailsOrderPageSize: number
}

const initialClientFilter = {
  page: 1,
  pageSize: 40,

  // Client Details
  detailsOrderPage: 1,
  detailsOrderPageSize: 20,
}

export interface ClientState {
  loading: ClientSystem.Loading
  error: RequestError | null

  clientPage: ClientPage

  currentlyViewed: Client | null

  clientOrders: OrderPage | null

  clientAvarageCheck: {
    clientId: string | null
    loading: ClientSystem.Loading
    error: RequestError | null
    data: ChartAvarageValue[]
  }

  filter: ClientFilter
}

const initialClientPage = {
  page: 1,
  pageSize: 40,
  total: 0,
  records: [],
}

const initialState: ClientState = {
  loading: 'idle',
  error: null,

  clientPage: initialClientPage,

  currentlyViewed: null,
  clientAvarageCheck: {
    clientId: null,
    error: null,
    loading: 'idle',
    data: [],
  },
  clientOrders: null,
  filter: initialClientFilter,
}

const actionSetMarketStatus = (
  state: ClientState,
  {
    payload,
  }: PayloadAction<{ clientId: string; marketId: string; status: MarketStatus }>,
): ClientState => {
  const clients = state.clientPage.records.map((client) =>
    client.id === payload.clientId
      ? {
          ...client,
          markets: client.markets.map((market) =>
            market.id === payload.marketId
              ? {
                  ...market,
                  status: payload.status,
                }
              : market,
          ),
        }
      : client,
  )
  return {
    ...state,
    clientPage: { ...state.clientPage, records: clients },
  }
}

const clientSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    loading(state) {
      state.loading = 'pending'
    },
    resolve(state, action: PayloadAction<ClientPage>) {
      state.clientPage = action.payload
      state.loading = 'resolved'
      state.error = initialState.error
    },

    reject(state, action: PayloadAction<ClientState['error']>) {
      state.loading = 'rejected'
      state.error = action.payload
    },

    setCurrentlyViewed(state, action: PayloadAction<Client | null>) {
      state.currentlyViewed = action.payload
    },

    clientAvarageCheck(state, action: PayloadAction<string>) {
      state.clientAvarageCheck = {
        loading: 'pending',
        clientId: action.payload,
        error: null,
        data: [],
      }
    },

    clientAvarageCheckResolve(state, action: PayloadAction<ChartAvarageValue[]>) {
      if (state.currentlyViewed?.id === state.clientAvarageCheck.clientId) {
        state.clientAvarageCheck.loading = 'resolved'
        state.clientAvarageCheck.data = action.payload
      }
    },

    clientAvarageCheckReject(state, action: PayloadAction<ClientState['error']>) {
      if (state.currentlyViewed?.id === state.clientAvarageCheck.clientId) {
        state.clientAvarageCheck.loading = 'rejected'
        state.clientAvarageCheck.error = action.payload
      }
    },

    /* ACTION */

    actionCreateResolve(state, action: PayloadAction<Client>) {
      state.clientPage.records = [action.payload].concat(state.clientPage.records)
    },

    actionDeleteResolve(state, action: PayloadAction<Client>) {
      state.clientPage.records = state.clientPage.records.filter(
        (client) => client.id !== action.payload.id,
      )
    },

    actionUpdateResolve(state, action: PayloadAction<Client>) {
      state.clientPage.records = state.clientPage.records.map((c) =>
        c.id === action.payload.id ? action.payload : c,
      )
    },

    actionRemoveResolve(state, action: PayloadAction<Client['id']>) {
      state.clientPage.records = state.clientPage.records.filter(
        (c) => c.id !== action.payload,
      )
    },

    actionMarketApprove(
      state,
      action: PayloadAction<{ clientId: string; marketId: string }>,
    ) {
      state = actionSetMarketStatus(state, {
        ...action,
        payload: {
          ...action.payload,
          status: 'APPROVED' as MarketStatus,
        },
      })
    },

    actionMarketDecline(
      state,
      action: PayloadAction<{ clientId: string; marketId: string }>,
    ) {
      state = actionSetMarketStatus(state, {
        ...action,
        payload: {
          ...action.payload,
          status: 'DECLINED' as MarketStatus,
        },
      })
    },

    setClientOrders(state, action: PayloadAction<OrderPage | null>) {
      state.clientOrders = action.payload
    },

    setFilter(state, action: PayloadAction<Partial<ClientFilter>>) {
      state.filter = {
        ...state.filter,
        ...action.payload,
      }
    },

    clearDetailsFilter(state) {
      state.filter = {
        ...state.filter,
        detailsSection: undefined,
        detailsOrderStatus: undefined,
        detailsOrderCreateDateStart: undefined,
        detailsOrderCreateDateEnd: undefined,
        detailsOrderPage: 1,
        detailsOrderPageSize: 20,
      }
    },

    clearFilter(state) {
      state.filter = initialClientFilter
    },

    clear() {
      return initialState
    },
  },
})

export default clientSlice
