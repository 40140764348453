import React, { useEffect } from 'react'

import { Section } from 'components/Section'
import { Route } from 'react-router-dom'

import { ReportActions } from 'actions'

import { SectionHeader } from './components/SectionHeader'
import { ReportList } from './ReportList'

export function Reports() {
  useEffect(() => {
    return () => {
      ReportActions.clearFilter()
    }
  }, [])
  return (
    <Section titleComponent={<SectionHeader />} p="0 l">
      <Route exact path="/reports" component={ReportList} />
    </Section>
  )
}
