import React from 'react'

import { StaffRole } from '@foods-n-goods/server/src/shared/enum'
import { Button, Flexbox, Table } from '@stage-ui/core'
import { Bell, Plus } from '@stage-ui/icons'
import FilterCommon from 'components/common/FilterCommon'
import MenuNavigation from 'components/MenuNavigation'
import { useMouseDrop } from 'components/MouseDrop'
import useSelector from 'hooks/useSelector'
import { useHistory, useLocation } from 'react-router-dom'

import { objectEqual } from 'utils/objectEqual'

import { openStaffPushMessageModal } from 'components/Modals/StaffPushMessage'

import { openStaffCreateModal } from 'components/Modals/StaffCreate'

import { StaffActions } from 'actions'

import { columns, sections } from './misc'

export function StaffList() {
  const history = useHistory()
  const location = useLocation()

  const addStaffHint = useMouseDrop('Добавление персонала')
  const pushMessageHint = useMouseDrop('Групповая рассылка', {
    drop: {
      justify: 'end',
    },
    popover: {
      arrowOffset: '3.5625rem',
    },
  })

  const staffData = useSelector((state) => state.staff.data, objectEqual)
  const filter = useSelector((state) => state.staff.filter, objectEqual)

  const data = staffData
    .filter((staff) => {
      if (filter.section === 'office') {
        return [
          StaffRole.ADMIN,
          StaffRole.SUPERUSER,
          StaffRole.OPERATOR,
          StaffRole.CONTENT_MANAGER,
          StaffRole.OPERATOR,
        ].includes(staff.role.value)
      }
      if (filter.section === 'warehouse') {
        return [StaffRole.WAREHOUSE, StaffRole.STOREKEEPER].includes(staff.role.value)
      }
      if (filter.section === 'purchases') {
        return [StaffRole.PURCHASE, StaffRole.MAIN_PURCHASE].includes(staff.role.value)
      }
      if (filter.section === 'couriers') {
        return [StaffRole.COURIER].includes(staff.role.value)
      }
      if (filter.search) {
        return !!`${staff.name || ''}${staff.login || ''}${staff.phone || ''}`
          .toUpperCase()
          .match(filter.search.toUpperCase())
      }
      return true
    })
    .map((staff) => ({
      ...staff,
      roleText: staff.role.text,
    }))

  return (
    <>
      <Flexbox mb="m" justifyContent="space-between">
        <MenuNavigation
          items={sections}
          currentSection={filter.section}
          onChange={(section) => StaffActions.setFilter({ section })}
        />
        <Flexbox flex={1} justifyContent="flex-end">
          <FilterCommon onSearch={(search) => StaffActions.setFilter({ search })} />
          <Button
            ml="s"
            w="2.5rem"
            p={0}
            textColor="onPrimary"
            // @ts-expect-error
            onClick={openStaffCreateModal}
            {...addStaffHint}
          >
            <Plus size="1.25rem" />
          </Button>
          <Button
            color="yellow400"
            onClick={() => openStaffPushMessageModal()}
            w="1.5rem"
            ml="s"
            {...pushMessageHint}
          >
            <Bell color="white" size="1.5rem" />
          </Button>
        </Flexbox>
      </Flexbox>
      <Table
        data={data}
        columns={columns}
        onClick={() => undefined}
        onRowClick={(ctx) => {
          const { row } = ctx
          history.push(`/staff/${row.id}${location.search}`)
        }}
      />
    </>
  )
}
