import React, { Fragment } from 'react'

import { DatePicker, Select } from '@stage-ui/core'
import SelectTypes from '@stage-ui/core/control/Select/types'
import moment from 'moment'
import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'
import { ClientActions } from 'actions'

enum DebtStatus {
  WITH_DEBT = 'WITH_DEBT',
  WITHOUT_DEBT = 'WITHOUT_DEBT',
}

interface IData {
  dateArr: [string | Date, string | Date] | undefined
}

export function ClientListFilter() {
  const filter = useSelector((state) => state.client.filter, objectEqual)

  const clientDebtStatusOptions = [
    {
      text: 'Есть долг',
      value: 1,
      code: DebtStatus.WITH_DEBT,
    },
    {
      text: 'Нет долга',
      value: 0,
      code: DebtStatus.WITHOUT_DEBT,
    },
  ]

  const selectedClientDebtStatus = clientDebtStatusOptions.filter(
    (s) => s.code === filter.debt,
  )

  const selectHasDebt = (options: (SelectTypes.Option & { code?: DebtStatus })[]) => {
    if (!options[0]) return
    ClientActions.setFilter({
      page: 1,
      debt: options[0].code,
    })
  }

  const selectDebtClear = () => {
    ClientActions.setFilter({ page: 1, debt: undefined })
  }

  return (
    <>
      <DatePicker
        range
        clearable
        size="s"
        type="day"
        format="DD MMM"
        placeholder="Дата регистрации"
        defaultValue={
          filter.registerDateStart && filter.registerDateEnd
            ? [filter.registerDateStart, filter.registerDateEnd]
            : undefined
        }
        onChangeRange={(range) => {
          if (range[0] && range[1]) {
            ClientActions.setFilter({
              page: 1,
              registerDateStart: moment(range[0]).format('YYYY-MM-DD'),
              registerDateEnd: moment(range[1]).format('YYYY-MM-DD'),
            })
          }
        }}
        onClear={() => {
          ClientActions.setFilter({
            page: 1,
            registerDateStart: undefined,
            registerDateEnd: undefined,
          })
        }}
        overrides={{
          field: {
            transition: 'background .2s ease-in-out',
            background: 'transparent',
            ':focus-within': {
              background: 'white',
            },
            minWidth: '13.25rem',
          },
        }}
      />

      <Select
        size="s"
        maxScrollHeight="20rem"
        placeholder="Задолженность"
        options={clientDebtStatusOptions}
        values={selectedClientDebtStatus}
        onChange={selectHasDebt}
        onClear={selectDebtClear}
        overrides={{
          field: {
            transition: 'background .2s ease-in-out',
            background: 'transparent',
            ':focus-within': {
              background: 'white',
            },
            minWidth: '13.25rem',
          },
        }}
        clearable
        searchable
      />
    </>
  )
}
