import React, { useEffect } from 'react'

import { Flexbox } from '@stage-ui/core'
import { Section } from 'components/Section'

import { ChatActions } from 'actions'

import DialogMessages from './Messages'
import DialogRooms from './Rooms'

export function Dialogs() {
  useEffect(() => {
    return () => {
      ChatActions.clearFilter()
    }
  }, [])

  return (
    <Section p={0} title="Сообщения">
      <Flexbox h="calc(100vh - 6rem)" style={{ overflow: 'hidden' }}>
        <DialogRooms />
        <DialogMessages />
      </Flexbox>
    </Section>
  )
}
