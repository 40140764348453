import productFields from '../fragments/productFields.gql'

export default `
mutation productImage($id: String!, $payload: String!) {
  productImage(id: $id, payload: $payload) {
    ...productFields
  }
}
${productFields}
`
