import { SocketType } from '@foods-n-goods/server/src/socket/types'
import { ChatActions } from 'actions'
import { EXPRESS_HOST } from 'env'
import socket from 'socket.io-client'
import store from 'store'
import localStorage from 'utils/localStorage'

let keepAliveInterval: ReturnType<typeof setInterval>

// For debugging purposes replace export to connect beta socket
// export const io = socket('wss://console.beta.fg.shds.io', {
export const io = socket(EXPRESS_HOST.replace('https://', 'wss://'), {
  transports: ['websocket'],
})

// @ts-expect-error
window.io = io

io.on(SocketType.Events.Connect, () => {
  clearInterval(keepAliveInterval)
  keepAliveInterval = setInterval(() => {
    if (io?.connected && store.getState().app.user?.id) {
      io.emit(SocketType.Events.KeepAlive, store.getState().app.user?.id)
    }
  }, 30000)

  const token = localStorage.getPreference('token')

  if (token) {
    io.emit(SocketType.Events.Login, { token }, (data: SocketType.SocketLoginObject) => {
      console.log('Socket logged in')
      if (data.ok) {
        ChatActions.getRooms()
      }
    })
  }
})
