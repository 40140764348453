import React from 'react'

import { Flexbox, Text } from '@stage-ui/core'
import { ColorText } from 'components/ColorText'
import FlexboxTypes from '@stage-ui/core/layout/Flexbox/types'

type PalletsProps = {
  containers: string | undefined
} & FlexboxTypes.Props

function Pallets(props: PalletsProps) {
  const { containers, ...flexboxProps } = props

  if (!containers) {
    return null
  }

  return (
    <Flexbox alignItems="baseline" {...flexboxProps}>
      <Text size="xs" color="gray600" mr="0.25rem">
        Паллет
      </Text>
      <Flexbox wrap="wrap" style={{ gap: '0.25rem' }}>
        {containers?.split(',').map((containerName, index) => (
          <ColorText
            key={`${containerName}${index}`}
            weight={500}
            color="gray900"
            size="xs"
            borderColor="gray300"
            p="xs s"
            backgroundColor="white"
          >
            {containerName}
          </ColorText>
        ))}
      </Flexbox>
    </Flexbox>
  )
}

export default Pallets
