import React from 'react'

import { TextField } from '@stage-ui/core'
import TextFieldTypes from '@stage-ui/core/control/TextField/types'
import { Rifm } from 'rifm'

export interface RifmFieldProps extends Omit<TextFieldTypes.Props, 'value' | 'onChange'> {
  options?: {
    accept?: RegExp
    format?: (value: string) => string
    replace?: (value: string) => string
  }
  mask?: boolean
  value: string | number
  onChange: (value: string) => void
}

function RifmField(props: RifmFieldProps) {
  const {
    mask,
    options,
    value,
    onChange: onChangeRifm,
    onClear: onClearRifm,
    ...inputProps
  } = props

  return (
    <Rifm
      format={options?.format || ((v) => v)}
      accept={options?.accept}
      replace={options?.replace}
      value={String(value)}
      mask={mask}
      onChange={onChangeRifm}
      onClear={onClearRifm}
    >
      {({ value, onChange, onClear }) => (
        <TextField
          value={value}
          onChange={onChange}
          onClick={(e) => e.stopPropagation()}
          onClear={onClear}
          {...inputProps}
          style={{
            cursor: 'text',
          }}
        />
      )}
    </Rifm>
  )
}

export default RifmField
