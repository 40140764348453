import { OrderStatusCode } from '@foods-n-goods/client/system/types'
import { QueryOrdersArgs } from '@foods-n-goods/server/generated/schema'

import { LogisticsOrderStatus } from 'store/delivery'

export const filterWithStatus: Record<LogisticsOrderStatus, QueryOrdersArgs['filter']> = {
  all: {
    statusIds: [
      OrderStatusCode.PAYED,
      OrderStatusCode.SORTING,
      OrderStatusCode.AWAIT_COURIER,
      OrderStatusCode.PICKED_UP,
      OrderStatusCode.DELIVERY,
      OrderStatusCode.DELIVERED,
      OrderStatusCode.NOT_DELIVERED,
    ],
  },
  warehouse: {
    statusIds: [
      OrderStatusCode.PAYED,
      OrderStatusCode.SORTING,
      OrderStatusCode.AWAIT_COURIER,
    ],
  },
  delivery: {
    statusIds: [OrderStatusCode.PICKED_UP, OrderStatusCode.DELIVERY],
  },
  done: {
    statusIds: [OrderStatusCode.DELIVERED, OrderStatusCode.NOT_DELIVERED],
  },
  unassigned: {
    statusIds: [
      OrderStatusCode.PAYED,
      OrderStatusCode.SORTING,
      OrderStatusCode.AWAIT_COURIER,
      OrderStatusCode.PICKED_UP,
      OrderStatusCode.DELIVERY,
      OrderStatusCode.DELIVERED,
      OrderStatusCode.NOT_DELIVERED,
    ],
    onlyWithNoCourierAssigned: true,
  },
  coordinator: {
    statusIds: [
      OrderStatusCode.PAYED,
      OrderStatusCode.SORTING,
      OrderStatusCode.AWAIT_COURIER,
      OrderStatusCode.PICKED_UP,
      OrderStatusCode.DELIVERY,
    ],
  },
  coordinatorAll: {
    statusIds: [
      OrderStatusCode.PAYED,
      OrderStatusCode.SORTING,
      OrderStatusCode.AWAIT_COURIER,
      OrderStatusCode.PICKED_UP,
      OrderStatusCode.DELIVERY,
      OrderStatusCode.DELIVERED,
      OrderStatusCode.NOT_DELIVERED,
    ],
  },
}
