import React from 'react'

import ChatTypes from '@foods-n-goods/server/src/socket/chat/types'
import { Flexbox, Text } from '@stage-ui/core'
import { AllDone, Done } from '@stage-ui/icons'
import Avatar from 'components/Avatar'
import useSelector from 'hooks/useSelector'
import moment from 'moment'
import { objectEqual } from 'utils/objectEqual'

interface RoomCellProps {
  userId: string
  isTyping: boolean
  isActive: boolean
  room: ChatTypes.Room
  onClick: () => void
}

function RoomCell(props: RoomCellProps) {
  const {
    room: { name, roomId, messages, users },
    onClick,
    userId,
    isTyping,
    isActive,
  } = props

  let roomName = name

  if (!roomName) {
    roomName = `Диалог ${roomId.slice(0, 4)}`

    if (users.length > 0) {
      roomName = users[0].name
      if (users.length > 1) {
        roomName += ` и еще ${users.length - 1}`
      }
    }
  }

  const lastMessage = messages[0]
  const time = lastMessage?.dateTime ? moment(lastMessage.dateTime).format('LT') : ''

  const unreadMark = messages.some(
    (m) => !m.readTime && (!m.user || m.user.id !== userId),
  )
  let subtitle = 'Нет сообщений'
  if (isTyping) {
    subtitle = 'Печатает...'
  } else {
    const prevMessage = messages[messages.length - 1]
    if (messages[messages.length - 1]) {
      subtitle = prevMessage.msg || prevMessage.file?.name || 'Пустое сообщение'
    }
  }

  const userForAvatar = users.find((user) =>
    users.length === 2 ? userId !== user.id : userId === user.id,
  )

  const user = userForAvatar || users[0]
  const isOnline = useSelector(
    (state) =>
      users.length === 2 &&
      user.type === 'STAFF' &&
      state.staff.data.some(({ id, online }) => id === user.id && !!online),
    objectEqual,
  )

  return (
    <Flexbox
      flex={1}
      p="1.5rem"
      onClick={onClick}
      backgroundColor={isActive ? 'green100' : 'surface'}
    >
      <Avatar
        user={{
          ...user,
          online: isOnline,
        }}
        label={users.length > 2 ? users.length : 0}
      />
      <Flexbox w="100%" ml="m" flex={1} column>
        <Text size="1rem" weight={600} pb="0.375rem" children={roomName} />
        <Text
          weight={500}
          style={{
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitLineClamp: 1,
            WebkitBoxOrient: 'vertical',
            wordBreak: 'break-word',
          }}
          size="0.875rem"
          color={
            isTyping
              ? isActive
                ? 'green900'
                : 'green500'
              : isActive
              ? 'gray900'
              : 'gray500'
          }
          children={subtitle}
        />
      </Flexbox>
      <Flexbox column justifyContent="space-between" alignItems="flex-end">
        {lastMessage && (
          <Flexbox
            flexShrink={0}
            flexGrow={0}
            alignSelf="flex-end"
            alignItems="center"
            justifyContent="center"
          >
            <Text size="0.75rem" color="gray500">
              {time}
            </Text>
            {unreadMark && (
              <Flexbox
                ml="0.5rem"
                w="0.5rem"
                h="0.5rem"
                borderRadius="1rem"
                backgroundColor="red500"
              />
            )}
          </Flexbox>
        )}
        {lastMessage && (
          <>
            {lastMessage.readTime ? (
              <AllDone size="1.25rem" color="green500" />
            ) : (
              <Done size="1.25rem" color="green400" />
            )}
          </>
        )}
      </Flexbox>
    </Flexbox>
  )
}

export default RoomCell
