import React from 'react'

import { Button, Flexbox } from '@stage-ui/core'
import { Trash2, MessageCircle, Bell } from '@stage-ui/icons'
import { openStaffPushMessageModal } from 'components/Modals/StaffPushMessage'
import { Staff } from '@foods-n-goods/server/generated/schema'
import { openStaffDeleteModal } from 'components/Modals/StaffDelete'
import { openStaffSendChatMessageModal } from 'components/Modals/StaffSendChatMessage'
import { useMouseDrop } from 'components/MouseDrop'

type StaffDetailsToolbarProps = {
  staff: Staff
  modal: boolean
}

export function StaffDetailsToolbar(props: StaffDetailsToolbarProps) {
  const { staff, modal } = props

  const deleteHint = useMouseDrop('Удалить персонал', {
    drop: {
      align: 'bottom',
    },
    popover: {
      align: 'top',
    },
  })

  const pushMessageHint = useMouseDrop('Отправить пуш-уведомление', {
    drop: {
      align: 'bottom',
      justify: 'end',
    },
    popover: {
      align: 'top',
      arrowOffset: '87%',
    },
  })

  const handleDelete = () => {
    openStaffDeleteModal(staff)
  }

  const handlePushMessage = () => {
    openStaffPushMessageModal(staff)
  }

  const handleChatMessage = () => {
    openStaffSendChatMessageModal(staff)
  }

  return (
    <Flexbox
      justifyContent="flex-end"
      pb="xl"
      style={{
        position: modal ? 'relative' : 'sticky',
        top: modal ? 'unset' : '6rem',
        zIndex: 3,
      }}
    >
      <Flexbox backgroundColor="background">
        <Button
          w="2.5rem"
          h="2.5rem"
          p={0}
          color="red500"
          onClick={handleDelete}
          {...deleteHint}
        >
          <Trash2 type="filled" size="1.25rem" />
        </Button>
        <Button
          ml="s"
          label="Отправить сообщение"
          rightChild={<MessageCircle />}
          onClick={handleChatMessage}
        />
        <Button
          w="2.5rem"
          h="2.5rem"
          p={0}
          ml="s"
          color="yellow400"
          onClick={handlePushMessage}
          {...pushMessageHint}
        >
          <Bell size="1.25rem" color="white" />
        </Button>
      </Flexbox>
    </Flexbox>
  )
}
