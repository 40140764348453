import React from 'react'

import { Divider, Flexbox, Header, Range, Text, useTheme } from '@stage-ui/core'
import { rub } from 'utils/rub'

import { Client } from '@foods-n-goods/server/generated/schema'

const creditMaxLimit = 1000000

type ClientCreditLimitProps = {
  client: Client
}

export function ClientCreditLimit(props: ClientCreditLimitProps) {
  const { client } = props

  const { color } = useTheme()

  const clientCreditLimit = client.markets[0]?.creditLimit || 0
  const creditLimitParsed = rub(Number(clientCreditLimit))

  return (
    <Flexbox
      column
      decoration="surface"
      borderRadius="m"
      p="1.25rem"
      pt="2.5rem"
      borderColor="gray200"
    >
      <Header size="s">Кредитный лимит</Header>
      <Divider color="gray200" my="0.75rem" />
      <Flexbox column alignItems="center" mb="s">
        <Flexbox alignItems="center" mt="s" mb="s" w="100%">
          <Text weight={600} size="2rem" color="gray900">
            {clientCreditLimit < creditMaxLimit ? creditLimitParsed : creditMaxLimit}
          </Text>
        </Flexbox>

        <Range
          w="100%"
          min={0}
          max={creditMaxLimit}
          defaultValue={Number(client.markets[0].creditLimit)}
          value={Number(client.markets[0].creditLimit)}
          overrides={{
            track: {
              background: `${color.green[500].hex()} !important`,
              height: '0.375rem',
              width: `${
                (Number(client.markets[0].creditLimit) / 1000000) * 100
              }% !important`,
            },
            thumb: {
              display: 'none',
            },
          }}
          style={{
            pointerEvents: 'none',
          }}
        />
      </Flexbox>

      <Text size="s" color="gray500" weight={400}>
        Обновляется автоматически
      </Text>
    </Flexbox>
  )
}
