import React from 'react'

import { Flexbox, Text } from '@stage-ui/core'
import { ColorText } from 'components/ColorText'

type QuantityProps = {
  quantityBefore: string
  quantityAfter: string | undefined
  quantitySupposed: string | undefined
  tareWeight: number | undefined
}

const Quantity: React.FC<QuantityProps> = (props) => {
  const { quantityBefore, quantityAfter, quantitySupposed, tareWeight } = props

  return (
    <Flexbox column>
      <Flexbox justifyContent="space-between">
        <Flexbox alignItems="center" mr="xl">
          <Text color="gray900" size="xs" style={{ whiteSpace: 'nowrap' }}>
            В заказе
          </Text>
          <Flexbox alignItems="center">
            <ColorText
              size="xs"
              w="3.6rem"
              borderColor="green900"
              color="green900"
              py="0.125rem"
              backgroundColor="transparent"
              mx="s"
            >
              {quantityBefore.split(' ')[0]}
            </ColorText>
            <Text size="xs" weight={500} color="gray600">
              {quantityBefore.split(' ')[1]}
            </Text>
          </Flexbox>
        </Flexbox>
        {!!quantityAfter && (
          <Flexbox>
            <Text
              color="gray900"
              size="xs"
              alignSelf="center"
              style={{ whiteSpace: 'nowrap' }}
              // style={{ wordBreak: 'keep-all' }}
            >
              К отгрузке
            </Text>
            <Flexbox alignItems="center">
              <ColorText
                size="xs"
                w="3.5rem"
                borderColor="green900"
                py="0.125rem"
                background="linear-gradient(89.52deg, #10B981 0.53%, #08A671 97.83%)"
                color="white"
                weight={500}
                mx="s"
              >
                {quantityAfter.split(' ')[0]}
              </ColorText>
              <Text size="xs" weight={500} color="gray600">
                {quantityAfter.split(' ')[1]}
              </Text>
            </Flexbox>
          </Flexbox>
        )}
      </Flexbox>
      <Flexbox
        mt="1.125rem"
        justifyContent={quantitySupposed ? 'space-between' : 'flex-end'}
      >
        {!!quantitySupposed && (
          <Flexbox>
            <Text color="gray900" size="xs" alignSelf="center">
              Вес
            </Text>
            <Flexbox alignItems="center">
              <ColorText
                size="xs"
                w="3.5rem"
                borderColor="lightGreen800"
                color="lightGreen800"
                py="0.125rem"
                backgroundColor="transparent"
                mx="s"
              >
                ~{quantitySupposed.split(' ')[0]}
              </ColorText>
              <Text size="xs" weight={500} color="gray600">
                {quantitySupposed.split(' ')[1]}
              </Text>
            </Flexbox>
          </Flexbox>
        )}
        {!!tareWeight && (
          <Flexbox>
            <Text
              color="gray900"
              size="xs"
              weight={500}
              gridArea="3 / 4 / 4 / 5"
              alignSelf="center"
            >
              Тара
            </Text>
            <Flexbox gridArea="3 / 5 / 4 / 6" alignItems="center">
              <ColorText
                size="xs"
                w="3.5rem"
                borderColor="lightGreen800"
                color="green900"
                py="0.125rem"
                background="linear-gradient(269.09deg, #87E41A -68.86%, #D6FFA7 98.31%)"
                weight={500}
                mx="s"
              >
                {tareWeight}
              </ColorText>
              <Text color="gray900" size="xs" weight={500}>
                кг
              </Text>
            </Flexbox>
          </Flexbox>
        )}
      </Flexbox>
    </Flexbox>
  )
}

export default Quantity
