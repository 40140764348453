import React from 'react'

import { Button, Flexbox, Grid, useTheme } from '@stage-ui/core'
import { Email } from '@stage-ui/icons'
import FilterCommon from 'components/common/FilterCommon'
import MenuNavigation from 'components/MenuNavigation'
import { useMouseDrop } from 'components/MouseDrop'

import { openClientPushMessageModal } from 'components/Modals/ClientPushMessage'

import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'
import { ClientActions } from 'actions'

import { ClientListFilter } from './ClientListFilter'

export const clientListMenuItems = [
  { id: '', title: 'Все' },
  { id: '1', status: ['AWAITING'], title: 'Новые' },
  { id: '2', status: ['DECLINED', 'BLOCKED'], title: 'Заблокированные' },
]

export function ClientListToolbar() {
  const theme = useTheme()
  const filter = useSelector((state) => state.client.filter, objectEqual)

  const clientBroadCastTooltip = useMouseDrop('Отправить сообщение всем клиентам', {
    drop: {
      w: '13rem',
      align: 'bottom',
      justify: 'end',
    },
    popover: {
      align: 'top',
      arrowOffset: '5.25rem',
    },
  })

  return (
    <Flexbox
      justifyContent="space-between"
      pb="m"
      style={{
        position: 'sticky',
        top: '6rem',
        zIndex: 7,
        backgroundColor: theme.color.gray[50].hex(),
      }}
    >
      <MenuNavigation
        items={clientListMenuItems}
        currentSection={filter.section}
        onChange={(section) => ClientActions.setFilter({ section })}
      />
      <Grid ml="m" autoFlow="column" gap="0 0.5rem" w="max-content">
        <ClientListFilter />
        <FilterCommon
          onSearch={(st) => ClientActions.setFilter({ page: 1, search: st })}
          overrides={{ width: '15rem' }}
        />
        <Button
          size="m"
          label={<Email size="l" />}
          color="yellow400"
          textColor="surface"
          onClick={() => openClientPushMessageModal()}
          w="2.5rem"
          h="2.5rem"
          p={0}
          {...clientBroadCastTooltip}
        />
      </Grid>
    </Flexbox>
  )
}
