import React, { Fragment, useEffect, useImperativeHandle, useRef, useState } from 'react'

import { Button, Drop, Flexbox, Text, useTheme } from '@stage-ui/core'
import DropTypes from '@stage-ui/core/layout/Drop/types'
import * as Icons from '@stage-ui/icons'
import { scrollViewRef } from 'Router/MainView'

type MouseDropMenuProps = {
  disabled?: boolean
  drop?: React.ComponentProps<typeof Drop>
}

export type MouseDropMenuAction = (item: MouseDropMenuItem, index: number) => void

type MouseDropMenuRef = {
  setTarget: (
    target: HTMLElement | null,
    values: MouseDropMenuItem[],
    onAction?: MouseDropMenuAction,
    props?: MouseDropMenuProps,
  ) => void
  clearTarget: () => void
  getCurrentTarget: () => HTMLElement | null
  visible: boolean
}

export type MouseDropMenuItem = {
  text: string
  icon?: keyof typeof Icons
  color?: string
  value?: string | number
  onClick?: () => void
  hidden?: boolean
  disabled?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const MouseDropMenuAction: { action?: MouseDropMenuAction } = {
  action: (item: MouseDropMenuItem, index: number) => {},
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const MouseDropMenuRef = React.createRef<MouseDropMenuRef>()
export const useMouseDropMenu = <T extends HTMLElement>(
  values: MouseDropMenuItem[],
  onAction?: MouseDropMenuAction,
  props?: MouseDropMenuProps,
) => {
  const ref = useRef<T>(null)

  useEffect(() => {
    function onScroll() {
      MouseDropMenuRef.current?.clearTarget()
    }
    scrollViewRef.current?.container?.addEventListener('scroll', onScroll)
    return () => {
      scrollViewRef.current?.container?.removeEventListener('scroll', onScroll)
    }
  }, [])

  if (!values.length || props?.disabled) {
    return {}
  }

  return {
    ref,
    close: () => {
      MouseDropMenuRef.current?.clearTarget()
    },
    onClick: (e?: React.MouseEvent<T>) => {
      e?.stopPropagation?.()
      if (!MouseDropMenuRef.current) {
        return
      }
      if (MouseDropMenuRef.current.visible) {
        if (MouseDropMenuRef.current.getCurrentTarget()?.id !== ref.current?.id) {
          setTimeout(() => {
            MouseDropMenuRef.current?.setTarget(ref.current, values, onAction, props)
          }, 150)
          return
        }
        MouseDropMenuRef.current.clearTarget()
        return
      }
      MouseDropMenuRef.current.setTarget(ref.current, values, onAction, props)
    },
  }
}

let timer: ReturnType<typeof setTimeout>

function MouseDropMenu() {
  const [dropProps, setDropProps] = useState<React.ComponentProps<typeof Drop>>()

  const [values, setValues] = useState<MouseDropMenuItem[]>([])
  const [visible, setVisible] = useState(false)

  const dropRef = useRef<DropTypes.Ref>(null)
  const targetRef = useRef<HTMLElement | null>(null)

  const theme = useTheme()

  const setProps = (dropProps?: React.ComponentProps<typeof Drop>) => {
    const rect = targetRef.current?.getBoundingClientRect()

    setDropProps({
      align: rect && rect.top >= window.innerHeight / 2 ? 'top' : 'bottom',
      justify: rect && rect.right >= window.innerWidth / 2 ? 'end' : 'start',
      ...dropProps,
    })
  }

  useImperativeHandle(MouseDropMenuRef, () => ({
    getCurrentTarget: () => targetRef.current,
    setTarget: (el, values, onAction, props) => {
      clearTimeout(timer)
      targetRef.current = el
      MouseDropMenuAction.action = onAction
      dropRef.current?.updatePosition()
      setValues(values)
      setProps(props?.drop)
      setVisible(true)
    },
    clearTarget: () => {
      setVisible(false)
    },
    visible,
  }))

  return (
    <Drop
      ref={dropRef}
      target={targetRef}
      visible={visible}
      spacing={10}
      style={{
        zIndex: '5000 !important',
      }}
      animation={{
        type: 'slide',
        reverse: !!targetRef?.current,
        duration: 100,
      }}
      onClickOutside={(e, outTarget) => {
        if (outTarget) {
          e.stopPropagation()
          timer = setTimeout(() => {
            setVisible(false)
          }, 1)
        }
      }}
      {...dropProps}
    >
      <Flexbox
        column
        shadow="s"
        borderRadius="m"
        p="s"
        backgroundColor="gray900"
        style={{
          '@media print': {
            display: 'none',
          },
        }}
      >
        {values.map((value, index) => {
          if (value.hidden) return null
          const Icon = value.icon ? Icons[value.icon] : null
          return (
            <Fragment key={index.toString()}>
              <Button
                // p="0 m"
                w="100%"
                size="s"
                decoration="text"
                onClick={() => {
                  value.onClick?.()
                  MouseDropMenuAction.action?.(value, index)
                  setVisible(false)
                }}
                disabled={value.disabled}
                my="0.0625rem"
                borderRadius="s"
                overrides={{
                  container: {
                    justifyContent: 'flex-start',
                    ':hover': {
                      backgroundColor: value.disabled
                        ? theme.color.gray[900].hex()
                        : theme.color.gray[800].hex(),
                    },
                  },
                }}
              >
                {Icon && (
                  <Icon
                    mr="s"
                    color={value.disabled ? 'gray500' : value.color || 'white'}
                  />
                )}
                <Text
                  size="xs"
                  color={value.disabled ? 'gray500' : 'white'}
                  weight={600}
                  lineHeight="1rem"
                >
                  {value.text}
                </Text>
              </Button>
            </Fragment>
          )
        })}
      </Flexbox>
    </Drop>
  )
}

export default MouseDropMenu
