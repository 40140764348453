import React from 'react'

import { Divider, Flexbox, Header } from '@stage-ui/core'

import { StaffDetailsInfoRow, StaffDetailsInfoRowProps } from './InfoRow'

type StaffDetailsInfoBlockProps = {
  block: string
  title: string
  rows: StaffDetailsInfoRowProps[]
}

export function StaffDetailsInfoBlock(props: StaffDetailsInfoBlockProps) {
  const { block, title, rows } = props

  return (
    <Flexbox
      column
      p="1.25rem"
      decoration="surface"
      borderRadius="s"
      borderColor="gray200"
    >
      <Header size="xs" weight="600 !important">
        {title}
      </Header>
      <Divider color="gray200" mt="m" />
      {rows.map((row, i) => (
        <StaffDetailsInfoRow key={`${block}_${i.toString()}`} {...row} />
      ))}
    </Flexbox>
  )
}
