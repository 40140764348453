import React from 'react'

import { Order } from '@foods-n-goods/server/generated/schema'
import TableTypes from '@stage-ui/core/data/Table/types'
import CourierSelect from 'components/CourierSelect'

export function OrderListTableCourierCell(ctx: TableTypes.TableCellContext<Order>) {
  const { row } = ctx

  return <CourierSelect order={row} onlyIcon />
}
