import React from 'react'

import { Menu, Paragraph, Text, useTheme } from '@stage-ui/core'

type MenuItemId = string | number

type DnDObject = {
  source: MenuItemId
  target: MenuItemId
}

type MenuNavigationItem = {
  id: MenuItemId
  title: number | string
  subtitle?: number | string
  count?: number | string
}

type MenuNavigationProps = {
  items: MenuNavigationItem[]
  currentSection?: string
  onChange: (section: string) => void
  onItemDragged?: (DnDObject: DnDObject) => void
  onItemMouseEnter?: (item: MenuNavigationItem) => void
  onItemMouseLeave?: (item: MenuNavigationItem) => void
}

const dndObject: DnDObject = {
  source: '',
  target: '',
}

function MenuNavigation(props: MenuNavigationProps) {
  const {
    items,
    currentSection,
    onChange,
    onItemDragged,
    onItemMouseEnter,
    onItemMouseLeave,
  } = props

  const theme = useTheme()
  const activeValue = items.find((i) => i.id === currentSection)?.id ?? items[0].id

  return (
    <Menu
      h="2.5rem"
      value={activeValue}
      onChange={(val) => {
        onChange(val as string)
      }}
      defaultValue="all"
      decoration="marker"
      flexShrink={0}
      borderWidth="0 0 1px 0"
      borderColor="gray200"
      borderStyle="solid"
      w="fit-content"
      overrides={{
        item: () => ({
          flexShrink: 0,
          color: theme.color.gray[500].hex(),
          fontWeight: 500,
          fontSize: '0.875rem',
          padding: `${theme.spacing.s} 0.75rem`,
          lineHeight: '1.25rem',
          alignItems: 'center',
          '&[data-flow=menu-item]': {
            color: theme.color.gray[500].hex(),
            borderLeftColor: 'transparent',
            borderLeftStyle: 'dashed',
            borderLeftWidth: '0.125rem',
            ':before': {
              height: '2px',
              backgroundColor: theme.color.green[600].hex(),
            },
            '> span': {
              pointerEvents: 'none',
            },
          },
          '&[data-flow-active]': {
            lineHeight: '1.25rem',
            alignItems: 'center',
            padding: `${theme.spacing.s} 0.75rem`,
            color: theme.color.gray[900].hex(),
          },
        }),
      }}
    >
      {items.map((item) => (
        <Menu.Item
          key={item.id}
          value={item.id}
          draggable={!!onItemDragged}
          onMouseEnter={() => {
            onItemMouseEnter?.(item)
          }}
          onMouseLeave={() => {
            onItemMouseLeave?.(item)
          }}
          onDragStart={(e) => {
            dndObject.source = String(item.id)
            dndObject.target = ''
          }}
          onDragOver={(e) => {
            e.preventDefault()
          }}
          onDragEnter={(e) => {
            if (item.id !== dndObject.source) {
              ;(e.target as any).style.setProperty('border-left-color', '#bbb')
              dndObject.target = String(item.id)
            }
          }}
          onDragLeave={(e) => {
            ;(e.target as any).style.setProperty('border-left-color', 'transparent')
            dndObject.target = ''
          }}
          onDrop={(e) => {
            ;(e.target as any).style.setProperty('border-left-color', 'transparent')
            if (item.id !== dndObject.source) {
              dndObject.target = String(item.id)
              onItemDragged?.(dndObject)
            }
          }}
        >
          <Paragraph size="s" color={activeValue === item.id ? 'gray900' : 'gray500'}>
            {item.title}
            {!!item.count && (
              <Text size="xs" color={activeValue === item.id ? 'gray900' : 'gray500'}>
                {item.count ? ` (${item.count})` : ''}
              </Text>
            )}
          </Paragraph>
          {!!item.subtitle && (
            <Paragraph size="xs" color="gray500">
              {item.subtitle}
            </Paragraph>
          )}
        </Menu.Item>
      ))}
    </Menu>
  )
}

export default MenuNavigation
