import React from 'react'

import { OrderStatusCode } from '@foods-n-goods/client/system/types'
import { Button, modal } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import { Cube } from '@stage-ui/icons'
import { useMouseDrop } from 'components/MouseDrop'

import { OrderedPosition } from '..'

import { OrderPositionPurchaseModal } from './PurchaseModal'

export function OrderPositionPurchaseCell(
  ctx: TableTypes.TableCellContext<OrderedPosition>,
) {
  const { row } = ctx
  const { id, deleteDate, status, parentStatusValue } = row

  const disabled =
    !!deleteDate ||
    !!(parentStatusValue && ![OrderStatusCode.SORTING].includes(parentStatusValue)) ||
    [
      OrderStatusCode.PICKED_UP,
      OrderStatusCode.DELIVERY,
      OrderStatusCode.DELIVERED,
      OrderStatusCode.CANCELED,
      OrderStatusCode.REJECTED,
      OrderStatusCode.PURCHASE_CONFIRMED,
      OrderStatusCode.PURCHASE_FAILED,
      OrderStatusCode.PURCHASE_PENDING,
      OrderStatusCode.OUT_OF_STOCK,
      OrderStatusCode.NOT_DELIVERED,
    ].includes(status.value)

  const hint = useMouseDrop(!disabled && 'Отправить в докупку')

  const handlePurchase = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    modal({
      title: 'Создание докупки',
      overlayClose: false,
      render: (close) => (
        <OrderPositionPurchaseModal close={close} id={id} disabled={disabled} />
      ),
    })
  }

  return (
    <Button
      disabled={disabled}
      p={0}
      w="2.5rem"
      h="2.5rem"
      decoration="outline"
      color="yellow500"
      onClick={handlePurchase}
      {...hint}
    >
      <Cube size="1.5rem" />
    </Button>
  )
}
