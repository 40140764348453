import React from 'react'

import useSelector from 'hooks/useSelector'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { objectEqual } from 'utils/objectEqual'

const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const user = useSelector(({ app }) => app.user, objectEqual)

  return (
    <Route
      {...rest}
      render={({ location }) =>
        user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

export default PrivateRoute
