import { Container } from '@foods-n-goods/server/generated/schema'
import { Button, Flexbox } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import { Edit } from '@stage-ui/icons'

import { updateContainerDialog } from './form'

export const columns: TableTypes.TableColumn<Container>[] = [
  { key: 'id', title: 'ID', width: '4rem' },
  { key: 'name', width: 200, title: 'Наименование' },
  {
    key: '_',
    title: '',
    render: (ctx) => (
      <Flexbox justifyContent="flex-end">
        <Button
          ml="s"
          label="Изменить"
          decoration="plain"
          size="s"
          onClick={() => {
            updateContainerDialog(ctx.row)
          }}
          rightChild={<Edit />}
        />
      </Flexbox>
    ),
  },
]
