import { Order } from 'shared/order'

export namespace ChatTypes {
  /**
   * Generic chat user
   */
  export type User = {
    id: string
    name: string
    color: string
    photo?: string
    updateDate: Date
    type: 'STAFF' | 'CLIENT'
  }

  /**
   * Generic chat message
   */
  export type Message = {
    /**
     * Message unique id
     */
    id: number
    /**
     * Message type
     * 0 - default message sent by user
     * 1 - system message
     * 2 - alert message
     */
    type: number
    /**
     * Message text
     */
    msg: string
    /**
     * Unique roomId hex
     */
    roomId: string
    /**
     * Message create date
     */
    dateTime: string
    /**
     * Message read date
     * fillsup when anyone in room reads it
     */
    readTime?: string
    /**
     * Chat user, will be null if this message sent by system
     */
    user?: User
    /**
     * Attachment
     */
    file?: File
    /**
     * Custom message content
     */
    payload?: {
      /**
       * Attached order
       */
      order?: Order
    }
  }

  /**
   * Generic chat attachment
   */
  export type File = {
    name: string
    type: string
    size: number
    url?: string
    buffer?: ArrayBuffer
  }

  /**
   * Generic chat room
   */
  export type Room = {
    id: number
    roomId: string
    name: string
    time: number
    users: User[]
    messages: Message[]
    messagesUnread: boolean
  }

  /**
   * Events using by chat sockets
   */
  export enum Events {
    // New message
    Message = 'chat_message',
    MessageEvent = 'chat_message_event',

    // Read message
    Read = 'chat_read',
    ReadEvent = 'chat_read_event',

    // When client are typing
    Typing = 'chat_typing',
    TypingEvent = 'chat_typing_event',

    // Get all history messages
    GetMessages = 'chat_get_messages',
    // Get rooms
    GetRooms = 'chat_get_rooms',

    // Room create
    RoomCreate = 'chat_room_create',
    RoomCreateEvent = 'chat_room_create_event',

    // Room delete
    RoomDelete = 'chat_room_delete',
    RoomDeleteEvent = 'chat_room_delete_event',

    // Room update
    RoomUpdate = 'chat_room_update',
    RoomUpdateEvent = 'chat_room_update_event',

    // Joom join
    RoomJoin = 'chat_room_join',
    RoomJoinEvent = 'chat_room_join_event',

    // Room leave
    RoomLeave = 'chat_room_leave',
    RoomLeaveEvent = 'chat_room_event_event',

    // Room get in
    RoomGetIn = 'chat_get_in',
    RoomGetInEvent = 'chat_get_in_room_event',
  }

  /**
   * Data layer of events
   */
  export type Data = {
    GetMessages: {
      payload: Pick<Message, 'roomId'>
      callback?: (messages: Message[], error?: string) => void
    }
    GetRooms: {
      // eslint-disable-next-line @typescript-eslint/ban-types
      payload: {}
      callback?: (rooms: Room[], error?: string) => void
    }

    Message: {
      payload: Pick<Message, 'roomId' | 'msg' | 'file' | 'payload'>
      callback?: (message: Message | null, error?: string) => void
    }
    MessageEvent: {
      payload: Message
    }

    Read: {
      payload: Pick<Message, 'id' | 'roomId' | 'readTime'>
      callback?: (error?: string) => void
    }
    ReadEvent: {
      payload: Pick<Message, 'id' | 'roomId' | 'readTime'>
    }

    Typing: {
      payload: Pick<Message, 'roomId' | 'msg'>
      callback?: (error?: string) => void
    }
    TypingEvent: {
      payload: Pick<Message, 'roomId' | 'msg'>
    }

    RoomCreate: {
      payload: { name: string; staffIds: string[]; clientIds: string[] }
      callback?: (room: Room, error?: string) => void
    }
    RoomUpdate: {
      payload: {
        roomId: string
        name: string
        staffIds: string[]
        clientIds: string[]
      }
      callback?: (room: Room, error?: string) => void
    }
    RoomDelete: {
      payload: { roomId: string }
      callback?: (room: Room, error?: string) => void
    }
    RoomCreateEvent: {
      payload: Room
    }
    RoomUpdateEvent: {
      payload: Room
    }
    RoomDeleteEvent: {
      payload: Room
    }
    RoomJoin: {
      payload: Pick<Message, 'roomId'>
      callback?: (rooms: Room | null, error?: string) => void
    }
    RoomGetIn: {
      payload: Pick<Message, 'roomId'>
      callback?: (rooms: Room | null, error?: string) => void
    }
    RoomJoinEvent: {
      payload: Pick<Message, 'roomId'>
    }
    RoomLeave: {
      payload: { roomId: string }
      callback?: (error?: string) => void
    }
    RoomLeaveEvent: {
      payload: void
    }
  }
}

export default ChatTypes
