import React from 'react'

import { Flexbox, Text } from '@stage-ui/core'
import { Close } from '@stage-ui/icons'
import { ColorText } from 'components/ColorText'
import useSelector from 'hooks/useSelector'
import { OrderStore } from 'store'

import { objectEqual } from 'utils/objectEqual'

import { OrderListGroupControls } from './OrderGroupControls'

export function OrderListGroupSelect() {
  const orders = useSelector((state) => state.order.selectedOrders, objectEqual)

  return (
    <Flexbox justifyContent="flex-end" flex={1}>
      <Flexbox alignItems="center" flex={1}>
        <Text
          size="s"
          weight={500}
          color="gray500"
          alignSelf="center"
          style={{ whiteSpace: 'nowrap' }}
          mr="s"
          children="Выбрано заказов"
        />
        <ColorText
          w="1.5rem"
          h="1.5rem"
          weight={600}
          size="s"
          borderStyle="soild"
          borderWidth="1px"
          borderColor="gray300"
          backgroundColor="transparent"
          color="gray500"
        >
          {orders.length}
        </ColorText>
        <Close
          onClick={OrderStore.clearSelected}
          size="1.5rem"
          ml="s"
          alignSelf="center"
          color="primary"
        />
      </Flexbox>
      <OrderListGroupControls />
    </Flexbox>
  )
}
