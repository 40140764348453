import {
  ProductGroupInput,
  QueryProductGroupArgs,
} from '@foods-n-goods/server/generated/schema'
import request from 'requests/request'
import store, { AppStore, CategoryStore } from 'store'
import actionNotify from 'utils/actionNotify'

export default {
  async fetch(args: QueryProductGroupArgs = {}) {
    try {
      CategoryStore.loading()
      const response = await request('productGroup', {
        ...store.getState().category.productCategoryArgs,
        ...args,
      })
      CategoryStore.resolve(response)
    } catch (error) {
      CategoryStore.reject(error)
    }
  },

  async create(payload: ProductGroupInput, _cb?: () => void) {
    try {
      AppStore.pending(true)
      await request('productGroupCreate', { payload })
      CategoryStore.actionCreateResolve()
      const categories = await request('productGroup', {
        ...store.getState().category.productCategoryArgs,
      })
      CategoryStore.resolve(categories)
      actionNotify({
        title: payload.name,
        message: 'Категория успешно создана!',
        type: 'success',
      })

      _cb?.()
    } catch (error) {
      actionNotify({
        title: `Ошибка создания категории!`,
        message: `${error.message}`,
        type: 'error',
      })
      CategoryStore.actionReject(error.message)
    } finally {
      AppStore.pending(false)
    }
  },

  async delete(id: string) {
    try {
      await request('productGroupDelete', { id })
      CategoryStore.actionDeleteResolve()
      const categories = await request('productGroup')
      CategoryStore.resolve(categories)
      actionNotify({
        title: `Категория: ${id}`,
        message: `Категория успешно удалена!`,
        type: 'success',
      })
    } catch (error) {
      actionNotify({
        title: `Категория: ${id}.`,
        message: `Ошибка удаления категории: ${error.message}`,
        type: 'error',
      })
      CategoryStore.actionReject(error.message)
    }
  },

  async update(id: string, payload: ProductGroupInput, _cb: () => void) {
    try {
      AppStore.pending(true)
      const response = await request('productGroupUpdate', { id, payload })
      CategoryStore.actionUpdateResolve()
      const categories = await request('productGroup')
      CategoryStore.resolve(categories)
      actionNotify({
        title: `Категория: ${id}`,
        message: `Категория успешно обновлена!`,
        type: 'success',
      })

      _cb()
    } catch (error) {
      actionNotify({
        title: `Категория: ${id}.`,
        message: `Ошибка обновления категории: ${error.message}`,
        type: 'error',
      })
      CategoryStore.actionReject(error.message || error)
    } finally {
      AppStore.pending(false)
    }
  },

  async imageUpdate(id: string, payload: string) {
    try {
      AppStore.pending(true)
      await request('productGroupImage', { id, payload })
      CategoryStore.actionUpdateResolve()
      const categories = await request('productGroup')
      CategoryStore.resolve(categories)
      actionNotify({
        title: `id:${id}`,
        message: `Изображение категории успешно обновлено!`,
        type: 'success',
      })
    } catch (error) {
      actionNotify({
        title: `Категория: ${id}.`,
        message: `Ошибка обновления изображение категории: ${error.message}`,
        type: 'error',
      })
      CategoryStore.actionReject(error.message || error)
    } finally {
      AppStore.pending(false)
    }
  },
}
