import { ProductReview } from '@foods-n-goods/server/generated/schema'
import Content from 'components/Content'
import ViewField from 'components/Fields/ViewField'
import ViewGridBlock from 'components/ViewGridBlock'

interface ReviewViewAnswersProps {
  review: ProductReview
}

function ReviewViewAnswers(props: ReviewViewAnswersProps) {
  const {
    review: { staff, answer },
  } = props
  if (!staff) return null

  return (
    <Content>
      <ViewGridBlock templateColumns="repeat(4, 1fr)" title="Ответы">
        <ViewField text="Автор" value={staff.name || staff.login} />
        <ViewField text="Ответ" value={answer} gridColumnEnd="span 3" />
      </ViewGridBlock>
    </Content>
  )
}

export default ReviewViewAnswers
