import React from 'react'

import { Order } from '@foods-n-goods/server/generated/schema'
import { Text } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'

export function OrderListTableClientCell(ctx: TableTypes.TableCellContext<Order>) {
  const { row } = ctx
  const { client, marketId } = row
  const alias =
    client.markets.find((m) => m.id === marketId && m.alias)?.alias ?? client.name

  const currentDisplayMode = useSelector((state) => state.order.displayMode, objectEqual)

  return (
    <Text
      size={currentDisplayMode === 'short' ? 'xs' : 's'}
      weight={600}
      color="gray900"
      style={{
        wordBreak: 'break-word',
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
      }}
    >
      {alias}
    </Text>
  )
}
