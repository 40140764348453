import React from 'react'

import { Text } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import { PurchaseExtended } from 'pages/purchases/data'

export function OrderCell(ctx: TableTypes.TableCellContext<PurchaseExtended>) {
  const { row } = ctx
  if (row._isExpandable || !ctx.row.parentOrderId) return null
  return (
    <Text size="s" color="gray400">
      #{ctx.row.parentOrderId}
    </Text>
  )
}
