import TableTypes from '@stage-ui/core/data/Table/types'

import { Staff } from '@foods-n-goods/server/generated/schema'

import { StaffListNameCell } from './StaffNameCell'
import { StaffListRoleCell } from './StaffRoleCell'
import { StaffListLoginCell } from './StaffLoginCell'
import { StaffListCreateDateCell } from './StaffCreateDateCell'
import { StaffListContextMenuCell } from './StaffContextMenu'

type StaffSectionsType = '' | 'office' | 'warehouse' | 'purchases' | 'couriers'

type StaffSection = {
  id: StaffSectionsType
  title: string
}

export type StaffExtended = Staff & {
  roleText: string
}

export const sections: StaffSection[] = [
  { id: '', title: 'Все' },
  { id: 'office', title: 'Офис' },
  { id: 'warehouse', title: 'Склад' },
  { id: 'purchases', title: 'Докупщики' },
  { id: 'couriers', title: 'Водители' },
]

export const columns: TableTypes.TableColumn<StaffExtended>[] = [
  {
    key: 'name',
    title: 'Пользователь',
    sort: 'ASC',
    render: StaffListNameCell,
  },
  {
    key: 'roleText',
    title: 'Роль',
    sort: 'DEFAULT',
    width: '12rem',
    render: StaffListRoleCell,
  },
  {
    key: 'login',
    title: 'Логин',
    sort: 'DEFAULT',
    width: '12rem',
    render: StaffListLoginCell,
  },
  {
    key: 'createDate',
    title: 'Дата создания',
    sort: 'DEFAULT',
    width: '10rem',
    render: StaffListCreateDateCell,
  },
  { key: '_context', width: '1.5rem', render: StaffListContextMenuCell },
]
