import productFields from '../fragments/productFields.gql'

export default `
mutation productDelete($id: String!) {
  productDelete(id: $id) {
    ...productFields
  }
}
${productFields}
`
