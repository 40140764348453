import React from 'react'

import { useTheme } from '@stage-ui/core'
import { ProgressBar, ProgressBarEntity } from 'components/ProgressBar'
import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'

export function OrdersProgressBar() {
  const theme = useTheme()
  const statCounters = useSelector((state) => state.order.statCounters, objectEqual)

  const total = statCounters.positionsTotal - statCounters.positionsNew
  const entities: ProgressBarEntity[] = [
    {
      title: 'Собрано',
      amount: statCounters.positionsDelivery,
      dotColor: theme.color.lightGreen[500].hex(),
      background: `linear-gradient(90deg, ${theme.color.teal[400].hex()} 0%, #8DE770 100%)`,
    },
    {
      title: 'В докупке',
      amount: statCounters.positionsPurchase,
      dotColor: theme.color.yellow[500].hex(),
      background: `linear-gradient(90deg, ${theme.color.yellow[400].hex()} 0%, ${theme.color.yellow[500].hex()} 100%)`,
    },
    {
      title: 'Собираются',
      amount: statCounters.positionsSorting,
      dotColor: theme.color.lightGreen[600].hex(),
      background: `linear-gradient(90deg, ${theme.color.lightGreen[500].hex()} 0%, ${theme.color.green[500].hex()} 100%)`,
      loading: true,
    },
    {
      title: 'К сборке',
      amount: statCounters.positionsForSorting,
      dotColor: theme.color.gray[300].hex(),
      background: theme.color.gray[300].hex(),
      loading: true,
    },
  ]

  return (
    <ProgressBar
      total={total}
      entities={entities}
      noDataText="Здесь будет отображаться ход сборки, когда на линии будут заказы"
    />
  )
}
