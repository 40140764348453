import React, { useEffect, useState } from 'react'

import { SelectItem, StaffInput } from '@foods-n-goods/server/generated/schema'
import { Button, Flexbox, Grid, modal, Select, TextField, Text } from '@stage-ui/core'
import { StaffActions } from 'actions'
import useSelector from 'hooks/useSelector'
import ViewInputField from 'components/Fields/ViewInputField'
import { ColorDot } from 'components/ColorDot'
import { objectEqual } from 'utils/objectEqual'
import request from 'requests/request'
import SelectTypes from '@stage-ui/core/control/Select/types'
import { StaffRole } from '@foods-n-goods/server/src/shared/enum'

type StaffCreateModalProps = {
  close: () => void
}

function StaffCreateModal(props: StaffCreateModalProps) {
  const { close } = props

  const { rolesData, marketsData, colorsData, individualsData } = useSelector(
    (state) => ({
      rolesData: state.app.meta?.roles || [],
      marketsData: state.app.meta?.markets || [],
      colorsData: state.app.meta?.colors || [],
      individualsData: state.app.marketIndividuals || [],
    }),
    objectEqual,
  )

  const [marketUsers, setMarketUsers] = useState<SelectTypes.Option<string>[]>([])

  const getMarketUsers = async () => {
    const clients = await request('marketUsers')
    if (clients) {
      const data = clients
        .slice()
        .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
        .map((c) => ({ text: c.name, value: c.exId }))
      setMarketUsers(data)
    }
  }

  useEffect(() => {
    getMarketUsers()
  }, [])

  const roles = rolesData
    .slice()
    .sort((a, b) => (a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1))

  const markets = marketsData
    .slice()
    .sort((a, b) => (a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1))

  const colors = colorsData
    .slice()
    .sort((a, b) => (a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1))

  const individuals = individualsData
    .slice()
    .sort((a, b) => {
      if (a.category.trim().toLowerCase() === b.category.trim().toLowerCase()) {
        return a.fullName.toLowerCase() > b.fullName.toLowerCase() ? 1 : -1
      }
      return a.category.toLowerCase() > b.category.toLowerCase() ? 1 : -1
    })
    .map((i) => ({
      text: `[${i.category}] ${i.fullName}`,
      value: i.exId,
    }))

  const [payload, setPayload] = useState<StaffInput | null>(null)

  const handleCreate = async () => {
    if (!payload) return
    await StaffActions.create(payload, close)
  }

  const onChange: KeyValueChangeFunction<StaffInput> = (k, v) => {
    setPayload({ ...payload, [k]: v })
  }

  return (
    <Flexbox column>
      <Grid templateColumns="repeat(6, 1fr)" gap="1rem">
        <TextField
          defaultValue={payload?.login || ''}
          label="Логин"
          placeholder="Логин персонала"
          onChange={(e) => onChange('login', e.target.value)}
          gridColumn="span 3"
        />
        <TextField
          defaultValue={payload?.pwd || ''}
          label="Пароль"
          placeholder="Пароль персонала"
          onChange={(e) => onChange('pwd', e.target.value)}
          gridColumn="span 3"
        />
        <TextField
          defaultValue={payload?.name || ''}
          label="Имя"
          placeholder="Иванов Иван"
          onChange={(e) => onChange('name', e.target.value)}
          gridColumn="span 3"
        />
        <TextField
          defaultValue={payload?.tag || ''}
          label="Тэг"
          placeholder="Тэг"
          onChange={(e) => onChange('tag', e.target.value)}
          gridColumn="span 3"
        />
        <Select
          label="Роль"
          placeholder="Role"
          labelType="outside"
          options={roles}
          values={roles.filter((r) => r.value === payload?.role)}
          // @ts-expect-error
          onChange={(option) => onChange('role', option[0].value)}
          gridColumn="span 3"
        />
        <Select
          label="Цвет"
          labelType="outside"
          options={colors}
          values={colors.filter((c) => c.value === payload?.color)}
          leftChild={
            <ColorDot color={colors.find((c) => c.value === payload?.color)?.code} />
          }
          // @ts-expect-error
          renderOption={(option: SelectItem) => (
            <Flexbox alignItems="center">
              <ColorDot
                color={option.code}
                style={{
                  marginRight: '0.25rem',
                }}
              />
              <Text>{option.text}</Text>
            </Flexbox>
          )}
          // @ts-expect-error
          onChange={(option) => onChange('color', option[0].value)}
          gridColumn="span 3"
        />
        <ViewInputField
          label="Телефон"
          value={payload?.phone || ''}
          onChange={(value) => onChange('phone', value)}
          type="phone"
          leftChild="+7"
          gridColumn="span 2"
        />
        <ViewInputField
          label="Электронная почта"
          placeholder="@"
          value={payload?.email || ''}
          onChange={(value) => onChange('email', value)}
          gridColumn="span 4"
        />
        <Select
          gridColumnEnd="span 2"
          label="Магазин / Поставщик"
          labelType="outside"
          options={markets}
          values={markets.filter((m) => m.value === payload?.market)}
          // @ts-expect-error
          onChange={(option) => onChange('market', option[0].value)}
          gridColumn="span 2"
        />
        <Select
          clearable
          searchable
          label="Код внешней системы"
          labelType="outside"
          options={individuals}
          values={individuals.filter((x) => x.value === payload?.exId)}
          // @ts-expect-error
          onChange={(option) => onChange('exId', option[0].value)}
          onClear={() => onChange('exId', null)}
          gridColumn="span 4"
        />
        {payload?.role &&
          [StaffRole.PURCHASE, StaffRole.MAIN_PURCHASE].includes(payload.role) && (
            <Select
              clearable
              searchable
              label="Код внешней системы (Поставщики)"
              labelType="outside"
              options={marketUsers}
              values={marketUsers.filter((x) => x.value === payload?.exIndividualId)}
              // @ts-expect-error
              onChange={(option) => onChange('exIndividualId', option[0].value)}
              onClear={() => onChange('exIndividualId', null)}
              gridColumn="span 6"
            />
          )}
      </Grid>
      <Flexbox mt="xl" alignItems="center" justifyContent="flex-end">
        <Button decoration="text" label="Отмена" onClick={close} color="gray500" />
        <Button w="8.5rem" label="Подтвердить" onClick={handleCreate} ml="m" />
      </Flexbox>
    </Flexbox>
  )
}

export const openStaffCreateModal = (
  e?: React.MouseEvent<HTMLDivElement, MouseEvent>,
) => {
  e?.stopPropagation()
  e?.preventDefault()

  modal({
    w: '40rem',
    title: 'Создание персонала',
    overlayClose: false,
    render: (close) => <StaffCreateModal close={close} />,
  })
}
