import ClientSystem from '@foods-n-goods/client/system/types'
import { Product, ProductPage } from '@foods-n-goods/server/generated/schema'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import uniqueArray from 'utils/uniqueArray'

export type ProductFilter = {
  section: string
  search?: string

  nomenclaturePage: number
  nomenclaturePageSize: number
}

const initialProductFilter: ProductFilter = {
  section: 'products',
  nomenclaturePage: 1,
  nomenclaturePageSize: 40,
}

export interface ProductState {
  loading: ClientSystem.Loading
  error: RequestError | null
  data: Pick<ProductPage, 'total' | 'records'>
  filter: ProductFilter
}

export const initialState: ProductState = {
  loading: 'idle',
  error: null,
  data: {
    total: 0,
    records: [],
  },
  filter: initialProductFilter,
}

const productSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    loading(state) {
      state.loading = 'pending'
    },
    resolve(state, action: PayloadAction<ProductState['data']>) {
      state.loading = 'resolved'
      state.error = initialState.error
      state.data = action.payload
    },
    findResolve(state, action: PayloadAction<ProductState['data']>) {
      state.data.records = uniqueArray<Product>(
        state.data.records.concat(action.payload.records),
      ).sort((a, b) => Number(b.id) - Number(a.id))
    },
    reject(state, action: PayloadAction<ProductState['error']>) {
      state.loading = 'rejected'
      state.error = action.payload
    },

    /* ACTION */
    actionCreateResolve(state, action: PayloadAction<Product>) {
      state.data.records = [action.payload].concat(state.data.records)
    },
    actionDeleteResolve(state, action: PayloadAction<Product>) {
      state.data.records = state.data.records.filter(
        (product) => product.id !== action.payload.id,
      )
    },
    actionUpdateResolve(state, action: PayloadAction<Product>) {
      state.data.records = state.data.records.map((product) =>
        product.id === action.payload.id ? { ...product, ...action.payload } : product,
      )
    },
    setFilter(state, action: PayloadAction<Partial<ProductFilter>>) {
      state.filter = {
        ...state.filter,
        ...action.payload,
      }
    },

    clearFilter(state) {
      state.filter = initialProductFilter
    },
    clear(state) {
      return initialState
    },
  },
})

export default productSlice
