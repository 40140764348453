import React, { useMemo } from 'react'

import { OrderQualityItem } from '@foods-n-goods/server/generated/schema'
import { Divider, Flexbox, useTheme } from '@stage-ui/core'
import FlexboxTypes from '@stage-ui/core/layout/Flexbox/types'
import { Camera } from '@stage-ui/icons'

import useSelector from 'hooks/useSelector'

import { prettyPackDate } from 'utils/prettyDate'

import { openPhotoReportModal } from 'components/Modals/PhotoReport'

import { objectEqual } from 'utils/objectEqual'

import { OrderQualityActions } from 'actions'

import Quantity from './Quantity'

import Pallets from './Pallets'
import ProductName from './QualityProductName'
import WarehouseWorker from './QualityWarehouseWorker'
import QualityPackDateHeader from './QualityPackDateHeader'
import QualityClientName from './QualityClientName'
import QualityOrder from './QualityOrder'

type PhotoReportProps = FlexboxTypes.Props & {
  allReports: OrderQualityItem[]
  item: OrderQualityItem
}

export const PhotoReport: React.FC<PhotoReportProps> = (props) => {
  const { allReports, item } = props

  const theme = useTheme()

  const devices = useSelector((state) => state.devices.data, objectEqual)

  const {
    id,
    photo,
    clientName,
    orderId,
    quantityAfter,
    productName,
    quantityBefore,
    quantitySupposed,
    containers,
    tareWeight,
    warehouseWorkerName,
    packParams,
    ...rest
  } = item

  const scaleName = useMemo(() => {
    if (!packParams?.scaleId) {
      return ''
    }
    return devices.find((d) => Number(d.id) === packParams.scaleId)?.name || ''
  }, [packParams, devices])

  const packDate = prettyPackDate(item.packDate)

  const handleOpen = () => {
    if (!photo) return
    OrderQualityActions.setFilter({
      positionId: id,
    })
    openPhotoReportModal(allReports)
  }

  return (
    <Flexbox
      column
      w="100%"
      borderColor="gray200"
      borderStyle="solid"
      borderWidth="1px"
      borderRadius="0.25rem"
      style={{
        overflow: 'hidden',
      }}
      {...rest}
    >
      <Flexbox
        w="100%"
        h="15.5rem"
        flexShrink={0}
        textAlign="center"
        display="flex"
        centered
        onClick={handleOpen}
        style={[
          [
            {
              background: theme.color.gray[400].hex(),
            },
            photo && {
              background: `url("${photo}") 50% 50% / cover no-repeat`,
              justifyContent: 'center',
              ':hover': {
                '[id^=expand]': {
                  opacity: 1,
                  pointerEvents: 'all',
                },
              },
            },
          ],
        ]}
      >
        <QualityPackDateHeader packDate={packDate} scaleName={scaleName} />
        {!photo && <Camera size="6rem" color="gray200" />}
      </Flexbox>
      <Flexbox column px="l" pt="1rem" pb="l">
        <Flexbox mt="s" justifyContent="space-between" alignItems="center">
          <WarehouseWorker>{warehouseWorkerName || '-'}</WarehouseWorker>
          <Pallets containers={containers} />
        </Flexbox>
        <ProductName mt="0.875rem" mb="s">
          {productName}
        </ProductName>
        <Flexbox alignItems="center" justifyContent="space-between">
          <QualityClientName name={clientName} />
          <QualityOrder id={orderId} button />
        </Flexbox>
        <Divider color="gray300" mt="s" mb="1.875rem" />
        <Quantity
          quantityBefore={quantityBefore}
          quantityAfter={quantityAfter}
          quantitySupposed={quantitySupposed}
          tareWeight={tareWeight}
        />
      </Flexbox>
    </Flexbox>
  )
}
