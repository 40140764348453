import moment from 'moment'

export const humanize = {
  seconds: (sec: number = 0): string => {
    const time = moment.duration(sec, 'seconds')

    if (time.years() > 0) {
      return `${time.years()} год ${time.months()} мес`
    }
    if (time.months() > 0) {
      return `${time.months()} мец ${time.days()} день`
    }
    if (time.days() > 0) {
      return `${time.days()} д ${time.hours()} час`
    }
    if (time.hours() > 0) {
      return `${time.hours()} ч ${time.minutes()} мин`
    }
    if (time.minutes() > 0) {
      return `${time.minutes()} мин`
    }
    return 'Меньше минуты'
  },
  meters: (len: number = 0): string => {
    return `${(len / 1000).toFixed(1)} км`
  },
}
