import React from 'react'

import { ProductReview } from '@foods-n-goods/server/generated/schema'
import Content from 'components/Content'
import ViewField from 'components/Fields/ViewField'
import Rater from 'components/Rater'
import ViewGridBlock from 'components/ViewGridBlock'
import moment from 'moment'

import ReviewViewHeader from './Header'

interface ReviewViewGeneralProps {
  modal?: boolean
  review: ProductReview
}

function ReviewViewGeneral(props: ReviewViewGeneralProps) {
  const {
    modal,
    review: {
      product,
      order,
      createDate,
      rating,
      moderateDate,
      published,
      description,
      text,
    },
  } = props

  return (
    <Content>
      <ReviewViewHeader modal={modal} />
      <ViewGridBlock templateColumns="repeat(3, 1fr)">
        <ViewField text="Заказ" value={order.id} />
        <ViewField
          text="Дата отзыва"
          value={moment(createDate).format('DD MMMM, YYYY')}
        />
        <ViewField
          text="Дата модерации"
          value={moderateDate && moment(moderateDate).format('DD MMMM, YYYY')}
        />
        <ViewField text="Продукт" value={product.name} />
        <ViewField text="Рейтинг" value={<Rater rating={rating} />} />
        <ViewField text="Опубликован" value={published ? 'Да' : 'Нет'} />
        <ViewField text="Краткое описание" value={description} gridColumnEnd="span 3" />
        <ViewField text="Отзыв" value={text} gridColumnEnd="span 3" />
      </ViewGridBlock>
    </Content>
  )
}

export default ReviewViewGeneral
