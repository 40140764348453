export default `
    query ($marketId: String) {
        marketIndividuals(marketId: $marketId) {
            id
            fullName
            category
            exId
        }
    }
`
