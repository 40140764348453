import { useEffect } from 'react'

import { StaffRole } from '@foods-n-goods/server/src/shared/enum'
import { GeoData } from '@foods-n-goods/server/src/socket/geo'
import { SocketType } from '@foods-n-goods/server/src/socket/types'
import { DeliveryActions } from 'actions'
import useSelector from 'hooks/useSelector'
import { io } from 'requests/socket'

import { objectEqual } from 'utils/objectEqual'

import { ym } from '../hooks/useYandexMaps'

export function CourierRenderHelper() {
  const currentCourier = useSelector(
    (state) => state.delivery.currentCourier,
    objectEqual,
  )

  const couriers = useSelector(
    (state) => state.staff.data.filter((d) => d.role.value === StaffRole.COURIER),
    objectEqual,
  )

  /**
   * Render couriers on map
   */
  useEffect(() => {
    if (ym.ref) {
      ym.ref.courierPlacemarkRemoveAll()
      couriers
        .filter((courier) => {
          if (currentCourier && currentCourier !== courier?.id) {
            return false
          }
          return true
        })
        .forEach((courier, index) => {
          const coords: [number, number] = [
            Number(courier.lastGeoPosition?.coords.latitude),
            Number(courier.lastGeoPosition?.coords.longitude),
          ]
          if (coords[0] && coords[1]) {
            ym.ref?.courierPlacemarkCreate(
              coords,
              Number(courier.lastGeoPosition?.coords.heading || '0'),
              courier,
            )
          }
        })
    }
  }, [couriers, currentCourier])

  /**
   * Render gps track
   */
  useEffect(() => {
    ym.ref?.trackClear()
    if (currentCourier) {
      setTimeout(() => {
        io.emit(
          SocketType.Events.GetGeoTrackData,
          currentCourier,
          (points: Array<[number, number]>) => {
            if (points.length) {
              const courier = couriers.find((c) => c.id === currentCourier)
              if (courier) {
                ym.ref?.trackCreate(courier, points, {
                  strokeColor: courier.color?.code || '#3e9eff',
                })
              }
            }
          },
        )
      }, 250)
    }
  }, [currentCourier])

  /**
   * Handle track and couriers update
   */
  useEffect(() => {
    if (ym.ref) {
      io.on(SocketType.Events.GeoData, (data: GeoData) => {
        const courier = DeliveryActions.updateCourierPosition(data)
        if (ym.ref && courier) {
          const point: [number, number] = [
            Number(data.info.coords.latitude),
            Number(data.info.coords.longitude),
          ]
          // update courier track
          ym.ref.trackUpdate(courier.id, point, data.info.coords.speed)
          // update courier position
          ym.ref.courierPlacemarkUpdate(courier, point)
        }
      })
      io.emit(SocketType.Events.SubscribeGeoData)
    }
    return () => {
      io.off(SocketType.Events.GeoData)
      io.emit(SocketType.Events.UnsubscribeGeoData)
    }
  }, [])

  return null
}
