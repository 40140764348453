import React, { useEffect } from 'react'

import { ProductActions } from 'actions'
import BreadCrumbs from 'components/BreadCrumbs/BreadCrumbs'
import { Section } from 'components/Section'
import useSelector from 'hooks/useSelector'
import { Route, Switch, useLocation } from 'react-router-dom'

import { objectEqual } from 'utils/objectEqual'

import ProductsTable from './Table'
import CategoryView from './View/CategoriesView'
import ProductView from './View/ProductsView'

export function Products() {
  const location = useLocation()

  const { filter, products, categories } = useSelector(
    (state) => ({
      filter: state.product.filter,
      products: state.product.data.records,
      categories: state.category.data.records,
    }),
    objectEqual,
  )

  const id = location.pathname.split('/')[filter.section === 'products' ? 2 : 3]
  const sectionPath = location.pathname.split('/')[1] + location.search

  useEffect(() => {
    if (filter.section === 'products') {
      ProductActions.fetch({
        page: filter.nomenclaturePage,
        pageSize: filter.nomenclaturePageSize,
        search: filter.search,
      })
    }
  }, [filter])

  useEffect(() => {
    return () => {
      ProductActions.clearFilter()
    }
  }, [])

  const getProductName = (() => {
    const product = products.find((p) => p.id === id)
    const category = categories.find((c) => c.id === id)

    return product?.name || category?.name || ''
  })()

  return (
    <Section
      titleComponent={
        <BreadCrumbs
          path={id}
          selectedItem={getProductName}
          sectionName="Номенклатура"
          sectionPath={sectionPath}
        />
      }
    >
      <Switch location={location}>
        <Route exact path="/products" component={ProductsTable} />
        <Route exact path="/products/:id" component={ProductView} />
        <Route exact path="/products/categories/:id" component={CategoryView} />
      </Switch>
    </Section>
  )
}
