import React from 'react'

import { Flexbox } from '@stage-ui/core'
import { ButtonGroup } from 'components/ButtonGroup'
import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'
import { DeliveryActions } from 'actions'
import { LogisticsCourierStatus } from 'store/delivery'

export function LogisticsCouriersFilter() {
  const { tab, staffs, deliveryOrders } = useSelector(
    (state) => ({
      tab: state.delivery.filter.couriersTab,
      staffs: state.staff.data,
      deliveryOrders: state.delivery.orderPage.records.map((order) => ({
        ...order,
        positions: [],
      })),
    }),
    objectEqual,
  )

  let couriersAll = 0
  let couriersOnline = 0
  let couriersHasOrders = 0

  staffs.forEach((staff) => {
    if (staff.role.code === 'COURIER') {
      couriersAll++
      if (staff.online) {
        couriersOnline++
      }
      if (deliveryOrders.some((order) => order.courier?.id === staff.id)) {
        couriersHasOrders++
      }
    }
  })

  const statuses: Record<LogisticsCourierStatus, string> = {
    all: `Все ${couriersAll}`,
    online: `Онлайн ${couriersOnline}`,
    hasOrders: `С заказами ${couriersHasOrders} `,
  }

  return (
    <Flexbox w="100%" mb="s">
      <ButtonGroup
        data={Object.keys(statuses).map((statusTab) => ({
          active: tab === statusTab,
          text: statuses[statusTab as LogisticsCourierStatus],
          onClick: () => {
            DeliveryActions.setFilter({
              couriersTab: statusTab as LogisticsCourierStatus,
            })
          },
        }))}
      />
    </Flexbox>
  )
}
