import React, { useMemo, useRef, useState } from 'react'

import { Button, Drop, Flexbox, Grid } from '@stage-ui/core'
import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'
import { PersonAdd } from '@stage-ui/icons'
import DropTypes from '@stage-ui/core/layout/Drop/types'
import { useMouseDrop } from 'components/MouseDrop'
import { PurchasesActions } from 'actions'

export function PurchaserFilter() {
  const filter = useSelector((state) => state.purchases.filter, objectEqual)
  const purchasers = useSelector(
    (state) => state.staff.data.filter((staff) => [9, 10].includes(staff.role.value)),
    objectEqual,
  )

  const targetRef = useRef(null)
  const dropRef = useRef<DropTypes.Ref>(null)

  const [dropVisible, setDropVisible] = useState(false)
  const hint = useMouseDrop(
    !dropVisible && filter.section !== 'byProducts' && 'Фильтрация по докупщику',
    {
      drop: {
        align: 'bottom',
        justify: 'auto-horizontal',
      },
      popover: {
        arrowSize: '0',
      },
    },
  )

  const options = useMemo(
    () =>
      [{ text: 'Все докупщики', value: '' }].concat(
        purchasers.map((staff) => ({
          value: staff.id,
          text: staff.name || staff.login || '',
        })),
      ),
    [purchasers],
  )

  return (
    <>
      <Flexbox {...hint}>
        <Button
          p={0}
          w="2.5rem"
          h="2.5rem"
          decoration="outline"
          color={filter.purchaserId ? 'primary' : 'gray400'}
          borderColor={filter.purchaserId ? 'green500' : 'gray200'}
          backgroundColor={filter.purchaserId ? 'white' : 'transparent'}
          onClick={() => {
            hint.onMouseLeave?.()
            setTimeout(() => {
              setDropVisible(!dropVisible)
            }, 1)
          }}
          disabled={filter.section === 'byProducts'}
          ref={targetRef}
        >
          <PersonAdd size="1.25rem" />
        </Button>
      </Flexbox>
      <Drop
        spacing={10}
        visible={dropVisible}
        ref={dropRef}
        target={targetRef}
        animation={{
          type: 'slide',
          reverse: !!targetRef.current,
          duration: 100,
        }}
        onClickOutside={(e, outTarget) => {
          if (outTarget) {
            e.stopPropagation()
            setDropVisible(false)
          }
        }}
      >
        <Grid
          borderRadius="m"
          autoFlow="row"
          gap="0.0625rem"
          shadow="m"
          p="s"
          backgroundColor="white"
        >
          {options.map((option) => (
            <Button
              key={option.value}
              decoration={filter.purchaserId === option.value ? 'filled' : 'text'}
              textAlign="left"
              w="100%"
              size="s"
              px="s"
              label={option.text}
              onClick={() => {
                PurchasesActions.setFilter({ purchaserId: option.value })
                setDropVisible(false)
              }}
              overrides={{
                container: {
                  justifyContent: 'flex-start',
                },
              }}
            />
          ))}
        </Grid>
      </Drop>
    </>
  )
}
