import React from 'react'

import { OrderQualityItem } from '@foods-n-goods/server/generated/schema'
import TableTypes from '@stage-ui/core/data/Table/types'
import { ColorText } from 'components/ColorText'
import { Flexbox } from '@stage-ui/core'

const QualityPalleteCell = (ctx: TableTypes.TableCellContext<OrderQualityItem>) => {
  const {
    row: { containers },
  } = ctx

  if (!containers) {
    return null
  }

  return (
    <Flexbox wrap="wrap" style={{ gap: '0.25rem' }}>
      {containers?.split(',').map((containerName, index) => (
        <ColorText
          key={`${containerName}${index}`}
          weight={500}
          color="gray900"
          size="xs"
          borderColor="gray300"
          p="xs s"
          backgroundColor="white"
        >
          {containerName}
        </ColorText>
      ))}
    </Flexbox>
  )
}
export default QualityPalleteCell
