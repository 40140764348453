import { useEffect } from 'react'

import useSelector from 'hooks/useSelector'

import { OrderDelivery } from 'store/delivery'

import { objectEqual } from 'utils/objectEqual'

import { StaffRole } from '@foods-n-goods/server/src/shared/enum'

import { ym } from '../hooks/useYandexMaps'

export function OrderRenderHelper() {
  const couriers = useSelector(
    (state) => state.staff.data.filter((staff) => staff.role.value === StaffRole.COURIER),
    objectEqual,
  )

  const deliveryRecords = useSelector(
    (state) =>
      state.delivery.orderPage.records.map((order) => ({
        id: order.id,
        courier: couriers.find((c) => c.id === order.courier?.id),
        deliveryAddress: order.deliveryAddress,
        status: order.status,
        sequenceOrder: order.sequenceOrder,
      })),
    objectEqual,
  )

  const currentCourier = useSelector(
    (state) => state.delivery.currentCourier,
    objectEqual,
  )

  /**
   * Render orders on map
   */
  useEffect(() => {
    if (ym.ref) {
      ym.ref.orderPlacemarkRemoveAll()
      const orderGroups: Record<string, OrderDelivery[]> = {}
      const displayOrders = deliveryRecords.filter((order) => {
        const { courier } = order
        // Hide order if selected courier not
        // associated with particular order
        if (currentCourier && courier?.id && currentCourier !== courier.id) {
          return false
        }
        // Skip / no address
        if (
          !order.deliveryAddress.address ||
          !order.deliveryAddress.lat ||
          !order.deliveryAddress.lat
        ) {
          return false
        }
        return true
      })

      displayOrders.forEach((order) => {
        const coords: [number, number] = [
          Number(order.deliveryAddress.lat),
          Number(order.deliveryAddress.lng),
        ]
        if (coords[0] && coords[1]) {
          const sameCoordsOrders = displayOrders.filter(
            (o) =>
              order.deliveryAddress.lat === o.deliveryAddress.lat &&
              order.deliveryAddress.lng === o.deliveryAddress.lng,
          )
          // if order is single, just add to map
          if (sameCoordsOrders.length === 1) {
            return ym.ref?.orderPlacemarkCreate(coords, [order as OrderDelivery])
          }
          // if order has couple
          // we create group of orders
          const groupKey = coords.join(',')
          if (!orderGroups[groupKey]) {
            orderGroups[groupKey] = []
          }
          orderGroups[groupKey].push(order as OrderDelivery)
        }
      })
      Object.keys(orderGroups).map((key) => {
        const orders: OrderDelivery[] = orderGroups[key]
        const firstOrder = orders[0]
        const coords: [number, number] = [
          Number(firstOrder.deliveryAddress.lat),
          Number(firstOrder.deliveryAddress.lng),
        ]
        return ym.ref?.orderPlacemarkCreate(coords, orders)
      })
    }
  }, [deliveryRecords, currentCourier])

  return null
}
