import { notify } from '@stage-ui/core'

import ActionNotifyContent from './Content'

export interface ActionNotifyProps {
  title: string
  message: string
  image?: string | null
  type?: 'success' | 'error' | 'warning'
}

const actionNotify = (props: ActionNotifyProps) =>
  notify({
    title: '',
    message: '',
    timeout: 5000,
    placement: 'bottomLeft',
    render: () => <ActionNotifyContent {...props} />,
  })

export default actionNotify
