import orderFields from '../fragments/orderFields.gql'

export default `
    mutation orderSort($ids: [String!]!) {
        orderSort(ids: $ids) {
            ...orderFields
        }
    }
    ${orderFields}
`
