/* eslint-disable no-param-reassign */
import React from 'react'

import { Client } from '@foods-n-goods/server/generated/schema'
import { Text } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'

export function AddressCell(ctx: TableTypes.TableCellContext<Client>) {
  const { row } = ctx
  let address = ''

  if (row.deliveryAddresses[0]) {
    address =
      row.deliveryAddresses.reduce((acc, val) => {
        if (val.isDefault) {
          if (val.address) acc = acc.concat(val.address)
          if (val.entrance) acc = acc.concat(`, ${val.entrance}`)
          if (val.apartment) acc = acc.concat(`, ${val.apartment}`)
          if (val.intercom) acc = acc.concat(`, ${val.intercom}`)
        }
        return acc
      }, '') || ''
  }

  return (
    <Text
      size="s"
      weight={!address ? 600 : 500}
      decoration={!address ? 'underline' : 'none'}
      color={!address ? 'red500' : 'gray500'}
      ellipsis
    >
      {!address ? 'Адрес доставки не указан!' : address}
    </Text>
  )
}
