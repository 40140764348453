import marketConfigFields from '../fragments/marketConfigFields.gql'

export default `
    query marketConfig {
        marketConfig {
            ...marketConfigFields
        }
    }
    ${marketConfigFields}
`
