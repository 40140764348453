import { OrderAddProductCustom } from '.'

import wordForNum from '@foods-n-goods/client/utils/wordForNum'
import { Grid, Text } from '@stage-ui/core'
import { rub } from 'utils/rub'

type OrderAddPositionsSubtotalProps = {
  addProducts: OrderAddProductCustom[]
}

export const OrderAddPositionsSubtotal: React.FC<OrderAddPositionsSubtotalProps> = (
  props,
) => {
  const { addProducts } = props

  const total = addProducts.reduce((a, b) => {
    const avgWeight =
      b.availableUnits.find((au) => au.value === b.unit.value)?.weight || 1
    const price = parseFloat(b.price) * parseFloat(b.quantity || '0') * avgWeight

    return (a += price)
  }, 0)

  return (
    <Grid templateColumns="9rem 1fr" gap="0.25rem 1rem">
      <Text gridColumn="1" size="s" color="gray600">
        Всего к добавлению:
      </Text>
      <Text gridColumn="2" size="s" color="gray900" weight={700}>
        {`${addProducts.length} ${wordForNum(addProducts.length, [
          'товар',
          'товара',
          'товаров',
        ])}`}
      </Text>
      <Text gridColumn="1" size="s" color="gray600">
        На сумму:
      </Text>
      <Text gridColumn="2" size="s" color="gray900" weight={700}>
        {rub(total)}
      </Text>
    </Grid>
  )
}
