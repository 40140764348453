import React from 'react'

import { Text } from '@stage-ui/core'
import TextTypes from '@stage-ui/core/content/Text/types'
import { ShoppingCart } from '@stage-ui/icons'
import { openOrderModal } from 'components/Modals/OrderView'
import { useLocation } from 'react-router-dom'
import { OrderQualityActions } from 'actions'

type QualityOrderProps = {
  id: string
  button?: boolean
  close?: () => void
} & TextTypes.Props

const QualityOrder: React.FC<QualityOrderProps> = (props) => {
  const { id, button, close, ...textProps } = props

  const location = useLocation()

  const isOrderPage = location.pathname.split('/')[1] === 'orders'

  const handleClick = () => {
    if (isOrderPage) {
      close?.()
      OrderQualityActions.setFilter({
        section: undefined,
      })
    } else {
      openOrderModal(id)
    }
  }

  if (button) {
    return (
      <ShoppingCart
        color="gray600"
        onClick={(e) => {
          e.stopPropagation()
          handleClick()
        }}
      />
    )
  }

  return (
    <Text size="xs" color="gray600" weight={400} onClick={handleClick} {...textProps}>
      #{id}
    </Text>
  )
}

export default QualityOrder
