import React, { useEffect, useState } from 'react'

import passIcon from '@foods-n-goods/client/assets/icons/Lock-filled.svg'
import userIcon from '@foods-n-goods/client/assets/icons/UI-User.svg'
import { Button, Flexbox, Spinner, Text, TextField, useTheme } from '@stage-ui/core'
import TextFieldTypes from '@stage-ui/core/control/TextField/types'
import { AppActions } from 'actions'
import Logo from 'components/Logo'
import SVG from 'components/SVG'
import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'

function Field(props: TextFieldTypes.Props) {
  const theme = useTheme()

  return (
    <TextField
      {...props}
      size="xl"
      overrides={{
        container: {
          boxShadow: '0 1.5rem 3rem -0.5rem rgba(0,0,0,0.05)',
        },
        label: {
          color: theme.color.light.hex(),
        },
        field: {
          fontSize: '1rem',
          background: theme.color.surface.hex(),
        },
      }}
    />
  )
}

export function SignIn() {
  const error = useSelector(({ app }) => app.userError, objectEqual)

  const [loading, setLoading] = useState(false)
  const [login, setLogin] = useState('')
  const [pwd, setPwd] = useState('')

  const handleSignIn = () => {
    if (!login || !pwd || loading) return
    setLoading(true)
    AppActions.signIn(login, pwd)
  }

  useEffect(() => {
    if (error && loading) setLoading(false)
  }, [error])

  return (
    <Flexbox alignItems="center" justifyContent="center" h="100%">
      <Flexbox column style={{ width: '24rem' }}>
        <Logo alignSelf="center" mb="2rem" />
        <Field
          disabled={loading}
          placeholder="Имя пользователя"
          leftChild={
            <SVG
              src={userIcon}
              style={[
                {
                  width: '1.5rem',
                  height: '1.5rem',
                  marginRight: '0.25rem',
                  fill: 'transparent',
                  stroke: '#bbbbbb',
                },
              ]}
            />
          }
          value={login}
          onChange={(e) => setLogin(e.target.value)}
          mb="1rem"
        />
        <Field
          disabled={loading}
          placeholder="Пароль"
          leftChild={
            <SVG
              src={passIcon}
              style={[
                {
                  width: '1.5rem',
                  height: '1.5rem',
                  marginRight: '0.25rem',
                  fill: 'transparent',
                  stroke: '#bbbbbb',
                },
              ]}
            />
          }
          type="password"
          value={pwd}
          onChange={(e) => setPwd(e.target.value)}
          mb="1rem"
          onEnter={handleSignIn}
        />
        <Button
          onClick={handleSignIn}
          children={loading ? <Spinner color="onPrimary" /> : 'Войти'}
          w="100%"
          h="3rem"
        />
        {error && <Text mt="1rem" color="error" children={error.message} />}
      </Flexbox>
    </Flexbox>
  )
}
