import React from 'react'

import { Text } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import { PurchaseExtended } from 'pages/purchases/data'
import { useStaff } from 'hooks/useStaff'

export function PurchaserCell(ctx: TableTypes.TableCellContext<PurchaseExtended>) {
  const { row } = ctx
  const buyer = useStaff(row._isExpandable ? row._purchasers[0]?.id : row.buyer?.id)

  // fake group buyers
  if (row._isExpandable) {
    if (!row._purchasers.length) {
      return (
        <Text size="s" color="gray900" weight={700}>
          Нет докупщиков
        </Text>
      )
    }
    return (
      <Text size="s" color={buyer?.color?.code || 'gray900'} weight={700}>
        {buyer?.name}{' '}
        {row._purchasers.length > 1 && (
          <Text size="s" color="gray600">
            [+{row._purchasers.length - 1}]
          </Text>
        )}
      </Text>
    )
  }

  return (
    <Text size="s" color={buyer ? buyer?.color?.code || 'gray900' : 'gray600'}>
      {buyer?.name || 'Нет докупщика'}
    </Text>
  )
}
