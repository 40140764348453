import React from 'react'

import { StaffExtended } from '.'

import { Flexbox, Text } from '@stage-ui/core'
import Avatar from 'components/Avatar'

import TableTypes from '@stage-ui/core/data/Table/types'

export const StaffListNameCell = (ctx: TableTypes.TableCellContext<StaffExtended>) => {
  const { row } = ctx

  return (
    <Flexbox alignItems="center">
      <Avatar
        size="2.625rem"
        user={{
          id: row.id,
          name: row.name || row.login || row.role.text,
          color: row.color?.code || '#333',
          online: !!row.online,
          photo: row.photo,
          updateDate: row.updateDate,
        }}
      />
      <Text ml="m" color="gray900" weight={600} size="s">
        {row.name}
      </Text>
    </Flexbox>
  )
}
