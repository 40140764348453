export enum StaffRole {
  SUPERUSER = 1,
  CONTENT_MANAGER = 2,
  OPERATOR = 3,
  COURIER = 4,
  WAREHOUSE = 5,
  ADMIN = 8,
  PURCHASE = 9,
  MAIN_PURCHASE = 10,
  MANAGER = 11,
  STOREKEEPER = 12,
}
