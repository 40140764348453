import ClientSystem from '@foods-n-goods/client/system/types'
import {
  ProductGroupPage,
  QueryProductGroupArgs,
} from '@foods-n-goods/server/generated/schema'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface CategoryState {
  loading: ClientSystem.Loading
  data: ProductGroupPage
  error: RequestError | null
  productCategoryArgs: QueryProductGroupArgs
  actionError: RequestError | null
}

export const initialState: CategoryState = {
  loading: 'idle',
  data: {
    page: 1,
    pageSize: 1000,
    total: 0,
    records: [],
    search: '',
  },
  error: null,
  productCategoryArgs: {},
  actionError: null,
}

const categorySlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    loading(state) {
      state.loading = 'pending'
      state.data = {
        ...state.data,
      }
    },
    resolve(state, action: PayloadAction<CategoryState['data']>) {
      state.loading = 'resolved'
      state.data = action.payload
      state.error = initialState.error
    },

    reject(state, action: PayloadAction<CategoryState['error']>) {
      state.loading = 'rejected'
      state.error = action.payload
    },

    /* ACTION */
    actionCreateResolve(state) {
      state.actionError = initialState.actionError
    },
    actionDeleteResolve(state) {
      state.actionError = initialState.actionError
    },
    actionUpdateResolve(state) {
      state.actionError = initialState.actionError
    },
    actionReject(state, action: PayloadAction<CategoryState['actionError']>) {
      state.actionError = action.payload
    },
    clear(state) {
      return initialState
    },
  },
})

export default categorySlice
