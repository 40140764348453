import T, { ChatTypes } from '@foods-n-goods/server/src/socket/chat/types'
import { notify } from '@stage-ui/core'
import { EXPRESS_HOST } from 'env'
import Push from 'push.js'
import { io } from 'requests/socket'
import { history } from 'Router/MainView'
import store, { ChatStore } from 'store'
import actionNotify from 'utils/actionNotify'

import { debounce } from '@foods-n-goods/client/utils/debounce'
import { ChatFilter } from 'store/chat'

// @ts-ignore
function emit<E extends any>(
  event: T.Actions,
  data: E['payload'],
  callback?: E['callback'],
) {
  io.emit(event, data, callback)
}

export default {
  getRooms() {
    emit<T.Data['GetRooms']>(T.Events.GetRooms, {}, (rooms, error) => {
      if (error) {
        ChatStore.reject(error)
        return actionNotify({
          title: 'Ошибка запроса списка диалогов.',
          message: error,
          type: 'error',
        })
      }

      ChatStore.roomsSet(rooms)
    })
  },

  getMessages(roomId: string): Promise<void> {
    return new Promise((resolve) => {
      ChatStore.roomActivity({ roomId, isFetchingHistory: true })
      emit<T.Data['GetMessages']>(T.Events.GetMessages, { roomId }, (messages, error) => {
        if (error) {
          ChatStore.reject(error)
          resolve()
          ChatStore.roomActivity({ roomId, isFetchingHistory: false })
          return actionNotify({
            title: 'Ошибка запроса сообщений для диалога.',
            message: error,
            type: 'error',
          })
        }
        ChatStore.messagesSet(messages)
        ChatStore.roomActivity({ roomId, isFetchingHistory: false })
        resolve()
      })
    })
  },

  roomGetIn(roomId: string) {
    emit<T.Data['RoomGetIn']>(T.Events.RoomGetIn, { roomId }, (room, error) => {
      if (error) {
        ChatStore.reject(error)
        return actionNotify({
          title: 'Ошибка присоединения к диалогу',
          message: error,
          type: 'error',
        })
      }
      room && ChatStore.roomUpdate(room)
    })
  },

  sendMessage(roomId: string, msg: string, file?: ChatTypes.File) {
    emit<T.Data['Message']>(
      T.Events.Message,
      {
        msg,
        file,
        roomId,
      },
      (message, error) => {
        if (error) {
          ChatStore.reject(error)
          return actionNotify({
            title: 'Ошибка отправки сообщения.',
            message: error,
            type: 'error',
          })
        }
        message && ChatStore.roomMessageAppend(message)
      },
    )
  },

  typing(roomId: string, msg: string) {
    emit<T.Data['Typing']>(T.Events.Typing, { roomId, msg })
  },

  messageRead(message: ChatTypes.Message) {
    emit<T.Data['Read']>(T.Events.Read, message)
    ChatStore.messageRead({ ...message, readTime: Date() })
    ChatStore.roomMessagesRead(message.roomId)
  },

  roomMessagesRead(roomId: ChatTypes.Message['roomId']) {
    const userId = store.getState().app.user?.id
    const room = store.getState().chat.rooms.find((r) => r.roomId === roomId)
    if (room) {
      ChatStore.roomMessagesRead(roomId)
      room.messages.forEach((message) => {
        if (!message.readTime && (!message.user || message.user.id === userId)) {
          emit<T.Data['Read']>(T.Events.Read, message)
          ChatStore.messageRead(message)
        }
      })
    }
  },

  createRoom(name: string, staffIds: string[], clientIds: string[]): Promise<T.Room> {
    return new Promise((resolve, reject) => {
      emit<T.Data['RoomCreate']>(
        T.Events.RoomCreate,
        { name, staffIds, clientIds },
        (room, error) => {
          if (error) {
            ChatStore.reject(error)
            reject(error)
            return actionNotify({
              title: 'Ошибка создания диалога.',
              message: error,
              type: 'error',
            })
          }
          ChatStore.roomAppend({
            ...room,
            messages: [],
            messagesUnread: false,
          })
          ChatStore.roomActivate(room.roomId)
          if (room) resolve(room)
        },
      )
    })
  },

  updateRoom(
    roomId: string,
    name: string,
    staffIds: string[],
    clientIds: string[],
  ): Promise<T.Room> {
    return new Promise((resolve, reject) => {
      emit<T.Data['RoomUpdate']>(
        T.Events.RoomUpdate,
        { roomId, name, staffIds, clientIds },
        (payload, error) => {
          if (error) {
            ChatStore.reject(error)
            reject(error)
            return actionNotify({
              title: 'Ошибка создания диалога.',
              message: error,
              type: 'error',
            })
          }
          ChatStore.roomUpdate(payload)
          if (payload) resolve(payload)
        },
      )
    })
  },

  deleteRoom(roomId: string): Promise<T.Room> {
    return new Promise((resolve, reject) => {
      emit<T.Data['RoomDelete']>(T.Events.RoomDelete, { roomId }, (payload, error) => {
        if (error) {
          ChatStore.reject(error)
          reject(error)
          return actionNotify({
            title: 'Ошибка создания диалога.',
            message: error,
            type: 'error',
          })
        }
        ChatStore.roomRemove(payload)
        if (payload) resolve(payload)
      })
    })
  },

  setFilter(filter: Partial<ChatFilter>) {
    ChatStore.setFilter(filter)
  },

  clearFilter() {
    ChatStore.clearFilter()
  },
}

io.on(T.Events.RoomCreateEvent, (payload: T.Data['RoomCreateEvent']['payload']) => {
  const { rooms } = store.getState().chat
  const alreadyExists = rooms.find((r) => r.roomId === payload.roomId)

  if (alreadyExists) return

  // Send join room channel
  emit<T.Data['RoomJoin']>(T.Events.RoomJoin, { roomId: payload.roomId })

  ChatStore.roomAppend({
    ...payload,
    messages: [],
    messagesUnread: false,
  })
})

io.on(T.Events.RoomUpdateEvent, (payload: T.Data['RoomUpdateEvent']['payload']) => {
  const roomExist = store
    .getState()
    .chat.rooms.find((room) => room.roomId === payload.roomId)
  if (roomExist) {
    ChatStore.roomUpdate(payload)
  } else {
    ChatStore.roomAppend({
      ...payload,
      messages: [],
      messagesUnread: false,
    })
    emit<T.Data['RoomJoin']>(T.Events.RoomJoin, { roomId: payload.roomId })
  }
})

io.on(T.Events.RoomDeleteEvent, (payload: T.Data['RoomDeleteEvent']['payload']) => {
  // Send leave room to leave channel
  emit<T.Data['RoomLeave']>(T.Events.RoomLeave, { roomId: payload.roomId })
  // Remove from redux
  ChatStore.roomRemove(payload)
})

io.on(T.Events.MessageEvent, (payload: T.Data['MessageEvent']['payload']) => {
  const fromName = payload.user?.name || 'Unknown sender'
  ChatStore.roomMessageAppend(payload)
  ChatStore.roomActivity({ roomId: payload.roomId, isTyping: false })

  if (payload.type === 2) {
    alert(payload.msg)
  }

  Push.create(`Cообщение от ${fromName}`, {
    body: payload.msg,
    onClick: () => {
      window.open(`${EXPRESS_HOST}/dialogs?id=${payload.roomId}`)
    },
  })

  if (!location.pathname.match('/dialogs')) {
    notify({
      title: fromName,
      message: payload.msg,
      timeout: 5000,
      onClick: () => history.push(`/dialogs?id=${payload.roomId}`),
    })
  } else if (store.getState().chat.activeRoomId === payload.roomId) {
    ChatStore.messageRead(payload)
  }
})

io.on(T.Events.ReadEvent, (payload: T.Data['ReadEvent']['payload']) => {
  ChatStore.messageRead(payload)
  ChatStore.roomMessagesRead(payload.roomId)
})

const roomsActivitiesTimers: Record<string, Function> = {}

io.on(T.Events.TypingEvent, (payload: T.Data['TypingEvent']['payload']) => {
  const { roomId } = payload
  ChatStore.roomActivity({ roomId: payload.roomId, isTyping: true })

  if (!roomsActivitiesTimers[roomId]) {
    const clearTyping = () => {
      ChatStore.roomActivity({ roomId: payload.roomId, isTyping: false })
      delete roomsActivitiesTimers[roomId]
    }
    roomsActivitiesTimers[roomId] = debounce(clearTyping, 2000)
  }

  roomsActivitiesTimers[roomId]()
})
