import orderFields from '../fragments/orderFields.gql'

export default `
    query orders($page: Float, $pageSize: Float, $search: String, $filter: OrderFilter) {
        orders(page: $page, pageSize: $pageSize search: $search, filter: $filter) {
            total
            records {
                ...orderFields
            }
        }
    }
    ${orderFields}
`
