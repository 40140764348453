import React from 'react'

import { Order } from '@foods-n-goods/server/generated/schema'
import { Flexbox, Text, useTheme, CircularProgress } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import { useMouseDrop } from 'components/MouseDrop'

import { AlertCircle, CloseCircle, Cube } from '@stage-ui/icons'

import { OrderBadgeHint } from './OrderBadgeHint'

export function OrderListTablePurchasesCell({ row }: TableTypes.TableCellContext<Order>) {
  const { color } = useTheme()
  const {
    inPurchase,
    notReadyForDelivery,
    readyForDelivery,
    posRejected,
    posDeliveredPartial,
  } = row.positionsStat

  const hintComponent = useMouseDrop(<OrderBadgeHint order={row} />, {
    drop: {
      align: 'top',
    },
    popover: {
      align: 'bottom',
      arrowOffset: 'calc(-1.375rem - 1px)',
    },
  })

  const innerIcon = (() => {
    if (posRejected > 0) {
      return <CloseCircle type="filled" color="red500" />
    }
    if (posDeliveredPartial > 0) {
      return <AlertCircle type="filled" color="orange500" />
    }
    if (inPurchase > 0) {
      return <Cube color="yellow500" />
    }
    return null
  })()

  return (
    <Flexbox alignItems="center" justifyContent="space-between" {...hintComponent}>
      <Flexbox mr="xs" alignItems="center" w="1.5rem">
        <CircularProgress
          strokeBackgroundColor={color.gray[300].hex()}
          color={color.green[600].hex()}
          size="1.5rem"
          overrides={{
            text: {
              '> span': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              },
            },
          }}
          value={(readyForDelivery / (readyForDelivery + notReadyForDelivery)) * 100}
        >
          {innerIcon}
        </CircularProgress>
      </Flexbox>
      <Text size="xs" color="gray500" weight={600}>
        {readyForDelivery}/{readyForDelivery + notReadyForDelivery}
      </Text>
    </Flexbox>
  )
}
