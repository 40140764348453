import React, { FC, ReactNode, useState } from 'react'

import { Flexbox, Grid, Paragraph } from '@stage-ui/core'
import GridTypes from '@stage-ui/core/layout/Grid/types'
import { ArrowIosDown } from '@stage-ui/icons'

interface ViewGridBlockProps {
  title?: string
  rightActions?: ReactNode
  collapsed?: boolean
}

const ViewGridBlock: FC<GridTypes.Props & ViewGridBlockProps> = (props) => {
  const { title, rightActions, collapsed, children, ...gridProps } = props
  const [isCollapsed, collapse] = useState(collapsed || false)

  return (
    <Grid
      flex={1}
      templateColumns="repeat(3, 1fr)"
      rowGap="1rem"
      columnGap="2rem"
      {...gridProps}
    >
      {title && (
        <Flexbox
          gridColumn="span 3"
          alignItems="center"
          justifyContent="space-between"
          onClick={() => collapse(!isCollapsed)}
        >
          <Paragraph
            m={0}
            children={title}
            transform="uppercase"
            color="gray400"
            weight={700}
            size="1.125rem"
          />
          <ArrowIosDown color="hard" size="1.5rem" rotate={isCollapsed ? 0 : 180} />
        </Flexbox>
      )}
      {!isCollapsed ? rightActions : null}
      {!isCollapsed && children}
    </Grid>
  )
}

export default ViewGridBlock
