import React from 'react'

import { Flexbox, Text, useTheme } from '@stage-ui/core'
import { useHistory, useLocation } from 'react-router-dom'
import * as Icons from '@stage-ui/icons'

import { useMouseDrop } from 'components/MouseDrop'

import { DialogCount } from './DialogCount'
import { OrderCount } from './OrderCount'
import { PurchaseCount } from './PurchaseCount'
import { DeliveryCount } from './DeliveryCount'

type MenuItemProps = {
  value: string
  title: string
  icon: keyof typeof Icons
  compact: boolean
}

export const MenuItem = (props: MenuItemProps) => {
  const { value, title, icon, compact } = props

  const location = useLocation()
  const currentPage = `/${location.pathname.split('/')[1]}`
  const hint = useMouseDrop(compact && title, {
    drop: {
      align: 'right',
    },
    popover: {
      arrowSize: '0px',
    },
  })

  const history = useHistory()

  const { color } = useTheme()

  const onMenuItemClick = (path?: string | number) => {
    const locationSearch = currentPage === path ? location.search : ''

    if (typeof path === 'string') {
      history.push(`${path}${locationSearch}`)
    }
  }

  const Icon = icon ? Icons[icon as 'Text'] : null
  const isActive = value === currentPage

  return (
    <Flexbox
      alignItems="center"
      p="0.75rem"
      borderRadius="0.5rem"
      onClick={() => onMenuItemClick(value)}
      {...hint}
      style={[
        [
          {
            color: color.gray[900].hex(),
            ':hover': {
              backgroundColor: color.gray[100].hex(),
            },
          },
          isActive && {
            backgroundColor: color.green[100].hex(),
            ':hover': {
              backgroundColor: color.green[100].hex(),
            },
          },
        ],
      ]}
    >
      <Flexbox alignItems="center" flex={1}>
        {Icon && (
          <Icon
            size="xl"
            type={isActive ? 'filled' : 'outline'}
            color={isActive ? 'green600' : 'gray500'}
          />
        )}
        <Text
          flex={1}
          ml="m"
          size="s"
          weight={600}
          display={compact ? 'none' : 'inline-block'}
        >
          {title}
        </Text>
        {!compact && (
          <>
            {value === '/dialogs' && <DialogCount />}
            {value === '/orders' && <OrderCount />}
            {value === '/purchases' && <PurchaseCount />}
            {value === '/logistics' && <DeliveryCount />}
          </>
        )}
      </Flexbox>
    </Flexbox>
  )
}
