import React from 'react'

import { Flexbox, Text } from '@stage-ui/core'
import { ProductGroup } from '@foods-n-goods/server/generated/schema'
import TableTypes from '@stage-ui/core/data/Table/types'
import moment from 'moment'

export function CDatetimeFullCell(ctx: TableTypes.TableCellContext<ProductGroup>) {
  const { value } = ctx
  const date = value?.toString()

  if (!date) return null

  return (
    <Flexbox column>
      <Text children={moment(date).format('HH:mm:ss')} />
      <Text size="xs" color="light" children={moment(date).format('DD MMMM, YYYY')} />
    </Flexbox>
  )
}

export function CDatetimeShortCell(ctx: TableTypes.TableCellContext<ProductGroup>) {
  const { value } = ctx
  const date = value?.toString()

  if (!date) return null

  return (
    <Flexbox column>
      <Text children={moment(date).format('HH:mm')} />
      <Text size="xs" color="light" children={moment(date).format('DD MMMM')} />
    </Flexbox>
  )
}
