import productFields from '../fragments/productFields.gql'

export default `
mutation productUpdate($id: String!, $payload: ProductInput!) {
  productUpdate(id: $id, payload: $payload) {
    ...productFields
  }
}
${productFields}
`
