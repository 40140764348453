import request from 'requests/request'
import { JournalStore } from 'store'

export default {
  async getAll() {
    try {
      JournalStore.loading()
      const journal = await request('journal')
      JournalStore.resolve(journal)
    } catch (error) {
      JournalStore.reject(error)
    }
  },
}
