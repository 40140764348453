import React from 'react'

import useSelector from 'hooks/useSelector'
import * as Pages from 'pages'
import { Redirect, Route, Switch } from 'react-router-dom'
import localStorage from 'utils/localStorage'

import { objectEqual } from 'utils/objectEqual'

import MainView from './MainView'

function AppRouter() {
  const { userLoading, metaLoading, error, user } = useSelector(
    ({ app }) => ({
      userLoading: app.userLoading,
      metaLoading: app.metaLoadingStatus,
      user: app.user,
      error: app.error,
    }),
    objectEqual,
  )

  if (error) return <Pages.Unavailable />

  if (
    (localStorage.getPreference('token') && userLoading === 'idle') ||
    userLoading === 'pending' ||
    (user && (metaLoading === 'idle' || metaLoading === 'pending'))
  ) {
    return <Pages.Loading />
  }

  return (
    <Switch>
      {/* TEMPORARY VIEWS AND REDIRECTS */}
      <Route exact path="/">
        {user ? <Redirect to="/home" /> : <Redirect to="/signin" />}
      </Route>
      <Route exact path="/signin">
        {user ? <Redirect to="/home" /> : <Pages.SignIn />}
      </Route>
      <MainView />
    </Switch>
  )
}

export default AppRouter
