export default `mutation clientDeliveryAddressUpdate($clientId: String, $payload: ClientDeliveryAddressInput!, $id: String!, $shouldBeDefault: Boolean) {
    clientDeliveryAddressUpdate(clientId: $clientId, payload: $payload, id: $id, shouldBeDefault: $shouldBeDefault) {
        id
        name
        address
        entrance
        floor
        apartment
        intercom
        lat
        lng
        isDefault
    }
}`
