import React from 'react'

import { Flexbox, Text } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'

import { OrderedPosition } from '..'

export function OrderPositionWorkerCell(
  ctx: TableTypes.TableCellContext<OrderedPosition>,
) {
  const { row } = ctx
  return (
    <Flexbox alignItems="center" style={{ whiteSpace: 'nowrap' }}>
      <Text size="s" color="gray900">
        {row.warehouseWorker?.name}
      </Text>
    </Flexbox>
  )
}
