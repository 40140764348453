import React, { useCallback, useEffect } from 'react'

import { Flexbox, Paragraph, Spinner, Table, Text } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import { List } from '@stage-ui/icons'

import useSelector from 'hooks/useSelector'
import { io } from 'requests/socket'
import { SocketType } from '@foods-n-goods/server/src/socket/types'

import { PurchaseProduct } from '@foods-n-goods/server/generated/schema'
import { PurchasesActions } from 'actions'
import moment from 'moment'

import { objectEqual } from 'utils/objectEqual'

import { OrderQuantityCell } from './cells/OrderQuantityCell'
import { PurchaseQuantityCell } from './cells/PurchaseQuantityCell'
import { UpdateDateCell } from './cells/UpdateDateCell'

export function PurchasesByProductTable() {
  const { productsData, loading, filter } = useSelector(
    (state) => ({
      productsData: state.purchases.byProducts,
      loading: state.purchases.byProductsLoading,
      filter: state.purchases.filter,
    }),
    objectEqual,
  )

  const fetch = useCallback(
    (silent?: boolean) => {
      PurchasesActions.fetchByProducts(
        {
          filter: {
            dateStart: moment(filter.createDateStart, 'YYYY-MM-DD')
              .startOf('day')
              .toDate(),
            dateEnd: moment(filter.createDateEnd, 'YYYY-MM-DD').endOf('day').toDate(),
          },
        },
        silent,
      )
    },
    [filter.createDateStart, filter.createDateEnd],
  )

  useEffect(() => {
    fetch()

    const refetch = () => fetch(true)

    io.on(SocketType.Broadcast.OrdersUpdate, refetch)
    io.on(SocketType.Broadcast.OrderRemove, refetch)
    io.on(SocketType.Broadcast.PurchaseUpdate, refetch)
    io.on(SocketType.Broadcast.PurchaseRemove, refetch)
    io.on(SocketType.Broadcast.PurchaseRemoveByPosition, refetch)

    return () => {
      io.off(SocketType.Broadcast.OrdersUpdate, refetch)
      io.off(SocketType.Broadcast.OrderRemove, refetch)
      io.off(SocketType.Broadcast.PurchaseUpdate, refetch)
      io.off(SocketType.Broadcast.PurchaseRemove, refetch)
      io.off(SocketType.Broadcast.PurchaseRemoveByPosition, refetch)
    }
  }, [fetch])

  const data = productsData.filter((i) => {
    if (filter.search) {
      return !!`${i.productName}`.toUpperCase().match(filter.search.toUpperCase())
    }
    return true
  })

  const columns: TableTypes.TableColumn<PurchaseProduct>[] = [
    {
      key: 'productName',
      title: 'Товар',
      sort: 'ASC',
      render: (ctx) => (
        <Text size="s" color="gray900">
          {ctx.row.productName}
        </Text>
      ),
    },
    {
      key: 'updateDate',
      title: 'Дата обновления',
      width: 170,
      render: UpdateDateCell,
    },
    {
      key: 'orderQuantity',
      title: 'Количество в заказах',
      render: OrderQuantityCell,
      width: 170,
    },
    {
      key: 'purchaseQuantity',
      title: 'Количество в докупку',
      width: 240,
      sort: 'DEFAULT',
      render: (ctx) => <PurchaseQuantityCell ctx={ctx} />,
    },
  ]

  if (loading === 'idle' || loading === 'pending') {
    return (
      <Flexbox centered p="xl" mt="m">
        <Spinner size="s" mr="s" />
        <Text size="s" color="gray500" weight={600}>
          Загрузка данных по докупкам
        </Text>
      </Flexbox>
    )
  }

  if (!data.length) {
    return (
      <Flexbox
        column
        centered
        p="m"
        decoration="surface"
        borderRadius="s"
        borderColor="gray200"
      >
        <List size="1.5rem" color="gray300" />
        <Paragraph size="s" color="gray400">
          {filter.search ? 'Ничего не найдено' : 'Список пуст'}
        </Paragraph>
      </Flexbox>
    )
  }

  return (
    <Table
      columns={columns}
      data={data}
      dataKey="productId"
      overrides={{
        rowCell: {
          height: '1.5rem',
        },
      }}
    />
  )
}
