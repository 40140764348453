import React from 'react'

import { PageTable } from 'components/PageTable'
import useSelector from 'hooks/useSelector'
import { useHistory } from 'react-router-dom'

import { objectEqual } from 'utils/objectEqual'

import { columns } from './misc'

function PromotionsTable() {
  const history = useHistory()
  const data = useSelector(({ promotion }) => promotion.data, objectEqual)

  return (
    <PageTable
      noDataText="Не найдено акций."
      data={data}
      columns={columns}
      onRowClick={(ctx) => {
        const { row } = ctx
        history.push(`/promotions/${row.id}`)
      }}
    />
  )
}

export default PromotionsTable
