import { Promotion, PromotionInput } from '@foods-n-goods/server/generated/schema'
import { Block, Flexbox } from '@stage-ui/core'
import { PromotionActions } from 'actions'
import Content from 'components/Content'
import ViewInputField from 'components/Fields/ViewInputField'
import ImageController from 'components/ImageController'
import ViewGridBlock from 'components/ViewGridBlock'

import PromotionViewHeader from './Header'

interface PromotionViewGeneralProps {
  modal?: boolean
  id?: Promotion['id']
  image?: Promotion['image']
  url: PromotionInput['url']
  published: PromotionInput['published']
  name: PromotionInput['name']
  searchWords?: PromotionInput['searchWords']
  onChange: KeyValueChangeFunction<PromotionInput>
}

function PromotionViewGeneral(props: PromotionViewGeneralProps) {
  const { modal, onChange, published, name, searchWords, id, image, url } = props

  return (
    <Content>
      <PromotionViewHeader modal={modal} published={published} onChange={onChange} />
      <Flexbox mt="1.25rem" justifyContent="space-between" alignItems="flex-start">
        <ViewGridBlock m={0}>
          <ViewInputField
            label="Наименование"
            value={name}
            onChange={(value) => onChange('name', value)}
          />
          <ViewInputField
            label="URL"
            value={url}
            onChange={(value) => onChange('url', value)}
            gridColumnEnd="span 2"
          />
          <ViewInputField
            label="Поисковые слова"
            labelType="outside"
            value={searchWords || ''}
            onChange={(value) => onChange('searchWords', value)}
            gridColumnEnd="span 3"
          />
        </ViewGridBlock>
        {id && (
          <Block ml="5rem" flexShrink={0}>
            <ImageController
              id={id}
              uploadAction={PromotionActions.imageUpdate}
              url={image}
              size="11.5rem"
            />
          </Block>
        )}
      </Flexbox>
    </Content>
  )
}

export default PromotionViewGeneral
