import React from 'react'

import { Flexbox, Text } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import { OrderDeliveryCoordinator } from 'store/delivery'

export function LogisticsCoordinatorClientCell(
  ctx: TableTypes.TableCellContext<OrderDeliveryCoordinator>,
) {
  const { value, row } = ctx

  const deliveryAddress = row.orders[0]?.deliveryAddress

  return (
    <Flexbox
      w="inherit"
      attributes={{
        'data-id': row.orders[0].id,
        'data-hover': 'false',
      }}
      style={(t) => [
        {
          '&[data-hover="true"]': {
            background: t.color.gray[100].hex(),
          },
          ...(deliveryAddress.address
            ? {}
            : {
                background: `${t.color.red[100].hex()} !important`,
              }),
          '@media print': {
            // @ts-ignore
            backgroundColor: row.filled
              ? 'rgb(190,190,190) !important'
              : '#fff !important',
          },
        },
      ]}
    >
      <Text
        w="100%"
        size="xs"
        color="gray900"
        ellipsis
        mr="s"
        style={{
          '@media print': {
            whiteSpace: 'normal',
          },
        }}
      >
        {value}
      </Text>
    </Flexbox>
  )
}
