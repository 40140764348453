import { GQLRequest } from '@foods-n-goods/client/utils/createGQLClient'
import {
  Mutation,
  MutationAtolRecieptArgs,
  MutationB2bGetUserAuthorizedUrlArgs,
  MutationBroadcastPushMessageArgs,
  MutationChangeClientReferalArgs,
  MutationClientDeliveryAddressAddArgs,
  MutationClientDeliveryAddressDeleteArgs,
  MutationClientDeliveryAddressUpdateArgs,
  MutationClientGeoResolveArgs,
  MutationClientGetAddressOptionsArgs,
  MutationClientMarketUpdateArgs,
  MutationClientUpdateArgs,
  MutationContainerCreateArgs,
  MutationContainerDeleteArgs,
  MutationContainersClearArgs,
  MutationContainerUpdateArgs,
  MutationCreatePurchaseArgs,
  MutationDeliveryAreaCreateArgs,
  MutationDeliveryAreaDeleteArgs,
  MutationDeliveryAreaUpdateArgs,
  MutationDeliveryIntervalCreateArgs,
  MutationDeliveryIntervalDeleteArgs,
  MutationDeliveryIntervalUpdateArgs,
  MutationDeviceCreateArgs,
  MutationDeviceDeleteArgs,
  MutationDeviceUpdateArgs,
  MutationLineOutOfStockArgs,
  MutationMarketConfigUpdateArgs,
  MutationOrderAddProductArgs,
  MutationOrderCourierAutoAssignArgs,
  MutationOrderDeleteArgs,
  MutationOrderDeletePositionArgs,
  MutationOrderFileDeleteArgs,
  MutationOrderFileUploadArgs,
  MutationOrderPriceActualizationArgs,
  MutationOrderProductSetPushaserArgs,
  MutationOrderRefundArgs,
  MutationOrderRestoreDeletedProductArgs,
  MutationOrderSetCourierArgs,
  MutationOrderSetStatusArgs,
  MutationOrderSortArgs,
  MutationOrderUpdateCommentArgs,
  MutationOrderUpdateDeliveryDataArgs,
  MutationOrderUpdateDeliverySequenceArgs,
  MutationOrderUpdateQuantityArgs,
  MutationProductCreateArgs,
  MutationProductDeleteArgs,
  MutationProductGroupAddBundleProductArgs,
  MutationProductGroupCreateArgs,
  MutationProductGroupDeleteArgs,
  MutationProductGroupDeleteBundleProductArgs,
  MutationProductGroupImageArgs,
  MutationProductGroupUpdateArgs,
  MutationProductImageArgs,
  MutationProductPrintInfoArgs,
  MutationProductReviewModerateArgs,
  MutationProductUpdateArgs,
  MutationPromotionCreateArgs,
  MutationPromotionDeleteArgs,
  MutationPromotionImageArgs,
  MutationPromotionUpdateArgs,
  MutationPurchaseDeleteArgs,
  MutationPurchaseSetStatusArgs,
  MutationPurchaseCreateIncomesArgs,
  MutationPurchaseUpdateArgs,
  MutationSendPushMessageArgs,
  MutationStaffCreateArgs,
  MutationStaffDeleteArgs,
  MutationStaffPhotoArgs,
  MutationStaffPrefsUpdateArgs,
  MutationStaffSendPushMessageArgs,
  MutationStaffSignInArgs,
  MutationStaffUpdateArgs,
  MutationStaffWarehouseUpdateArgs,
  MutationVariableUpdateArgs,
  Query,
  QueryClientsArgs,
  QueryMarketIndividualsArgs,
  QueryMarketUsersArgs,
  QueryOrderProductsArgs,
  QueryOrderQualityArgs,
  QueryOrdersArgs,
  QueryOrderWorkflowArgs,
  QueryProductGroupArgs,
  QueryProductGroupBundleProductsArgs,
  QueryProductReviewsArgs,
  QueryProductsArgs,
  QueryPurchaseProductsArgs,
  QueryPurchasesArgs,
  QueryStaffArgs,
  QueryClientAverageCheckArgs,
  MutationReportProcessArgs,
} from '@foods-n-goods/server/generated/schema'

import clients from './queries/clients'
import containers from './queries/containers.gql'
import deliveryAreas from './queries/deliveryAreas.gql'
import deliveryIntervals from './queries/deliveryIntervals.gql'
import devices from './queries/devices.gql'
import journal from './queries/journal.gql'
import marketIndividuals from './queries/markeIndividuals'
import marketConfig from './queries/marketConfig'
import marketUsers from './queries/marketUsers'
import meta from './queries/meta.gql'
import orderProducts from './queries/orderProducts.gql'
import orderQuality from './queries/orderQuality.gql'
import orders from './queries/orders'
import orderWorkflow from './queries/orderWorkflow.gql'
import productGroup from './queries/productGroup.gql'
import productGroupBundleProducts from './queries/productGroupBundleProducts.gql'
import productReviews from './queries/productReviews.gql'
import products from './queries/products'
import promotions from './queries/promotions.gql'
import purchases from './queries/purchases'
import staff from './queries/staff'
import variables from './queries/variables.gql'
import clientAverageCheck from './queries/clientAverageCheck'
import reportAvailable from './queries/reportAvailable'
import reports from './queries/reports'

import atolReciept from './mutations/atolReciept.gql'
import b2bGetUserAuthorizedUrl from './mutations/b2bGetUserAuthorizedUrl.gql'
import broadcastPushMessage from './mutations/broadcastPushMessage.gql'
import changeClientReferal from './mutations/changeClientReferal.gql'
import containerCreate from './mutations/containerCreate.gql'
import containerDelete from './mutations/containerDelete.gql'
import containerUpdate from './mutations/containerUpdate.gql'
import deliveryAreaCreate from './mutations/deliveryAreaCreate.gql'
import deliveryAreaDelete from './mutations/deliveryAreaDelete.gql'
import deliveryAreaUpdate from './mutations/deliveryAreaUpdate.gql'
import deliveryIntervalCreate from './mutations/deliveryIntervalCreate.gql'
import deliveryIntervalDelete from './mutations/deliveryIntervalDelete.gql'
import deliveryIntervalUpdate from './mutations/deliveryIntervalUpdate.gql'
import orderAddProduct from './mutations/orderAddProduct'
import orderDelete from './mutations/orderDelete.gql'
import orderDeletePosition from './mutations/orderDeletePosition.gql'
import orderFileDelete from './mutations/orderFileDelete.gql'
import orderFileUpload from './mutations/orderFileUpload.gql'
import orderPriceActualization from './mutations/orderPriceActualization'
import orderProductSetPushaser from './mutations/orderProductSetPushaser.gql'
import orderRefund from './mutations/orderRefund'
import orderSetCourier from './mutations/orderSetCourier'
import orderSetStatus from './mutations/orderSetStatus'
import orderSort from './mutations/orderSort'
import orderUpdateComment from './mutations/orderUpdateComment.gql'
import productCreate from './mutations/productCreate'
import productDelete from './mutations/productDelete'
import productUpdate from './mutations/productUpdate'
import productImage from './mutations/productImage'
import productGroupAddBundleProduct from './mutations/productGroupAddBundleProduct.gql'
import productGroupCreate from './mutations/productGroupCreate.gql'
import productGroupDelete from './mutations/productGroupDelete.gql'
import productGroupDeleteBundleProduct from './mutations/productGroupDeleteBundleProduct.gql'
import productGroupImage from './mutations/productGroupImage.gql'
import productGroupUpdate from './mutations/productGroupUpdate.gql'
import productReviewModerate from './mutations/productReviewModerate.gql'
import promotionCreate from './mutations/promotionCreate.gql'
import promotionDelete from './mutations/promotionDelete.gql'
import promotionImage from './mutations/promotionImage.gql'
import productPrintInfo from './mutations/productPrintInfo'
import promotionUpdate from './mutations/promotionUpdate.gql'
import purchaseUpdate from './mutations/purchaseUpdate.gql'
import sendPushMessage from './mutations/sendPushMessage.gql'
import staffAuth from './mutations/staffAuth.gql'
import staffCreate from './mutations/staffCreate'
import staffDelete from './mutations/staffDelete.gql'
import staffPhoto from './mutations/staffPhoto'
import staffPrefsUpdate from './mutations/staffPrefsUpdate.gql'
import staffSendPushMessage from './mutations/staffSendPushMessage.gql'
import staffSignIn from './mutations/staffSignIn'
import staffSignOut from './mutations/staffSignOut.gql'
import staffUpdate from './mutations/staffUpdate'
import variableUpdate from './mutations/variableUpdate.gql'
// import deliveryIntervalDelete from './mutations/deliveryIntervalDelete.gql'
import containersClear from './mutations/containersClear.gql'
import createPurchase from './mutations/createPurchase.gql'
import deviceCreate from './mutations/deviceCreate.gql'
import deviceDelete from './mutations/deviceDelete.gql'
import deviceUpdate from './mutations/deviceUpdate.gql'
import lineOutOfStock from './mutations/lineOutOfStock.gql'
import marketConfigUpdate from './mutations/marketConfigUpdate'
import orderRestoreDeletedProduct from './mutations/orderRestoreDeletedProduct.gql'
import orderUpdateDeliveryData from './mutations/orderUpdateDeliveryData'
import orderUpdateDeliverySequence from './mutations/orderUpdateDeliverySequence'
import orderUpdateQuantity from './mutations/orderUpdateQuantity.gql'
import purchaseDelete from './mutations/purchaseDelete.gql'
import purchaseSetStatus from './mutations/purchaseSetStatus.gql'
import clientGeoResolve from './mutations/clientGeoResolve'
import clientGetAddressOptions from './mutations/clientGetAddressOptions'
import clientDeliveryAddressAdd from './mutations/clientDeliveryAddressAdd'
import clientDeliveryAddressDelete from './mutations/clientDeliveryAddressDelete'
import clientDeliveryAddressUpdate from './mutations/clientDeliveryAddressUpdate'
import purchaseProducts from './queries/purchaseProducts'
import orderCourierAutoAssign from './mutations/orderCourierAutoAssign'
import staffWarehouseUpdate from './mutations/staffWarehouseUpdate'
import purchaseCreateIncomes from './mutations/purchaseCreateIncomes.gql'
import clientUpdate from './mutations/clientUpdate'
import clientMarketUpdate from './mutations/clientMarketUpdate'
import purchaseDeleteCompleted from './mutations/purchaseDeleteCompleted'
import reportProcess from './mutations/reportProcess'

type Queries = {
  purchaseProducts: GQLRequest<QueryPurchaseProductsArgs, Query['purchaseProducts']>
  clients: GQLRequest<QueryClientsArgs, Query['clients']>
  meta: GQLRequest<void, Query['meta']>
  orders: GQLRequest<QueryOrdersArgs, Query['orders']>
  orderWorkflow: GQLRequest<QueryOrderWorkflowArgs, Query['orderWorkflow']>
  orderProducts: GQLRequest<QueryOrderProductsArgs, Query['orderProducts']>
  products: GQLRequest<QueryProductsArgs, Query['products']>
  productGroup: GQLRequest<QueryProductGroupArgs, Query['productGroup']>
  productGroupBundleProducts: GQLRequest<
    QueryProductGroupBundleProductsArgs,
    Query['productGroupBundleProducts']
  >
  staff: GQLRequest<QueryStaffArgs, Query['staff']>
  deliveryAreas: GQLRequest<void, Query['deliveryAreas']>
  journal: GQLRequest<void, Query['journal']>
  variables: GQLRequest<void, Query['variables']>
  productReviews: GQLRequest<QueryProductReviewsArgs, Query['productReviews']>
  promotions: GQLRequest<void, Query['promotions']>
  deliveryIntervals: GQLRequest<void, Query['deliveryIntervals']>
  containers: GQLRequest<void, Query['containers']>
  purchases: GQLRequest<QueryPurchasesArgs, Query['purchases']>
  devices: GQLRequest<void, Query['devices']>
  orderQuality: GQLRequest<QueryOrderQualityArgs, Query['orderQuality']>
  marketUsers: GQLRequest<QueryMarketUsersArgs, Query['marketUsers']>
  marketIndividuals: GQLRequest<QueryMarketIndividualsArgs, Query['marketIndividuals']>
  marketConfig: GQLRequest<void, Query['marketConfig']>
  clientAverageCheck: GQLRequest<QueryClientAverageCheckArgs, Query['clientAverageCheck']>
  reportAvailable: GQLRequest<void, Query['reportAvailable']>
  reports: GQLRequest<void, Query['reports']>
}

export const queries = {
  purchaseProducts,
  clients,
  meta,
  orders,
  orderWorkflow,
  orderProducts,
  products,
  productGroup,
  productGroupBundleProducts,
  staff,
  deliveryAreas,
  journal,
  variables,
  productReviews,
  promotions,
  deliveryIntervals,
  containers,
  purchases,
  devices,
  orderQuality,
  marketUsers,
  marketIndividuals,
  marketConfig,
  clientAverageCheck,
  reportAvailable,
  reports,
}

type Mutations = {
  purchaseDeleteCompleted: GQLRequest<void, void>
  staffWarehouseUpdate: GQLRequest<
    MutationStaffWarehouseUpdateArgs,
    Mutation['staffWarehouseUpdate']
  >
  orderCourierAutoAssign: GQLRequest<
    MutationOrderCourierAutoAssignArgs,
    Mutation['orderCourierAutoAssign']
  >
  clientDeliveryAddressAdd: GQLRequest<
    MutationClientDeliveryAddressAddArgs,
    Mutation['clientDeliveryAddressAdd']
  >
  clientDeliveryAddressDelete: GQLRequest<
    MutationClientDeliveryAddressDeleteArgs,
    Mutation['clientDeliveryAddressDelete']
  >
  clientDeliveryAddressUpdate: GQLRequest<
    MutationClientDeliveryAddressUpdateArgs,
    Mutation['clientDeliveryAddressUpdate']
  >
  clientGeoResolve: GQLRequest<MutationClientGeoResolveArgs, Mutation['clientGeoResolve']>
  clientGetAddressOptions: GQLRequest<
    MutationClientGetAddressOptionsArgs,
    Mutation['clientGetAddressOptions']
  >
  staffAuth: GQLRequest<void, Mutation['staffAuth']>
  staffSignIn: GQLRequest<MutationStaffSignInArgs, Mutation['staffSignIn']>
  staffSignOut: GQLRequest<void, Mutation['staffSignOut']>
  staffCreate: GQLRequest<MutationStaffCreateArgs, Mutation['staffCreate']>
  staffUpdate: GQLRequest<MutationStaffUpdateArgs, Mutation['staffUpdate']>
  staffDelete: GQLRequest<MutationStaffDeleteArgs, Mutation['staffDelete']>
  staffPrefsUpdate: GQLRequest<MutationStaffPrefsUpdateArgs, Mutation['staffPrefsUpdate']>
  orderSort: GQLRequest<MutationOrderSortArgs, Mutation['orderSort']>
  orderFileDelete: GQLRequest<MutationOrderFileDeleteArgs, Mutation['orderFileDelete']>
  orderFileUpload: GQLRequest<MutationOrderFileUploadArgs, Mutation['orderFileUpload']>
  b2bGetUserAuthorizedUrl: GQLRequest<
    MutationB2bGetUserAuthorizedUrlArgs,
    Mutation['b2bGetUserAuthorizedUrl']
  >
  orderRefund: GQLRequest<MutationOrderRefundArgs, Mutation['orderRefund']>
  orderDelete: GQLRequest<MutationOrderDeleteArgs, Mutation['orderDelete']>
  orderSetStatus: GQLRequest<MutationOrderSetStatusArgs, Mutation['orderSetStatus']>
  orderSetCourier: GQLRequest<MutationOrderSetCourierArgs, Mutation['orderSetCourier']>
  orderPriceActualization: GQLRequest<
    MutationOrderPriceActualizationArgs,
    Mutation['orderPriceActualization']
  >
  orderAddProduct: GQLRequest<MutationOrderAddProductArgs, Mutation['orderAddProduct']>
  orderDeletePosition: GQLRequest<
    MutationOrderDeletePositionArgs,
    Mutation['orderDeletePosition']
  >
  orderUpdateComment: GQLRequest<
    MutationOrderUpdateCommentArgs,
    Mutation['orderUpdateComment']
  >
  purchaseUpdate: GQLRequest<MutationPurchaseUpdateArgs, Mutation['purchaseUpdate']>
  productGroupCreate: GQLRequest<
    MutationProductGroupCreateArgs,
    Mutation['productGroupCreate']
  >
  productGroupUpdate: GQLRequest<
    MutationProductGroupUpdateArgs,
    Mutation['productGroupUpdate']
  >
  productGroupDelete: GQLRequest<
    MutationProductGroupDeleteArgs,
    Mutation['productGroupDelete']
  >
  productGroupImage: GQLRequest<
    MutationProductGroupImageArgs,
    Mutation['productGroupImage']
  >
  productGroupAddBundleProduct: GQLRequest<
    MutationProductGroupAddBundleProductArgs,
    Mutation['productGroupAddBundleProduct']
  >
  productGroupDeleteBundleProduct: GQLRequest<
    MutationProductGroupDeleteBundleProductArgs,
    Mutation['productGroupDeleteBundleProduct']
  >
  productCreate: GQLRequest<MutationProductCreateArgs, Mutation['productCreate']>
  productUpdate: GQLRequest<MutationProductUpdateArgs, Mutation['productUpdate']>
  productDelete: GQLRequest<MutationProductDeleteArgs, Mutation['productDelete']>
  productImage: GQLRequest<MutationProductImageArgs, Mutation['productImage']>
  productPrintInfo: GQLRequest<MutationProductPrintInfoArgs, Mutation['productPrintInfo']>

  deliveryAreaCreate: GQLRequest<
    MutationDeliveryAreaCreateArgs,
    Mutation['deliveryAreaCreate']
  >
  deliveryAreaUpdate: GQLRequest<
    MutationDeliveryAreaUpdateArgs,
    Mutation['deliveryAreaUpdate']
  >
  deliveryAreaDelete: GQLRequest<
    MutationDeliveryAreaDeleteArgs,
    Mutation['deliveryAreaDelete']
  >
  staffPhoto: GQLRequest<MutationStaffPhotoArgs, Mutation['staffPhoto']>
  sendPushMessage: GQLRequest<MutationSendPushMessageArgs, Mutation['sendPushMessage']>
  staffSendPushMessage: GQLRequest<
    MutationStaffSendPushMessageArgs,
    Mutation['staffSendPushMessage']
  >
  broadcastPushMessage: GQLRequest<
    MutationBroadcastPushMessageArgs,
    Mutation['broadcastPushMessage']
  >
  productReviewModerate: GQLRequest<
    MutationProductReviewModerateArgs,
    Mutation['productReviewModerate']
  >
  promotionCreate: GQLRequest<MutationPromotionCreateArgs, Mutation['promotionCreate']>
  promotionUpdate: GQLRequest<MutationPromotionUpdateArgs, Mutation['promotionUpdate']>
  promotionDelete: GQLRequest<MutationPromotionDeleteArgs, Mutation['promotionDelete']>
  promotionImage: GQLRequest<MutationPromotionImageArgs, Mutation['promotionImage']>
  variableUpdate: GQLRequest<MutationVariableUpdateArgs, Mutation['variableUpdate']>
  atolReciept: GQLRequest<MutationAtolRecieptArgs, Mutation['atolReciept']>
  deliveryIntervalCreate: GQLRequest<
    MutationDeliveryIntervalCreateArgs,
    Mutation['deliveryIntervalCreate']
  >
  deliveryIntervalUpdate: GQLRequest<
    MutationDeliveryIntervalUpdateArgs,
    Mutation['deliveryIntervalUpdate']
  >
  deliveryIntervalDelete: GQLRequest<
    MutationDeliveryIntervalDeleteArgs,
    Mutation['deliveryIntervalDelete']
  >
  changeClientReferal: GQLRequest<
    MutationChangeClientReferalArgs,
    Mutation['changeClientReferal']
  >
  containerCreate: GQLRequest<MutationContainerCreateArgs, Mutation['containerCreate']>
  containerUpdate: GQLRequest<MutationContainerUpdateArgs, Mutation['containerUpdate']>
  containerDelete: GQLRequest<MutationContainerDeleteArgs, Mutation['containerDelete']>
  orderProductSetPushaser: GQLRequest<
    MutationOrderProductSetPushaserArgs,
    Mutation['orderProductSetPushaser']
  >
  orderUpdateQuantity: GQLRequest<
    MutationOrderUpdateQuantityArgs,
    Mutation['orderUpdateQuantity']
  >
  purchaseSetStatus: GQLRequest<
    MutationPurchaseSetStatusArgs,
    Mutation['purchaseSetStatus']
  >
  orderRestoreDeletedProduct: GQLRequest<
    MutationOrderRestoreDeletedProductArgs,
    Mutation['orderRestoreDeletedProduct']
  >
  createPurchase: GQLRequest<MutationCreatePurchaseArgs, Mutation['createPurchase']>
  lineOutOfStock: GQLRequest<MutationLineOutOfStockArgs, Mutation['lineOutOfStock']>
  purchaseDelete: GQLRequest<MutationPurchaseDeleteArgs, Mutation['purchaseDelete']>
  deviceCreate: GQLRequest<MutationDeviceCreateArgs, Mutation['deviceCreate']>
  deviceUpdate: GQLRequest<MutationDeviceUpdateArgs, Mutation['deviceUpdate']>
  deviceDelete: GQLRequest<MutationDeviceDeleteArgs, Mutation['deviceDelete']>
  containersClear: GQLRequest<MutationContainersClearArgs, Mutation['containersClear']>
  marketConfigUpdate: GQLRequest<
    MutationMarketConfigUpdateArgs,
    Mutation['marketConfigUpdate']
  >
  orderUpdateDeliveryData: GQLRequest<
    MutationOrderUpdateDeliveryDataArgs,
    Mutation['orderUpdateDeliveryData']
  >
  orderUpdateDeliverySequence: GQLRequest<
    MutationOrderUpdateDeliverySequenceArgs,
    Mutation['orderUpdateDeliverySequence']
  >
  purchaseCreateIncomes: GQLRequest<
    MutationPurchaseCreateIncomesArgs,
    Mutation['purchaseCreateIncomes']
  >
  clientUpdate: GQLRequest<MutationClientUpdateArgs, Mutation['clientUpdate']>
  clientMarketUpdate: GQLRequest<
    MutationClientMarketUpdateArgs,
    Mutation['clientMarketUpdate']
  >
  reportProcess: GQLRequest<MutationReportProcessArgs, Mutation['reportProcess']>
}

export const mutations = {
  purchaseDeleteCompleted,
  staffWarehouseUpdate,
  orderCourierAutoAssign,
  clientDeliveryAddressAdd,
  clientDeliveryAddressDelete,
  clientDeliveryAddressUpdate,
  clientGetAddressOptions,
  clientGeoResolve,
  staffAuth,
  staffSignIn,
  staffSignOut,
  staffCreate,
  staffUpdate,
  staffDelete,
  staffPrefsUpdate,
  orderSort,
  orderFileUpload,
  orderFileDelete,
  b2bGetUserAuthorizedUrl,
  orderRefund,
  orderDelete,
  orderSetStatus,
  orderSetCourier,
  orderPriceActualization,
  orderAddProduct,
  orderDeletePosition,
  orderUpdateComment,
  purchaseUpdate,
  productGroupCreate,
  productGroupUpdate,
  productGroupDelete,
  productGroupImage,
  productGroupAddBundleProduct,
  productGroupDeleteBundleProduct,
  productCreate,
  productUpdate,
  productDelete,
  productImage,
  productPrintInfo,
  deliveryAreaCreate,
  deliveryAreaUpdate,
  deliveryAreaDelete,
  staffPhoto,
  sendPushMessage,
  staffSendPushMessage,
  broadcastPushMessage,
  productReviewModerate,
  promotionCreate,
  promotionUpdate,
  promotionDelete,
  promotionImage,
  variableUpdate,
  atolReciept,
  deliveryIntervalUpdate,
  deliveryIntervalCreate,
  deliveryIntervalDelete,
  changeClientReferal,
  containerCreate,
  containerUpdate,
  containerDelete,
  orderProductSetPushaser,
  orderUpdateQuantity,
  orderRestoreDeletedProduct,
  createPurchase,
  lineOutOfStock,
  purchaseDelete,
  deviceCreate,
  deviceUpdate,
  deviceDelete,
  containersClear,
  marketConfigUpdate,
  orderUpdateDeliveryData,
  orderUpdateDeliverySequence,
  purchaseSetStatus,
  purchaseCreateIncomes,
  clientUpdate,
  clientMarketUpdate,
  reportProcess,
}

export type Requests = Queries & Mutations
export default { ...queries, ...mutations }
