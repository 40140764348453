import React from 'react'

import TableTypes from '@stage-ui/core/data/Table/types'
import { Text } from '@stage-ui/core'
import { Order } from '@foods-n-goods/server/generated/schema'

export function OrderIdCell(ctx: TableTypes.TableCellContext<Order>) {
  const { row } = ctx
  const { id } = row
  return <Text size="s">#{id}</Text>
}
