import React from 'react'

import { ProductReview } from '@foods-n-goods/server/generated/schema'
import { Button, Text } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import { MoreVertical } from '@stage-ui/icons'
import { openOrderModal } from 'components/Modals/OrderView'
import { openProductModal } from 'components/Modals/ProductView'
import { useMouseDropMenu } from 'components/MouseMenuDrop'
import Rater from 'components/Rater'
import { DateTime } from 'components/DateTime'
import { useHistory } from 'react-router-dom'

function ContextCell(ctx: TableTypes.TableCellContext<ProductReview>) {
  const history = useHistory()

  const reviewContextMenu = useMouseDropMenu<HTMLButtonElement>(
    [
      {
        text: 'Просмотреть отзыв',
        value: 'DETAILS',
        icon: 'Text',
      },
      {
        text: 'Детализация заказа',
        value: 'ORDER',
        icon: 'ShoppingCart',
      },
      {
        text: 'Карточка товара',
        value: 'PRODUCT',
        icon: 'ShoppingCart',
      },
    ],
    ({ value }) => {
      if (value === 'DETAILS') {
        return history.push(`/reviews/${ctx.row.id}`)
      }
      if (value === 'ORDER') {
        return openOrderModal(ctx.row.order.id)
      }
      if (value === 'PRODUCT') {
        return openProductModal(ctx.row.product.id)
      }
    },
  )

  return (
    <Button
      id={`review_row_action_${ctx.row.id}`}
      decoration="text"
      color="gray500"
      p={0}
      w="2rem"
      h="2rem"
      {...reviewContextMenu}
    >
      <MoreVertical size="1.5rem" />
    </Button>
  )
}

function RateCell(ctx: TableTypes.TableCellContext<ProductReview>) {
  const rating = ctx.value as number
  return <Rater rating={rating} count={5} />
}

export const columns: TableTypes.TableColumn<ProductReview>[] = [
  {
    key: 'createDate',
    title: 'Дата создания',
    render: (ctx) => <DateTime date={ctx.row.createDate} />,
    width: '10rem',
  },
  {
    key: 'moderateDate',
    title: 'Дата модерации',
    render: (ctx) => <DateTime date={ctx.row.moderateDate} />,
    width: '10rem',
  },
  {
    key: 'product',
    title: 'Продукция',
    render: (ctx) => (
      <Text size="s" color="gray900">
        {ctx.row.product.name}
      </Text>
    ),
  },
  {
    key: 'order',
    title: 'Заказ',
    render: (ctx) => (
      <Text size="s" color="gray900">
        #{ctx.row.order.id}
      </Text>
    ),
  },
  // { key: 'client', title: 'Клиент', render: CClientCell },
  { key: 'rating', title: 'Рейтинг', render: RateCell, width: '9.5rem' },
  { key: '_context', title: '', width: 32, render: ContextCell },
]
