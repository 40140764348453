import React, { useEffect } from 'react'

import { Flexbox, Spinner, Text, Block } from '@stage-ui/core'

import { useParams } from 'react-router-dom'
import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'
import { StaffActions } from 'actions'

import { Staff } from '@foods-n-goods/server/generated/schema'

import { StaffRole } from '@foods-n-goods/server/src/shared/enum'

import { StaffDetailsGeneral } from './General'
import { StaffDetailsAdditional } from './Additional'
import { StaffDetailsToolbar } from './components/Toolbar'

interface StaffDetailsProps {
  onClose?: () => void
  id?: Staff['id']
}

export function StaffDetails(props: StaffDetailsProps) {
  const { id, onClose } = props
  const params: { id: string } = useParams()

  const currentlyViewedStaff = useSelector(
    (state) => state.staff.currentlyViewed,
    objectEqual,
  )

  const prefetch = () => {
    const staffId = id || params.id
    if (!staffId || !!currentlyViewedStaff) return
    StaffActions.fetchById(staffId)
  }

  useEffect(() => {
    prefetch()
    return () => {
      StaffActions.clearCurrentlyViewed()
    }
  }, [])

  if (!currentlyViewedStaff) {
    return (
      <Flexbox centered p="xl">
        <Spinner mr="s" />
        <Text color="gray500" size="s">
          Загрузка карточки персонала...
        </Text>
      </Flexbox>
    )
  }

  const additionalAvailable = ![
    StaffRole.ADMIN,
    StaffRole.CONTENT_MANAGER,
    StaffRole.MANAGER,
    StaffRole.OPERATOR,
  ].includes(currentlyViewedStaff.role.value)

  const modal = !!onClose

  return (
    <Block mb={modal ? 0 : 'xl'}>
      {/* {modal && (
        <Close
          size="1.5rem"
          color="gray500"
          style={{
            position: 'absolute',
            right: '0',
            top: '0',
            zIndex: 10,
          }}
          onClick={onClose}
        />
      )} */}
      <StaffDetailsToolbar modal={modal} staff={currentlyViewedStaff} />
      <Flexbox>
        <StaffDetailsGeneral
          modal={modal}
          staff={currentlyViewedStaff}
          additionalAvailable={additionalAvailable}
        />
        {additionalAvailable && (
          <StaffDetailsAdditional modal={modal} staff={currentlyViewedStaff} />
        )}
      </Flexbox>
    </Block>
  )
}
