import React from 'react'

import { Product, ProductInput } from '@foods-n-goods/server/generated/schema'

import { Block, Checkbox, Paragraph, TextField } from '@stage-ui/core'

import { OnValueParamChangeFunction } from '..'

/**
Страна происхождения: Турция
Производитель: «AKGUN TARIM
ITHALAT IHRACAT NAKLYE
TURIZMSAN.VETIC.LTD.STI.»
Импортер: ООО «ФТК» Россия,
г.Москва,107150,ул.Бойцовая,
д.22, эт.2
 */
interface ProductPrintLabelProps {
  productId?: Product['id']
  params: ProductInput['params']
  onChange: OnValueParamChangeFunction
}

function ProductPrintLabel(props: ProductPrintLabelProps) {
  const { params, productId, onChange } = props
  if (!productId) return null

  return (
    <Block decoration="surface" borderColor="gray100" p="l" mb="m">
      <Paragraph weight={500} size="l" color="gray900" mb="m">
        Параметры стикера
      </Paragraph>
      <Checkbox
        checked={params.PRINT_EAC === '1'}
        label="Печатать EAC соответсвие"
        mb="m"
        onChange={(preValue) => onChange('PRINT_EAC', !preValue ? '1' : '0')}
      />
      <TextField
        multiline
        label="Текст для печати"
        w="26rem"
        defaultValue={params.PRINT_BODY || ''}
        onChange={(e) => onChange('PRINT_BODY', e.target.value)}
        style={{
          minHeight: '14rem',
          textarea: {
            fontFamily: 'SF Mono, Consolas, DejaVu Sans Mono, Roboto Mono',
            fontSize: 11,
            height: '100%',
            resize: 'none',
            lineHeight: 1.2,
          },
        }}
      />
    </Block>
  )
}

export default ProductPrintLabel
