import React, { useEffect, useState } from 'react'

import { Product, ProductInput } from '@foods-n-goods/server/generated/schema'
import useSelector from 'hooks/useSelector'
import { useHistory, useParams } from 'react-router-dom'

import { objectEqual } from 'utils/objectEqual'

import ProductViewAttributes from './Attributes'
import ProductViewControllers from './Controllers'
import ProductViewEnergyValue from './EnergyValue'
import ProductViewGeneral from './General'
import ProductViewPresentation from './Presentation'
import ProductViewPricing from './Pricing'
import ProductPrintLabel from './PrintLabel'

export type OnValueParamChangeFunction = <K extends keyof ProductInput['params']>(
  key: K,
  value: ProductInput['params'][K],
) => void
export type OnValueAttributeChangeFunction = (
  attributes: ProductInput['attributes'],
) => void

interface ProductViewProps {
  modal?: boolean
  close?: () => void
  id?: Product['id']
}

function ProductView(props: ProductViewProps) {
  const { modal, close } = props

  const params: { id: string } = useParams()
  const history = useHistory()

  // eslint-disable-next-line react/destructuring-assignment
  const id = props.id || params.id
  const { meta, product } = useSelector(
    ({ app, product }) => ({
      meta: app.meta,
      product: product.data?.records?.find((c) => c.id === id),
    }),
    objectEqual,
  )

  const goBack = close || (() => history.push(`/products`))

  useEffect(() => {
    if (!modal && !product) {
      goBack()
    }
  }, [product])

  const [productInput, setProductInput] = useState<ProductInput>({
    name: product?.name || '',
    alias: product?.alias || '',
    published: product?.published || false,
    article: product?.article || '',
    price: product?.price || '',
    searchWords: product?.searchWords || null,
    weight: product?.weight || '',
    params: product?.params || {},
    unit: product?.unit || meta!.units[0],
    scaleable: !!product?.scaleable,
    attributes: product?.attributes || [],
    description: product?.description || '',
    productGroups: product?.productGroups || [],
    sortWeight: product?.sortWeight || 0,
  })

  const onValueChange: KeyValueChangeFunction<ProductInput> = (key, value) => {
    setProductInput({ ...productInput, [key]: value })
  }

  const onParamChange: OnValueParamChangeFunction = (key, value) => {
    setProductInput({
      ...productInput,
      params: { ...productInput.params, [key]: value },
    })
  }

  const onAttributeChange: OnValueAttributeChangeFunction = (attributes) => {
    setProductInput({ ...productInput, attributes })
  }

  return (
    <>
      <ProductViewGeneral modal={modal} onChange={onValueChange} input={productInput} />

      <ProductViewPricing
        units={meta?.units || []}
        onChange={onValueChange}
        {...productInput}
      />

      <ProductViewEnergyValue onChange={onParamChange} params={productInput.params} />

      <ProductViewPresentation
        onChange={onValueChange}
        description={productInput.description}
        product={product}
      />

      <ProductViewAttributes
        onChange={onAttributeChange}
        productId={product?.id}
        attributes={productInput.attributes}
      />

      <ProductPrintLabel
        onChange={onParamChange}
        productId={product?.id}
        params={productInput.params}
      />

      <ProductViewControllers input={productInput} goBack={goBack} product={product} />
    </>
  )
}

export default ProductView
