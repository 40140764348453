import React, { useRef, useState } from 'react'

import { Block, Button, Calendar, Drop } from '@stage-ui/core'
import { Calendar as CalendarIcon } from '@stage-ui/icons'
import useSelector from 'hooks/useSelector'

import { objectEqual } from 'utils/objectEqual'
import DropTypes from '@stage-ui/core/layout/Drop/types'
import moment from 'moment'
import { OrderActions } from 'actions'

export function OrderListDeliveryFilter() {
  const buttonRef = useRef<HTMLButtonElement>(null)
  const dropRef = useRef<DropTypes.Ref>(null)

  const [dropVisible, setDropVisible] = useState(false)

  const { deliveryDateStart, deliveryDateEnd } = useSelector(
    (state) => ({
      deliveryDateStart: state.order.filter.deliveryDateStart,
      deliveryDateEnd: state.order.filter.deliveryDateEnd,
    }),
    objectEqual,
  )

  const isActive = deliveryDateStart && deliveryDateEnd

  const onDateChange = (start: Date, end?: Date) => {
    if (!start || !end) return

    const startDate = moment(start).format('YYYY-MM-DD')
    const endDate = moment(end).format('YYYY-MM-DD')

    OrderActions.setFilter({
      deliveryDateStart: startDate,
      deliveryDateEnd: endDate,
    })

    setDropVisible(false)
  }

  return (
    <>
      <Button
        ref={buttonRef}
        color={isActive ? 'green500' : 'gray500'}
        decoration="text"
        size="xs"
        p={0}
        w="1.25rem"
        h="1.25rem"
        onClick={() => setDropVisible(!dropVisible)}
      >
        <CalendarIcon size="0.875rem" />
      </Button>
      <Drop
        ref={dropRef}
        target={buttonRef}
        visible={dropVisible}
        justify="end"
        spacing={8}
        onClickOutside={(e, outTarget) => {
          if (outTarget) {
            setDropVisible(false)
          }
        }}
      >
        <Block borderRadius="s" p="s" shadow="xl" backgroundColor="white">
          <Calendar
            range
            value={[new Date(deliveryDateStart), new Date(deliveryDateEnd)]}
            onChange={onDateChange}
          />
        </Block>
      </Drop>
    </>
  )
}
