import React, { DetailedHTMLProps, HTMLAttributes } from 'react'

import { EmotionStyles } from '@stage-ui/system/types'

interface SVGProps
  extends Omit<
    DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>,
    'style'
  > {
  style?: EmotionStyles
  src: string
}

function SVG(props: SVGProps) {
  const { src, style, ...otherProps } = props

  return (
    <span
      css={{ '>svg': [{ display: 'block' }, style] }}
      dangerouslySetInnerHTML={{ __html: src }}
      {...otherProps}
    />
  )
}

export default SVG
