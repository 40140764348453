import React, { useState } from 'react'

import { Order } from '@foods-n-goods/server/generated/schema'
import { Button, Flexbox, modal, TextField } from '@stage-ui/core'
import { OrderActions } from 'actions'

type OrderCommentChangeModalProps = {
  order: Order
  close: () => void
}

function OrderCommentChangeModal(props: OrderCommentChangeModalProps) {
  const { order, close } = props
  const { comment, id } = order

  const [value, setValue] = useState(comment ?? '')
  const hasChangedComment = value !== comment

  const updateComment = () => {
    OrderActions.updateComment({ id, comment: value })
    close()
  }

  return (
    <Flexbox column>
      <TextField
        w="100%"
        flexBasis="100%"
        multiline
        placeholder="Добавить комментарий..."
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <Flexbox justifyContent="flex-end" mt="xl">
        <Button
          color="gray500"
          textColor="gray500"
          decoration="text"
          label="Отмена"
          mr="m"
          onClick={close}
        />
        <Button
          label="Подтвердить"
          disabled={!hasChangedComment}
          onClick={updateComment}
        />
      </Flexbox>
    </Flexbox>
  )
}

export const openOrderCommentChangeModal = (
  order: Order,
  e?: React.MouseEvent<HTMLDivElement, MouseEvent>,
) => {
  e?.stopPropagation()
  e?.preventDefault()

  modal({
    title: 'Комментарий к заказу',
    subtitle: `Заказ [#${order.id}] ${order.client.name ?? '-'}`,
    w: '30rem',
    overrides: {
      header: { marginBottom: '1rem' },
    },
    overlayClose: false,
    render: (close) => <OrderCommentChangeModal close={close} order={order} />,
  })
}
