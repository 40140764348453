import React from 'react'

import { OrderQualityItem } from '@foods-n-goods/server/generated/schema'
import { Flexbox, Text } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import moment from 'moment'

import { Timer } from '@stage-ui/icons'

import QualityPalleteCell from './QualityPalleteCell'
import QualityQuantityCell from './QualityQuantityCell'

export const qualityOrderPositions: TableTypes.TableColumn<OrderQualityItem>[] = [
  {
    key: 'orderId',
    title: 'Номер',
    width: 80,
    render: (ctx) => (
      <Text size="s" color="gray500">
        #{ctx.row.orderId}
      </Text>
    ),
  },
  {
    key: 'product',
    title: 'Продукт',
    width: 220,
    render: (ctx) => <Text size="s">{ctx.row.productName}</Text>,
  },
  {
    key: 'client',
    title: 'Клиент',
    render: (ctx) => (
      <Text size="s" color="gray600">
        {ctx.row.clientName}
      </Text>
    ),
  },
  {
    key: 'packDate',
    title: 'Сборка',
    width: 100,
    render: (ctx) => (
      <Flexbox alignItems="center">
        <Timer color="gray500" mr="s" />
        <Text size="s" color="gray500">
          {moment(ctx.row.packDate).locale('ru').format('DD.MM.YY')}
        </Text>
      </Flexbox>
    ),
  },
  {
    key: 'containers',
    title: 'Паллет',
    width: 80,
    render: QualityPalleteCell,
  },
  {
    key: 'quantityBefore',
    title: 'В заказе',
    width: 80,
    render: (ctx) => <QualityQuantityCell quantity={ctx.row.quantityBefore} />,
  },
  {
    key: 'quantityAfter',
    title: 'К отгрузке',
    width: 80,
    render: (ctx) => <QualityQuantityCell quantity={ctx.row.quantityAfter} />,
  },
  {
    key: 'quantitySupposed',
    title: 'Вес',
    width: 80,
    render: (ctx) => <QualityQuantityCell quantity={ctx.row.quantitySupposed} />,
  },
  {
    key: 'tareWeight',
    title: 'Тара',
    width: 80,
    render: (ctx) => <QualityQuantityCell quantity={String(ctx.row.tareWeight)} />,
  },
  {
    key: 'warehouseWorker',
    title: 'Сборщик',
    width: 150,
    render: (ctx) => (
      <Text size="s" color="gray600">
        {ctx.row.warehouseWorkerName}
      </Text>
    ),
  },
]
