import React from 'react'

import { Order } from '@foods-n-goods/server/generated/schema'
import { Flexbox, Paragraph, Table, useTheme, Text } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import { List } from '@stage-ui/icons'
import { OrderActions } from 'actions'
import useSelector from 'hooks/useSelector'
import { useHistory } from 'react-router-dom'

import { PageLoading } from 'components/PageLoading'

import { objectEqual } from 'utils/objectEqual'

import { OrderListTableClientCell } from './components/OrderClientCell'
import { OrderListTableCourierCell } from './components/OrderCourierCell'
import { OrderListTableCreateDateCell } from './components/OrderCreateDateCell'
import { OrderListTableDeliveryDateCell } from './components/OrderDeliveryDateCell'
import { OrderListTableIdCell } from './components/OrderIdCell'
import { OrderListTablePurchasesCell } from './components/OrderPurchasesCell'
import { OrderListTableStatusCell } from './components/OrderStatusCell'
import { OrderListTableSynchronizationCell } from './components/OrderSynchronizationCell'
import { OrderListTableCommentCell } from './components/OrderCommentCell'
import { OrderListDeliveryFilter } from './filters/Delivery'
import { OrderListOrderFilter } from './filters/OrderID'
import { OrderListCreateFilter } from './filters/Create'
import { OrderListStatusFilter } from './filters/Status'
import { OrderListClientFilter } from './filters/Client'
import { OrderListTableContainerCell } from './components/OrderContainerCell'
import { OrderListTableAmountCell } from './components/OrderAmountCell'
import { OrderListTableContextMenuCell } from './components/OrderContextMenu'

export function OrderListTable() {
  const { color } = useTheme()
  const history = useHistory()

  const {
    loading,
    selectedOrders,
    currentDisplayMode,
    palletsAutomation,
    total,
    records,
  } = useSelector(
    (state) => ({
      loading: state.order.loading === 'idle' || state.order.loading === 'pending',
      selectedOrders: state.order.selectedOrders,
      currentDisplayMode: state.order.displayMode,
      palletsAutomation: state.app.marketConfig?.palletsAutomation === '1',
      total: state.order.orderPage.total,
      records: state.order.orderPage.records.map((order) => ({
        ...order,
        positions: [],
      })),
    }),
    objectEqual,
  )

  let orderTableColumns: TableTypes.TableColumn<Order>[] = [
    {
      key: 'id',
      title: (
        <Flexbox w="100%" alignItems="center">
          <OrderListOrderFilter />
          <Text size="xs" color="gray500" ml="s">
            Номер
          </Text>
        </Flexbox>
      ),
      width: 65,
      primary: true,
      render: OrderListTableIdCell,
    },
    {
      key: 'createDate',
      title: (
        <Flexbox w="100%" alignItems="center">
          <OrderListCreateFilter />
          <Text size="xs" color="gray500" ml="s">
            Дата создания
          </Text>
        </Flexbox>
      ),
      width: 90,
      render: OrderListTableCreateDateCell,
    },
    {
      key: 'client',
      title: (
        <Flexbox w="100%" alignItems="center">
          <OrderListClientFilter />
          <Text size="xs" color="gray500" ml="s">
            Клиент
          </Text>
        </Flexbox>
      ),
      render: OrderListTableClientCell,
    },
    {
      key: 'status',
      title: (
        <Flexbox w="100%" alignItems="center">
          <OrderListStatusFilter />
          <Text size="xs" color="gray500" ml="s">
            Состояние заказа
          </Text>
        </Flexbox>
      ),
      width: 140,
      render: OrderListTableStatusCell,
    },
    {
      key: 'comment',
      width: 24,
      render: OrderListTableCommentCell,
    },
    {
      key: 'synchronization',
      width: 24,
      render: (ctx) => <OrderListTableSynchronizationCell order={ctx.row} />,
    },
    {
      key: 'purchases',
      width: 70,
      render: OrderListTablePurchasesCell,
    },
    {
      key: 'courier',
      width: 24,
      render: OrderListTableCourierCell,
    },
    {
      key: 'deliveryDate',
      title: (
        <Flexbox w="100%" alignItems="center">
          <OrderListDeliveryFilter />
          <Text size="xs" color="gray500" ml="s">
            Доставка
          </Text>
        </Flexbox>
      ),
      width: 90,
      render: OrderListTableDeliveryDateCell,
    },
    {
      key: 'container',
      width: 90,
      title: 'Паллет',
      render: OrderListTableContainerCell,
    },
    {
      key: 'amount',
      title: 'На сумму',
      width: 110,
      render: OrderListTableAmountCell,
    },
    {
      key: '_context',
      width: 32,
      render: OrderListTableContextMenuCell,
    },
  ]

  if (!palletsAutomation) {
    orderTableColumns = orderTableColumns.filter(({ key }) => key !== 'container')
  }

  const onRowClick = (ctx: TableTypes.TableRowContext<Order>) => {
    if (loading) return
    const data = [{ order: ctx.row, isSelected: ctx.isSelected }]
    OrderActions.setSelected(data)
  }

  const onCheckboxClick = (ctx: TableTypes.TableRowContext<Order>[]) => {
    if (loading) return
    const data = ctx.map((c) => ({ order: c.row, isSelected: c.isSelected }))
    OrderActions.setSelected(data)
  }

  const onRowDoubleClick = (ctx: TableTypes.TableRowContext<Order>) => {
    if (loading) return
    history.push(`/orders/${ctx.row.id}`)
  }

  return (
    <PageLoading loading={loading}>
      <Table
        selected={selectedOrders}
        onRowClick={onRowClick}
        onCheckboxClick={onCheckboxClick}
        onRowDoubleClick={onRowDoubleClick}
        columns={orderTableColumns}
        data={records}
        overrides={{
          container: {
            userSelect: 'none',
          },
          row: (props) => [
            props.selected && {
              backgroundColor: `${color.green[50].hex()} !important`,
            },
          ],
          headCell: () => [
            {
              backgroundColor: color.background.hex(),
              position: 'sticky',
              top: 'calc(9.5rem + 1px)',
              zIndex: 6,
              padding:
                currentDisplayMode === 'short' ? '0.5rem 0.5rem' : '0.75rem 0.625rem',
            },
          ],
          rowCell: {
            padding:
              currentDisplayMode === 'short' ? '0.5rem 0.5rem' : '1.25rem 0.625rem',
          },
        }}
      />
      {total === 0 && (
        <Flexbox column centered p="m" my="xl">
          <List size="1.5rem" color="gray300" />
          <Paragraph size="s" color="gray400">
            Список пуст
          </Paragraph>
        </Flexbox>
      )}
    </PageLoading>
  )
}
