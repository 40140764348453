export default `
    query ($inn: String) {
        marketUsers(inn: $inn) {
            id
            name
            inn
            exId
        }
    }
`
