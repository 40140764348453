import React, { useEffect, useRef, useState } from 'react'

import { Button, Drop, Flexbox, TextField } from '@stage-ui/core'
import { Search } from '@stage-ui/icons'
import useSelector from 'hooks/useSelector'

import { objectEqual } from 'utils/objectEqual'
import DropTypes from '@stage-ui/core/layout/Drop/types'
import { OrderActions } from 'actions'
import TextFieldTypes from '@stage-ui/core/control/TextField/types'

export function OrderListOrderFilter() {
  const buttonRef = useRef<HTMLButtonElement>(null)
  const dropRef = useRef<DropTypes.Ref>(null)
  const fieldRef = useRef<TextFieldTypes.Ref>(null)

  const { id } = useSelector(
    (state) => ({
      id: state.order.filter.id,
    }),
    objectEqual,
  )

  const [dropVisible, setDropVisible] = useState(false)
  const [searchId, setSearchId] = useState('')

  useEffect(() => {
    if (dropVisible) {
      setSearchId(id || '')
      setTimeout(() => fieldRef.current?.input?.focus(), 1)
    }
  }, [dropVisible])

  const handleSearch = () => {
    OrderActions.setFilter({
      id: searchId,
    })
    setDropVisible(false)
  }

  const handleClear = () => {
    OrderActions.setFilter({
      id: undefined,
    })
    setDropVisible(false)
  }

  return (
    <>
      <Button
        ref={buttonRef}
        color={id ? 'green500' : 'gray500'}
        decoration="text"
        size="xs"
        p={0}
        w="1.25rem"
        h="1.25rem"
        onClick={() => setDropVisible(!dropVisible)}
      >
        <Search size="0.875rem" />
      </Button>
      <Drop
        ref={dropRef}
        target={buttonRef}
        visible={dropVisible}
        justify="start"
        spacing={8}
        onClickOutside={(e, outTarget) => {
          if (outTarget) {
            setDropVisible(false)
          }
        }}
      >
        <Flexbox
          column
          w="16rem"
          borderRadius="s"
          p="s"
          shadow="xl"
          backgroundColor="white"
        >
          <TextField
            ref={fieldRef}
            label="Номер заказа"
            onEnter={handleSearch}
            size="s"
            placeholder="123456"
            value={searchId}
            onChange={(e) => setSearchId(e.target.value)}
          />
          <Flexbox mt="s" justifyContent="flex-end">
            <Button decoration="text" label="Сбросить" size="s" onClick={handleClear} />
            <Button ml="s" label="Найти" size="s" onClick={handleSearch} />
          </Flexbox>
        </Flexbox>
      </Drop>
    </>
  )
}
