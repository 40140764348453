import React, { Fragment } from 'react'

import { Order, Staff } from '@foods-n-goods/server/generated/schema'
import { Divider, Flexbox, Grid, Text } from '@stage-ui/core'
import { Car, CloseCircle, Info } from '@stage-ui/icons'
import { useMouseDrop } from 'components/MouseDrop'
import wordForNum from '@foods-n-goods/client/utils/wordForNum'

type OrderCourierChangeModalRowProps = {
  courier: Staff | null
  currentCourier: Staff['id']
  orders: Array<Order>
  onChange: (id: string) => void
}

export function OrderCourierChangeModalRow(props: OrderCourierChangeModalRowProps) {
  const { orders, courier, currentCourier, onChange } = props

  const courierOrders = courier
    ? orders.filter((o) => o.courier?.id === courier.id)
    : null

  const ordersAlert = useMouseDrop(
    courierOrders &&
      courierOrders.length > 0 &&
      `Назначен на ${courierOrders.length} ${wordForNum(courierOrders.length, [
        'заказ',
        'заказа',
        'заказов',
      ])}`,
  )

  const handleChange = () => {
    onChange(courier?.id || '')
  }

  const isCurrent = orders.length === 1 && orders[0].courier?.id === courier?.id
  const isEmpty = orders[0].courier?.id === currentCourier

  return (
    <>
      <Grid
        templateColumns="1fr 4rem 1.25rem"
        gap="0.5rem"
        p="s m"
        backgroundColor={
          courier?.id === currentCourier || (!currentCourier && !courier)
            ? 'green50'
            : 'transparent'
        }
        onClick={handleChange}
        alignItems="center"
        justifyContent="space-between"
      >
        <Flexbox alignItems="center">
          {courier && (
            <Car
              size="1.125rem"
              mr="s"
              color={courier?.color?.code || 'gray400'}
              type="filled"
            />
          )}
          {!courier && <CloseCircle size="1.125rem" mr="s" color="gray400" />}
          <Flexbox column>
            <Text
              mr="s"
              size="s"
              color={courier ? 'gray900' : 'gray400'}
              weight={600}
              ellipsis
            >
              {courier?.name || courier?.login || 'Нет водителя'}
            </Text>
          </Flexbox>
        </Flexbox>

        {isCurrent && (
          <Text
            gridColumn="2"
            size="s"
            color={orders[0].courier?.id === currentCourier ? 'green500' : 'gray400'}
          >
            Текущий
          </Text>
        )}
        <Info
          display={
            orders.length > 1 && courierOrders && courierOrders.length > 0
              ? 'flex'
              : 'none'
          }
          size="1.25rem"
          color="orange500"
          gridColumn="3"
          {...ordersAlert}
        />
      </Grid>
      <Divider color="gray200" />
    </>
  )
}
