import marketConfigFields from '../fragments/marketConfigFields.gql'

export default `
    mutation marketConfigUpdate($config: MarketConfigInput!) {
        marketConfigUpdate(config: $config) {
            ...marketConfigFields
        }
    }
    ${marketConfigFields}

`
