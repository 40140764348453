import React from 'react'

import { OrderStatusCode } from '@foods-n-goods/client/system/types'
import { Order } from '@foods-n-goods/server/generated/schema'
import { useTheme } from '@stage-ui/core'
import FlexboxTypes from '@stage-ui/core/layout/Flexbox/types'
import { ColorText } from 'components/ColorText'

export const mapStatusToColor = (
  status: OrderStatusCode,
  type: BackgroundType,
  { color }: Stage.Theme,
) => {
  switch (status) {
    case OrderStatusCode.NEW:
      return {
        background:
          type === 'gradient'
            ? `linear-gradient(89.52deg, ${color.green[500].hex()} 0.53%, ${color.green[600].hex()} 97.83%)`
            : color.green[500].hex(),
        color: 'white',
        borderColor: 'green600',
      }
    case OrderStatusCode.PAYED:
      return {
        background: color.green[500].hex(),
        color: 'white',
        borderColor: 'green600',
      }
    case OrderStatusCode.SORTING:
      return {
        background:
          type === 'gradient'
            ? `linear-gradient(268.7deg, ${color.lime.A700.hex()} 0.55%,  ${color.lightGreen.A100.hex()} 79.65%)`
            : color.lightGreen.A100.hex(),
        color: 'lightGreen900',
        borderColor: 'lightGreen300',
      }
    case OrderStatusCode.AWAIT_COURIER:
      return {
        background:
          type === 'gradient'
            ? `linear-gradient(268.7deg, ${color.blue[100].hex()} 0.55%,  ${color.blue[200].hex()} 79.65%)`
            : color.blue[100].hex(),
        color: 'blue800',
        borderColor: 'blue300',
      }
    case OrderStatusCode.PICKED_UP:
      return {
        background:
          type === 'gradient'
            ? `linear-gradient(88.57deg, ${color.blue[500].hex()} 7.2%, ${color.blue[600].hex()} 95.53%)`
            : color.blue[500].hex(),
        color: 'white',
        borderColor: 'blue600',
      }
    case OrderStatusCode.DELIVERY:
      return {
        background:
          type === 'gradient'
            ? `linear-gradient(269.44deg, ${color.purple[500].hex()} 0.43%, ${color.purple[600].hex()} 99.73%)`
            : color.purple[500].hex(),
        color: 'white',
        borderColor: 'purple600',
      }
    case OrderStatusCode.DELIVERED:
      return {
        background:
          type === 'gradient'
            ? `linear-gradient(89.21deg, ${color.white.hex()} 46.4%, ${color.blue[50].hex()} 99.32%)`
            : color.white.hex(),
        color: 'blue600',
        borderColor: 'blue100',
      }
    case OrderStatusCode.NOT_DELIVERED:
      return {
        background:
          type === 'gradient'
            ? `linear-gradient(88.37deg, ${color.orange[500].hex()} 3.45%, ${color.red[600].hex()} 99.25%)`
            : color.orange[500].hex(),
        color: 'white',
        borderColor: 'orange600',
      }
    case OrderStatusCode.OUT_OF_STOCK:
      return {
        background:
          type === 'gradient'
            ? `linear-gradient(88.52deg, ${color.yellow[300].hex()} 1.26%, ${color.yellow[400].hex()} 95.61%)`
            : color.yellow[300].hex(),
        color: 'yellow900',
        borderColor: 'yellow400',
      }
    case OrderStatusCode.PURCHASE_PENDING:
      return {
        background:
          type === 'gradient'
            ? `linear-gradient(89.87deg, ${color.pink[400].hex()} -14.45%, ${color.pink[600].hex()} 126.04%)`
            : color.pink[400].hex(),
        color: 'surface',
        borderColor: 'pink500',
      }
    case OrderStatusCode.PURCHASE_FAILED:
      return {
        background:
          type === 'gradient'
            ? `linear-gradient(266.3deg, ${color.red[100].hex()} -5.63%, ${color.rose[200].hex()} 100%)`
            : color.red[200].hex(),
        color: 'red800',
        borderColor: 'red300',
      }
    case OrderStatusCode.REJECTED:
      return {
        background: color.red[500].hex(),
        color: 'surface',
        borderColor: 'red600',
      }
    case OrderStatusCode.CANCELED:
    default:
      return {
        background: color.white.hex(),
        color: 'gray600',
        borderColor: 'gray200',
      }
  }
}

type BackgroundType = 'gradient' | 'fill'

type StatusIndicatorProps = FlexboxTypes.Props & {
  status: Order['status']
  text?: string
  hint?: string
  type?: BackgroundType
}

export function StatusIndicator(props: StatusIndicatorProps) {
  const { status, text, hint, type = 'gradient', ...flexProps } = props

  const theme = useTheme()
  const statusColors = mapStatusToColor(status.value, type, theme)

  return (
    <ColorText
      borderRadius="5rem"
      p="xs 0.75rem"
      size="xs"
      weight={600}
      hint={hint}
      {...statusColors}
      {...flexProps}
    >
      {text || status.text}
    </ColorText>
  )
}
