import staffFields from '../fragments/staffFields.gql'

export default `
    query staff($staffId: String, $search: String) {
        staff(orderBy: { id: DESC }, staffId: $staffId, search: $search) {
            ...staffFields
        }
    }
    ${staffFields}
`
