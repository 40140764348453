import React from 'react'

import { Flexbox, Text } from '@stage-ui/core'
import { At, Phone } from '@stage-ui/icons'
import { Client } from '@foods-n-goods/server/generated/schema'

type ClientHeaderContactsProps = {
  client: Client
}

export function ClientHeaderContacts(props: ClientHeaderContactsProps) {
  const { client } = props
  const { phone, email } = client

  return (
    <Flexbox>
      <Flexbox alignItems="center">
        <Flexbox
          h="2.5rem"
          w="2.5rem"
          borderWidth="1px"
          borderStyle="solid"
          borderRadius="50%"
          borderColor="gray200"
          centered
        >
          <Phone size="l" color="gray500" />
        </Flexbox>
        <Text ml="s" color="gray500" size="s">
          {phone || 'Нет телефона'}
        </Text>
      </Flexbox>

      <Flexbox alignItems="center" ml="4.5rem">
        <Flexbox
          h="2.5rem"
          w="2.5rem"
          borderWidth="1px"
          borderStyle="solid"
          borderRadius="50%"
          borderColor="gray200"
          centered
        >
          <At size="l" color="gray500" />
        </Flexbox>
        <Text ml="s" color="gray500" size="s">
          {email || 'Нет эл.почты'}
        </Text>
      </Flexbox>
    </Flexbox>
  )
}
