import React, { useRef, useState } from 'react'

import { Block, Button, Calendar, Drop } from '@stage-ui/core'
import { Calendar as CalendarIcon } from '@stage-ui/icons'
import useSelector from 'hooks/useSelector'

import { objectEqual } from 'utils/objectEqual'
import DropTypes from '@stage-ui/core/layout/Drop/types'
import moment from 'moment'
import { OrderActions } from 'actions'

export function OrderListCreateFilter() {
  const buttonRef = useRef<HTMLButtonElement>(null)
  const dropRef = useRef<DropTypes.Ref>(null)

  const [dropVisible, setDropVisible] = useState(false)

  const { createDateStart, createDateEnd } = useSelector(
    (state) => ({
      createDateStart: state.order.filter.createDateStart,
      createDateEnd: state.order.filter.createDateEnd,
    }),
    objectEqual,
  )

  const isActive = createDateStart && createDateEnd

  const onDateChange = (start: Date, end?: Date) => {
    if (!start || !end) return

    const startDate = moment(start).format('YYYY-MM-DD')
    const endDate = moment(end).format('YYYY-MM-DD')

    OrderActions.setFilter({
      createDateStart: startDate,
      createDateEnd: endDate,
    })

    setDropVisible(false)
  }

  const handleClear = () => {
    OrderActions.setFilter({
      createDateEnd: undefined,
      createDateStart: undefined,
    })
    setDropVisible(false)
  }

  return (
    <>
      <Button
        ref={buttonRef}
        color={isActive ? 'green500' : 'gray500'}
        decoration="text"
        size="xs"
        p={0}
        w="1.25rem"
        h="1.25rem"
        onClick={() => setDropVisible(!dropVisible)}
      >
        <CalendarIcon size="0.875rem" />
      </Button>
      <Drop
        ref={dropRef}
        target={buttonRef}
        visible={dropVisible}
        justify="start"
        spacing={8}
        onClickOutside={(e, outTarget) => {
          if (outTarget) {
            setDropVisible(false)
          }
        }}
      >
        <Block borderRadius="s" p="s" shadow="xl" backgroundColor="white">
          <Calendar
            range
            value={
              createDateStart && createDateEnd
                ? [new Date(createDateStart), new Date(createDateEnd)]
                : [undefined, undefined]
            }
            onChange={onDateChange}
          />
          <Button
            mt="s"
            alignSelf="flex-end"
            decoration="text"
            color="gray900"
            label="Очистить"
            onClick={handleClear}
          />
        </Block>
      </Drop>
    </>
  )
}
