import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import type ClientSystem from '@foods-n-goods/client/system/types'
import type { Report, ReportAvailable } from '@foods-n-goods/server/generated/schema'

export type ReportFilter = {
  section?: string
  search?: string
}
export interface ReportState {
  reportAvailable: ClientSystem.StoreData<ReportAvailable[]>
  reports: ClientSystem.StoreData<Report[]>
  filter: ReportFilter
}

export const initialState: ReportState = {
  reportAvailable: {
    loading: 'idle',
    data: [],
  },
  reports: {
    loading: 'idle',
    data: [],
  },
  filter: {},
}

const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    reportAvailableFetch(state) {
      state.reportAvailable.loading = 'pending'
      state.reportAvailable.error = null
    },

    reportAvailableResolve(state, action: PayloadAction<ReportAvailable[]>) {
      state.reportAvailable.loading = 'resolved'
      state.reportAvailable.data = action.payload
    },

    reportAvailableReject(state, action: PayloadAction<ClientSystem.Error>) {
      state.reportAvailable.loading = 'rejected'
      state.reportAvailable.error = action.payload
      state.reportAvailable.data = []
    },

    reportsFetch(state) {
      state.reports.loading = 'pending'
      state.reports.error = null
    },

    reportsResolve(state, action: PayloadAction<Report[]>) {
      state.reports.loading = 'resolved'
      state.reports.data = action.payload
    },

    reportsUpsert(state, action: PayloadAction<Report>) {
      state.reports.loading = 'resolved'
      const idx = state.reports.data.findIndex(
        (report) => report.id === action.payload.id,
      )
      if (idx < 0) {
        state.reports.data = state.reports.data.concat(action.payload)
      } else {
        state.reports.data[idx] = action.payload
      }
    },

    reportsReject(state, action: PayloadAction<ClientSystem.Error>) {
      state.reports.loading = 'rejected'
      state.reports.error = action.payload
      state.reports.data = []
    },

    setFilter(state, action: PayloadAction<Partial<ReportFilter>>) {
      state.filter = {
        ...state.filter,
        ...action.payload,
      }
    },

    clearFilter(state) {
      state.filter = {}
    },

    clear() {
      return { ...initialState }
    },
  },
})

export default reportSlice
