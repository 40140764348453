import React from 'react'

import { Text } from '@stage-ui/core'
import TextTypes from '@stage-ui/core/content/Text/types'

type QualityProductNameProps = TextTypes.Props

const QualityProductName: React.FC<QualityProductNameProps> = (props) => {
  return <Text size="m" {...props} />
}

export default QualityProductName
