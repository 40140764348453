import React from 'react'

import { Flexbox, Pageswitch, Select, useTheme } from '@stage-ui/core'

type OnChangeArgs = { page: number; pageSize: number }

type PageSwitchProps = {
  total: number
  page: number
  pageSize: number
  onChange: (args: OnChangeArgs) => void
}

const pageSizeOptions = [
  { value: 10, text: '10' },
  { value: 20, text: '20' },
  { value: 40, text: '40' },
  { value: 100, text: '100' },
  { value: 500, text: '500' },
]

export function PageSwitch(props: PageSwitchProps) {
  const { total, pageSize, page, onChange } = props

  const theme = useTheme()

  if (!total) return null

  const counter = Math.min(
    pageSize,
    Math.max(
      total - Number(page || 1) * pageSize,
      total - (Number(page || 1) - 1) * pageSize,
    ),
  )

  return (
    <Flexbox
      backgroundColor="background"
      py="m"
      style={{
        position: 'sticky',
        bottom: 0,
        zIndex: 6,
      }}
    >
      <Flexbox flex={1} justifyContent="space-between" alignItems="center">
        <Select
          w="3rem"
          size="s"
          textColor="gray500"
          decoration="none"
          defaultValues={pageSizeOptions.filter(
            (x) => x.value === Number(pageSize || 40),
          )}
          options={pageSizeOptions}
          onChange={(v) =>
            onChange({
              page,
              pageSize: v[0].value as number,
            })
          }
        />
        {total > counter && (
          <Pageswitch
            length={total}
            pageSize={pageSize}
            value={page}
            onChange={(p) => {
              onChange({
                pageSize,
                page: p,
              })
            }}
            overrides={(t) => ({
              pageButton: (state) => [
                {
                  width: '1.5rem',
                  height: '1.5rem',
                  borderRadius: '0.25rem',
                  padding: 0,
                  alignItems: 'center',
                  justifyContent: 'center',
                  boxShadow: 'none',
                  background: t.color.surface.hex(),
                  border: `0.0625rem solid`,
                  borderColor: t.color.gray[200].hex(),
                  color: t.color.gray[500].hex(),
                },
                state.current && {
                  background: t.color.primary.hex(),
                  borderColor: t.color.primary.hex(),
                  color: t.color.surface.hex(),
                },
              ],
              pageButtonText: () => [
                {
                  ...theme.assets.typography.text.xs,
                },
              ],
            })}
          />
        )}
      </Flexbox>
    </Flexbox>
  )
}
