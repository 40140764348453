import React from 'react'

import { Order } from '@foods-n-goods/server/generated/schema'
import TableTypes from '@stage-ui/core/data/Table/types'
import { StatusIndicator } from 'components/StatusIndicator'
import { openOrderStatusChangeModal } from 'components/Modals/OrderStatusChange'

export function OrderListTableStatusCell(ctx: TableTypes.TableCellContext<Order>) {
  const { row } = ctx
  const { status } = row

  const handleStatusChange = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    openOrderStatusChangeModal([row])
  }

  return <StatusIndicator onClick={handleStatusChange} status={status} w="fit-content" />
}
