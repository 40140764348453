import React from 'react'

import { Flexbox, Header, Switch, Text, useTheme } from '@stage-ui/core'
import Avatar from 'components/Avatar'
import { openClientStatusChangeModal } from 'components/Modals/ClientStatusChange'
import { RegisterIconStatus } from 'pages/clients/components/RegisterIconStatus'
import moment from 'utils/moment'
import { Client } from '@foods-n-goods/server/generated/schema'

import { ClientStatusBanner } from './StatusBanner'
import { ClientHeaderContacts } from './Contacts'

type ClientHeaderProps = {
  client: Client
  isModal: boolean
}

export function ClientHeader(props: ClientHeaderProps) {
  const { client, isModal } = props
  const { color } = useTheme()

  const { id, markets, name = '', photo, identifier, updateDate } = client

  const isBlocked = markets[0]?.status === 'BLOCKED'
  const clientName = client.markets[0]?.alias || name || identifier || 'Неопознанный енот'

  return (
    <Flexbox
      column
      backgroundColor="background"
      pb="m"
      style={{
        position: 'sticky',
        top: isModal ? 0 : '10.3125rem',
        zIndex: 1,
      }}
    >
      <Flexbox flex={1} justifyContent="space-between">
        <Avatar
          size="7.5rem"
          user={{
            id,
            name: clientName,
            photo,
            color: color.gray[400].hex(),
            online: false,
            updateDate,
          }}
        />

        <Flexbox flex={1} ml="2.5rem" column justifyContent="space-between">
          <Flexbox column>
            <Flexbox wrap="nowrap" alignItems="center">
              <RegisterIconStatus {...client} />
              <Text ml="s" size="s" color="gray500">
                Регистрация {moment(client.createDate.toString()).format('DD.MM.YYYY')}
              </Text>
            </Flexbox>
            <Header mt="s" size="xs">
              {clientName}
            </Header>
          </Flexbox>
          <ClientHeaderContacts client={client} />
        </Flexbox>

        {(markets[0]?.status === 'BLOCKED' || markets[0]?.status === 'APPROVED') && (
          <Flexbox alignSelf="flex-start" alignItems="center">
            <Text size="s" color="gray500" weight={400}>
              {isBlocked ? 'Разблокировать' : 'Заблокировать'}
            </Text>
            <Switch
              ml="s"
              h="min-content"
              textColor="gray300"
              checked={isBlocked}
              onChange={() => {
                openClientStatusChangeModal(client)
              }}
              overrides={{
                check: ({ checked }) => [
                  checked && {
                    background: color.red[500].hex(),
                  },
                ],
              }}
            />
          </Flexbox>
        )}
      </Flexbox>
      <ClientStatusBanner client={client} />
    </Flexbox>
  )
}
