import ClientSystem from '@foods-n-goods/client/system/types'
import T, { ChatTypes } from '@foods-n-goods/server/src/socket/chat/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type ChatFilter = {
  section?: string
  currentId?: string

  createSection?: string
  createPage: number
  createPageSize: number
}

const initialChatFilter = {
  createPage: 1,
  createPageSize: 20,
}

export interface ChatState {
  loading: ClientSystem.Loading
  error: string
  rooms: ChatTypes.Room[]
  activeRoomId: string
  roomActivity: {
    [roomId: string]: {
      isFetchingHistory: boolean
      isTyping: boolean
    }
  }
  filter: ChatFilter
}

export const initialState: ChatState = {
  loading: 'idle',
  error: '',
  rooms: [],
  activeRoomId: '',
  roomActivity: {},
  filter: initialChatFilter,
}

const dialogSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    loading(state) {
      state.loading = 'pending'
    },
    reject(state, action: PayloadAction<ChatState['error']>) {
      state.loading = 'rejected'
      state.error = action.payload
    },

    roomsSet(state, action: PayloadAction<ChatState['rooms']>) {
      state.rooms = action.payload.map((room) => {
        state.roomActivity[room.roomId] = {
          isFetchingHistory: false,
          isTyping: false,
        }
        const storedRoom = state.rooms.find((r) => r.roomId === room.roomId)
        return storedRoom || room
      })
      state.loading = 'resolved'
      state.error = initialState.error
    },
    roomActivate(state, action: PayloadAction<string>) {
      state.activeRoomId = action.payload
    },
    roomAppend(state, action: PayloadAction<ChatTypes.Room>) {
      state.rooms = [action.payload].concat(state.rooms)
      state.roomActivity[action.payload.roomId] = {
        isFetchingHistory: false,
        isTyping: false,
      }
    },
    roomUpdate(state, action: PayloadAction<T.Data['RoomUpdateEvent']['payload']>) {
      const index = state.rooms.findIndex(
        ({ roomId }) => action.payload.roomId === roomId,
      )
      if (index >= 0) {
        state.rooms[index] = {
          ...state.rooms[index],
          ...action.payload,
          messages: state.rooms[index].messages,
          messagesUnread: state.rooms[index].messagesUnread,
        }
      }
    },
    roomRemove(state, action: PayloadAction<T.Data['RoomDeleteEvent']['payload']>) {
      state.activeRoomId = ''
      state.rooms = state.rooms.filter((room) => room.roomId !== action.payload.roomId)
    },

    roomMessagesRead(state, action: PayloadAction<ChatTypes.Room['roomId']>) {
      const index = state.rooms.findIndex(({ roomId }) => roomId === action.payload)
      if (index >= 0) {
        state.rooms[index].messagesUnread = false
      }
    },
    roomMessageAppend(state, action: PayloadAction<T.Message>) {
      const index = state.rooms.findIndex(
        ({ roomId }) => roomId === action.payload.roomId,
      )
      if (index >= 0) {
        state.rooms[index].messagesUnread = true
        state.rooms[index].time = new Date().getTime()
        state.rooms[index].messages = state.rooms[index].messages.concat(action.payload)
      }
    },
    roomActivity(
      state,
      action: PayloadAction<{ roomId: string } & Partial<ChatState['roomActivity'][0]>>,
    ) {
      state.roomActivity[action.payload.roomId] = {
        ...state.roomActivity[action.payload.roomId],
        ...action.payload,
      }
    },

    messagesSet(state, action: PayloadAction<T.Message[]>) {
      if (action.payload.length) {
        const index = state.rooms.findIndex(
          ({ roomId }) => roomId === action.payload[0].roomId,
        )
        if (index >= 0) {
          state.rooms[index].messages = action.payload
        }
      }
    },

    messageRead(state, action: PayloadAction<T.Data['ReadEvent']['payload']>) {
      const index = state.rooms.findIndex(
        ({ roomId }) => roomId === action.payload.roomId,
      )
      if (index >= 0) {
        const idx = state.rooms[index].messages.findIndex(
          ({ id }) => id === action.payload.id,
        )
        if (idx >= 0) {
          state.rooms[index].messages[idx].readTime = action.payload.readTime
        }
      }
    },

    setFilter(state, action: PayloadAction<Partial<ChatFilter>>) {
      state.filter = {
        ...state.filter,
        ...action.payload,
      }
    },

    clearFilter(state) {
      state.filter = initialChatFilter
    },

    clear() {
      return initialState
    },
  },
})

export default dialogSlice
