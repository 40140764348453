import React from 'react'

import { Table, Text } from '@stage-ui/core'
import { TableLoadingStab } from 'components/TableLoadingStab'
import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'

import { columns } from './columns'

function ScaleDevices() {
  const { loading, data } = useSelector(
    ({ devices }) => ({
      loading: devices.loading,
      data: devices.data.filter((device) => device.type === 'SCALE'),
    }),
    objectEqual,
  )

  return (
    TableLoadingStab(loading) || (
      <Table
        columns={columns}
        data={data}
        footer={
          data.length === 0 && (
            <Text size="s" color="gray500">
              Нет данных
            </Text>
          )
        }
      />
    )
  )
}

export default ScaleDevices
