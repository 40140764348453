import React, { useEffect } from 'react'

import { Flexbox } from '@stage-ui/core'

import { DeliveryActions } from 'actions'

import { CourierOrdersView } from './CourierOrdersView'
import { CourierSideView } from './CourierSideView'

export function LogisticsCouriers() {
  useEffect(() => {
    return () => {
      DeliveryActions.setFilter({
        couriersTab: 'all',
      })
    }
  }, [])

  return (
    <Flexbox h="calc(100vh - 12.5rem)">
      <CourierSideView />
      <CourierOrdersView />
    </Flexbox>
  )
}
