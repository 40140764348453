import React from 'react'

import { ReportTable } from './components/ReportTable'

import { Toolbar } from './components/Toolbar'

export function ReportList() {
  return (
    <>
      <Toolbar />
      <ReportTable />
    </>
  )
}
