import React from 'react'

import { OrderedProduct } from '@foods-n-goods/server/generated/schema'
import { Button, Flexbox, Text } from '@stage-ui/core'
import { PurchasesActions } from 'actions'

type OrderPositionPurchaseModalProps = {
  close: () => void
  id: OrderedProduct['id']
  disabled: boolean
}

export const OrderPositionPurchaseModal: React.FC<OrderPositionPurchaseModalProps> = (
  props,
) => {
  const { close, id, disabled } = props

  const setPositionOutOfStock = () => {
    if (disabled) return
    PurchasesActions.setPositionOutOfStock({ orderId: id })
  }

  const handleConfirm = () => {
    setPositionOutOfStock()
    close()
  }

  return (
    <Flexbox column>
      <Text color="gray900">Вы уверены, что хотите отправить позицию в докупку?</Text>
      <Flexbox justifyContent="flex-end" mt="xl">
        <Button
          mr="s"
          label="Отмена"
          color="gray500"
          textColor="gray900"
          decoration="text"
          onClick={close}
        />
        <Button label="Отправить" onClick={handleConfirm} />
      </Flexbox>
    </Flexbox>
  )
}
