import React from 'react'

import { Flexbox, Text } from '@stage-ui/core'
import FlexboxTypes from '@stage-ui/core/layout/Flexbox/types'

type QualityScaleProps = {
  name: string
} & FlexboxTypes.Props

const QualityScale: React.FC<QualityScaleProps> = (props) => {
  const { name, ...flexboxProps } = props

  return (
    <Flexbox
      centered
      flex={1}
      borderRadius="s"
      px="m"
      backgroundColor="gray200"
      style={{
        visibility: name ? 'initial' : 'hidden',
        boxSizing: 'border-box',
        whiteSpace: 'nowrap',
      }}
      {...flexboxProps}
    >
      <Text my="0.1875rem" size="xs" color="gray500">
        {name}
      </Text>
    </Flexbox>
  )
}

export default QualityScale
