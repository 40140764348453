import ClientSystem from '@foods-n-goods/client/system/types'
import {
  Purchase,
  PurchasePage,
  PurchaseProduct,
} from '@foods-n-goods/server/generated/schema'
import { SocketType } from '@foods-n-goods/server/src/socket/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import moment from 'moment'

export type PurchasesFilter = {
  section?: string
  page: number
  pageSize: number
  createDateStart: string
  createDateEnd: string
  purchaserId?: string
  search?: string
}

export interface PurchasesState {
  loading: ClientSystem.Loading
  data: {
    records: PurchasePage['records']
    total: PurchasePage['total']
  }
  filter: PurchasesFilter
  error: RequestError | null

  byProductsLoading: ClientSystem.Loading
  byProducts: PurchaseProduct[]
  statistics: SocketType.PurchasesStat
}

const initialStatistics = {
  all: 0,
  confirmed: 0,
  failed: 0,
  inRepurchase: 0,
}

const initialFilter = {
  createDateStart: moment().format('YYYY-MM-DD'),
  createDateEnd: moment().format('YYYY-MM-DD'),
  page: 1,
  pageSize: 40,
}

export const initialState: PurchasesState = {
  loading: 'idle',
  data: {
    records: [],
    total: 0,
  },
  filter: initialFilter,
  error: null,

  byProductsLoading: 'idle',
  byProducts: [],

  statistics: initialStatistics,
}

const purchasesSlice = createSlice({
  name: 'purchases',
  initialState,
  reducers: {
    fetch(state) {
      state.loading = 'pending'
    },
    resolve(state, action: PayloadAction<PurchasesState['data']>) {
      state.data = action.payload
      state.loading = 'resolved'
      state.error = initialState.error
    },
    reject(state, action: PayloadAction<PurchasesState['error']>) {
      state.loading = 'rejected'
      state.error = action.payload
    },

    byProductsFetch(state) {
      state.byProductsLoading = 'pending'
    },
    byProductsResolve(state, action: PayloadAction<PurchasesState['byProducts']>) {
      state.byProducts = action.payload
      state.byProductsLoading = 'resolved'
    },
    byProductsReject(state) {
      state.byProductsLoading = 'rejected'
    },

    upsert(state, action: PayloadAction<Purchase>) {
      const idx = state.data.records.findIndex((r) => r.id === action.payload.id)
      const sliceTo =
        state.data.records.length >= state.filter.pageSize
          ? -1
          : state.data.records.length

      if (idx >= 0) {
        state.data.records[idx] = action.payload
      } else {
        const nextIndex = Math.max(
          state.data.records.findIndex((purchase) => purchase.id < action.payload.id),
          0,
        )
        state.data.records = [
          ...state.data.records.slice(0, nextIndex),
          action.payload,
          ...state.data.records.slice(nextIndex, sliceTo),
        ]
        state.data.total += 1
      }
    },

    removeById(state, { payload }: PayloadAction<Purchase['id'][]>) {
      const records = state.data.records.filter((r) => !payload.includes(r.id))
      state.data.records = records
      state.data.total = records.length
    },

    removeByPositionId(state, { payload }: PayloadAction<Purchase['id'][]>) {
      const records = state.data.records.filter((r) => {
        if (r.orderId) return !payload.includes(r.orderId)
        return true
      })
      state.data.records = records
      state.data.total = records.length
    },

    updateStatistics(state, { payload }: PayloadAction<SocketType.PurchasesStat>) {
      state.statistics = payload
    },

    setFilter(state, action: PayloadAction<Partial<PurchasesFilter>>) {
      state.filter = {
        ...state.filter,
        ...action.payload,
      }
    },

    clearFilter(state) {
      state.filter = initialFilter
    },

    clear(state) {
      return initialState
    },
  },
})

export default purchasesSlice
