import React, { useEffect, useState } from 'react'

import { Flexbox } from '@stage-ui/core'
import FlexboxTypes from '@stage-ui/core/layout/Flexbox/types'
import { AlertTriangle } from '@stage-ui/icons'
import { useMouseDrop } from 'components/MouseDrop'

type SelfPhoneInfoData = {
  backgroundModeEnabled: boolean
  batteryLife: number
  device: string
  gpsAvailable: boolean
  locationServicesEnabled: boolean
  networkAvailable: boolean
  os: string
  staffId: string
  uptime: number
}

type ErrorIndicatorProps = FlexboxTypes.Props & {
  courierId: string
  selfPhoneInfo: string
}

export function ErrorIndicator(props: ErrorIndicatorProps) {
  const { courierId, selfPhoneInfo, ...restProps } = props
  const [error, setError] = useState('')
  const hint = useMouseDrop(error)

  useEffect(() => {
    try {
      const info: SelfPhoneInfoData = JSON.parse(selfPhoneInfo)
      if (Object.keys(info).length === 0) {
        throw new Error('Нет данных с телефона')
      }
      if (!info.networkAvailable) {
        setError('Сеть выключена')
      }
      if (!info.backgroundModeEnabled) {
        setError('Фоновый режим отключен')
      }
      if (!info.locationServicesEnabled) {
        setError('Location service выключен')
      }
      if (!info.gpsAvailable) {
        setError('GPS недоступен')
      }
    } catch (error) {
      setError(error.messsage)
    }
  }, [courierId, selfPhoneInfo])

  if (!error) {
    return null
  }

  return (
    <Flexbox centered ml="xs" {...hint} {...restProps}>
      <AlertTriangle color="orange500" />
    </Flexbox>
  )
}
