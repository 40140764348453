import {
  Client,
  Staff,
  Product,
  Order,
  Purchase,
} from '@foods-n-goods/server/generated/schema'

type ObjectType = Client | Staff | Product | Order | Purchase
const uniqueArray = <T extends ObjectType>(arr: Array<T>, field: keyof T = 'id') =>
  arr.filter(
    (v, i, a) => v[field] === null || a.findIndex((t) => t[field] === v[field]) === i,
  )

export default uniqueArray
