import React from 'react'

import { StaffExtended } from '.'

import { Text } from '@stage-ui/core'

import TableTypes from '@stage-ui/core/data/Table/types'

export const StaffListRoleCell = (ctx: TableTypes.TableCellContext<StaffExtended>) => {
  const { row } = ctx

  return (
    <Text size="s" color="gray500">
      {row.roleText}
    </Text>
  )
}
