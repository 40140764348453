import React from 'react'

import { Flexbox, Text } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import useSelector from 'hooks/useSelector'
import { PurchaseProduct } from '@foods-n-goods/server/generated/schema'
import { objectEqual } from 'utils/objectEqual'

export function OrderQuantityCell(ctx: TableTypes.TableCellContext<PurchaseProduct>) {
  const { row } = ctx

  const units = useSelector((state) => state.app.meta?.units, objectEqual)
  const unitName = units?.find((u) => u.value === Number(row.unitId))?.shortText

  const quantity = row.unitId === '1' ? row.orderQuantity.toFixed(3) : row.orderQuantity

  return (
    <Flexbox>
      <Text size="s" color="gray900" w="4rem">
        {quantity}
      </Text>
      <Text size="s" ml="xs" color="gray400">
        {unitName}
      </Text>
    </Flexbox>
  )
}
