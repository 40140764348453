import React from 'react'

import { Divider, Flexbox } from '@stage-ui/core'

import { LogisticsProgressBar } from './ProgressBar'

export function LogisticsHeader() {
  return (
    <Flexbox alignItems="flex-end" h="100%">
      <Divider h="100%" w="0.0625rem" color="gray200" mx="2rem" />
      <LogisticsProgressBar />
    </Flexbox>
  )
}
