import React from 'react'

import { Flexbox } from '@stage-ui/core'
import FlexboxTypes from '@stage-ui/core/layout/Flexbox/types'
import { Pin } from '@stage-ui/icons'
import { useMouseDrop } from 'components/MouseDrop'

export function NoGpsIndicator(props: FlexboxTypes.Props) {
  const hint = useMouseDrop('Нет данных GPS')

  return (
    <Flexbox centered ml="xs" {...hint} {...props}>
      <Pin color="red500" />
    </Flexbox>
  )
}
