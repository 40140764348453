import React from 'react'

import { Flexbox, Text } from '@stage-ui/core'
import moment from 'moment'

type DateTimeProps = {
  date?: Date | null
  short?: boolean
}

export function DateTime(props: DateTimeProps) {
  const { date, short = false } = props

  if (!date) return null

  const topFormat = short ? 'HH:mm' : 'HH:mm:ss'
  const bottomFormat = short ? 'DD MMMM' : 'DD MMMM, YYYY'

  return (
    <Flexbox column>
      <Text color="gray900">{moment(date.toString()).format(topFormat)}</Text>
      <Text size="xs" color="gray500">
        {moment(date.toString()).format(bottomFormat)}
      </Text>
    </Flexbox>
  )
}
