import React from 'react'

import TableTypes from '@stage-ui/core/data/Table/types'
import { Flexbox, Meter, Text } from '@stage-ui/core'
import { Order } from '@foods-n-goods/server/generated/schema'
import { OrderStatusCode } from '@foods-n-goods/client/system/types'

export function OrderPercentPaidCell(ctx: TableTypes.TableCellContext<Order>) {
  const { row } = ctx
  const { positions, status } = row

  if (!positions) return null

  // @Alexander: сделал через quantity и quantityAfter потому что нужно получить разницу по индивидуальным товарам в позиции.
  // changed не дает этих данных ....
  const percentBuy1 = positions.reduce(
    (acc, val) => {
      acc.notAccepted +=
        Number(val.quantityAfter) > val.quantity
          ? val.quantity
          : Number(val.quantityAfter)
      acc.total += val.quantity
      return acc
    },
    { total: 0, notAccepted: 0 },
  )

  const ratio = Number((100 * (percentBuy1.notAccepted / percentBuy1.total)).toFixed(0))

  if (
    !positions.length ||
    percentBuy1.total === 0 ||
    Number(status.value) !== OrderStatusCode.DELIVERED
  ) {
    return (
      <Text w="3rem" size="s" style={{ whiteSpace: 'nowrap' }}>
        Нет данных
      </Text>
    )
  }

  return (
    <Flexbox justifyContent="space-between" alignItems="center" wrap="nowrap">
      <Text w="3rem" size="s">
        {ratio}%
      </Text>
      <Meter size="s" ml="s" w="100%" value={ratio} color="green500" />
    </Flexbox>
  )
}
