import React, { useEffect } from 'react'

import { ReviewActions } from 'actions'
import LoadingData from 'components/LoadingData'
import { Section } from 'components/Section'
import useSelector from 'hooks/useSelector'
import { Route, Switch, useLocation } from 'react-router-dom'

import { objectEqual } from 'utils/objectEqual'

import ReviewsTable from './Table'
import ReviewView from './View'

export function Reviews() {
  const dataResolved = useSelector(
    ({ review: { loading } }) => loading === 'resolved',
    objectEqual,
  )
  const location = useLocation()

  useEffect(() => {
    if (!dataResolved) {
      ReviewActions.getAll()
    }
  }, [])

  return (
    <Section title="Отзывы">
      <LoadingData storeKey="review">
        <Switch location={location}>
          <Route exact path="/reviews" component={ReviewsTable} />
          <Route exact path="/reviews/:id" component={ReviewView} />
        </Switch>
      </LoadingData>
    </Section>
  )
}
