/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useEffect } from 'react'

import { Divider, Flexbox, Header, Spinner, Text, useTheme } from '@stage-ui/core'
import { ArrowLeftUp, ArrowRightDown, Info } from '@stage-ui/icons'

import { Client } from '@foods-n-goods/server/generated/schema'
import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'
import { ClientActions } from 'actions'
import { rub } from 'utils/rub'
import { Line } from 'react-chartjs-2'
import { MONTHS } from 'store/app'

type ClientAverageCheckProps = {
  client: Client
}

export function ClientAverageCheck(props: ClientAverageCheckProps) {
  const { client } = props

  const { color } = useTheme()
  const clientAvarageCheck = useSelector(
    (state) => state.client.clientAvarageCheck,
    objectEqual,
  )

  useEffect(() => {
    ClientActions.fetchClientAvarageCheck(client.id)
  }, [])

  let amount = 0
  let direction = 0

  if (clientAvarageCheck.data.length) {
    amount = clientAvarageCheck.data[clientAvarageCheck.data.length - 1].value
    const amount2 = clientAvarageCheck.data[clientAvarageCheck.data.length - 2]?.value
    if (amount2) {
      direction = amount > amount2 ? 1 : -1
    }
  }

  return (
    <Flexbox
      column
      decoration="surface"
      borderRadius="m"
      p="1.25rem"
      pt="2.5rem"
      borderColor="gray200"
    >
      <Flexbox column mb="m" justifyContent="space-between">
        <Header size="s">Средний чек</Header>
        <Divider color="gray200" my="0.75rem" />
        <Flexbox alignItems="center">
          {clientAvarageCheck.loading !== 'resolved' ? (
            <Flexbox alignItems="center">
              <Spinner mr="s" my="s" />
              <Text size="s" color="gray500">
                Загрузка данных
              </Text>
            </Flexbox>
          ) : (
            <>
              {direction === -1 && (
                <Flexbox
                  borderRadius="0.125rem"
                  backgroundColor="red500"
                  h="1rem"
                  w="1rem"
                  centered
                >
                  <ArrowRightDown color="white" size="s" />
                </Flexbox>
              )}
              {direction === 0 && (
                <Flexbox
                  borderRadius="0.125rem"
                  backgroundColor="gray300"
                  h="1rem"
                  w="1rem"
                  centered
                >
                  <Info color="white" size="s" />
                </Flexbox>
              )}
              {direction === 1 && (
                <Flexbox
                  borderRadius="0.125rem"
                  backgroundColor="green500"
                  h="1rem"
                  w="1rem"
                  centered
                >
                  <ArrowLeftUp color="white" size="s" />
                </Flexbox>
              )}

              <Header size="s" ml="s">
                {rub(amount)}
              </Header>
            </>
          )}
        </Flexbox>
      </Flexbox>
      {clientAvarageCheck.data.length > 1 && (
        <Line
          options={{
            scales: {
              y: {
                display: false,
              },
            },
            plugins: {
              legend: {
                display: false,
              },
              title: {
                display: false,
              },
              tooltip: {
                displayColors: false,
                callbacks: {
                  label: (item) => rub(item.raw),
                },
              },
            },
          }}
          data={{
            labels: clientAvarageCheck.data.map((x) => MONTHS[x.month - 1]),
            datasets: [
              {
                label: 'Средний чек',
                borderWidth: 2,
                pointRadius: 4,
                pointHoverRadius: 6,
                data: clientAvarageCheck.data.map((x) => x.value),
                borderColor:
                  direction > 0 ? color.green[500].hex() : color.red[500].hex(),
                backgroundColor:
                  direction > 0 ? color.green[200].hex() : color.red[200].hex(),
              },
            ],
          }}
        />
      )}
      <Divider my="m" color="gray100" />
      <Text size="s" color="gray500" weight={400}>
        Учитываются все доставленные заказы
      </Text>
    </Flexbox>
  )
}
