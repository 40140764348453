import React, { useMemo } from 'react'

import { Staff } from '@foods-n-goods/server/generated/schema'
import { Block, Button, Flexbox, modal, Paragraph, Text, useTheme } from '@stage-ui/core'
import { Route } from '@stage-ui/icons'
import TwoDots from 'components/Icons/TwoDots'
import { openStaffModal } from 'components/Modals/StaffView'
import { useMouseDropMenu } from 'components/MouseMenuDrop'
import useSelector from 'hooks/useSelector'
import { RoomEditForm } from 'pages/dialogs/Configurate'
import { makeRoute } from 'pages/logistics/utils/makeRoute'
import { history } from 'Router/MainView'
import { humanize } from 'pages/logistics/utils/humanize'
import { objectEqual } from 'utils/objectEqual'

const openCourierProfile = (courier: Staff) => {
  openStaffModal(courier.id)
}

const showRoomEditForm = (find?: string) => {
  modal({
    title: 'Создание группового чата',
    overlayClose: false,
    overrides: (t) => ({
      window: () => [
        {
          padding: '1.5rem',
          background: t.color.gray[50].hex(),
        },
      ],
    }),
    render: (close) => (
      <RoomEditForm
        onClose={() => {
          close()
          history.push('/dialogs')
        }}
        find={find}
      />
    ),
  })
}

export function CourierHeaderView() {
  const { staffs, courierId, courierRoute, deliveryOrders } = useSelector(
    (state) => ({
      staffs: state.staff.data,
      courierId: state.delivery.currentCourier,
      courierRoute: state.delivery.courierRoute,
      deliveryOrders: state.delivery.orderPage.records.map((order) => ({
        ...order,
        positions: [],
      })),
    }),
    objectEqual,
  )

  const courier = staffs.find((staff) => staff.id === courierId)
  const orders = deliveryOrders.filter((order) => order.courier?.id === courierId)

  const theme = useTheme()

  const humanizedRoute = useMemo(
    () => ({
      time: humanize.seconds(courierRoute?.time),
      length: humanize.meters(courierRoute?.length),
    }),
    [courierRoute],
  )

  const contextMenu = useMouseDropMenu<HTMLButtonElement>(
    [
      { text: 'Чат с водителем', icon: 'MessageSquare', value: 'CHAT' },
      { text: 'Карточка водителя', icon: 'Car', value: 'COURIER' },
    ],
    ({ value }) => {
      if (!courier) {
        return
      }
      if (value === 'COURIER') {
        openCourierProfile(courier)
      }
      if (value === 'CHAT') {
        showRoomEditForm(courier.name || '')
      }
    },
  )

  if (!courier) {
    return null
  }

  return (
    <Block
      p="m"
      borderColor="gray200"
      style={{
        borderBottomWidth: '0.25rem',
        borderBottomStyle: 'solid',
      }}
    >
      <Flexbox alignItems="flex-start">
        <Block
          mr="s"
          borderRadius="0.125rem"
          w="0.25rem"
          h="1.5rem"
          backgroundColor={courier.color?.code || 'gray200'}
        />
        <Paragraph
          flex={1}
          color="gray900"
          weight={600}
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {courier.name || courier.login}
        </Paragraph>
        <Button
          flexShrink={0}
          mr="-0.25rem"
          decoration="text"
          color="gray500"
          p={0}
          w="1.5rem"
          h="1.5rem"
          {...contextMenu}
        >
          <TwoDots fillColor={theme.color.gray[500].hex()} />
        </Button>
      </Flexbox>
      {(!!courier.vehicleRegPlate || !!courier.vehicleModel) && (
        <Flexbox mt="s">
          <Flexbox flex={1} alignItems="center">
            {courier.vehicleRegPlate && (
              <Text
                size="xs"
                p="0.125rem 0.25rem"
                weight={300}
                borderColor="gray400"
                borderRadius="0.25rem"
                borderWidth="1px"
                borderStyle="solid"
                backgroundColor="surface"
                mr="s"
              >
                {courier.vehicleRegPlate
                  .replace(/(\d+)([\D+]+)/g, '$1 $2')
                  .replace(/([\D+]+)(\d+)/g, '$1 $2')}
              </Text>
            )}
            {!!courier.vehicleModel && <Text size="xs">{courier.vehicleModel}</Text>}
          </Flexbox>
        </Flexbox>
      )}
      <Flexbox alignItems="center" mt="0.875rem">
        <Button
          color="blue500"
          size="s"
          disabled={orders.length === 0}
          onClick={() => makeRoute(courier, orders, true)}
          rightChild={<Route />}
          label="Маршрут"
          px="s"
        />
        {!!courierRoute && (
          <Flexbox ml="s" flex={1} alignItems="center" justifyContent="flex-end">
            <Text color="gray900" size="s">
              {humanizedRoute.time}
            </Text>
            <Text color="gray500" size="s" mx="s">
              |
            </Text>
            <Text color="gray900" size="s">
              {humanizedRoute.length}
            </Text>
          </Flexbox>
        )}
      </Flexbox>
    </Block>
  )
}
