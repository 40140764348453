import React from 'react'

import { OrderStatusCode } from '@foods-n-goods/client/system/types'
import { Order } from '@foods-n-goods/server/generated/schema'
import { Button, Flexbox, Grid, modal } from '@stage-ui/core'
import { Archive, ArrowRight, Car, Pricetag, Printer, Swap } from '@stage-ui/icons'
import { OrderActions } from 'actions'
import { ColorText } from 'components/ColorText'
import { useMouseDrop } from 'components/MouseDrop'
import useSelector from 'hooks/useSelector'

import { openOrderStatusChangeModal } from 'components/Modals/OrderStatusChange'

import { objectEqual } from 'utils/objectEqual'

import { openOrderCourierChangeModal } from 'components/Modals/OrderCourierChange'

import { openDevicePrinterModal } from 'components/Modals/DevicePrinterSelect'

import { OrderArchive } from '../../../../components/OrderArchiveModal'

type MappedData = {
  ids: Array<string>
  availableActualize: Array<Order>
  availableSort: Array<Order>
  availableArchive: Array<Order>
  availableChangeCourier: Array<Order>
}

export function OrderListGroupControls() {
  const orders = useSelector((state) => state.order.selectedOrders, objectEqual)

  const data: MappedData = {
    ids: [],
    availableActualize: [],
    availableSort: [],
    availableArchive: [],
    availableChangeCourier: [],
  }

  orders.forEach((order) => {
    data.ids.push(order.id)
    if (order.actionPermissions.actualize) {
      data.availableActualize.push(order)
    }
    if (order.actionPermissions.sorting) {
      data.availableSort.push(order)
    }
    if (!order.deleteDate && order.status.value === OrderStatusCode.CANCELED) {
      data.availableArchive.push(order)
    }
  })

  const handleStatusChange = () => {
    openOrderStatusChangeModal(orders)
  }

  const handleChangeCourier = () => {
    openOrderCourierChangeModal(orders)
  }

  const handleArchive = () => {
    modal({
      title: `Отправить в архив`,
      overlayClose: false,
      w: '31rem',
      render: (close) => <OrderArchive close={close} orders={data.availableArchive} />,
    })
  }

  const hintActionSetStatus = useMouseDrop('Сменить статус')
  const hintActionPriceActualize = useMouseDrop(
    data.availableActualize.length && 'Актуализировать цены',
  )
  const hintEacPrint = useMouseDrop('Печать стикеров ЕАС')
  const hintActionCourierAssign = useMouseDrop('Назначить водителя')

  const hintActionArchive = useMouseDrop(
    !!data.availableArchive.length && 'Отправить в архив',
  )

  return (
    <Grid autoFlow="column" gap="0.5rem">
      <Button
        p={0}
        w="2.5rem"
        h="2.5rem"
        {...hintActionSetStatus}
        decoration="text"
        onClick={handleStatusChange}
      >
        <Swap size="1.5rem" />
      </Button>
      <Button
        disabled={!data.availableActualize.length}
        p={0}
        w="2.5rem"
        h="2.5rem"
        {...hintActionPriceActualize}
        decoration="text"
        onClick={() => OrderActions.actualizePrice({ ids: data.ids })}
      >
        <Pricetag size="1.5rem" />
      </Button>
      <Button
        p={0}
        w="2.5rem"
        h="2.5rem"
        {...hintEacPrint}
        decoration="text"
        onClick={() => {
          openDevicePrinterModal((deviceId) => {
            OrderActions.printInfo({
              deviceId,
              orderIds: data.ids,
            })
          })
        }}
      >
        <Printer size="1.5rem" />
      </Button>

      <Button
        p={0}
        w="2.5rem"
        h="2.5rem"
        {...hintActionCourierAssign}
        decoration="text"
        onClick={handleChangeCourier}
      >
        <Car size="1.5rem" />
      </Button>
      <Button
        p={0}
        w="2.5rem"
        h="2.5rem"
        {...hintActionArchive}
        color="red500"
        onClick={handleArchive}
        disabled={!data.availableArchive.length}
      >
        <Archive size="1.5rem" type="outline" />
      </Button>
      <Button
        disabled={!data.availableSort.length}
        onClick={() => {
          const availableSortIds = data.availableSort.map((o) => o.id)
          OrderActions.sort({ ids: availableSortIds })
        }}
        rightChild={
          <Flexbox>
            {data.ids.length > 1 && (
              <ColorText
                w="1.5rem"
                h="1.5rem"
                weight={600}
                size="s"
                borderStyle="soild"
                borderWidth="1px"
                borderColor="white"
                backgroundColor="transparent"
                color="white"
                mr="s"
              >
                {data.availableSort.length}
              </ColorText>
            )}
            <ArrowRight size="1.5rem" />
          </Flexbox>
        }
        label="На сборку"
      />
    </Grid>
  )
}
