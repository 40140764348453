import React from 'react'

import { Flexbox } from '@stage-ui/core'

import QualityPackDate from './QualityPackDate'
import QualityScale from './QualityScale'

type QualityPackDateHeaderProps = {
  packDate: string
  scaleName: string
}

const QualityPackDateHeader: React.FC<QualityPackDateHeaderProps> = (props) => {
  const { packDate, scaleName } = props

  return (
    <Flexbox
      justifyContent="space-between"
      style={{
        position: 'absolute',
        top: '1rem',
        left: '1rem',
        right: '1rem',
        zIndex: 1,
      }}
    >
      <QualityPackDate date={packDate} mr="l" />
      <QualityScale name={scaleName} />
    </Flexbox>
  )
}

export default QualityPackDateHeader
