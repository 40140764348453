import { Flexbox, Text } from '@stage-ui/core'
import { Done } from '@stage-ui/icons'

type DropSelectProps = {
  text: string
  value: number
  selectedInterval: number | null
}

export function DropSelect(props: DropSelectProps) {
  const { text, value, selectedInterval } = props

  return (
    <Flexbox flex={1} justifyContent="space-between" alignItems="center">
      <Flexbox alignItems="center">
        <Text size="s">{text}</Text>
      </Flexbox>
      {value === selectedInterval && <Done color="primary" size="1.25rem" />}
    </Flexbox>
  )
}
