import React from 'react'

import { OrderAddProductCustom } from '.'

import { SelectItem } from '@foods-n-goods/server/generated/schema'
import { Button, Flexbox, Select, Table, Text } from '@stage-ui/core'
import SelectTypes from '@stage-ui/core/control/Select/types'
import TableTypes from '@stage-ui/core/data/Table/types'
import { Trash2 } from '@stage-ui/icons'
import ViewInputField from 'components/Fields/ViewInputField'
import { rub } from 'utils/rub'

type OrderAddPositionsTableProps = {
  addProducts: OrderAddProductCustom[]
  onProductDelete: (p: OrderAddProductCustom) => void
  onProductUpdate: (p: OrderAddProductCustom) => void
}

export const OrderAddPositionsTable: React.FC<OrderAddPositionsTableProps> = (props) => {
  const { addProducts, onProductDelete, onProductUpdate } = props

  const columns: TableTypes.TableColumn<OrderAddProductCustom>[] = [
    {
      key: 'name',
      title: 'Наименование',
      render: ({ row }) => (
        <Text size="s" color="gray900">
          {row.name}
        </Text>
      ),
    },
    {
      key: 'quantity',
      title: 'Количество',
      width: '5rem',
      render: ({ row }) => {
        const onChange = (quantity: string) => {
          onProductUpdate({ ...row, quantity })
        }
        return (
          <ViewInputField
            w="5rem"
            placeholder="Кол-во"
            value={row.quantity}
            onChange={onChange}
            type={row.unit.value === 1 ? 'digits' : 'integer'}
            digits={3}
            size="s"
          />
        )
      },
    },
    {
      key: 'unit',
      title: 'Тара',
      width: '5rem',
      render: ({ row }) => {
        const onChange = (values: SelectTypes.Option<string | number | boolean>[]) => {
          onProductUpdate({ ...row, unit: values[0] as SelectItem })
        }
        return (
          <Select
            w="5rem"
            size="s"
            defaultValues={[{ text: row.unit.text, value: row.unit.value }]}
            options={row.availableUnits}
            onChange={onChange}
          />
        )
      },
    },
    {
      key: 'amountToPay',
      title: 'К оплате',
      width: '5rem',
      render: ({ row }) => {
        const avgWeight =
          row.availableUnits.find((au) => au.value === row.unit.value)?.weight || 1
        const price = parseFloat(row.price) * parseFloat(row.quantity || '0') * avgWeight
        return (
          <Text size="s" color="gray900">
            {rub(Number(price))}
          </Text>
        )
      },
    },
    {
      key: '_delete',
      width: '2rem',
      render: ({ row }) => (
        <Button
          w="2rem"
          h="2rem"
          color="red500"
          decoration="text"
          onClick={() => onProductDelete(row)}
        >
          <Trash2 size="1.125rem" />
        </Button>
      ),
    },
  ]

  if (!addProducts.length) {
    return (
      <Flexbox centered my="l" p="l" decoration="surface">
        <Text size="s" color="orange500">
          Список пуст. Введите название товара в строку поиска и начните заполнение.
        </Text>
      </Flexbox>
    )
  }

  return (
    <Flexbox column my="l">
      <Text weight={600} color="gray900" mb="m">
        Список продуктов для добавления в заказ
      </Text>
      <Table data={addProducts} columns={columns} />
    </Flexbox>
  )
}
