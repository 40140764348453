import React, { useState } from 'react'

import { Button, Flexbox } from '@stage-ui/core'
import { PurchasesActions } from 'actions'
import ViewInputField from 'components/Fields/ViewInputField'

import { PurchaseExtended } from '../data'

type PurchaseUpdateQuantityModalProps = {
  purchaseRow: PurchaseExtended
  close: () => void
}

export const PurchaseUpdateQuantityModal: React.FC<PurchaseUpdateQuantityModalProps> = (
  props,
) => {
  const { purchaseRow, close } = props

  const [pending, setPending] = useState(false)
  const [quantity, setQuantity] = useState(String(purchaseRow.quantity || ''))

  const handleSave = () => {
    if (pending) return
    setPending(true)

    PurchasesActions.update(
      {
        id: purchaseRow.id,
        quantity: Number(quantity),
      },
      close,
    )
  }

  return (
    <Flexbox column>
      <ViewInputField
        autoFocus
        label="Количество продукции"
        value={quantity}
        onChange={(v) => setQuantity(v)}
        onEnter={handleSave}
        type={purchaseRow.product.unit.value == 1 ? 'digits' : 'integer'}
        digits={2}
        rightChild={purchaseRow.product.unit.text}
      />
      <Flexbox mt="l" justifyContent="flex-end">
        <Button decoration="text" color="gray500" label="Отмена" onClick={close} mr="m" />
        <Button label="Сохранить изменения" onClick={handleSave} />
      </Flexbox>
    </Flexbox>
  )
}
