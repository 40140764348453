import React from 'react'

import { useTheme } from '@stage-ui/core'
import { ProgressBar, ProgressBarEntity } from 'components/ProgressBar'
import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'

export function PurchasesProgressBar() {
  const { color } = useTheme()
  const statistics = useSelector((state) => state.purchases.statistics, objectEqual)

  const entities: ProgressBarEntity[] = [
    {
      title: 'В докупке',
      amount: statistics.inRepurchase,
      dotColor: color.yellow[500].hex(),
      background: `linear-gradient(90deg, ${color.yellow[400].hex()} 0%, ${color.yellow[500].hex()} 100%)`,
    },
    {
      title: 'Куплено',
      amount: statistics.confirmed,
      dotColor: color.lightGreen[500].hex(),
      background: `linear-gradient(90deg, #8DE770 0%, ${color.teal[400].hex()} 100%)`,
    },
    {
      title: 'Нет в наличии',
      amount: statistics.failed,
      dotColor: color.red[500].hex(),
      background: `linear-gradient(90deg, ${color.red[300].hex()} 0%, ${color.red[500].hex()} 100%)`,
      loading: true,
    },
  ]

  return (
    <ProgressBar
      total={statistics.all}
      entities={entities}
      noDataText="Здесь будет отображаться статус по докупке"
    />
  )
}
