import React, { Fragment } from 'react'

import { PlacemarkTemplate } from '../templates/PlacemarkTemplate'
import { CourierTemplate } from '../templates/CourierTemplate'

export type Template = {
  html: () => string
}

export type Templates = {
  placemark: Template
  courier: Template
}

export const useTemplates = (): [Templates, React.ReactNode] => {
  const templates = {
    placemark: PlacemarkTemplate(),
    courier: CourierTemplate(),
  }
  return [
    templates,
    <span style={{ position: 'fixed', zIndex: -1 }}>
      {Object.keys(templates).map((template) => {
        // @ts-expect-error
        return <Fragment key={template}>{templates[template].render}</Fragment>
      })}
    </span>,
  ]
}
