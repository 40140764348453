import React from 'react'

import { Button, Flexbox, modal, Text } from '@stage-ui/core'
import { StaffActions } from 'actions'
import { AlertCircle } from '@stage-ui/icons'
import { Staff } from '@foods-n-goods/server/generated/schema'
import { useHistory } from 'react-router-dom'

type StaffDeleteModalProps = {
  staff: Staff
  close: () => void
}

function StaffDeleteModal(props: StaffDeleteModalProps) {
  const { close, staff } = props
  const history = useHistory()

  const handleDelete = async () => {
    if (!staff) return
    await StaffActions.delete(staff.id, () => {
      close()
      history.push('/staff')
    })
  }

  return (
    <Flexbox column>
      <Flexbox
        p="m"
        backgroundColor="gray100"
        borderRadius="m"
        borderWidth="0.0625rem"
        borderStyle="solid"
        borderColor="gray300"
      >
        <AlertCircle mt="0.0625rem" size="1.125rem" color="red500" mr="s" />
        <Text size="s" color="red500">
          После удаления, данные персонала невозможно востановить.
        </Text>
      </Flexbox>
      <Flexbox mt="xl" alignItems="center" justifyContent="flex-end">
        <Button decoration="text" label="Отмена" onClick={close} color="gray500" />
        <Button w="8.5rem" label="Удалить" color="red500" onClick={handleDelete} ml="m" />
      </Flexbox>
    </Flexbox>
  )
}

export const openStaffDeleteModal = (
  staff: Staff,
  e?: React.MouseEvent<HTMLDivElement, MouseEvent>,
) => {
  e?.stopPropagation()
  e?.preventDefault()

  modal({
    w: '30rem',
    title: 'Вы собираетесь удалить персонал',
    subtitle: staff.name || staff.login || '',
    overlayClose: false,
    render: (close) => <StaffDeleteModal staff={staff} close={close} />,
  })
}
