import { useEffect } from 'react'

import useSelector from 'hooks/useSelector'

import { objectEqual } from 'utils/objectEqual'

import { ym } from '../hooks/useYandexMaps'

export function RouteRemoveHelper() {
  const currentCourier = useSelector(
    (state) => state.delivery.currentCourier,
    objectEqual,
  )

  useEffect(() => {
    if (ym.ref) {
      ym.ref.courierRouteRemove()
    }
  }, [currentCourier])

  return null
}
