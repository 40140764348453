import { WeightRounding } from '@foods-n-goods/server/generated/schema'

export const scaleAcceptOptions = [
  {
    text: 'Ничего не делать',
    value: '0',
  },
  {
    text: 'Предупреждать',
    value: '1',
  },
  {
    text: 'Запрещать',
    value: '2',
  },
]

export const alertTypeOptions = [
  {
    text: 'Всегда разрешать',
    value: '0',
  },
  {
    text: 'Предупреждать о не собранных позициях',
    value: '1',
  },
  {
    text: 'Запрещать пока есть несобранные позиции',
    value: '2',
  },
]

export const onOffLabels = [
  {
    text: 'Выключено',
    value: '0',
  },
  {
    text: 'Включено',
    value: '1',
  },
]

type WeightRoundingLabel = {
  text: string
  value: WeightRounding
}

export const weightRoundingLabels: Array<WeightRoundingLabel> = [
  {
    text: 'Не округлять',
    value: 'dontRound',
  },
  {
    text: 'Математическое',
    value: 'math',
  },
  {
    text: 'В большую сторону',
    value: 'roundUp',
  },
  {
    text: 'В меньшую сторону',
    value: 'roundDown',
  },
]
