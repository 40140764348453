import ClientSystem from '@foods-n-goods/client/system/types'
import { Variable } from '@foods-n-goods/server/generated/schema'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface VariablesState {
  loading: ClientSystem.Loading
  data: Variable[]
  error: RequestError | null

  actionError: RequestError | null
}

export const initialState: VariablesState = {
  loading: 'idle',
  data: [],
  error: null,

  actionError: null,
}

const variablesSlice = createSlice({
  name: 'variables',
  initialState,
  reducers: {
    loading(state) {
      state.loading = 'pending'
    },
    resolve(state, action: PayloadAction<VariablesState['data']>) {
      state.data = action.payload
      state.loading = 'resolved'
      state.error = initialState.error
    },
    reject(state, action: PayloadAction<VariablesState['error']>) {
      state.loading = 'rejected'
      state.error = action.payload
    },
    actionUpdateResolve(state, action: PayloadAction<Variable>) {
      state.data = state.data.map((variable) =>
        variable.name === action.payload.name
          ? { ...variable, ...action.payload }
          : variable,
      )
      state.actionError = initialState.actionError
    },
    actionReject(state, action: PayloadAction<VariablesState['actionError']>) {
      state.actionError = action.payload
    },

    clear(state) {
      return initialState
    },
  },
})

export default variablesSlice
