import { OnValueParamChangeFunction } from '.'

import { ProductInput } from '@foods-n-goods/server/generated/schema'
import { Flexbox } from '@stage-ui/core'
import Content from 'components/Content'
import ViewInputField from 'components/Fields/ViewInputField'
import ViewGridBlock from 'components/ViewGridBlock'

interface ProductViewEnergyValueProps {
  params: ProductInput['params']
  onChange: OnValueParamChangeFunction
}

function ProductViewEnergyValue(props: ProductViewEnergyValueProps) {
  const {
    onChange,
    params: { FATS, PROTEINS, CARBOHYDRATES, ENERGY },
  } = props

  return (
    <Content>
      <ViewGridBlock title="Энергетическая ценность">
        <Flexbox gridColumnEnd="span 1">
          <ViewInputField
            label="Жиры (г)"
            value={FATS || ''}
            onChange={(value) => onChange('FATS', value)}
            digits={1}
            type="digits"
          />
          <ViewInputField
            label="Белки (г)"
            ml="0.5rem"
            value={PROTEINS || ''}
            onChange={(value) => onChange('PROTEINS', value)}
            digits={1}
            type="digits"
          />
          <ViewInputField
            label="Углеводы (г)"
            ml="0.5rem"
            value={CARBOHYDRATES || ''}
            onChange={(value) => onChange('CARBOHYDRATES', value)}
            digits={1}
            type="digits"
          />
        </Flexbox>
        <ViewInputField
          label="Калорийность (ккал)"
          ml="0.5rem"
          value={ENERGY || ''}
          onChange={(value) => onChange('ENERGY', value)}
          type="formatInteger"
        />
      </ViewGridBlock>
    </Content>
  )
}

export default ProductViewEnergyValue
