import React, { useEffect, useRef, useState } from 'react'

type HTMLRenderReturn = {
  render: React.ReactNode
  html: () => string
}

export const useHTMLRender = (c: React.ReactNode): HTMLRenderReturn => {
  const ref = useRef<HTMLSpanElement>(null)
  const [html, setHTML] = useState('')
  useEffect(
    () => setHTML(ref.current?.innerHTML.replace(/aria-details/g, 'style') || ''),
    [],
  )
  return {
    render: (
      <span ref={ref} style={{ visibility: 'hidden' }}>
        {c}
      </span>
    ),
    // @ts-expect-error
    html: () => ymaps?.templateLayoutFactory.createClass(html),
  }
}
