import React from 'react'

import { OrderStatusCode } from '@foods-n-goods/client/system/types'
import { Flexbox } from '@stage-ui/core'
import { StatusIndicator } from 'components/StatusIndicator'
import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'

export const OrderCount = () => {
  const { ordersNew, ordersWarehouse } = useSelector(
    (state) => ({
      ordersNew: state.order.statCounters.ordersNew,
      ordersWarehouse: state.order.statCounters.ordersWarehouse,
    }),
    objectEqual,
  )

  return (
    <Flexbox alignItems="center">
      {!!ordersNew && (
        <StatusIndicator
          status={{
            text: String(ordersNew),
            value: OrderStatusCode.NEW,
          }}
          hint="Новых заказов"
          ml="xs"
          p="0 s"
          h="1.25rem"
          type="fill"
        />
      )}
      {!!ordersWarehouse && (
        <StatusIndicator
          status={{
            text: String(ordersWarehouse),
            value: OrderStatusCode.SORTING,
          }}
          hint="Заказов в сборке"
          ml="xs"
          p="0 s"
          h="1.25rem"
          type="fill"
        />
      )}
    </Flexbox>
  )
}
