import staffFields from '../fragments/staffFields.gql'

export default `
    mutation staffSignIn($login: String! $pwd: String!) {
        staffSignIn(login: $login, pwd: $pwd) {
            ...staffFields
        }
    }
    ${staffFields}
`
