import { ActionNotifyProps } from '.'

import { Flexbox, Paragraph } from '@stage-ui/core'
import ImageController from 'components/ImageController'
import { AlertTriangle, CheckmarkCircle2 } from '@stage-ui/icons'

function ActionNotifyContent(props: ActionNotifyProps) {
  let backgroundColor = 'surface'
  let color = 'onSurface'
  switch (props.type) {
    case 'error':
      backgroundColor = 'red50'
      color = 'red500'
      break
    case 'success':
      backgroundColor = 'green50'
      color = 'green500'
      break
    case 'warning':
      backgroundColor = 'yellow50'
      color = 'yellow500'
      break
  }
  return (
    <Flexbox
      p="1rem"
      w="20rem"
      decoration="surface"
      borderRadius="s"
      borderColor={color}
      backgroundColor={backgroundColor}
    >
      {props.image && (
        <Flexbox mr="1rem">
          <ImageController readonly hideTitle size="3rem" url={props.image} />
        </Flexbox>
      )}
      {props.type == 'error' && (
        <AlertTriangle size="1.5rem" type="filled" color={color} mr="1rem" p={0} />
      )}
      {props.type == 'success' && (
        <CheckmarkCircle2 size="1.5rem" type="filled" color={color} mr="1rem" p={0} />
      )}
      <Flexbox column>
        <Paragraph
          color={color}
          m={0}
          size="s"
          lineHeight="1.125rem"
          children={props.title}
        />
        <Paragraph color={color} m="0.25rem 0 0 0" size="xs" children={props.message} />
      </Flexbox>
    </Flexbox>
  )
}

export default ActionNotifyContent
