import { Flexbox } from '@stage-ui/core'
import { Star } from '@stage-ui/icons'
import IconTypes from '@stage-ui/icons/Icon/types'

interface RaterProps {
  count?: number
  rating: number
  size?: IconTypes.Props['size']
}

function Rater(props: RaterProps) {
  const { count = 5, rating, size = 'xl' } = props

  return (
    <Flexbox>
      {Array(count)
        .fill('')
        .map((s, i) => (
          <Star
            key={i}
            size={size}
            type={i < rating ? 'filled' : 'outline'}
            color={i < rating ? (c) => c.yellow[500].hex() : 'lightest'}
          />
        ))}
    </Flexbox>
  )
}

export default Rater
