import React from 'react'

import { Order } from '@foods-n-goods/server/generated/schema'
import { Flexbox, Text } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import useSelector from 'hooks/useSelector'
import { rub } from 'utils/rub'
import { objectEqual } from 'utils/objectEqual'

export function OrderListTableAmountCell(ctx: TableTypes.TableCellContext<Order>) {
  const { row } = ctx
  const { amount, amountAfter } = row
  const [priceRub, priceKop] = rub(amountAfter || amount).split(',')

  const currentDisplayMode = useSelector((state) => state.order.displayMode, objectEqual)

  return (
    <Flexbox alignItems="center">
      <Text
        size={currentDisplayMode === 'short' ? 'xs' : 's'}
        weight={600}
        color="green600"
        mr="0.375rem"
      >
        ₽
      </Text>
      <Text
        size={currentDisplayMode === 'short' ? 'xs' : 's'}
        color="gray900"
        weight={600}
      >
        {priceRub}.{priceKop.split(/\s+/)[0]}
      </Text>
    </Flexbox>
  )
}
