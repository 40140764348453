import React, { useEffect } from 'react'

import { ProductReview } from '@foods-n-goods/server/generated/schema'
import useSelector from 'hooks/useSelector'
import { Redirect, useHistory, useParams } from 'react-router-dom'

import { objectEqual } from 'utils/objectEqual'

import ReviewViewAnswers from './Answers'
import ReviewViewControllers from './Controllers'
import ReviewViewGeneral from './General'

interface ReviewViewProps {
  modal?: boolean
  close?: () => void
  id?: ProductReview['id']
}

function ReviewView(props: ReviewViewProps) {
  const { modal } = props
  const params: { id: string } = useParams()
  const history = useHistory()

  const id = props.id || params.id
  const review = useSelector(
    ({ review }) => review.data?.find((c) => c.id === id),
    objectEqual,
  )

  const goBack = props.close || (() => history.push('/reviews'))

  useEffect(() => {
    if (!modal && !review) history.push('/reviews')
  }, [review])

  if (!review) return <Redirect to="/reviews" />

  return (
    <>
      <ReviewViewGeneral modal={modal} review={review} />
      <ReviewViewAnswers review={review} />
      <ReviewViewControllers goBack={goBack} review={review} />
    </>
  )
}

export default ReviewView
