import React from 'react'

import { Block, modal, Flexbox } from '@stage-ui/core'

import ImageControllerInput from './Input'
import ImageWrapper from './Wrapper'

type ImageControllerProps = {
  id?: string
  url?: string | null
  uploadAction?: (id: string, payload: string, _cb?: () => void) => void
  size?: string
  round?: boolean
  readonly?: boolean
  hideTitle?: boolean
  disabled?: boolean
  title?: string
  clickable?: boolean
  person?: boolean
  wrapperCss?: React.CSSProperties
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

function ImageController(props: ImageControllerProps) {
  const { hideTitle, size, disabled, title, clickable, url, wrapperCss, onClick } = props

  const zoom = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    onClick?.(e)
    if (clickable && url) {
      modal({
        hideHeader: true,
        render: () => (
          <Block m="-1.25rem -2.125rem">
            <ImageWrapper
              image={url ? `url(${url})` : undefined}
              size={`calc(${`${window.innerHeight}px`} - 8rem)`}
              wrapperCss={wrapperCss}
            />
          </Block>
        ),
      })
    }
  }

  return (
    <Flexbox
      alignItems="center"
      style={{
        opacity: disabled ? 0.5 : 1,
        cursor: clickable || onClick ? 'pointer' : 'default',
      }}
      onClick={zoom}
    >
      <ImageWrapper
        {...props}
        image={props.url ? `url(${props.url})` : undefined}
        wrapperCss={wrapperCss}
      />

      <ImageControllerInput {...props} />
    </Flexbox>
  )
}

export default ImageController
