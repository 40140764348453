import ClientSystem from '@foods-n-goods/client/system/types'
import { Promotion } from '@foods-n-goods/server/generated/schema'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface PromotionState {
  loading: ClientSystem.Loading
  data: Promotion[]
  error: RequestError | null

  actionError: RequestError | null
}

export const initialState: PromotionState = {
  loading: 'idle',
  data: [],
  error: null,

  actionError: null,
}

const promotionSlice = createSlice({
  name: 'promotion',
  initialState,
  reducers: {
    loading(state) {
      state.loading = 'pending'
    },
    resolve(state, action: PayloadAction<PromotionState['data']>) {
      state.loading = 'resolved'
      state.data = action.payload
      state.error = initialState.error
    },
    reject(state, action: PayloadAction<PromotionState['error']>) {
      state.loading = 'rejected'
      state.error = action.payload
    },

    /* ACTION */
    actionCreateResolve(state, action: PayloadAction<Promotion>) {
      state.data = [action.payload].concat(state.data)
      state.actionError = initialState.actionError
    },
    actionDeleteResolve(state, action: PayloadAction<Promotion>) {
      state.data = state.data.filter((promotion) => promotion.id !== action.payload.id)
      state.actionError = initialState.actionError
    },
    actionUpdateResolve(state, action: PayloadAction<Promotion>) {
      state.data = state.data.map((promotion) =>
        promotion.id === action.payload.id
          ? { ...promotion, ...action.payload }
          : promotion,
      )
      state.actionError = initialState.actionError
    },
    actionReject(state, action: PayloadAction<PromotionState['actionError']>) {
      state.actionError = action.payload
    },

    clear(state) {
      return initialState
    },
  },
})

export default promotionSlice
