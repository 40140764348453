import React from 'react'

import { Table } from '@stage-ui/core'
import { TableLoadingStab } from 'components/TableLoadingStab'
import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'

import { columns } from './columns'

function ContainersDictionaryContent() {
  const { loading, data } = useSelector(
    (state) => ({
      loading: state.dictionaries.deliveryIntervalsLoading,
      data: state.dictionaries.deliveryIntervalsData,
    }),
    objectEqual,
  )

  return TableLoadingStab(loading) || <Table mb="1rem" columns={columns} data={data} />
}

export default ContainersDictionaryContent
