import React from 'react'

import { Client } from '@foods-n-goods/server/generated/schema'
import { CheckmarkCircle, Clock, Lock, CloseCircle, Activity } from '@stage-ui/icons'
import { useMouseDrop } from 'components/MouseDrop'
import IconTypes from '@stage-ui/icons/Icon/types'

const iconsMap: Record<
  string,
  {
    Icon: typeof Activity
    color: string
    text: string
    type?: IconTypes.IconType
  }
> = {
  IDLE: {
    Icon: Activity,
    color: 'primary',
    text: 'Новый клиент',
  },
  APPROVED: {
    Icon: CheckmarkCircle,
    color: 'primary',
    text: 'Зарегистрирован, контрагент заведен',
  },
  DECLINED: {
    Icon: CloseCircle,
    color: 'error',
    text: 'Отклонён',
    type: 'outline',
  },
  BLOCKED: {
    Icon: Lock,
    color: 'error',
    text: 'Заблокирован',
    type: 'filled',
  },
  AWAITING: {
    Icon: Clock,
    color: 'orange',
    text: 'Необходимо завести контрагента',
  },
}

export function RegisterIconStatus(client: Client) {
  const { markets } = client
  const status = markets[0]?.status || 'IDLE'
  const { Icon, color, text, type = 'outline' } = iconsMap[status]
  const hint = useMouseDrop(text)

  if (!status || status === 'IDLE') {
    return null
  }

  return <Icon size="xl" color={color} {...hint} type={type} />
}
