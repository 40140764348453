const FontFace = (font: string, style: string, weight: number) => ({
  '@font-face': {
    fontFamily: 'Manrope',
    fontWeight: weight,
    fontStyle: style,
    src: `url('https://fg.shds.io/bucket/assets/fonts/${font}') format('truetype')`,
  },
})

export const globalStyles = [
  FontFace('Manrope-ExtraLight.ttf', 'normal', 200),
  FontFace('Manrope-Light.ttf', 'normal', 300),
  FontFace('Manrope-Regular.ttf', 'normal', 400),
  FontFace('Manrope-Medium.ttf', 'normal', 500),
  FontFace('Manrope-SemiBold.ttf', 'normal', 600),
  FontFace('Manrope-Bold.ttf', 'normal', 700),
  FontFace('Manrope-ExtraBold.ttf', 'normal', 800),
  {
    'html, body, #print, #app': {
      minHeight: '100%',
      height: '100%',
      scrollBehavior: 'smooth',
      fontFamily: 'Manrope,sans-serif',
      fontWeight: 500,
      fontSize: '16px',
      position: 'absolute',
      inset: 0,
      overscrollBehavior: 'none',
    },
    '#app': {
      overflowX: 'auto !important',
    },
    '@media print': {
      html: {
        background: 'white !important',
      },
    },
  },
]
