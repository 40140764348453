import { Purchase, SelectItem, Staff } from '@foods-n-goods/server/generated/schema'
import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'

export type PurchaseExtended = Omit<Purchase, 'status'> & {
  _isExpandable: boolean
  _count: number
  _purchasers: Array<Staff>
  status: SelectItem | null
}

export type PurchaseStatus = 'all' | 'inRepurchase' | 'failed' | 'confirmed' | 'canceled'

export const usePurchasesData = (expandByProductId?: string) => {
  const { records, total, loading } = useSelector(
    (state) => ({
      loading:
        state.purchases.loading === 'idle' || state.purchases.loading === 'pending',
      records: state.purchases.data.records,
      total: state.purchases.data.total,
    }),
    objectEqual,
  )

  const fakeGroups: PurchaseExtended[] = []

  const expandFilter = (p: Purchase) =>
    expandByProductId ? p.product.id === expandByProductId : true

  const filteredData = records.filter(expandFilter).map((p) => ({
    ...p,
    _isExpandable: false,
    _count: 1,
    _purchasers: p.buyer ? [p.buyer] : [],
  }))

  filteredData.forEach((purchase) => {
    const idx = fakeGroups.findIndex((group) => group.product.id === purchase.product.id)
    if (idx >= 0) {
      fakeGroups[idx]._isExpandable = true
      fakeGroups[idx]._count += 1
      fakeGroups[idx].quantity += purchase.quantity
      if (purchase.buyer) {
        fakeGroups[idx]._purchasers = fakeGroups[idx]._purchasers
          .concat(purchase.buyer)
          .reduce((arr: Array<Staff>, c: Staff) => {
            if (c && arr.findIndex((b) => b.id === c.id) < 0) arr.push(c)
            return arr
          }, [])
      }
    } else {
      fakeGroups.push({ ...purchase })
    }
  })

  return {
    loading,
    tableData: expandByProductId ? filteredData : fakeGroups,
    filteredData,
    rerenderTrigger: fakeGroups.filter((g) => g._isExpandable).length,
    records,
    total,
  }
}
