import { Table as UITable } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'

import NoData from './NoData'

type TableProps<T> = TableTypes.Props<T> & {
  emptyText?: string
  overrides?: TableTypes.Props<any>['overrides']
}

export function Table<T extends {}>(props: TableProps<T>) {
  const { emptyText, overrides, ...rest } = props

  if (!props.data.length) {
    return <NoData text={emptyText} />
  }

  return <UITable {...rest} />
}
