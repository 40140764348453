import productFields from '../fragments/productFields.gql'

export default `
query products($page: Float, $filter: ProductFilter, $clientId: String, $marketId: String, $search: String) {
  products(search: $search, clientId: $clientId, filter: $filter, page: $page, marketId: $marketId) {
      total
      search
      records {
        ...productFields
      }
  }
}
${productFields}

`
