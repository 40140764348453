import React from 'react'

import { Grid } from '@stage-ui/core'
import ViewInputField from 'components/Fields/ViewInputField'
import { Staff, StaffInput } from '@foods-n-goods/server/generated/schema'

type StaffContactsUpdateProps = {
  autoFocusField?: keyof StaffInput
  phone?: Staff['phone']
  email?: Staff['email']
  onChange: KeyValueChangeFunction<StaffInput>
}

export function StaffContactsUpdate(props: StaffContactsUpdateProps) {
  const { autoFocusField, phone, email, onChange } = props

  return (
    <Grid autoFlow="row" gap="1rem">
      <ViewInputField
        autoFocus={autoFocusField === 'phone'}
        label="Телефон"
        value={phone || ''}
        maxLength={13}
        onChange={(value) => onChange('phone', value)}
        type="phone"
        placeholder="999 999-99-99"
        leftChild="+7"
      />
      <ViewInputField
        autoFocus={autoFocusField === 'email'}
        label="Электронная почта"
        placeholder="email@domain.com"
        maxLength={64}
        value={email || ''}
        onChange={(value) => onChange('email', value)}
      />
    </Grid>
  )
}
