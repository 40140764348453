import React from 'react'

import { Block, Button, Flexbox } from '@stage-ui/core'
import { MoreVertical } from '@stage-ui/icons'
import { ChatActions } from 'actions'
import { openClientModal } from 'components/Modals/ClientView'
import { openStaffModal } from 'components/Modals/StaffView'
import { useMouseDropMenu } from 'components/MouseMenuDrop'
import useSelector from 'hooks/useSelector'

import { objectEqual } from 'utils/objectEqual'

import { showRoomEditForm } from '../../Configurate'
import AvatarControl from '../AvatarControl'

function MessageRoomHeader() {
  const { activeRoom, userId } = useSelector(
    (state) => ({
      userId: state.app.user?.id,
      activeRoom: state.chat.rooms.find(
        ({ roomId }) => roomId === state.chat.activeRoomId,
      ),
    }),
    objectEqual,
  )

  const connected = activeRoom?.users.some((u) => u.id === userId)

  const contextMenu = useMouseDropMenu(
    [
      { text: 'Изменить группу', icon: 'Edit2', value: 'EDIT' },
      { text: 'Удалить', icon: 'Trash2', color: 'red500', value: 'DELETE' },
    ],
    ({ value }) => {
      if (value === 'EDIT' && activeRoom) {
        showRoomEditForm(activeRoom)
      }
      if (value === 'DELETE' && activeRoom) {
        ChatActions.deleteRoom(activeRoom.roomId)
      }
    },
  )

  if (!activeRoom) return null

  return (
    <Block
      backgroundColor="gray50"
      p="1.5rem"
      h="6.5rem"
      flexShrink={0}
      style={{
        boxSizing: 'border-box',
        ackground: 'rgba(255,255,255,0.6)',
        position: 'absolute',
        backdropFilter: 'blur(0.5rem)',
        zIndex: 10,
        width: '100%',
        boxShadow: '0 0.0625rem 1.5rem rgb(0 0 0 / 10%)',
      }}
    >
      <Flexbox
        alignItems="center"
        style={{
          transition: 'all 0.25s',
          ':hover': {
            '> div:not(:last-child)': {
              width: '2.25rem',
            },
          },
        }}
      >
        <AvatarControl
          hint
          animated
          users={activeRoom?.users || []}
          onEdit={() => showRoomEditForm(activeRoom)}
          onClick={(user, e) => {
            if (user.type === 'STAFF') {
              openStaffModal(user.id, e)
            } else {
              openClientModal(user.id, e)
            }
          }}
        />
        <MoreVertical color="gray500" size="1.5rem" {...contextMenu} />
      </Flexbox>
      {!connected && (
        <Flexbox mt="xs" justifyContent="flex-end">
          <Button
            size="s"
            label="Присоединиться к диалогу"
            onClick={() => {
              ChatActions.roomGetIn(activeRoom.roomId)
            }}
          />
        </Flexbox>
      )}
    </Block>
  )
}

export default MessageRoomHeader
