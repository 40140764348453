import React from 'react'

import { PageTable } from 'components/PageTable'
import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'

import { columns } from './misc'

function JournalTable() {
  const data = useSelector(({ journal }) => journal.data, objectEqual)

  return (
    <PageTable
      noDataText="Не найдено действий."
      data={data}
      columns={columns}
      pagination={{
        pageSize: 40,
      }}
    />
  )
}

export default JournalTable
