import React, { useCallback, useEffect } from 'react'

import { Flexbox } from '@stage-ui/core'
import { DeliveryActions } from 'actions'
import { Section } from 'components/Section'
import moment from 'moment'

import useSelector from 'hooks/useSelector'

import { objectEqual } from 'utils/objectEqual'

import { LogisticsHeader } from './Header'
import { Mapview } from './MapView'
import { SideView } from './SideView'
import { filterWithStatus } from './misc'

export function Logistics() {
  const filter = useSelector((state) => state.delivery.filter, objectEqual)

  const fetch = useCallback(() => {
    if (!filter.date) return

    const filterParams = {
      preferredDeliveryDateFrom: moment(filter.date).startOf('day').toDate(),
      preferredDeliveryDateTo: moment(filter.date).endOf('day').toDate(),

      onlyActive: true,
      includeContainers: false,
      includePositions: false,
      includePositionStat: false,

      ...(filter.section === 'orders' && filterWithStatus[filter.ordersTab]),

      // coordinator section filter
      ...(filter.section === 'coordinator' &&
        filterWithStatus[filter.coordinatorShowAll ? 'coordinatorAll' : 'coordinator']),
    }

    DeliveryActions.fetchOrders({
      page: filter.section === 'orders' ? filter.ordersPage : 1,
      // keep it, we need all orders
      pageSize:
        filter.section === 'couriers' || filter.section === 'coordinator'
          ? 5000
          : filter.ordersPageSize,
      filter: filterParams,
    })
  }, [filter])

  useEffect(() => {
    fetch()
  }, [filter])

  useEffect(() => {
    return () => {
      DeliveryActions.clearFilter()
    }
  }, [])

  return (
    <Section title="Логистика" titleComponent={<LogisticsHeader />} p={0}>
      <Flexbox h="calc(100vh - 6rem)" style={{ overflow: 'hidden' }}>
        <SideView />
        <Mapview />
      </Flexbox>
    </Section>
  )
}
