import React from 'react'

import { Staff } from '@foods-n-goods/server/generated/schema'
import { Button, Divider, Flexbox, Header, Select, Text, TextField } from '@stage-ui/core'
import { Settings2 } from '@stage-ui/icons'
import { openStaffWarehouseUpdateModal } from 'components/Modals/StaffWarehouseUpdate'

import { StaffActions } from 'actions'

import { StaffWarehouseInput } from '@foods-n-goods/server/src/shared/staff'

import { DragableWarehouse } from './DragableWarehouse'
import { StaffRole } from '@foods-n-goods/server/src/shared/enum'

interface StaffDetailsAdditionalWarehousesProps {
  staff: Staff
}

const CfgWarehousePosTypeOptions = [
  {
    text: 'По клиентам',
    value: 0,
  },
  {
    text: 'По продуктам',
    value: 1,
  },
]

export function StaffDetailsAdditionalWarehouses(
  props: StaffDetailsAdditionalWarehousesProps,
) {
  const { staff } = props

  const onChange = (id: number, priority: number) => {
    const prioritizedWarehouses: StaffWarehouseInput[] = staff.warehouses
      .slice()
      .sort((a, b) =>
        (a.warehouseId === id ? priority : a.priority) >
        (b.warehouseId === id ? priority : b.priority)
          ? 1
          : -1,
      )
      .map((w, i) => ({ id: w.warehouseId, priority: i + 1 }))

    StaffActions.warehouseUpdate(staff.id, prioritizedWarehouses)
  }

  const sortedWarehouses = staff.warehouses
    .slice()
    .sort((a, b) => (a.priority > b.priority ? 1 : -1))

  return (
    <Flexbox
      column
      p="1.25rem"
      decoration="surface"
      borderRadius="s"
      borderColor="gray200"
    >
      <Header size="xs" weight="600 !important">
        {staff.role.value === StaffRole.PURCHASE ? 'Настройка докупки' : 'Настройка сборки'}
      </Header>
      <Divider color="gray200" mt="m" />
      {staff.role.value !== StaffRole.PURCHASE && (
        <>
        <Select
          mt="m"
          label="Тип сборки"
          options={CfgWarehousePosTypeOptions}
          defaultValues={CfgWarehousePosTypeOptions.filter(
            (x) => x.value === staff.cfgWarehousePosType,
          )}
          onChange={([{ value }]) => {
            StaffActions.update(staff.id, {
              cfgWarehousePosType: Number(value),
            })
          }}
        />
        <TextField
          mt="m"
          label="Выдавать позиций за раз"
          type="number"
          defaultValue={String(staff.cfgWarehousePosLimit)}
          onChange={({ target }) => {
            StaffActions.update(staff.id, {
              cfgWarehousePosLimit: Number(target.value),
            })
          }}
        />
        </>
      )}
      <Text size="s" color="hard" mt="m">
        Порядок складов для выдачи
      </Text>
      {!staff.warehouses.length && (
        <Text color="gray500" my="xl">
          Нет активных складов
        </Text>
      )}
      {sortedWarehouses.map((warehouse, index) => (
        <DragableWarehouse
          key={warehouse.warehouseId}
          onChange={onChange}
          last={index + 1 === staff.warehouses.length}
          warehouse={warehouse}
          warehouses={sortedWarehouses}
        />
      ))}
      <Button
        decoration="text"
        size="s"
        ml="-0.5rem"
        p="0.25rem"
        color="gray400"
        label="Настройка складов"
        leftChild={<Settings2 />}
        onClick={() => openStaffWarehouseUpdateModal(staff)}
      />
    </Flexbox>
  )
}
