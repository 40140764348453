import React, { useEffect, useRef } from 'react'

import { Device } from '@foods-n-goods/server/generated/schema'
import { Button, Flexbox, modal, Text } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import { Edit, Video } from '@stage-ui/icons'
import { VIDEO_HOST } from 'env'

import { updateDeviceDialog } from '../shared/form'

type FullScreenVideoProps = {
  device: Device
  width?: string
  style?: React.CSSProperties
}

export function FullScreenVideo(props: FullScreenVideoProps) {
  const { device, width = '40rem', style } = props
  const ref = useRef<HTMLVideoElement>(null)
  useEffect(() => {
    let ws: WebSocket
    const { host, port, credentials } = device
    const [login, password] = atob(credentials).split(':')
    const uri = `protocol=rtsp&login=${login}&password=${password}&host=${host}&port=554&uri=/RVi/1/1`

    if (ref.current) {
      let sourceBuffer: SourceBuffer | null
      let streamingStarted = false
      const mediaSource = new MediaSource()
      const queue: BufferSource[] = []

      const sourceOpen = () => {
        ws = new WebSocket(`${VIDEO_HOST.replace('http', 'ws')}/stream?${uri}`)
        ws.binaryType = 'arraybuffer'
        ws.onopen = () => console.log(`Camera stream open ${host}:$554`)
        ws.onerror = (error) => console.log(error)
        ws.onmessage = (event) => {
          const data = new Uint8Array(event.data)
          if (data[0] != 9) {
            return pckPush(event.data)
          }
          let codec
          const decoded = data.slice(1)
          codec = new TextDecoder('utf-8').decode(decoded)
          sourceBuffer = mediaSource.addSourceBuffer(`video/mp4; codecs="${codec}"`)
          sourceBuffer.mode = 'segments'
          sourceBuffer.addEventListener('updateend', pckLoad)
        }
      }

      const pckLoad = () => {
        if (sourceBuffer) {
          if (!sourceBuffer.updating && queue.length > 0) {
            return sourceBuffer.appendBuffer(queue.shift()!)
          }
          streamingStarted = false
        }
      }

      const pckPush = (bufferSource: BufferSource) => {
        if (!sourceBuffer) {
          return
        }
        if (!streamingStarted) {
          sourceBuffer.appendBuffer(bufferSource)
          streamingStarted = true
          return
        }
        queue.push(bufferSource)
        pckLoad()
      }

      mediaSource.addEventListener('sourceopen', sourceOpen, false)
      ref.current.src = window.URL.createObjectURL(mediaSource)
    }
    return () => {
      if (ws) {
        ws.close()
      }
    }
  }, [device])

  return (
    <video
      controls
      autoPlay
      muted
      ref={ref}
      style={{
        width,
        ...style,
      }}
    />
  )
}
function VideoPreview(props: { ctx: TableTypes.TableCellContext<Device> }) {
  return (
    <Button
      label="Стрим"
      color="secondary"
      textColor="surface"
      size="s"
      onClick={() => {
        modal({
          title: props.ctx.row.name,
          overlayClose: false,
          render: () => <FullScreenVideo device={props.ctx.row} />,
        })
      }}
      rightChild={<Video />}
    />
  )
}
export const columns: TableTypes.TableColumn<Device>[] = [
  { key: 'id', title: 'ID', width: '4rem', sort: 'ASC' },
  { key: 'name', width: 200, title: 'Наименование', sort: 'ASC' },
  {
    key: 'name',
    width: 200,
    title: 'Привязана к весам',
    render: (ctx) => <Text>{ctx.row.linkedDevice?.name || '-'}</Text>,
  },
  {
    key: '_',
    title: '',
    render: (ctx) => (
      <Flexbox justifyContent="flex-end">
        <VideoPreview ctx={ctx} />
        <Button
          ml="s"
          label="Изменить"
          decoration="plain"
          size="s"
          onClick={() => {
            updateDeviceDialog(ctx.row)
          }}
          rightChild={<Edit />}
        />
      </Flexbox>
    ),
  },
]
