import React from 'react'

import { StaffExtended } from '.'

import { Button } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import { useMouseDropMenu } from 'components/MouseMenuDrop'
import { useHistory } from 'react-router-dom'
import TwoDots from 'components/Icons/TwoDots'
import { openStaffSendChatMessageModal } from 'components/Modals/StaffSendChatMessage'
import { openStaffDeleteModal } from 'components/Modals/StaffDelete'

export function StaffListContextMenuCell(
  ctx: TableTypes.TableCellContext<StaffExtended>,
) {
  const history = useHistory()

  const { row } = ctx

  const contextMenu = useMouseDropMenu<HTMLButtonElement>(
    [
      { text: 'Открыть карточку', icon: 'Person', value: 'OPEN' },
      { text: 'Написать в чат', icon: 'MessageCircle', value: 'MESSAGE' },
      { text: 'Удалить', icon: 'Trash2', value: 'DELETE', color: 'red500' },
    ],
    ({ value }) => {
      if (value === 'OPEN') {
        history.push(`/staff/${row.id}`)
      }
      if (value === 'MESSAGE') {
        openStaffSendChatMessageModal(row)
      }
      if (value === 'DELETE') {
        openStaffDeleteModal(row)
      }
    },
  )

  return (
    <Button
      id={`staff_row_action_${row.id}`}
      decoration="text"
      color="gray500"
      p={0}
      w="2rem"
      h="2rem"
      {...contextMenu}
    >
      <TwoDots fillColor="gray900" size="1.5rem" />
    </Button>
  )
}
