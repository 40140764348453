import { Flexbox, Paragraph } from '@stage-ui/core'
import { AlertTriangle } from '@stage-ui/icons'

export function Unavailable() {
  return (
    <Flexbox alignItems="center" justifyContent="center" h="100%">
      <Flexbox column alignItems="center">
        <AlertTriangle size="10rem" color="gray300" />
        <Paragraph weight={600} size="xl" color="gray700" mt="1rem">
          Сервис недоступен
        </Paragraph>
        <Paragraph weight={600} color="gray500" mt="1rem">
          Сделайте паузу и попробуйте позже, мы уже занимаемся этой проблемой
        </Paragraph>
      </Flexbox>
    </Flexbox>
  )
}
