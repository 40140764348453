import React from 'react'

import { Button, Grid } from '@stage-ui/core'
import useSelector from 'hooks/useSelector'
import genCameraName from 'utils/genCameraName'
import { objectEqual } from 'utils/objectEqual'
import { OrderQualityActions } from 'actions'

const CamerasNavigation = () => {
  const { devices, filter } = useSelector(
    ({ devices, orderQuality }) => ({
      filter: orderQuality.filter,
      devices: devices.data.filter((device) => device.type === 'CAMERA'),
    }),
    objectEqual,
  )

  const handleClick = (sectionName: string) => {
    OrderQualityActions.setFilter({
      section: filter.section !== sectionName ? sectionName : undefined,
    })
  }

  return (
    <Grid gap="0.5rem" autoFlow="column">
      {devices.map((camera) => {
        const sectionName = genCameraName(camera.id)
        return (
          <Button
            key={camera.id}
            decoration={filter.section === sectionName ? 'filled' : 'outline'}
            borderWidth="0.0625rem"
            onClick={() => handleClick(sectionName)}
          >
            {camera.name}
          </Button>
        )
      })}
    </Grid>
  )
}

export default CamerasNavigation
