import React from 'react'

import { Button, Flexbox, Grid, Text } from '@stage-ui/core'
import { Close, Flip2 } from '@stage-ui/icons'

export type StaffDetailsInfoRowProps = {
  param: string
  value: string | React.ReactNode
  onChange?: () => void
  onClear?: () => void | Promise<void>
  disabled?: boolean
}

export function StaffDetailsInfoRow(props: StaffDetailsInfoRowProps) {
  const { disabled, param, value, onChange, onClear } = props

  if (disabled) return null

  return (
    <Flexbox
      py="s"
      alignItems="center"
      borderWidth="0 0 1px 0"
      borderStyle="solid"
      borderColor="gray200"
      style={{
        minHeight: '2.75rem',
        boxSizing: 'border-box',
      }}
    >
      <Text
        flexBasis="8.5rem"
        flexShrink={0}
        size="s"
        color="gray500"
        weight={400}
        mr="m"
      >
        {param}
      </Text>
      <Flexbox flex={1} alignItems="center" justifyContent="space-between">
        {typeof value === 'string' || !value ? (
          <Text flex={1} color={!value ? 'gray400' : 'gray900'}>
            {value || '–'}
          </Text>
        ) : (
          value
        )}
        <Grid autoFlow="column" gap="0.125rem">
          {onClear && !!value && (
            <Button
              w="1.75rem"
              h="1.75rem"
              p={0}
              size="s"
              decoration="text"
              color="red500"
              onClick={onClear}
            >
              <Close size="1.125rem" />
            </Button>
          )}
          {onChange && (
            <Button
              w="1.75rem"
              h="1.75rem"
              p={0}
              size="s"
              decoration="text"
              color="orange500"
              onClick={onChange}
            >
              <Flip2 />
            </Button>
          )}
        </Grid>
      </Flexbox>
    </Flexbox>
  )
}
