import React, { useEffect } from 'react'

import { DictionariesActions } from 'actions'
import useSelector from 'hooks/useSelector'

import { objectEqual } from 'utils/objectEqual'

import ContentTable from './Content'

function ContainersDictionary() {
  const loading = useSelector(
    ({ dictionaries }) => dictionaries.deliveryIntervalsLoading,
    objectEqual,
  )

  useEffect(() => {
    if (loading !== 'resolved') {
      DictionariesActions.getDeliveryIntervals()
    }
  }, [])

  return <ContentTable />
}

export default ContainersDictionary
