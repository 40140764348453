import React from 'react'

import { ProductGroup, ProductGroupInput } from '@foods-n-goods/server/generated/schema'
import { Block, Button, Flexbox, Text } from '@stage-ui/core'
import { CategoryActions } from 'actions'
import AcceptDialog from 'components/AcceptDialog'
import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'

interface CategoryViewControllersProps {
  goBack: () => void
  input: ProductGroupInput
  category?: ProductGroup
}

function CategoryViewControllers(props: CategoryViewControllersProps) {
  const { category, input, goBack } = props
  const actionError = useSelector(({ category }) => category.actionError, objectEqual)

  const actionButtonLabel = !category?.id ? 'Добавить' : 'Сохранить'

  const handleCreate = () => CategoryActions.create(input, goBack)
  const handleUpdate = () =>
    category?.id && CategoryActions.update(category.id, input, goBack)
  const handleDelete = () => category?.id && CategoryActions.delete(category.id)

  const onActionClick = category?.id ? handleUpdate : handleCreate

  return (
    <Block decoration="surface" borderColor="gray100" p="l" mt="m">
      {actionError && (
        <Flexbox pb="m">
          <Text size="s" color="error" children={actionError} />
        </Flexbox>
      )}
      <Flexbox>
        {category && (
          <AcceptDialog
            action={() => {
              handleDelete()
              goBack()
            }}
            title="Удаление группы"
            children="Удалить"
            color="error"
            text={`Группа «${category.name}» будет удалена без возможности восстановления! Удалить категорию?`}
          />
        )}
        <Flexbox flex={1} justifyContent="flex-end" alignItems="center">
          <Button children="Отмена" mx="0.5rem" decoration="text" onClick={goBack} />
          <Button
            children={actionButtonLabel}
            textColor="onPrimary"
            onClick={onActionClick as () => Promise<void>}
          />
        </Flexbox>
      </Flexbox>
    </Block>
  )
}

export default CategoryViewControllers
