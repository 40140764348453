import React from 'react'

import { Button, Flexbox } from '@stage-ui/core'

type ButtonGroupProps = {
  data: {
    id?: string | number
    active: boolean
    text: string
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  }[]
}

export const ButtonGroup: React.FC<ButtonGroupProps> = (props) => {
  const { data } = props
  return (
    <Flexbox backgroundColor="gray100" p="xs" borderRadius="s">
      {data.map((button) => (
        <Button
          key={button.id || button.text}
          size="xs"
          mr="xs"
          px="s"
          borderStyle="solid"
          borderWidth="0.0625rem"
          borderColor={button.active ? 'gray300' : 'gray100'}
          textColor={button.active ? 'gray700' : 'gray500'}
          color={button.active ? 'surface' : 'gray100'}
          label={button.text}
          onClick={button.active ? undefined : (e) => button.onClick(e)}
        />
      ))}
    </Flexbox>
  )
}
