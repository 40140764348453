import moment from 'moment'
import request from 'requests/request'
import store, { OrderQualityStore } from 'store'
import { OrderQualityFilter } from 'store/orderQuality'

export default {
  async append() {
    const { data } = store.getState().orderQuality

    if (data.length) {
      const last = data.slice().sort((a, b) => {
        return moment(a.lineDate).unix() < moment(b.lineDate).unix() ? 1 : -1
      })[0]

      try {
        const res = await request('orderQuality', {
          lastDate: moment(last.lineDate).toISOString(),
        })
        if (res.length) {
          OrderQualityStore.append(res)
        }
      } catch (error) {
        console.error(error)
      }
    }
  },

  async fetch() {
    try {
      OrderQualityStore.loading()
      const res = await request('orderQuality')
      OrderQualityStore.resolve(res)
    } catch (error) {
      console.error(error)
    }
  },

  setFilter(filter: Partial<OrderQualityFilter>) {
    OrderQualityStore.setFilter(filter)
  },

  clearFilter() {
    OrderQualityStore.clearFilter()
  },
}
