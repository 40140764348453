import React from 'react'

import { Flexbox } from '@stage-ui/core'
import { ButtonGroup } from 'components/ButtonGroup'

import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'

import { DeliveryActions } from 'actions'

import { LogisticsOrderStatus } from 'store/delivery'

const statuses: Record<LogisticsOrderStatus, string> = {
  all: 'Все',
  warehouse: 'На складе',
  delivery: 'В доставке',
  done: 'Завершенные',
  unassigned: 'Нет водителя',
}

export function LogisticsOrdersFilter() {
  const filter = useSelector((state) => state.delivery.filter, objectEqual)

  return (
    <Flexbox justifyContent="space-between" mb="s" flex={1}>
      <ButtonGroup
        data={Object.keys(statuses).map((status) => ({
          active: filter.ordersTab === status,
          text: statuses[status as LogisticsOrderStatus],
          onClick: () => {
            DeliveryActions.setFilter({
              ordersTab: status as LogisticsOrderStatus,
            })
          },
        }))}
      />
    </Flexbox>
  )
}
