import React from 'react'

import useSelector from 'hooks/useSelector'
import { ProgressBar } from 'components/ProgressBar'
import { useTheme } from '@stage-ui/core'
import { objectEqual } from 'utils/objectEqual'

export function LogisticsProgressBar() {
  const theme = useTheme()

  const statCounters = useSelector(
    (state) => ({
      ordersTotal: state.order.statCounters.ordersTotal,
      ordersDelivered: state.order.statCounters.ordersDelivered,
      ordersAssigned: state.order.statCounters.ordersAssigned,
      ordersNotAssigned: state.order.statCounters.ordersNotAssigned,
    }),
    objectEqual,
  )

  return (
    <ProgressBar
      total={statCounters.ordersTotal + statCounters.ordersDelivered}
      entities={[
        {
          title: 'Сегодня доставлено',
          amount: statCounters.ordersDelivered,
          dotColor: theme.color.blue[600].hex(),
          background: `linear-gradient(90deg, ${theme.color.blue[400].hex()} 0%, ${theme.color.blue[600].hex()} 100%)`,
        },
        {
          title: 'Водитель назначен',
          amount: statCounters.ordersAssigned,
          dotColor: theme.color.blue[400].hex(),
          background: `linear-gradient(90deg, ${theme.color.blue[200].hex()} 0%, ${theme.color.blue[400].hex()} 100%)`,
        },
        {
          title: 'Нет водителя',
          amount: statCounters.ordersNotAssigned,
          dotColor: theme.color.blue[200].hex(),
          background: theme.color.blue[200].hex(),
          loading: true,
        },
      ]}
      noDataText="Здесь будут отображаться данные по доставке"
    />
  )
}
