import React, { useImperativeHandle, useRef, useState } from 'react'

import { Drop, Popover, Text } from '@stage-ui/core'
import DropTypes from '@stage-ui/core/layout/Drop/types'

type MouseDropProps = {
  disabled?: boolean
  drop?: React.ComponentProps<typeof Drop>
  popover?: React.ComponentProps<typeof Popover>
}

type MouseDropRef = {
  setTarget: (
    target: HTMLElement | null,
    text: React.ReactNode,
    props?: MouseDropProps,
  ) => void
  clearTarget: () => void
}

const mouseDropRef = React.createRef<MouseDropRef>()
export const useMouseDrop = (text: React.ReactNode, props?: MouseDropProps) => {
  const ref = useRef(null)
  if (!text || props?.disabled) {
    return {}
  }
  return {
    ref,
    onMouseOver: () => {
      mouseDropRef.current?.setTarget(ref.current, text, props)
    },
    onMouseLeave: () => {
      mouseDropRef.current?.clearTarget()
    },
  }
}

function MouseDrop() {
  const [dropProps, setDropProps] = useState<React.ComponentProps<typeof Drop>>()
  const [popoverProps, setPopoverProps] = useState<React.ComponentProps<typeof Popover>>()

  const [text, setText] = useState('')
  const [visible, setVisible] = useState(false)

  const dropRef = useRef<DropTypes.Ref>(null)
  const targetRef = useRef<HTMLElement | null>(null)

  useImperativeHandle(mouseDropRef, () => ({
    setTarget: (el, tooltip, props) => {
      targetRef.current = el
      dropRef.current?.updatePosition()
      // @ts-expect-error
      setText(tooltip)
      setVisible(true)
      setDropProps(props?.drop)
      setPopoverProps(props?.popover)
    },
    clearTarget: () => {
      setVisible(false)
    },
  }))

  return (
    <Drop
      ref={dropRef}
      target={targetRef}
      visible={visible}
      align="top"
      justify="center"
      spacing={10}
      style={{
        zIndex: '5000 !important',
      }}
      {...dropProps}
    >
      <Popover
        backgroundColor="gray900"
        align="bottom"
        borderWidth="0"
        style={{
          border: 'none',
          '@media print': {
            display: 'none',
          },
        }}
        {...popoverProps}
      >
        <Text
          size="s"
          color="gray50"
          weight={500}
          lineHeight="1rem"
          style={{
            wordBreak: 'break-word',
            whiteSpace: 'pre-wrap',
          }}
        >
          {text}
        </Text>
      </Popover>
    </Drop>
  )
}

export default MouseDrop
