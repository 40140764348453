import React from 'react'

import { Client } from '@foods-n-goods/server/generated/schema'
import { modal } from '@stage-ui/core'
import { ClientDetails } from 'pages/clients/ClientDetails'

export const openClientModal = (
  id: Client['id'],
  e?: React.MouseEvent<HTMLDivElement, MouseEvent>,
) => {
  e?.stopPropagation()
  e?.preventDefault()

  modal({
    decoration: 'panel',
    hideHeader: true,
    overrides: (t) => ({
      window: () => [
        {
          background: t.color.background.hex(),
          minWidth: '90vw',
          minHeight: '90vh',
        },
      ],
    }),
    render: (close) => <ClientDetails id={id} onClose={close} />,
  })
}
