import React from 'react'

import Lottie from 'react-lottie'

import loading from './data/loading.json'
import error from './data/error.json'
import avocadoBro from './data/avocadoBro.json'

const animations = {
  loading,
  error,
  avocadoBro,
}
interface AnimationProps {
  type: keyof typeof animations
  loop?: boolean
  autoplay?: true
  w?: number
  h?: number
}

const Animation: React.FC<AnimationProps> = (props) => {
  const { w = 100, h = 100, loop = true, autoplay = true } = props
  const animationData = animations[props.type]

  return (
    <Lottie
      options={{
        loop,
        autoplay,
        animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      }}
      width={w}
      height={h}
    />
  )
}

export default Animation
