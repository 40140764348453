import React from 'react'

import { Product, ProductInput } from '@foods-n-goods/server/generated/schema'
import { Button, Flexbox } from '@stage-ui/core'
import { ProductActions } from 'actions'
import AcceptDialog from 'components/AcceptDialog'
import Content from 'components/Content'

interface ProductViewControllersProps {
  goBack: () => void
  input: ProductInput
  product?: Product
}

function ProductViewControllers(props: ProductViewControllersProps) {
  const { product, input, goBack } = props

  const actionButtonLabel = !product?.id ? 'Добавить' : 'Сохранить'

  const _create = () => ProductActions.create(input, goBack)
  const _update = () => product?.id && ProductActions.update(product.id, input, goBack)
  const _delete = () => product?.id && ProductActions.delete(product.id)

  const onActionClick = product?.id ? _update : _create

  return (
    <Content>
      <Flexbox>
        {product && (
          <AcceptDialog
            action={_delete}
            title="Удаление продукта"
            children="Удалить"
            color="error"
            text={`Продукт «${product.name}» будет удален без 
                            возможности восстановления! Удалить продукт?`}
          />
        )}
        <Flexbox flex={1} justifyContent="flex-end" alignItems="center">
          <Button children="Отмена" mx="0.5rem" decoration="text" onClick={goBack} />
          <Button
            children={actionButtonLabel}
            textColor="onPrimary"
            onClick={onActionClick}
          />
        </Flexbox>
      </Flexbox>
    </Content>
  )
}

export default ProductViewControllers
