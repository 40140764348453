export default `
    query purchaseProducts($search: String, $filter: PurchaseProductFilter!) {
        purchaseProducts(search: $search, filter: $filter) {
            productId
            productName
            updateDate
            unitId
            orderQuantity
            purchaseQuantity
        }
    }
`
