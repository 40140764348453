import React from 'react'

import { Flexbox, Text } from '@stage-ui/core'
import { List } from '@stage-ui/icons'

const Empty: React.FC<{ text?: string; icon?: React.ReactNode }> = (props) => {
  const Icon = props.icon || <List size="2rem" color="gray400" />
  return (
    <Flexbox centered p="m" column>
      {Icon}
      <Text size="s" color="gray500" mt="s">
        {props.text || 'Нет данных'}
      </Text>
    </Flexbox>
  )
}

export default Empty
