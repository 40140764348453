import React, { useEffect } from 'react'

import { Section } from 'components/Section'
import { Route } from 'react-router-dom'

import { OrderActions } from 'actions'

import { OrdersSectionHeader } from './components/SectionHeader'
import { OrderDetails } from './OrderDetails'
import { OrderList } from './OrderList'

export function Orders() {
  useEffect(() => {
    return () => {
      OrderActions.clearFilter()
    }
  }, [])

  return (
    <Section titleComponent={<OrdersSectionHeader />} p="0 l">
      <Route exact path="/orders" component={OrderList} />
      <Route path="/orders/:id" component={OrderDetails} />
    </Section>
  )
}
