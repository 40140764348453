import React, { Block, Text } from '@stage-ui/core'
import { useHTMLRender } from 'pages/logistics/MapView/hooks/useHTMLRender'

export const CourierTemplate = (): ReturnType<typeof useHTMLRender> => {
  const arrowProps = {
    'data-type': 'courier',
    'data-id': '{{properties.courier.id}}',
    'data-hover': '{{properties.hover}}',
  }
  const svgProps = {
    'aria-details': 'transform: rotate({{properties.style.rotate}}deg);',
  }

  const { render, html } = useHTMLRender(
    <Block
      w="1.5rem"
      h="1.5rem"
      attributes={arrowProps}
      backgroundColor="transparent"
      style={{
        transition: 'all 0.125s',
        filter: 'none',
        '> svg': {
          transition: 'all 0.125s',
        },
        '> svg > path': {
          transition: 'all 0.125s',
        },
        '> div': {
          transition: 'all 0.125s',
          '> span': {
            transition: 'all 0.125s',
          },
        },
        '&[data-hover="true"], &:hover': {
          cursor: 'pointer',
          filter: 'drop-shadow(0rem 0.125rem 0.25rem rgba(0, 0, 0, 0.1))',
          '> svg': {
            transform: 'scale(1.2)',
          },
          '> svg > path:nth-child(1)': {
            fill: '#3e9eff',
          },
          '> svg > path:nth-child(2)': {
            fill: '#FFFFFF',
          },
          '> div': {
            backgroundColor: '#3e9eff',
            '> span': {
              color: '#fff',
            },
          },
        },
      }}
    >
      <svg
        {...svgProps}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.9303 23.2006L22.9382 23.194L22.948 23.1847C22.9646 23.1697 23.0155 23.1239 23.0694 23.0696C24.0291 22.1106 24.2818 20.635 23.6619 19.4003L14.8403 1.75496C14.3007 0.681371 13.2049 0.000736888 12.0013 2.39111e-06C10.8017 -0.0014666 9.69606 0.674026 9.15842 1.75961L0.337849 19.3993C-0.311428 20.6937 -0.00196853 22.2499 1.06939 23.2006C2.14564 24.1554 3.73015 24.2668 4.93273 23.4679L11.9994 18.7894L19.0699 23.4711C20.2686 24.2656 21.8531 24.1566 22.9303 23.2006Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.6 21.7005C21.6174 21.6847 21.6348 21.669 21.6522 21.6517C22.0062 21.2976 22.0981 20.754 21.8706 20.3015L13.0469 2.65198C12.8483 2.25653 12.4438 2.00586 12.0004 2.00586C11.5561 2.00504 11.1508 2.25488 10.9547 2.65281L2.13019 20.3015C1.89194 20.7747 2.00362 21.348 2.40071 21.7005C2.79698 22.0521 3.3802 22.0926 3.82114 21.7989L11.3543 16.812C11.7464 16.5522 12.2543 16.5522 12.6465 16.812L20.1788 21.7997C20.6206 22.0926 21.2038 22.0521 21.6 21.7005Z"
          fill="{{properties.style.color}}"
        />
      </svg>
      <Block
        borderRadius="m"
        backgroundColor="white"
        p="0 0.125rem"
        style={{
          position: 'absolute',
          bottom: '-0.5rem',
          left: '1.25rem',
        }}
      >
        <Text
          color="gray900"
          size="0.6875rem"
          weight={700}
        >{`{{properties.courier.tag}}`}</Text>
      </Block>
    </Block>,
  )

  return { render, html }
}
