import React from 'react'

import { OrderStatusCode } from '@foods-n-goods/client/system/types'
import { Staff } from '@foods-n-goods/server/generated/schema'
import { Block, CircularProgress, Flexbox, Text } from '@stage-ui/core'
import useSelector from 'hooks/useSelector'

import { objectEqual } from 'utils/objectEqual'

import { OrderDelivery } from 'store/delivery'

import { BatteryIndicator } from './misc/BatteryIndicator'
import { ErrorIndicator } from './misc/ErrorIndicator'
import { NoGpsIndicator } from './misc/NoGpsIndicator'

type Props = {
  courier: Staff
  selected: boolean
  divider: boolean
  onClick: () => void
  onMouseEnter: () => void
  onMouseLeave: () => void
}

function CourierRowComponent(props: Props) {
  const { selected, divider, courier, onClick, onMouseEnter, onMouseLeave } = props
  const { id, name, color, online, batteryLife, lastGeoPosition, selfPhoneInfo, login } =
    courier

  const { hideSimilarClientOrders, ordersData } = useSelector(
    (state) => ({
      hideSimilarClientOrders:
        state.app.marketConfig?.logisticsCourierHideSimilarClientOrders,
      ordersData: state.delivery.orderPage.records.map((order) => ({
        ...order,
        positions: [],
      })),
    }),
    objectEqual,
  )

  const orders = ordersData.reduce((res, item) => {
    if (item.courier?.id === id) {
      if (Number(hideSimilarClientOrders || '1')) {
        if (!res.some((o) => o.client.id === item.client.id)) {
          res.push(item)
        }
      } else {
        res.push(item)
      }
    }
    return res
  }, [] as OrderDelivery[])

  const totalLen = orders.length
  const deliveredLen = orders.filter(
    (order) => order.status.value === OrderStatusCode.DELIVERED,
  ).length

  return (
    <Flexbox
      p="0.75rem"
      column
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      attributes={{
        'data-id': courier.id,
        'data-hover': 'false',
      }}
      backgroundColor={selected ? 'green50' : 'surface'}
      borderRadius="s"
      style={(t) => [
        {
          borderBottom: '0.0625rem solid rgba(0,0,0,0)',
          boxShadow: selected ? '0 0 0.75rem rgba(0,0,0,0.2)' : '0 0 0 rgba(0,0,0,0)',
          borderColor: selected ? t.color.green[500].hex() : 'transparent',
          borderWidth: '0.0625rem',
          borderStyle: 'solid',
          zIndex: selected ? 2 : 1,
          '&[data-hover="true"]': {
            backgroundColor: selected ? t.color.green[50].hex() : t.color.gray[100].hex(),
            ':hover': {
              backgroundColor: selected
                ? t.color.green[50].hex()
                : t.color.gray[100].hex(),
            },
          },
          top: selected ? 0 : '0.125rem',
          ...(divider &&
            !selected && {
              borderBottomRightRadius: '0 !important',
              borderBottomLeftRadius: '0 !important',
              borderBottomColor: t.color.gray[100].hex(),
            }),
        },
      ]}
    >
      <Flexbox alignItems="center">
        <Block
          mr="s"
          borderRadius="0.125rem"
          w="0.25rem"
          h="1rem"
          backgroundColor={color?.code || 'gray200'}
        />
        <Text
          lineHeight={1}
          size="s"
          weight="500"
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: '4rem',
            pointerEvents: 'none',
          }}
        >
          {name || login}
        </Text>
      </Flexbox>
      <Flexbox pt="s" alignItems="center" justifyContent="space-between">
        <Flexbox alignItems="center">
          <CircularProgress
            mr="xs"
            color="blue600"
            size="1.125rem"
            strokeBackgroundColor={totalLen ? 'blue200' : 'gray200'}
            value={(deliveredLen / totalLen) * 100}
          />
          <Text size="0.6875rem" color={totalLen ? 'gray900' : 'gray300'}>
            {deliveredLen}/{totalLen}
          </Text>
        </Flexbox>
        <Flexbox alignItems="center">
          {!lastGeoPosition?.coords && <NoGpsIndicator />}
          <ErrorIndicator courierId={id} selfPhoneInfo={selfPhoneInfo} />
          {!!online && <BatteryIndicator batteryLife={batteryLife} />}
        </Flexbox>
      </Flexbox>
    </Flexbox>
  )
}

const jsonMemo = (p: Props) =>
  JSON.stringify({
    selected: p.selected,
    courier: {
      ...p.courier,
      lastGeoPosition: !!p.courier.lastGeoPosition,
    },
  })

const MemoComponent = React.memo(
  CourierRowComponent,
  (p, n) => jsonMemo(p) === jsonMemo(n),
)

export function CourierRow(props: Props) {
  return <MemoComponent {...props} />
}
