import { Product, ProductInput } from '@foods-n-goods/server/generated/schema'
import { ProductActions } from 'actions'
import Content from 'components/Content'
import ViewInputField from 'components/Fields/ViewInputField'
import ImageController from 'components/ImageController'
import ViewGridBlock from 'components/ViewGridBlock'

interface ProductViewPresentationProps {
  description: ProductInput['description']
  product?: Product
  onChange: KeyValueChangeFunction<ProductInput>
}

function ProductViewPresentation(props: ProductViewPresentationProps) {
  const { description, product, onChange } = props
  if (!product) return null

  return (
    <Content>
      <ViewGridBlock title="Отображение" templateColumns="11.5rem 1fr 1fr">
        <ImageController
          id={product.id}
          uploadAction={ProductActions.imageUpdate}
          url={product?.image}
          size="11.5rem"
        />
        <ViewInputField
          label="Описание"
          value={description || ''}
          onChange={(value) => onChange('description', value)}
          multiline
          gridColumnEnd="span 2"
        />
      </ViewGridBlock>
    </Content>
  )
}

export default ProductViewPresentation
