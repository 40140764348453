import React from 'react'

import { Order } from '@foods-n-goods/server/generated/schema'
import { Button, Flexbox, ScrollView, Table, Text } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import { Info } from '@stage-ui/icons'
import { OrderActions } from 'actions'
import useSelector from 'hooks/useSelector'
import { useHistory, useRouteMatch } from 'react-router-dom'

import { objectEqual } from 'utils/objectEqual'

import { OrderListAmountCellRight } from '../OrderList/components/Table/components/OrderAmountCellRight'
import { OrderListTableClientCell } from '../OrderList/components/Table/components/OrderClientCell'
import { OrderListTableIdCell } from '../OrderList/components/Table/components/OrderIdCell'

type OrderArchiveProps = {
  close: () => void
  orders: Array<Order>
}

export function OrderArchive(props: OrderArchiveProps) {
  const { close, orders } = props

  const { params } = useRouteMatch<{ id?: string }>()
  const history = useHistory()

  const handleConfirm = () => {
    OrderActions.delete({ ids: orders.map((order) => order.id) })
    if (params.id) {
      history.push('/orders')
    }
    close()
  }

  const orderPage = useSelector(
    (state) => ({
      ...state.order.orderPage,
      loading: state.order.loading === 'pending',
    }),
    objectEqual,
  )

  const { selectedOrders } = useSelector(
    (state) => ({
      selectedOrders: state.order.selectedOrders,
    }),
    objectEqual,
  )

  const orderTableColumns: TableTypes.TableColumn<Order>[] = [
    {
      key: 'id',
      title: 'Номер',
      width: 65,
      primary: true,
      render: OrderListTableIdCell,
    },
    {
      key: 'client',
      title: 'Клиент',
      render: OrderListTableClientCell,
    },
    {
      key: 'amount',
      title: 'На сумму',
      width: 110,
      render: OrderListAmountCellRight,
    },
  ]

  const onRowClick = (ctx: TableTypes.TableRowContext<Order>) => {
    if (orderPage.loading) return
    const data = [{ order: ctx.row, isSelected: ctx.isSelected }]
    OrderActions.setSelected(data)
  }

  const onCheckboxClick = (ctx: TableTypes.TableRowContext<Order>[]) => {
    if (orderPage.loading) return
    const data = ctx.map((c) => ({ order: c.row, isSelected: c.isSelected }))
    OrderActions.setSelected(data)
  }

  return (
    <Flexbox column>
      <Flexbox flex={1} alignItems="center" mb="1.75rem" justifyContent="flex-end">
        {orders.length > 1 && (
          <Text size="s" ml="auto" color="gray500">
            Выбрано заказов: {orders.length}
          </Text>
        )}
      </Flexbox>
      <Flexbox
        backgroundColor="gray100"
        p="m"
        mb="l"
        borderRadius="s"
        borderStyle="solid"
        borderColor="gray300"
        borderWidth="1px"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Info size="1.25rem" color="red500" mr="m" />
        <Flexbox column>
          <Text size="s" weight={400} color="red500">
            {`После отправки в архив заказ${orders.length > 1 ? 'ы' : ''} пропад${
              orders.length > 1 ? 'ают' : 'ёт'
            } из списка!`}
          </Text>
        </Flexbox>
      </Flexbox>

      <Flexbox
        column
        h="20rem"
        alignItems="flex-start"
        backgroundColor="white"
        borderRadius="m"
        borderColor="gray200"
        borderWidth="1px"
        borderStyle="solid"
      >
        <ScrollView mode="scroll" w="100%" barOffset={4}>
          <Table
            selected={selectedOrders}
            onRowClick={onRowClick}
            onCheckboxClick={onCheckboxClick}
            columns={orderTableColumns}
            data={orders}
            overrides={{
              container: {
                thead: {
                  display: 'none',
                },
                tbody: {
                  tr: {
                    backgroundColor: 'transparent !important',

                    td: {
                      ':first-of-type': {
                        div: {
                          div: {
                            height: '16px',
                            width: '16px',
                          },
                        },
                      },
                      ':last-of-type': {
                        div: {
                          display: 'flex',
                          justifyContent: 'flex-end',
                          marginLeft: 'auto',
                          marginRight: '0',
                        },
                      },
                      borderLeftWidth: '0 !important',
                      borderRightWidth: '0 !important',
                      borderTopWidth: '0 !important',
                      borderBottomWidth: '1px !important',

                      borderRadius: '0 !important',
                      paddingTop: '8px !important',
                      paddingBottom: '8px !important',
                    },
                  },
                },
              },
            }}
          />
        </ScrollView>
      </Flexbox>

      <Flexbox justifyContent="space-between" mt="1.25rem">
        <Flexbox flex={1} justifyContent="flex-end">
          <Button
            mr="m"
            label="Отмена"
            color="gray500"
            textColor="gray500"
            decoration="text"
            onClick={close}
            pr="20px"
            pl="20px"
          />
          <Button
            color="red500"
            label="Отправить"
            pr="20px"
            pl="20px"
            onClick={handleConfirm}
          />
        </Flexbox>
      </Flexbox>
    </Flexbox>
  )
}
