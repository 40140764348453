import ClientSystem from '@foods-n-goods/client/system/types'
import { Journal } from '@foods-n-goods/server/generated/schema'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface JournalState {
  loading: ClientSystem.Loading
  data: Journal[]
  error: RequestError | null

  actionError: RequestError | null
}

export const initialState: JournalState = {
  loading: 'idle',
  data: [],
  error: null,

  actionError: null,
}

const journalSlice = createSlice({
  name: 'journal',
  initialState,
  reducers: {
    loading(state) {
      state.loading = 'pending'
    },
    resolve(state, action: PayloadAction<JournalState['data']>) {
      state.data = action.payload
      state.loading = 'resolved'
      state.error = initialState.error
    },
    reject(state, action: PayloadAction<JournalState['error']>) {
      state.loading = 'rejected'
      state.error = action.payload
    },
    clear(state) {
      return initialState
    },
  },
})

export default journalSlice
