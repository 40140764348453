import React from 'react'

import { Button, Flexbox, Grid, Header, Text } from '@stage-ui/core'
import { openClientExternalIdChangeModal } from 'components/Modals/ClientExIdChange'
import { openClientDeclineModal } from 'components/Modals/ClientDecline'
import { Client } from '@foods-n-goods/server/generated/schema'

type ClientStatusBannerProps = {
  client: Client
}
export function ClientStatusBanner(props: ClientStatusBannerProps) {
  const { client } = props

  if (client.markets[0]?.status === 'AWAITING') {
    return (
      <Flexbox
        mt="xl"
        flex={1}
        p="l"
        backgroundColor="yellow500"
        borderRadius="s"
        justifyContent="space-between"
      >
        <Flexbox
          column
          style={{
            maxWidth: '33rem',
          }}
          mr="m"
        >
          <Header size="xs" color="white" mb="m">
            Клиент не может сделать заказ!
          </Header>
          <Text size="s" color="white">
            Необходимо завести контрагента в 1С, синхронизировать контрагентов из 1С и
            выдать прайс-лист путем присвоения кода 1C клиенту.
          </Text>
        </Flexbox>

        <Grid
          flexShrink={0}
          gap="0.5rem"
          alignItems="center"
          style={{
            gridTemplateColumns: '1fr 1fr',
            gridTemplateRows: '1fr',
            gridAutoFlow: 'dense',
            'button:first-of-type': {
              gridColumn: 2,
            },
            '@media(max-width: 1680px)': {
              gridTemplateColumns: '1fr',
              gridTemplateRows: 'min-content min-content',
              placeContent: 'center',
              'button:first-of-type': {
                gridColumn: 1,
              },
            },
          }}
        >
          <>
            <Button
              size="m"
              label="Выдать прайс-лист"
              color="white"
              textColor="yellow500"
              onClick={() => openClientExternalIdChangeModal(client)}
            />
            <Button
              w="100%"
              size="m"
              label="Отказать"
              color="yellow500"
              textColor="white"
              decoration="outline"
              borderColor="white"
              onClick={() => openClientDeclineModal(client)}
            />
          </>
        </Grid>
      </Flexbox>
    )
  }

  if (['DECLINED', 'BLOCKED'].includes(client.markets[0]?.status)) {
    return (
      <Flexbox mt="xl" flex={1} p="l" backgroundColor="red500" borderRadius="s" column>
        <Header size="xs" color="white">
          Клиент {client.markets[0].status === 'DECLINED' ? 'отклонен' : 'заблокирован'}!
        </Header>
        {client.markets[0].comment && (
          <Text size="s" mt="m" color="white">
            Причина: {client.markets[0].comment}
          </Text>
        )}
      </Flexbox>
    )
  }

  return null
}
