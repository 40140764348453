import React, { useState } from 'react'

import { Client } from '@foods-n-goods/server/generated/schema'
import { Button, Flexbox, modal, Text, TextField } from '@stage-ui/core'
import { ClientActions } from 'actions'

type ClientAliasChangeModalProps = {
  client: Client
  close: () => void
}

function ClientAliasChangeModal(props: ClientAliasChangeModalProps) {
  const { client, close } = props
  const { id } = client

  const [aliasText, setAliasText] = useState<string>(client.markets[0]?.alias || '')

  const handleChangeAlias = async () => {
    if (!client || aliasText === client.markets[0]?.alias) return

    await ClientActions.marketUpdate({
      id,
      payload: {
        alias: aliasText,
      },
    })
    close()
  }

  return (
    <Flexbox column w="30rem">
      <Text mb="l" color="gray500">
        Алиас - это имя в системе, которое видят остальные пользователи.
      </Text>
      <TextField
        mt="m"
        value={aliasText}
        placeholder="Введите новый алиас"
        onChange={(e) => setAliasText(e.target.value)}
      />

      <Flexbox mt="xl" alignItems="center" justifyContent="flex-end">
        <Button decoration="text" label="Отмена" onClick={close} color="gray500" />
        <Button label="Подтвердить" onClick={handleChangeAlias} ml="m" />
      </Flexbox>
    </Flexbox>
  )
}

export const openClientAliasChangeModal = (
  client: Client,
  e?: React.MouseEvent<HTMLDivElement, MouseEvent>,
) => {
  e?.stopPropagation()
  e?.preventDefault()

  modal({
    title: 'Изменение алиаса',
    overlayClose: false,
    render: (close) => <ClientAliasChangeModal client={client} close={close} />,
  })
}
