import { Flexbox, Spinner } from '@stage-ui/core'

export function Loading() {
  return (
    <Flexbox
      alignItems="center"
      justifyContent="center"
      w="100%"
      h="100%"
      children={<Spinner size="1.5rem" />}
    />
  )
}
