import React from 'react'

import TableTypes from '@stage-ui/core/data/Table/types'
import { Flexbox, Text, useTheme, CircularProgress } from '@stage-ui/core'
import { AlertTriangle } from '@stage-ui/icons'
import { Order } from '@foods-n-goods/server/generated/schema'

export function OrderStatusCell(ctx: TableTypes.TableCellContext<Order>) {
  const { color } = useTheme()
  const { row } = ctx
  const { inPurchase, notReadyForDelivery, readyForDelivery } = row.positionsStat

  return (
    <Flexbox alignItems="center">
      <Flexbox mr="xs" alignItems="center" w="1.5rem">
        {inPurchase > 0 ? (
          <AlertTriangle size="1.5rem" color="yellow500" />
        ) : (
          <CircularProgress
            strokeBackgroundColor={color.gray[300].hex()}
            color={color.green[600].hex()}
            size="1.5rem"
            value={(readyForDelivery / (readyForDelivery + notReadyForDelivery)) * 100}
          />
        )}
      </Flexbox>
      <Text ml="s" size="s" color="gray500" weight={600}>
        {readyForDelivery}/{readyForDelivery + notReadyForDelivery}
      </Text>
    </Flexbox>
  )
}
