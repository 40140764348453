import React from 'react'

import { Block, Checkbox, Flexbox, Text } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import { OrderDeliveryCoordinator } from 'store/delivery'
import { useMouseDrop } from 'components/MouseDrop'
import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'
import { DeliveryStore } from 'store'

export function LogisticsCoordinatorIndexCell(
  ctx: TableTypes.TableCellContext<OrderDeliveryCoordinator>,
) {
  const { row } = ctx
  const hint = useMouseDrop('Приоритет доставки')
  const deliveryAddress = row.orders[0]?.deliveryAddress
  const checked = useSelector(
    (state) => state.delivery.coordinatorCheckedOrders.includes(row.orders[0].id),
    objectEqual,
  )

  return (
    <Block
      w="inherit"
      attributes={{
        'data-id': row.orders[0].id,
        'data-hover': 'false',
      }}
      style={(t) => [
        {
          '&[data-hover="true"]': {
            background: t.color.gray[100].hex(),
          },
          ...(deliveryAddress.address
            ? {}
            : {
                background: `${t.color.red[100].hex()} !important`,
              }),
          '@media print': {
            // @ts-ignore
            backgroundColor: row.filled
              ? 'rgb(190,190,190) !important'
              : '#fff !important',
          },
        },
      ]}
    >
      <Flexbox
        backgroundColor={row.orders[0]?.courier?.color?.code || 'gray200'}
        borderRadius="0.125rem"
        w="1rem"
        h="1rem"
        m="0.125rem"
        left="0.0625rem"
        centered
        {...hint}
        style={{
          '@media print': {
            // @ts-ignore
            backgroundColor: row.filled
              ? 'rgb(190,190,190) !important'
              : '#fff !important',
          },
        }}
      >
        <Text size="0.625rem" color="surface">
          {row.orders[0]?.sequenceOrder}
        </Text>
      </Flexbox>
      {row.orders[0].status.value <= 4 && (
        <Checkbox
          size="s"
          checked={checked}
          onChange={() => DeliveryStore.coordinatorCheckOrder(row.orders[0].id)}
          style={{
            position: 'absolute',
            top: '0rem',
            left: '0.1875rem',
            display: checked ? 'block !important' : 'block',
            '@media print': {
              display: 'none !important',
            },
          }}
        />
      )}
    </Block>
  )
}
