import React from 'react'

import { Text } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import { PurchaseExtended } from 'pages/purchases/data'

export function ClientCell(ctx: TableTypes.TableCellContext<PurchaseExtended>) {
  const { row } = ctx

  if (row._isExpandable || !row.client) return null

  return (
    <Text color="gray600" size="s">
      {row.clientAlias || row.clientCompanyName}
    </Text>
  )
}
