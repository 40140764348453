import React, { useMemo } from 'react'

import { DatePicker, Flexbox } from '@stage-ui/core'
import FlexboxTypes from '@stage-ui/core/layout/Flexbox/types'
import MenuNavigation from 'components/MenuNavigation'
import moment from 'moment'

import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'
import { DeliveryActions } from 'actions'

import { LogisticsMenuContextMenu } from './ContextMenu'

type LogisticsMenuProps = FlexboxTypes.Props

const logisticsMenuItems = [
  { id: 'orders', title: 'Заказы' },
  { id: 'couriers', title: 'Водители' },
  { id: 'coordinator', title: 'Координатор' },
]

export function LogisticsMenu(props: LogisticsMenuProps) {
  const filter = useSelector((state) => state.delivery.filter, objectEqual)

  const defaultValue = useMemo(() => {
    if (filter.date) {
      return moment(filter.date).toDate()
    }
    return moment().toDate()
  }, [])

  return (
    <Flexbox {...props} alignItems="center">
      <MenuNavigation
        items={logisticsMenuItems}
        currentSection={filter.section}
        onChange={(section) => DeliveryActions.setFilter({ section })}
      />
      <DatePicker
        ml="s"
        size="s"
        flexBasis="5rem"
        placeholder="Доставка"
        defaultValue={defaultValue}
        onChange={(value) => {
          DeliveryActions.setFilter({
            date: value ? moment(value).format('YYYY-MM-DD') : '',
          })
        }}
        format="DD MMM"
        overrides={{
          field: {
            transition: 'background .2s ease-in-out',
            background: defaultValue ? 'white' : 'transparent',
            ':focus-within': {
              background: 'white',
            },
          },
        }}
      />
      <LogisticsMenuContextMenu />
    </Flexbox>
  )
}
