import React, { ChangeEvent, useRef } from 'react'

import { Divider, Flexbox, Button } from '@stage-ui/core'
import { Edit2, Trash2 } from '@stage-ui/icons'
import { useMouseDrop } from 'components/MouseDrop'

interface AvatarUploadControllerProps {
  userId: string
  photo: boolean
  onUpload?: (id: string, payload: string, _cb?: () => void) => void
}

export function AvatarUploadController(props: AvatarUploadControllerProps) {
  const { userId, photo, onUpload } = props

  const inputRef = useRef<HTMLInputElement>(null)

  const editHint = useMouseDrop('Изменить аватар', {
    drop: {
      align: 'bottom',
    },
    popover: {
      align: 'top',
    },
  })
  const deleteHint = useMouseDrop('Удалить аватар', {
    drop: {
      align: 'bottom',
    },
    popover: {
      align: 'top',
    },
  })

  if (!userId || !onUpload) return null

  const performUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target
    const reader = new FileReader()

    reader.onload = () => {
      if (typeof reader.result === 'string') {
        onUpload(userId, reader.result)
      }
    }

    if (files) {
      reader.readAsDataURL(files[0])
    }
  }

  const handleUpload = () => {
    inputRef.current?.click()
  }

  const handleImageDelete = () => {
    onUpload(userId, '')
  }

  return (
    <>
      <Flexbox
        flex={1}
        justifyContent="center"
        pb="xs"
        id="avatar_upload_controller"
        backgroundColor={(c) => c.white.alpha(0.75).rgb().string()}
        h="2.375rem"
        style={{
          transition: 'all 0.25s ease-in-out',
          position: 'absolute',
          bottom: '-2.375rem',
          left: 0,
          right: 0,
          boxSizing: 'border-box',
        }}
      >
        <Flexbox centered>
          {photo && (
            <>
              <Button
                color="red500"
                p={0}
                w="1.625rem"
                h="1.625rem"
                decoration="text"
                size="s"
                onClick={handleImageDelete}
                {...deleteHint}
              >
                <Trash2 size="1.125rem" />
              </Button>
              <Divider h="1.375rem" w="0.0625rem" mx="xs" color="gray400" />
            </>
          )}
          <Button
            color="gray500"
            p={0}
            w="1.625rem"
            h="1.625rem"
            decoration="text"
            onClick={handleUpload}
            {...editHint}
          >
            <Edit2 size="1.125rem" />
          </Button>
        </Flexbox>
      </Flexbox>
      <input
        ref={inputRef}
        id="file"
        type="file"
        name="file"
        style={{ display: 'none' }}
        onChange={performUpload}
        accept="image/jpg,image/png,image/jpeg"
      />
    </>
  )
}
