import React from 'react'

import { Block, Button, Divider, Flexbox, Paragraph, Text } from '@stage-ui/core'
import { openOrderModal } from 'components/Modals/OrderView'
import { StatusIndicator } from 'components/StatusIndicator'
import moment from 'moment'

import { OrderDelivery } from 'store/delivery'

import { CourierSelect } from 'components/CourierSelect'

export function MapPanelOrder(props: { order?: OrderDelivery }) {
  const { order } = props
  if (!order) {
    return null
  }
  return (
    <Block p="m">
      <Flexbox alignItems="center">
        <Text
          flex={1}
          size="xs"
          mr="m"
          weight={600}
          color="gray900"
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {order.client.markets?.[0]?.alias || order.client.defaultCompany?.shortName}
        </Text>
        <StatusIndicator status={order.status} />
      </Flexbox>
      <Flexbox justifyContent="space-between" mt="m">
        <Paragraph
          size="xs"
          mr="m"
          weight={400}
          color="gray500"
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {order.deliveryAddress.address}
        </Paragraph>
        <Flexbox alignItems="center" mr="s" style={{ pointerEvents: 'none' }}>
          <Text size="xs" weight={500} color="gray900">
            {order.preferredDeliveryDate
              ? moment(order.preferredDeliveryDate).format('DD.MM')
              : '-'}
          </Text>
          <Divider vertical w="0.0625rem" h="1rem" color="gray500" mx="s" />
          <Text size="xs" weight={500} color="gray900">
            {order.preferDeliveryInterval}
          </Text>
        </Flexbox>
      </Flexbox>
      <Flexbox justifyContent="space-between" mt="m">
        <CourierSelect order={order} />
        <Button
          size="s"
          decoration="plain"
          label="Детали заказа"
          flexShrink={0}
          onClick={() => openOrderModal(order.id)}
        />
      </Flexbox>
    </Block>
  )
}
