import React from 'react'

import { Promotion, PromotionInput } from '@foods-n-goods/server/generated/schema'
import { Button, Flexbox, Text } from '@stage-ui/core'
import { PromotionActions } from 'actions'
import AcceptDialog from 'components/AcceptDialog'
import Content from 'components/Content'
import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'

interface PromotionViewControllersProps {
  goBack: () => void
  input: PromotionInput
  promotion?: Promotion
}

function PromotionViewControllers(props: PromotionViewControllersProps) {
  const { promotion, input, goBack } = props
  const actionError = useSelector(({ promotion }) => promotion.actionError, objectEqual)

  const actionButtonLabel = !promotion?.id ? 'Добавить' : 'Сохранить'

  const _create = () => PromotionActions.create(input, goBack)
  const _update = () =>
    promotion?.id && PromotionActions.update(promotion.id, input, goBack)
  const _delete = () => promotion?.id && PromotionActions.delete(promotion.id)

  const onActionClick = promotion?.id ? _update : _create

  return (
    <Content>
      {actionError && (
        <Flexbox pb="m">
          <Text size="s" color="error" children={actionError} />
        </Flexbox>
      )}
      <Flexbox>
        {promotion && (
          <AcceptDialog
            action={_delete}
            title="Удаление акции"
            children="Удалить"
            color="error"
            text={`Акция «${promotion.name}» будет удалена без 
                            возможности восстановления! Удалить акцию?`}
          />
        )}
        <Flexbox flex={1} justifyContent="flex-end" alignItems="center">
          <Button children="Отмена" mx="0.5rem" decoration="text" onClick={goBack} />
          <Button
            children={actionButtonLabel}
            textColor="onPrimary"
            onClick={onActionClick}
          />
        </Flexbox>
      </Flexbox>
    </Content>
  )
}

export default PromotionViewControllers
