import React, { useEffect, useState } from 'react'

import { Button, Flexbox, Grid, Text, useTheme } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import useSelector from 'hooks/useSelector'
import { PurchaseProduct } from '@foods-n-goods/server/generated/schema'
import { PurchasesActions } from 'actions'
import { objectEqual } from 'utils/objectEqual'
import ViewInputField from 'components/Fields/ViewInputField'
import { Checkmark, Close } from '@stage-ui/icons'

type PurchaseQuantityCellProps = {
  ctx: TableTypes.TableCellContext<PurchaseProduct>
}

export function PurchaseQuantityCell(props: PurchaseQuantityCellProps) {
  const { ctx } = props
  const { row, isModify, setModify } = ctx

  const theme = useTheme()

  const [toPurchase, setToPurchase] = useState('')

  const currentQuantity =
    row.unitId === '1' ? row.purchaseQuantity.toFixed(3) : String(row.purchaseQuantity)

  const units = useSelector((state) => state.app.meta?.units, objectEqual)
  const unitName = units?.find((u) => u.value === Number(row.unitId))?.shortText

  const handleSave = async () => {
    const quantity = Number(toPurchase) - row.purchaseQuantity

    await PurchasesActions.createPurchase({
      productId: Number(row.productId),
      quantity,
    })
    setModify(false)
  }

  useEffect(() => {
    if (row.purchaseQuantity > 0) {
      setToPurchase(currentQuantity)
    }
  }, [])

  const cancel = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    setModify(false)
    setToPurchase(row.purchaseQuantity > 0 ? currentQuantity : '')
  }

  const save = (
    e:
      | React.KeyboardEvent<HTMLInputElement>
      | React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    e.stopPropagation()
    setModify(false)
    handleSave()
  }

  if (!isModify) {
    return (
      <Flexbox
        w="100%"
        onClick={(e) => {
          e.stopPropagation()
          setModify(true)
        }}
      >
        {Number(currentQuantity) === 0 && (
          <Text size="s" color="gray300">
            Добавить в докупку
          </Text>
        )}
        {Number(currentQuantity) > 0 && (
          <Flexbox>
            <Text size="s" color="gray900" w="4rem">
              {currentQuantity}
            </Text>
            <Text size="s" ml="xs" color="gray400">
              {unitName}
            </Text>
          </Flexbox>
        )}
      </Flexbox>
    )
  }

  return (
    <ViewInputField
      autoFocus
      w="15rem"
      backgroundColor="onPrimary"
      placeholder="Количество"
      value={toPurchase}
      onChange={(v) => setToPurchase(v)}
      onEnter={save}
      onClick={(e) => {
        e.stopPropagation()
      }}
      type={row.unitId === '1' ? 'digits' : 'integer'}
      digits={3}
      rightChild={
        <Flexbox flex={1} justifyContent="space-between" alignItems="center">
          <Text size="s" ml="xs" mr="l" color="gray900" weight={600}>
            {unitName}
          </Text>
          <Grid autoFlow="column" gap="0.25rem">
            <Button color="primary" p={0} w="1.5rem" h="1.5rem" decoration="text">
              <Checkmark size="1.375rem" onClick={save} />
            </Button>
            <Button color="gray500" p={0} w="1.5rem" h="1.5rem" decoration="text">
              <Close size="1.375rem" onClick={cancel} />
            </Button>
          </Grid>
        </Flexbox>
      }
      style={{
        borderRadius: '0.25rem',
        borderColor: theme.color.gray[200].hex(),
        borderWidth: '0.0625rem',
        borderStyle: 'solid',
        ':focus-within': {
          borderColor: theme.color.green[500].hex(),
          backgroundColor: theme.color.white.hex(),
        },
      }}
      overrides={{
        field: {
          border: 'none',
          boxShadow: 'none',
          flexBasis: 'unset',
          padding: 0,
        },
        content: {
          flex: 0,
          flexBasis: '7rem',
        },
        rightChild: {
          flex: 1,
        },
        input: {
          fontSize: '0.875rem',
          fontWeight: 500,
          '&::placeholder': {
            color: theme.color.gray[400].hex(),
            fontWeight: 500,
            fontSize: 's',
          },
        },
      }}
    />
  )
}
