import React, { useEffect, useState } from 'react'

import { Staff, StaffInput } from '@foods-n-goods/server/generated/schema'
import { Button, Flexbox, Grid, modal, TextField } from '@stage-ui/core'
import { StaffActions } from 'actions'

import { StaffCredentialsUpdate } from './Credentials'
import { StaffRoleUpdate } from './Role'
import { StaffColorUpdate } from './Color'
import { StaffMarketUpdate } from './Market'
import { StaffExternalSourceUpdate } from './ExternalSource'
import { StaffExternalMarketSourceUpdate } from './ExternalMarketSource'
import { StaffVehicleUpdate } from './Vehicle'
import { StaffContactsUpdate } from './Contacts'

type StaffUpdateBlock =
  | 'color'
  | 'market'
  | 'role'
  | 'exId'
  | 'exIndividualId'
  | 'name'
  | 'tag'
  | 'credentials'
  | 'vehicle'
  | 'contacts'

type StaffUpdateModalProps = {
  staff: Staff
  updateKey: StaffUpdateBlock
  autoFocusField?: keyof StaffInput
  close: () => void
}

function StaffUpdateModal(props: StaffUpdateModalProps) {
  const { staff, updateKey, autoFocusField, close } = props
  const { id } = staff

  const [payload, setPayload] = useState<StaffInput | null>(null)

  const preparePayload = () => {
    const initialPayload: StaffInput = {}

    if (
      updateKey === 'exId' ||
      updateKey === 'exIndividualId' ||
      updateKey === 'name' ||
      updateKey === 'tag'
    ) {
      initialPayload[updateKey] = staff[updateKey]
    }

    if (updateKey === 'color' || updateKey === 'market' || updateKey === 'role') {
      initialPayload[updateKey] = staff[updateKey]?.value
    }

    if (updateKey === 'credentials') {
      initialPayload.login = staff.login
    }

    if (updateKey === 'contacts') {
      initialPayload.phone = staff.phone
      initialPayload.email = staff.email
    }

    if (updateKey === 'vehicle') {
      initialPayload.vehicleModel = staff.vehicleModel
      initialPayload.vehicleRegPlate = staff.vehicleRegPlate
      initialPayload.vehicleVolumeCapacity = staff.vehicleVolumeCapacity
      initialPayload.vehicleLoadCapacity = staff.vehicleLoadCapacity
    }

    setPayload(initialPayload)
  }

  useEffect(() => {
    preparePayload()
  }, [])

  const handleUpdate = async () => {
    if (!staff || !payload) return
    await StaffActions.update(id, payload, close)
  }

  const onChange: KeyValueChangeFunction<StaffInput> = (k, v) => {
    setPayload({ ...payload, [k]: v })
  }

  if (!payload) return null

  return (
    <Flexbox column>
      <Grid autoFlow="row" gap="1rem">
        {updateKey === 'tag' && (
          <TextField
            autoFocus
            defaultValue={payload.tag || ''}
            label="Тэг"
            placeholder="Короткий идентификатор"
            onChange={(e) => onChange('tag', e.target.value)}
          />
        )}
        {updateKey === 'name' && (
          <TextField
            autoFocus
            defaultValue={payload.name || ''}
            label="Имя"
            placeholder="Иванов Иван"
            onChange={(e) => onChange('name', e.target.value)}
          />
        )}
        {updateKey === 'credentials' && (
          <StaffCredentialsUpdate
            login={payload.login}
            password={payload.pwd}
            onChange={onChange}
          />
        )}
        {updateKey === 'role' && (
          <StaffRoleUpdate value={payload.role} onChange={onChange} />
        )}
        {updateKey === 'color' && (
          <StaffColorUpdate value={payload.color} onChange={onChange} />
        )}
        {updateKey === 'market' && (
          <StaffMarketUpdate value={payload.market} onChange={onChange} />
        )}
        {updateKey === 'exId' && (
          <StaffExternalSourceUpdate value={payload.exId} onChange={onChange} />
        )}
        {updateKey === 'exIndividualId' && (
          <StaffExternalMarketSourceUpdate
            value={payload.exIndividualId}
            onChange={onChange}
          />
        )}
        {updateKey === 'vehicle' && (
          <StaffVehicleUpdate
            autoFocusField={autoFocusField}
            vehicleModel={payload.vehicleModel}
            vehicleRegPlate={payload.vehicleRegPlate}
            vehicleLoadCapacity={payload.vehicleLoadCapacity}
            vehicleVolumeCapacity={payload.vehicleVolumeCapacity}
            onChange={onChange}
          />
        )}
        {updateKey === 'contacts' && (
          <StaffContactsUpdate
            autoFocusField={autoFocusField}
            phone={payload.phone}
            email={payload.email}
            onChange={onChange}
          />
        )}
      </Grid>
      <Flexbox mt="xl" alignItems="center" justifyContent="flex-end">
        <Button decoration="text" label="Отмена" onClick={close} color="gray500" />
        <Button w="8.5rem" label="Подтвердить" onClick={handleUpdate} ml="m" />
      </Flexbox>
    </Flexbox>
  )
}

export const openStaffUpdateModal = (
  staff: Staff,
  updateKey: StaffUpdateBlock,
  autoFocusField?: keyof StaffInput,
  e?: React.MouseEvent<HTMLDivElement, MouseEvent>,
) => {
  e?.stopPropagation()
  e?.preventDefault()

  modal({
    w: '30rem',
    title: 'Изменение данных персонала',
    overlayClose: false,
    render: (close) => (
      <StaffUpdateModal
        staff={staff}
        updateKey={updateKey}
        autoFocusField={autoFocusField}
        close={close}
      />
    ),
  })
}
