import React from 'react'

import { Divider } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import { ChevronRight } from '@stage-ui/icons'
import { PurchaseExtended } from 'pages/purchases/data'

import { PurchasesTable } from '..'

type ExpandCellProps = {
  ctx: TableTypes.TableCellContext<PurchaseExtended>
  expandByProductId?: string
}

export const ExpandCell: React.FC<ExpandCellProps> = (props) => {
  const { ctx, expandByProductId } = props

  const expandable = ctx.row._isExpandable

  const handleExpand = () => {
    if (!expandable) return
    if (!ctx.isExpand) {
      ctx.setExpand(<PurchasesTable expandByProductId={ctx.row.product.id} />)
    } else {
      ctx.setExpand(null)
    }
  }

  if (expandByProductId) {
    return <Divider w="1px" vertical color="primary" h="100%" dash="3px" />
  }

  return (
    <ChevronRight
      size="1.5rem"
      visibility={expandable ? 'visible' : 'hidden'}
      onClick={handleExpand}
      backgroundColor={ctx.isExpand ? 'green50' : 'transparent'}
      borderRadius="50%"
      color="green500"
      style={{
        transition: 'all 0.25s ease-in-out',
        transform: `rotate(${ctx.isExpand ? 90 : 0}deg)`,
      }}
    />
  )
}
