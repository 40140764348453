import {
  MutationOrderAddProductArgs,
  MutationOrderDeleteArgs,
  MutationOrderDeletePositionArgs,
  MutationOrderPriceActualizationArgs,
  MutationOrderRestoreDeletedProductArgs,
  MutationOrderSetCourierArgs,
  MutationOrderSetStatusArgs,
  MutationOrderSortArgs,
  MutationOrderUpdateCommentArgs,
  MutationOrderUpdateDeliveryDataArgs,
  MutationOrderUpdateQuantityArgs,
  MutationProductPrintInfoArgs,
  Order,
  QueryOrdersArgs,
} from '@foods-n-goods/server/generated/schema'
import { SocketType } from '@foods-n-goods/server/src/socket/types'
import { DeliveryActions } from 'actions'
import { EXPRESS_HOST } from 'env'
import moment from 'moment'
import { orderMenuItems } from 'pages/orders/OrderList/components/Toolbar/misc'
import Push from 'push.js'
import request from 'requests/request'
import { io } from 'requests/socket'
import store, { DeliveryStore, OrderStore } from 'store'
import { OrderFilter } from 'store/order'
import actionNotify from 'utils/actionNotify'
import declOfNum from 'utils/declOfNum'
import localStorage from 'utils/localStorage'

export const rowsPrePage = 20

export default {
  async fetch(args: QueryOrdersArgs = {}) {
    try {
      OrderStore.fetch()
      OrderStore.resolve(await request('orders', args))
    } catch (error) {
      OrderStore.reject(error)
    }
  },

  async fetchById(id: Order['id']) {
    try {
      const res = await request('orders', {
        filter: {
          orderId: id,
          includePositions: true,
          includeContainers: true,
        },
      })

      if (res.total !== 1) throw new Error('Некорректный номер заказа!')

      OrderStore.setCurrent({
        ...res.records[0],
        workflow: [],
      })
    } catch (error) {
      actionNotify({
        title: `Детализация заказа #${id}`,
        message: error.message as string,
        type: 'error',
      })
    }
  },

  async sort(args: MutationOrderSortArgs) {
    try {
      await request('orderSort', args)
      actionNotify({
        title: 'Отправка на линию',
        message: `Заказы ${args.ids.join(', ')} успешно отправлены на линию!`,
        type: 'success',
      })
      OrderStore.clearSelected()
    } catch (error) {
      actionNotify({
        title: `Заказы #${args.ids.join(', ')}!`,
        message: error.message as string,
        type: 'error',
      })
    }
  },

  async actualizePrice(args: MutationOrderPriceActualizationArgs) {
    try {
      await request('orderPriceActualization', args)
      actionNotify({
        title: 'Актуализация цен',
        message: 'Цены успешно обновлены!',
        type: 'success',
      })
      OrderStore.clearSelected()
    } catch (error) {
      actionNotify({
        title: `Заказы #${args.ids.join(', ')}!`,
        message: error.message as string,
        type: 'error',
      })
    }
  },

  async delete(args: MutationOrderDeleteArgs) {
    try {
      await request('orderDelete', args)
      OrderStore.clearSelected()
      this.fetch()
    } catch (error) {
      actionNotify({
        title: `Заказ #${args.ids.join(',')}!`,
        message: error.message as string,
        type: 'error',
      })
    }
  },

  async setStatus(args: MutationOrderSetStatusArgs) {
    try {
      await request('orderSetStatus', args)
      OrderStore.clearSelected()
    } catch (error) {
      actionNotify({
        title: `Заказ #${args.ids.join(',')}!`,
        message: error.message as string,
        type: 'error',
      })
      throw error
    }
  },

  async setCourier(args: MutationOrderSetCourierArgs) {
    try {
      await request('orderSetCourier', args)
      DeliveryStore.coordinatorClearOrders()
    } catch (error) {
      actionNotify({
        title: `Заказы #${args.ids.join(', ')}!`,
        message: error.message as string,
        type: 'error',
      })
    }
  },

  async addProducts(args: MutationOrderAddProductArgs, _cb: () => void) {
    try {
      await request('orderAddProduct', args)
      _cb()
    } catch (error) {
      actionNotify({
        title: `Заказ #${args.id}!`,
        message: `${error.message as string}`,
        type: 'error',
      })
    }
  },

  async orderUpdateQuantity(args: MutationOrderUpdateQuantityArgs, _cb?: () => void) {
    try {
      await request('orderUpdateQuantity', args)
      _cb?.()
    } catch (error) {
      actionNotify({
        title: `Заказ #${args.positionId}!`,
        message: `${error.message as string}`,
        type: 'error',
      })
    }
  },

  async deleteProduct(args: MutationOrderDeletePositionArgs, _cb?: () => void) {
    try {
      await request('orderDeletePosition', args)
      _cb?.()
    } catch (error) {
      actionNotify({
        title: `Заказ #${args.orderId}!`,
        message: `${error.message as string}`,
        type: 'error',
      })
    }
  },

  async restorePosition(args: MutationOrderRestoreDeletedProductArgs) {
    try {
      await request('orderRestoreDeletedProduct', args)
    } catch (error) {
      actionNotify({
        title: `Заказ #${args.positionId}!`,
        message: `${error.message as string}`,
        type: 'error',
      })
    }
  },

  async updateComment(args: MutationOrderUpdateCommentArgs, _cb?: () => void) {
    try {
      await request('orderUpdateComment', args)
      _cb?.()
    } catch (error) {
      actionNotify({
        title: `Заказ #${args.id}!`,
        message: `${error.message as string}`,
        type: 'error',
      })
    }
  },

  async fetchOrderWorkflow(orderId: string) {
    try {
      const workflow = await request('orderWorkflow', {
        orderId,
      })
      OrderStore.setCurrentWorkflow(workflow)
      return workflow
    } catch (error) {
      actionNotify({
        title: `История заказа #${orderId}!`,
        message: `${error.message as string}`,
        type: 'error',
      })
      return null
    }
  },

  async orderUpdateDeliveryData(args: MutationOrderUpdateDeliveryDataArgs) {
    try {
      await request('orderUpdateDeliveryData', args)
      actionNotify({
        title: `Детали доставки заказа #${args.id} успешно обновлены.`,
        message: '',
        type: 'success',
      })
      return true
    } catch (error) {
      actionNotify({
        title: `Ошибка обновления деталей доставки #${args.id}!`,
        message: `${error.message as string}`,
        type: 'error',
      })
      return false
    }
  },

  setTableDisplayMode(displayMode: 'full' | 'short') {
    localStorage.save('orders/displayMode', displayMode)
    OrderStore.setTableDisplayMode(displayMode)
  },

  setSelected(orders: Array<{ order: Order; isSelected: boolean }>) {
    OrderStore.setSelected(orders)
  },

  clearSelected() {
    OrderStore.clearSelected()
  },

  setCurrent(order: Order) {
    OrderStore.setCurrent(order)
  },

  async printInfo(args: MutationProductPrintInfoArgs): Promise<void> {
    try {
      const count = await request('productPrintInfo', args)
      if (count) {
        actionNotify({
          title: 'Печать этикеток',
          message: `Идет печать ${count} ${declOfNum(count, [
            'этикетки',
            'этикеток',
            'этикеток',
          ])}`,
        })
      } else {
        actionNotify({
          title: 'Печать этикеток',
          message: `В заказе нет товаров для печати этикеток`,
        })
      }
    } catch (error) {
      actionNotify({
        title: `Ошибка печати стикеров!`,
        message: `${error.message as string}`,
        type: 'error',
      })
    }
  },

  setFilter(filter: Partial<OrderFilter>) {
    OrderStore.setFilter(filter)
  },

  clearFilter() {
    OrderStore.clearFilter()
  },

  clearCurrent() {
    OrderStore.setCurrent(null)
  },
}

const browserNotification = (order: Order) => {
  const index = store
    .getState()
    .order.orderPage.records.findIndex(({ id }) => id === order.id)

  // Push notification whenever u can be
  if (order.status.value === 1 && index < 0 && !order.deleteDate) {
    const alias = order.client.markets[0]?.alias
    const title = `Новый заказ #${order.id} ${alias ? `от ${alias}` : ''}`
    const onClick = () => {
      window.open(`${EXPRESS_HOST}/orders/${order.id}`)
    }
    Push.create(title, { onClick })
  }
}

io.on(SocketType.Broadcast.OrdersUpdate, (orders: Order[]) => {
  const ordersLoading = store.getState().order.loading
  if (ordersLoading === 'resolved' || !!store.getState().order.currentlyViewed) {
    const { status, section, search, deliveryDateEnd, deliveryDateStart } =
      store.getState().order.filter

    orders.forEach((order) => {
      browserNotification(order)

      const isCurrentlyViewed = store.getState().order.currentlyViewed?.id === order.id
      if (isCurrentlyViewed) OrderStore.updateCurrentlyViewed(order)

      /**
       * Prevent re-renders for searchable data
       * if search or date range set
       */
      if (search) return

      /**
       * Do logic for different pages and statuses
       */
      const availableStatuses = orderMenuItems.find((i) => i.id === section)?.statusIds
      const exactStatus = availableStatuses
        ? availableStatuses.includes(order.status.value)
        : true

      const onSelectedDates = moment(order.preferredDeliveryDate).isBetween(
        moment(deliveryDateStart).startOf('day'),
        moment(deliveryDateEnd).endOf('day'),
        undefined,
        '[]',
      )

      if (
        onSelectedDates &&
        ((!status && exactStatus) || (status && Number(status) === order.status.value))
      ) {
        OrderStore.upsert(order)
      } else {
        // remove order from store (pagination problem?)
        OrderStore.remove(order)
      }
    })
  }

  const deliveryLoading = store.getState().delivery.loading
  if (deliveryLoading === 'resolved') {
    // FIXME: possible problem because of type
    // @ts-ignore
    DeliveryActions.updateOrders(orders)
  }
})

io.on(SocketType.Broadcast.OrdersStat, OrderStore.setStatCounters)
