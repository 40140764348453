import React from 'react'

import { Order } from '@foods-n-goods/server/generated/schema'
import { modal } from '@stage-ui/core'
import { OrderDetails } from 'pages/orders/OrderDetails'

export const openOrderModal = (
  id: Order['id'],
  e?: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined,
) => {
  e?.stopPropagation()
  e?.preventDefault()

  modal({
    decoration: 'panel',
    hideHeader: true,
    overrides: (t) => ({
      window: () => [
        {
          background: t.color.background.hex(),
          minWidth: '90vw',
          minHeight: '90vh',
        },
      ],
    }),
    render: (close) => <OrderDetails id={id} onClose={close} />,
  })
}
