import React from 'react'

import {
  Order,
  OrderedProduct,
  OrderPositionsStat,
} from '@foods-n-goods/server/generated/schema'
import TableTypes from '@stage-ui/core/data/Table/types'
import { Table } from 'components/Table'

import { openPhotoReportModal } from 'components/Modals/PhotoReport'

import { makeOrderQualityItem } from 'utils/makeOrderQualityItem'

import actionNotify from 'utils/actionNotify'

import { OrderPositionChangeQuantityCell } from './components/ChangeQuantityCell'
import { OrderPositionCommentCell } from './components/CommentCell'
import { OrderPositionDeleteCell } from './components/DeleteCell'
import { OrderPositionNameCell } from './components/NameCell'
import { OrderPositionPurchaseCell } from './components/PurchaseCell'
import { OrderPositionQuantityCell } from './components/QuantityCell'
import { OrderPositionStatusCell } from './components/StatusCell'
import { OrderPositionWorkerCell } from './components/WorkerCell'
import { OrderPositionQuantityAfterCell } from './components/QuantityAfterCell'

interface OrderViewProductsTableProps {
  order: Order
}

export type OrderedPosition = OrderedProduct & {
  orderContainerLabels: string
  orderId: Order['id']
}

const orderPositionColumns: TableTypes.TableColumn<OrderedPosition>[] = [
  {
    key: 'status',
    title: 'Статус',
    render: OrderPositionStatusCell,
    width: 64,
  },
  { key: 'name', title: 'Наименование', render: OrderPositionNameCell, sort: 'DEFAULT' },
  { key: 'quantity', title: 'В заказе', render: OrderPositionQuantityCell, width: 150 },
  {
    key: 'quantityAfter',
    title: 'К отгрузке',
    render: OrderPositionQuantityAfterCell,
    width: 150,
  },
  { key: 'worker', title: 'Работник', render: OrderPositionWorkerCell, width: 100 },
  { key: '_changeQuantity', render: OrderPositionChangeQuantityCell, width: 40 },
  { key: '_purchase', render: OrderPositionPurchaseCell, width: 40 },
  { key: '_delete', render: OrderPositionDeleteCell, width: 40 },
  {
    key: 'comments',
    title: 'Комментарии',
    render: OrderPositionCommentCell,
    width: 250,
  },
]

function positionPriority(k: keyof OrderPositionsStat) {
  switch (k) {
    case 'posSorted':
      return 1
    case 'posSorting':
      return 2
    case 'posPurchase':
      return 3
    case 'posPurchaseConfirmed':
      return 4
    case 'posPurchaseFailed':
      return 6
    case 'posPurchaseCanceled':
      return 7
    case 'posDeleted':
      return 8
    case 'posDelivered':
      return 9
    case 'posDeliveredPartial':
      return 10
    case 'posRejected':
      return 11
    default:
      return 5
  }
}

export function OrderPositionsTable(props: OrderViewProductsTableProps) {
  const { order } = props

  const data = (order.positions || [])
    .slice()
    .sort((a, b) => {
      const aP = positionPriority(a.statKey as keyof OrderPositionsStat)
      const bP = positionPriority(b.statKey as keyof OrderPositionsStat)

      if (aP === bP && a.name && b.name) {
        return a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : -1
      }
      return aP > bP ? 1 : -1
    })
    .map((item) => ({
      ...item,
      orderId: order.id,
      orderContainerLabels: order.containers.map((c) => c.name).join(', '),
    }))

  const handleRowClick = ({ row }: TableTypes.TableRowContext<OrderedPosition>) => {
    if (!row.photo) {
      return actionNotify({
        message: `Нет фотоотчета по позиции «${row.name || ''}»!`,
        title: 'Ошибка!',
        type: 'error',
      })
    }

    openPhotoReportModal([makeOrderQualityItem(order, row)])
  }

  return (
    <Table
      columns={orderPositionColumns}
      data={data}
      onClick={() => undefined}
      onRowClick={handleRowClick}
    />
  )
}
