import React from 'react'

import { Block, useTheme } from '@stage-ui/core'
import BlockTypes from '@stage-ui/core/layout/Block/types'

const TwoDots: React.ForwardRefRenderFunction<
  HTMLDivElement,
  BlockTypes.Props & { fillColor?: string; size?: string }
> = (props, ref) => {
  const { fillColor, size } = props
  const theme = useTheme()
  const fill = fillColor || theme.color.onPrimary.hex()

  return (
    <Block h={size || '1rem'} w={size || '1rem'} {...props} ref={ref}>
      <svg
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 16 16"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.66667 5.33333C9.66667 6.06933 9.06933 6.66667 8.33333 6.66667C7.59733 6.66667 7 6.06933 7 5.33333C7 4.59733 7.59733 4 8.33333 4C9.06933 4 9.66667 4.59733 9.66667 5.33333ZM7 9.99996C7 9.26396 7.59733 8.66662 8.33333 8.66662C9.06933 8.66662 9.66667 9.26396 9.66667 9.99996C9.66667 10.736 9.06933 11.3333 8.33333 11.3333C7.59733 11.3333 7 10.736 7 9.99996Z"
        />
      </svg>
    </Block>
  )
}

export default React.forwardRef(TwoDots)
