import React, { Fragment, useEffect, useState } from 'react'

import { Block, Divider, ScrollView } from '@stage-ui/core'
import useSelector from 'hooks/useSelector'

import { objectEqual } from 'utils/objectEqual'

import { MapPanelOrder } from './MapPanelOrder'

export function MapPannel() {
  const deliveryRecords = useSelector(
    (state) =>
      state.delivery.orderPage.records.map((order) => ({
        ...order,
        positions: [],
      })),
    objectEqual,
  )

  const { currentOrder, currentCourier, ordersSelected, couriersSelected } = useSelector(
    (state) => ({
      currentOrder: state.delivery.currentOrder,
      currentCourier: state.delivery.currentCourier,
      ordersSelected: state.delivery.ordersSelected,
      couriersSelected: state.delivery.couriersSelected,
    }),
    objectEqual,
  )

  const orders = deliveryRecords.filter(({ id }) => ordersSelected.includes(id))
  const [orderId, setActiveOrderId] = useState(currentOrder || orders[0]?.id)

  const order = orders.find((order) => orderId === order.id)

  const neighborOrders = deliveryRecords.filter(
    (o) =>
      order?.id !== o.id &&
      order?.deliveryAddress.lat === o.deliveryAddress.lat &&
      order?.deliveryAddress.lng === o.deliveryAddress.lng,
  )

  useEffect(() => {
    const currentOrderId = currentOrder || orders[0]?.id
    setActiveOrderId(currentOrderId)
  }, [currentOrder, currentCourier, ordersSelected, couriersSelected])

  if (!orderId) return null

  return (
    <ScrollView
      backgroundColor="gray50"
      size="s"
      barOffset={4}
      style={{
        position: 'absolute',
        left: '1rem',
        height: neighborOrders.length ? '10.5rem' : '9rem',
        width: '24rem',
        opacity: orders.length ? 1 : 0,
        visibility: orders.length ? 'visible' : 'hidden',
        bottom: orders.length ? '0' : '-8rem',
        transition: 'all 0.25s',
        borderRadius: '0.5rem 0.5rem 0 0',
        overflow: 'hidden',
        boxShadow: '0px 0px 2rem rgb(29 29 29 / 10%)',
        '@media (max-height: 36rem)': {
          left: '2rem',
        },
      }}
    >
      <MapPanelOrder order={order} />
      {neighborOrders.map((order) => (
        <Fragment key={order.id}>
          <Block px="m">
            <Divider backgroundColor="gray200" />
          </Block>
          <MapPanelOrder order={order} />
        </Fragment>
      ))}
    </ScrollView>
  )
}
