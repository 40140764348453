import clientFields from '../fragments/clientFields.gql'

export default `
    query clients($page: Float, $pageSize: Float, $search: String, $filter: ClientFilter) {
        clients(page: $page, pageSize: $pageSize, search: $search, filter: $filter) {
        total
        records {
            ...clientFields
            }
        }
    }
    ${clientFields}
`
