import {
  MutationCreatePurchaseArgs,
  MutationLineOutOfStockArgs,
  MutationPurchaseDeleteArgs,
  MutationPurchaseUpdateArgs,
  Purchase,
  PurchaseCreateIncomesPayload,
  QueryPurchaseProductsArgs,
  QueryPurchasesArgs,
  Staff,
} from '@foods-n-goods/server/generated/schema'
import { SocketType } from '@foods-n-goods/server/src/socket/types'
import moment from 'moment'
import { purchasesMenuItems } from 'pages/purchases/components/Toolbar'
import request from 'requests/request'
import { io } from 'requests/socket'
import store, { PurchasesStore } from 'store'
import { PurchasesFilter } from 'store/purchases'
import actionNotify from 'utils/actionNotify'

export default {
  async fetch(args: QueryPurchasesArgs, silent: boolean = false) {
    if (!silent) PurchasesStore.fetch()
    try {
      PurchasesStore.resolve(await request('purchases', args))
    } catch (error) {
      PurchasesStore.reject(error)
    }
  },

  async fetchByProducts(args: QueryPurchaseProductsArgs, silent: boolean = false) {
    if (!silent) PurchasesStore.byProductsFetch()
    try {
      PurchasesStore.byProductsResolve(await request('purchaseProducts', args))
    } catch (error) {
      PurchasesStore.byProductsReject()
    }
  },

  async update(args: MutationPurchaseUpdateArgs, _cb?: () => void): Promise<void> {
    try {
      await request('purchaseUpdate', args)
      _cb?.()
    } catch (error) {
      actionNotify({
        title: `Докупка #${args.id}!`,
        message: error.message,
        type: 'error',
      })
    }
  },

  async setPositionOutOfStock(args: MutationLineOutOfStockArgs, _cb?: () => void) {
    try {
      await request('lineOutOfStock', args)
      _cb?.()
    } catch (error) {
      PurchasesStore.reject(error)
    }
  },

  async createPurchase(args: MutationCreatePurchaseArgs) {
    try {
      await request('createPurchase', args)
    } catch (error) {
      PurchasesStore.reject(error)
    }
  },

  async purchaseDelete(args: MutationPurchaseDeleteArgs) {
    try {
      await request('purchaseDelete', args)
    } catch (error) {
      PurchasesStore.reject(error)
    }
  },

  removeById(ids: Array<Purchase['id']>) {
    PurchasesStore.removeById(ids)
  },

  removeByPositionId(ids: Array<Purchase['id']>) {
    PurchasesStore.removeByPositionId(ids)
  },

  async setPurchaser(id: Purchase['id'], staffId: Staff['id']) {
    try {
      await request('orderProductSetPushaser', { ids: [id], staffId })
    } catch (error) {
      PurchasesStore.reject(error)
    }
  },

  async createPurchaseIncomes(payload: PurchaseCreateIncomesPayload[]) {
    try {
      if (!payload) {
        throw new Error('Нет подходящих записей для отправки')
      }
      await request('purchaseCreateIncomes', { payload })
      actionNotify({
        title: 'Поступления',
        message: 'Сформировано и добавлено в очередь',
        type: 'success',
      })
    } catch (error) {
      actionNotify({
        title: 'Поступления',
        message: (error as Error).message,
        type: 'error',
      })
      PurchasesStore.reject(error)
    }
  },

  async deleteCompleted() {
    try {
      await request('purchaseDeleteCompleted')
      actionNotify({
        title: 'Докупки',
        message: 'Все купленные позиции удалены!',
        type: 'success',
      })
    } catch (error) {
      actionNotify({
        title: 'Докупки',
        message: (error as Error).message,
        type: 'error',
      })
    }
  },

  setFilter(filter: Partial<PurchasesFilter>) {
    PurchasesStore.setFilter(filter)
  },

  clearFilter() {
    PurchasesStore.clearFilter()
  },
}

/**
 * Socket Events
 */
io.on(SocketType.Broadcast.PurchaseUpdate, (purchases: Purchase[]) => {
  const { section, search, createDateStart, createDateEnd } =
    store.getState().purchases.filter

  purchases.forEach((purchase) => {
    /**
     * Prevent re-renders for searchable data if search
     */
    if (search) return

    /**
     * Do logic for different pages and statuses
     */
    const availableStatuses = purchasesMenuItems.find((i) => i.id === section)?.statusIds

    const matchesByStatus = availableStatuses
      ? availableStatuses.includes(purchase.status.value)
      : true

    const matchesByDate = moment(purchase.createDate).isBetween(
      moment(createDateStart).startOf('day'),
      moment(createDateEnd).endOf('day'),
      undefined,
      '[]',
    )

    if (!matchesByDate) {
      PurchasesStore.removeById([purchase.id])
      return
    }

    if (!matchesByStatus) return

    if (!purchase.deleteDate) {
      PurchasesStore.upsert(purchase)
    } else {
      PurchasesStore.removeById([purchase.id])
    }
  })
})

io.on(SocketType.Broadcast.PurchasesStat, PurchasesStore.updateStatistics)
