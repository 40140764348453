import { Device } from '@foods-n-goods/server/generated/schema'
import { Button, Flexbox, Text } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import { Edit } from '@stage-ui/icons'

import { updateDeviceDialog } from '../shared/form'

export const columns: TableTypes.TableColumn<Device>[] = [
  { key: 'id', title: 'ID', width: '4rem', sort: 'ASC' },
  { key: 'name', width: 200, title: 'Наименование', sort: 'ASC' },
  {
    key: 'bound',
    width: 200,
    title: 'Привязана к весам',
    render: (ctx) => <Text>{ctx.row.linkedDevice?.name || '-'}</Text>,
  },
  {
    key: '_',
    title: '',
    render: (ctx) => (
      <Flexbox justifyContent="flex-end">
        <Button
          ml="s"
          label="Изменить"
          decoration="plain"
          size="s"
          onClick={() => {
            updateDeviceDialog(ctx.row)
          }}
          rightChild={<Edit />}
        />
      </Flexbox>
    ),
  },
]
