import React from 'react'

import { Grid } from '@stage-ui/core'
import useSelector from 'hooks/useSelector'
import { PhotoReport } from 'pages/quality/components/PhotoReport'
import { objectEqual } from 'utils/objectEqual'

export function QualityReportView() {
  const data = useSelector((state) => state.orderQuality.data, objectEqual)

  return (
    <Grid templateColumns={['repeat(auto-fill, 22.25rem)', '1fr 1fr']} gap="1.5rem">
      {data.map((item, i) => (
        <PhotoReport key={`${item.id}_${i.toString()}`} item={item} allReports={data} mr="m" mb="m" />
      ))}
    </Grid>
  )
}
