function toRadians(degrees: number): number {
  return (degrees * Math.PI) / 180
}

function toDegrees(radians: number): number {
  return (radians * 180) / Math.PI
}

const distance = (p1: [number, number], p2: [number, number]): number => {
  const R = 6371e3
  const φ1 = (p1[0] * Math.PI) / 180
  const φ2 = (p2[0] * Math.PI) / 180
  const Δφ = ((p2[0] - p1[0]) * Math.PI) / 180
  const Δλ = ((p2[1] - p1[1]) * Math.PI) / 180

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  return R * c
}

function bearing(pr1: [number, number], pr2: [number, number]) {
  const p1 = [].concat(pr1)
  const p2 = [].concat(pr2)

  p1[0] = toRadians(p1[0])
  p1[1] = toRadians(p1[1])
  p2[0] = toRadians(p2[0])
  p2[1] = toRadians(p2[1])

  const y = Math.sin(p2[1] - p1[1]) * Math.cos(p2[0])
  const x =
    Math.cos(p1[0]) * Math.sin(p2[0]) -
    Math.sin(p1[0]) * Math.cos(p2[0]) * Math.cos(p2[1] - p1[1])
  const brng = toDegrees(Math.atan2(y, x))
  return (brng + 360) % 360
}

const GeoUtils = {
  distance,
  bearing,
  toRadians,
  toDegrees,
}

export default GeoUtils
