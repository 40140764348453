import React, { useState } from 'react'

import {
  ProductGroup,
  ProductGroupInput,
  SelectItem,
} from '@foods-n-goods/server/generated/schema'
import { Block, Flexbox, Paragraph, Select, Switch, TextField } from '@stage-ui/core'
import SelectTypes from '@stage-ui/core/control/Select/types'
import { CategoryActions } from 'actions'
import ImageController from 'components/ImageController'
import useSelector from 'hooks/useSelector'

import { objectEqual } from 'utils/objectEqual'

interface CategoryViewGeneralProps {
  id?: ProductGroup['id']
  image?: ProductGroup['image']
  type: ProductGroupInput['type']
  published: ProductGroupInput['published']
  name: ProductGroupInput['name']
  sortWeight?: ProductGroupInput['sortWeight']
  parentId?: ProductGroupInput['parentId']
  searchWords?: ProductGroupInput['searchWords']
  onChange: KeyValueChangeFunction<ProductGroupInput>
  productGroupType: SelectItem[]
}

function CategoryViewGeneral(props: CategoryViewGeneralProps) {
  const {
    onChange,
    published,
    name,
    sortWeight,
    parentId,
    searchWords,
    id,
    productGroupType,
    type,
    image,
  } = props

  const [selectionCategoryType, setSelectionCategoryType] = useState(type.code)

  const { storeCategories } = useSelector(
    ({ category }) => ({
      storeCategories: category.data.records,
    }),
    objectEqual,
  )

  const categories = storeCategories.reduce((acc, val) => {
    if (
      selectionCategoryType === 'WAREHOUSE' &&
      val.type.code !== selectionCategoryType
    ) {
      return acc
    }

    if (val.id !== id) {
      acc.push({ text: val.name, value: val.id })
    }

    return acc
  }, [] as SelectTypes.Option<string>[])

  const onTypeChange = (options: (SelectTypes.Option & { code?: string })[]) => {
    const type = options[0] as SelectItem
    onChange('type', type)
    setSelectionCategoryType(type.code)
  }

  const onParentChange = (options: SelectTypes.Option<string>[]) => {
    if (!options[0]) return
    onChange('parentId', options[0].value)
  }

  const handleClearParent = () => {
    onChange('parentId', null)
  }

  return (
    <Block decoration="surface" borderColor="gray100" p="l">
      <ImageController
        disabled={type?.code === 'FRONT_MARKET' && !parentId}
        id={id}
        uploadAction={(id, payload) => {
          CategoryActions.imageUpdate(id, payload)
        }}
        url={image}
        size="5rem"
      />

      <Flexbox
        mt="xl"
        alignItems="center"
        mb="m"
        style={(t) => [{ borderBottom: `0.0625rem solid ${t.color.gray[200].hex()}` }]}
      >
        <Paragraph color="gray500" size="s" flex={0.5} mr="m">
          Тип
        </Paragraph>
        <Select
          disabled={!!id}
          decoration="none"
          placeholder="Тип не указана"
          options={productGroupType || []}
          values={type ? [type] : []}
          onChange={onTypeChange}
        />
      </Flexbox>

      <Flexbox
        alignItems="center"
        mb="m"
        style={(t) => [{ borderBottom: `0.0625rem solid ${t.color.gray[200].hex()}` }]}
      >
        <Paragraph color="gray500" size="s" flex={0.5} mr="m">
          Статус (активно/не активно)
        </Paragraph>
        <Switch
          mb="0.5rem"
          checked={published}
          onChange={() => onChange('published', !published)}
        />
      </Flexbox>

      <Flexbox
        alignItems="center"
        mb="m"
        style={(t) => [{ borderBottom: `0.0625rem solid ${t.color.gray[200].hex()}` }]}
      >
        <Paragraph color="gray500" size="s" flex={0.5} mr="m">
          Наименование
        </Paragraph>
        <TextField
          decoration="none"
          p={0}
          value={name}
          placeholder="Группа 1"
          onChange={(e) => onChange('name', e.target.value)}
        />
      </Flexbox>
      <Flexbox
        alignItems="center"
        mb="m"
        style={(t) => [{ borderBottom: `0.0625rem solid ${t.color.gray[200].hex()}` }]}
      >
        <Paragraph color="gray500" size="s" flex={0.5} mr="m">
          Сортировка
        </Paragraph>
        <TextField
          decoration="none"
          p={0}
          type="number"
          value={(sortWeight || 0).toString()}
          placeholder="0"
          onChange={(e) => onChange('sortWeight', Number(e.target.value || '0'))}
        />
      </Flexbox>

      {categories && type && (
        <Flexbox
          alignItems="center"
          mb="m"
          style={(t) => [{ borderBottom: `0.0625rem solid ${t.color.gray[200].hex()}` }]}
        >
          <Paragraph color="gray500" size="s" flex={0.5} mr="m">
            Родительская группа
          </Paragraph>
          <Select
            clearable
            decoration="none"
            placeholder="Группа не указана"
            options={categories}
            values={parentId ? categories.filter((c) => c.value === parentId) : []}
            // @ts-expect-error FIX SELECT STAGE-UI PLEASE
            onChange={onParentChange}
            onClear={handleClearParent}
            maxScrollHeight="10rem"
          />
        </Flexbox>
      )}
      <Flexbox
        alignItems="center"
        mb="m"
        style={(t) => [{ borderBottom: `0.0625rem solid ${t.color.gray[200].hex()}` }]}
      >
        <Paragraph color="gray500" size="s" flex={0.5} mr="m">
          Поисковые слова
        </Paragraph>
        <TextField
          decoration="none"
          p={0}
          placeholder="Слова используемые для поиска"
          value={searchWords || ''}
          onChange={(e) => onChange('searchWords', e.target.value)}
        />
      </Flexbox>
    </Block>
  )
}

export default CategoryViewGeneral
