import { ProductInput, SelectItem } from '@foods-n-goods/server/generated/schema'
import { Select } from '@stage-ui/core'
import Content from 'components/Content'
import ViewInputField from 'components/Fields/ViewInputField'
import ViewGridBlock from 'components/ViewGridBlock'

interface ProductViewPricingProps {
  units: Array<SelectItem>
  unit: ProductInput['unit']
  price: ProductInput['price']
  weight?: ProductInput['weight']
  scaleable: ProductInput['scaleable']
  onChange: KeyValueChangeFunction<ProductInput>
}

function ProductViewPricing(props: ProductViewPricingProps) {
  const { onChange, units, unit, price, weight, scaleable } = props

  const scaleableOptions = [
    { text: 'В упаковке', value: 0 },
    { text: 'По весу', value: 1 },
  ]

  return (
    <Content>
      <ViewGridBlock title="Ценообразование">
        <Select
          label="Мера измерения товара"
          labelType="outside"
          options={units || []}
          values={unit ? [unit] : []}
          onChange={(option) => onChange('unit', option[0])}
        />
        <ViewInputField
          label="Цена (руб.)"
          value={price}
          onChange={(value) => onChange('price', value)}
          gridRow="2"
          type="rubles"
        />
        <ViewInputField
          label="Вес/Объем"
          gridRow="2"
          value={weight || ''}
          onChange={(value) => onChange('weight', value)}
          digits={2}
          type="digits"
        />
        <Select
          label="Отгрузка"
          disabled={unit.value === 1}
          labelType="outside"
          options={[
            { text: 'В упаковке', value: 0 },
            { text: 'По весу', value: 1 },
          ]}
          values={
            unit.value === 1
              ? [scaleableOptions[1]]
              : [scaleableOptions[scaleable ? 1 : 0]]
          }
          onChange={(option) => onChange('scaleable', !!option[0].value)}
        />
      </ViewGridBlock>
    </Content>
  )
}

export default ProductViewPricing
