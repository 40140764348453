import { Block, Flexbox } from '@stage-ui/core'
import FlexboxTypes from '@stage-ui/core/layout/Flexbox/types'

type ColorDotTypes = FlexboxTypes.Props & {
  color?: string | null
}

export const ColorDot: React.FC<ColorDotTypes> = (props) => {
  const { color, children, ...flexProps } = props

  return (
    <Flexbox
      centered
      borderRadius="100%"
      h="1.125rem"
      w="1.125rem"
      p="0.125rem"
      borderColor={color || 'gray400'}
      borderWidth="0.125rem"
      borderStyle="solid"
      style={{
        boxSizing: 'border-box',
      }}
      {...flexProps}
    >
      <Block
        h="100%"
        w="100%"
        borderRadius="100%"
        backgroundColor={color || 'gray400'}
        children={children}
      />
    </Flexbox>
  )
}
