import React from 'react'

import { Client } from '@foods-n-goods/server/generated/schema'
import { useTheme } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import Avatar from 'components/Avatar'

export function AvatartCell(ctx: TableTypes.TableCellContext<Client>) {
  const { row } = ctx
  const { id, name = '', photo, updateDate } = row
  const { color } = useTheme()
  return (
    <Avatar
      user={{
        id,
        name: row.markets?.[0]?.alias || String(name),
        photo,
        color: color.gray[400].hex(),
        online: false,
        updateDate,
      }}
    />
  )
}
