const formatFixedPointNumber = (value: string, digits: number) => {
  const [head, tail] = value.split('.')

  const scaledTail = tail?.slice(0, digits) || ''
  const nextValue = typeof tail === 'undefined' ? value : `${head}.${scaledTail}`

  return !Number.isNaN(Number.parseFloat(nextValue)) ? nextValue : ''
}

export default formatFixedPointNumber
