import React, { useEffect, useState } from 'react'

import { Block, Flexbox, Grid, Text } from '@stage-ui/core'

import { MarketUser, Staff } from '@foods-n-goods/server/generated/schema'

import useSelector from 'hooks/useSelector'

import { objectEqual } from 'utils/objectEqual'

import { openStaffUpdateModal } from 'components/Modals/StaffUpdate'

import { StaffActions } from 'actions'

import request from 'requests/request'

import { StaffRole } from '@foods-n-goods/server/src/shared/enum'

import { StaffDetailsInfoRowProps } from '../components/InfoRow'

import { StaffDetailsInfoBlock } from '../components/InfoBlock'

import { StaffDetailsHeader } from './Header'

interface StaffDetailsGeneralProps {
  staff: Staff
  additionalAvailable: boolean
  modal: boolean
}

export function StaffDetailsGeneral(props: StaffDetailsGeneralProps) {
  const { staff, ...rest } = props

  const marketIndividuals = useSelector(({ app }) => app.marketIndividuals, objectEqual)
  const individual = marketIndividuals.find((m) => m.exId === staff.exId)

  const [marketUsers, setMarketUsers] = useState<MarketUser[]>([])
  const marketUser = marketUsers.find((mu) => mu.exId === staff.exIndividualId)

  const getMarketUsers = async () => {
    const clients = await request('marketUsers')
    setMarketUsers(clients)
  }

  useEffect(() => {
    getMarketUsers()
  }, [])

  const profileRows: StaffDetailsInfoRowProps[] = [
    {
      param: 'Имя',
      value: staff.name,
      onChange: () => openStaffUpdateModal(staff, 'name'),
    },
    {
      param: 'Логин',
      value: staff.login,
      onChange: () => openStaffUpdateModal(staff, 'credentials'),
    },
    // {
    //   param: 'Роль',
    //   value: staff.role.text,
    //   onChange: () => openStaffUpdateModal(staff, 'role'),
    // },
    // {
    //   param: 'Магазин/Поставщик',
    //   value: staff.market.text,
    //   onChange: () => openStaffUpdateModal(staff, 'market'),
    // },
  ]

  const systemRows: StaffDetailsInfoRowProps[] = [
    {
      param: 'Тег',
      value: staff.tag,
      onChange: () => openStaffUpdateModal(staff, 'tag'),
    },
    {
      param: 'Цвет',
      value: (
        <Flexbox alignItems="center" flex={1}>
          <Flexbox
            w="0.875rem"
            h="0.875rem"
            borderRadius="50%"
            borderColor={staff.color?.code || 'gray900'}
            borderWidth="0.125rem"
            borderStyle="solid"
            backgroundColor="white"
            mr="s"
            centered
          >
            <Block
              w="0.625rem"
              h="0.625rem"
              borderRadius="50%"
              backgroundColor={staff.color?.code || 'gray900'}
            />
          </Flexbox>
          <Text color={staff.color?.code || 'gray900'}>{staff.color?.text}</Text>
        </Flexbox>
      ),
      onChange: () => openStaffUpdateModal(staff, 'color'),
    },
  ]

  const externalInfoRows: StaffDetailsInfoRowProps[] = [
    {
      param: 'Код внешней системы',
      value: individual ? `[${individual.category}] ${individual.fullName}` : undefined,
      onChange: () => openStaffUpdateModal(staff, 'exId'),
      onClear: () => StaffActions.update(staff.id, { exId: null }),
    },
    {
      param: 'Код внешней системы (Поставщики)',
      value: marketUser
        ? `${marketUser.name} (ИНН: ${marketUser.inn})`
        : staff.exIndividualId,
      onChange: () => openStaffUpdateModal(staff, 'exIndividualId'),
      onClear: () => StaffActions.update(staff.id, { exIndividualId: null }),
      disabled: ![StaffRole.PURCHASE, StaffRole.MAIN_PURCHASE].includes(staff.role.value),
    },
  ]

  return (
    <Grid
      flex={1}
      autoFlow="row"
      gap="1rem"
      style={{ marginTop: rest.modal ? '-4.25rem' : 'unset' }}
    >
      <StaffDetailsHeader staff={staff} {...rest} />
      <StaffDetailsInfoBlock block="profile" title="Профиль" rows={profileRows} />
      <StaffDetailsInfoBlock block="system" title="В системе" rows={systemRows} />
      <StaffDetailsInfoBlock block="1c" title="1C" rows={externalInfoRows} />
    </Grid>
  )
}
