import { VariableInput } from '@foods-n-goods/server/generated/schema'
import request from 'requests/request'
import { AppStore, VariablesStore } from 'store'
import actionNotify from 'utils/actionNotify'

export default {
  async getAll() {
    try {
      VariablesStore.loading()
      const variables = await request('variables')
      VariablesStore.resolve(variables)
    } catch (error) {
      VariablesStore.reject(error)
    }
  },

  async update(variable: VariableInput, _cb: () => void) {
    try {
      AppStore.pending(true)
      const response = await request('variableUpdate', { variable })
      VariablesStore.actionUpdateResolve(response)

      actionNotify({
        title: response.name,
        message: `Конфигурация успешно обновлена!`,
      })

      _cb()
    } catch (error) {
      VariablesStore.actionReject(error.message || error)
    } finally {
      AppStore.pending(false)
    }
  },
}
