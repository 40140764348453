import { OrderStatusCode } from '@foods-n-goods/client/system/types'

export const orderMenuItems = [
  {
    id: '',
    title: 'Все',
  },
  {
    id: 'new',
    title: 'Новые',
    statusIds: [OrderStatusCode.NEW, OrderStatusCode.PAYED],
  },
  {
    id: 'assemble',
    title: 'К сборке',
    statusIds: [
      OrderStatusCode.SORTING,
      OrderStatusCode.OUT_OF_STOCK,
      OrderStatusCode.PURCHASE_PENDING,
      OrderStatusCode.PURCHASE_CONFIRMED,
      OrderStatusCode.PURCHASE_FAILED,
    ],
  },
  {
    id: 'delivery',
    title: 'В доставке',
    statusIds: [
      OrderStatusCode.AWAIT_COURIER,
      OrderStatusCode.PICKED_UP,
      OrderStatusCode.DELIVERY,
    ],
  },
  {
    id: 'completed',
    title: 'Завершенные',
    statusIds: [
      OrderStatusCode.DELIVERED,
      OrderStatusCode.NOT_DELIVERED,
      OrderStatusCode.REJECTED,
      OrderStatusCode.CANCELED,
    ],
  },
]
