import React from 'react'

import { Timer } from '@stage-ui/icons'
import { Flexbox, Text } from '@stage-ui/core'
import FlexboxTypes from '@stage-ui/core/layout/Flexbox/types'

type QualityPackDateProps = {
  date: string
} & FlexboxTypes.Props

const QualityPackDate: React.FC<QualityPackDateProps> = (props) => {
  const { date, ...flexboxProps } = props

  if (!date) {
    return null
  }

  return (
    <Flexbox
      centered
      flex={1}
      px="xs"
      pr="s"
      borderRadius="s"
      backgroundColor="blue400"
      style={{
        boxSizing: 'border-box',
        whiteSpace: 'nowrap',
      }}
      {...flexboxProps}
    >
      <Timer color="white" mr="0.375rem" />
      <Text my="0.1875rem" size="xs" color="white">
        {date}
      </Text>
    </Flexbox>
  )
}

export default QualityPackDate
