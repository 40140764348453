import {
  Device,
  MutationDeviceCreateArgs,
  MutationDeviceUpdateArgs,
} from '@foods-n-goods/server/generated/schema'
import request from 'requests/request'
import { AppStore, DevicesStore } from 'store'
import actionNotify from 'utils/actionNotify'

export default {
  async fetch() {
    try {
      DevicesStore.resolve(await request('devices'))
    } catch (error) {
      DevicesStore.reject(error)
    }
  },

  async create(device: MutationDeviceCreateArgs, _cb: () => void) {
    try {
      AppStore.pending(true)
      const response = await request('deviceCreate', device)
      DevicesStore.create(response)
      actionNotify({
        title: `«${response.name}»`,
        message: `Устройство успешно добавлено!`,
        type: 'success',
      })

      _cb()
    } catch (error) {
      DevicesStore.actionReject(error.message || error)
      actionNotify({
        title: `Ошибка добавления устройства`,
        message: error.message,
        type: 'error',
      })
    } finally {
      AppStore.pending(false)
    }
  },

  async update(device: MutationDeviceUpdateArgs, _cb: () => void) {
    try {
      AppStore.pending(true)
      const response = await request('deviceUpdate', device)
      DevicesStore.update(response)

      actionNotify({
        title: `«${response.name}»`,
        message: `Устройство успешно обновлено!`,
        type: 'success',
      })

      _cb()
    } catch (error) {
      DevicesStore.actionReject(error.message || error)
      actionNotify({
        title: `Ошибка изменения`,
        message: error.message,
        type: 'error',
      })
    } finally {
      AppStore.pending(false)
    }
  },

  async delete(device: Device, _cb: () => void) {
    try {
      AppStore.pending(true)
      await request('deviceDelete', { id: device.id })
      DevicesStore.delete(device.id)

      actionNotify({
        title: `«${device.name}»`,
        message: `Устройство успешно удалено!`,
        type: 'success',
      })

      _cb()
    } catch (error) {
      actionNotify({
        title: `Ошибка удаления`,
        message: error.message,
        type: 'error',
      })
      DevicesStore.actionReject(error.message || error)
    } finally {
      AppStore.pending(false)
    }
  },
}
