import React from 'react'

import { Divider, Flexbox, Text } from '@stage-ui/core'
import User from 'components/Sidebar/User'

type PageHeaderProps = {
  title?: string
  titleComponent?: React.ReactNode
  divider?: boolean
}

export const PageHeader: React.FC<PageHeaderProps> = (props) => {
  const { title, titleComponent, divider = true } = props

  return (
    <Flexbox
      justifyContent="space-between"
      alignItems="flex-end"
      p="l 2rem"
      h="3rem"
      backgroundColor="gray50"
      style={{
        position: 'sticky',
        top: 0,
        zIndex: 3,
        '@media print': {
          display: 'none',
        },
      }}
    >
      <Flexbox flex={1} alignItems="flex-end" h="100%">
        {title && (
          <Text weight={700} color="gray900" size="1.125rem" flex={1}>
            {title}
          </Text>
        )}
        {titleComponent && <>{titleComponent}</>}
      </Flexbox>
      {divider && <Divider vertical color="gray200" w="0.0625rem" h="100%" mx="2rem" />}
      <User />
    </Flexbox>
  )
}
