import productFields from '../fragments/productFields.gql'

export default `
mutation productCreate($payload: ProductInput!) {
  productCreate(payload: $payload) {
    ...productFields
  }
}
${productFields}
`
