import ClientSystem, { OrderStatusCode } from '@foods-n-goods/client/system/types'
import { Order, OrderPage, OrderWorkflow } from '@foods-n-goods/server/generated/schema'
import { SocketType } from '@foods-n-goods/server/src/socket/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import moment from 'moment'
import localStorage from 'utils/localStorage'

type DisplayMode = 'full' | 'short'

export type OrderSection = 'new' | 'assemble' | 'delivery' | 'completed'

export type OrderFilter = {
  section?: string
  deliveryDateStart: string
  deliveryDateEnd: string
  id?: string
  createDateStart?: string
  createDateEnd?: string
  client?: string
  status?: OrderStatusCode | null
  page: number
  pageSize: number
  search?: string

  detailsSection?: string
}

export interface OrderState {
  loading: ClientSystem.Loading
  error: RequestError | null

  statCounters: SocketType.OrdersStat
  orderPage: OrderPage

  filter: OrderFilter

  selectedOrders: Order[]
  currentlyViewed: Order | null
  displayMode: DisplayMode
}

const initialOrderPage = {
  page: 1,
  pageSize: 40,
  total: 0,
  records: [],
}

const initialFilter = {
  deliveryDateStart: moment().add(1, 'week').add(-1, 'month').format('YYYY-MM-DD'),
  deliveryDateEnd: moment().add(1, 'week').format('YYYY-MM-DD'),
  page: 1,
  pageSize: 40,
}

const initialStatCounters = {
  positionsTotal: 0,
  positionsNew: 0,
  positionsSorting: 0,
  positionsForSorting: 0,
  positionsPurchase: 0,
  positionsDelivery: 0,
  ordersTotal: 0,
  ordersNew: 0,
  ordersWarehouse: 0,
  ordersDelivery: 0,
  ordersAssigned: 0,
  ordersNotAssigned: 0,
  ordersDelivered: 0,
  ordersNotDelivered: 0,
  byWarehouse: {},
}

const initialState: OrderState = {
  loading: 'idle',
  orderPage: initialOrderPage,
  filter: initialFilter,
  statCounters: initialStatCounters,
  error: null,
  selectedOrders: [],
  currentlyViewed: null,
  displayMode: (localStorage.load('orders/displayMode') as DisplayMode) ?? 'full',
}

const orderSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    fetch(state) {
      state.loading = 'pending'
    },
    resolve(state, action: PayloadAction<OrderPage>) {
      state.orderPage = action.payload
      state.loading = 'resolved'
      state.error = initialState.error
    },
    reject(state, action: PayloadAction<OrderState['error']>) {
      state.loading = 'rejected'
      state.error = action.payload
    },

    setStatCounters(state, action: PayloadAction<SocketType.OrdersStat>) {
      state.statCounters = action.payload
    },

    setCurrent(state, action: PayloadAction<Order | null>) {
      state.currentlyViewed = action.payload
    },
    setCurrentWorkflow(state, action: PayloadAction<OrderWorkflow[]>) {
      if (state.currentlyViewed) {
        state.currentlyViewed.workflow = action.payload
      }
    },

    /**
     * Update only existing order or current detailed
     */
    upsert(state, action: PayloadAction<Order>) {
      const idx = state.orderPage.records.findIndex((r) => r.id === action.payload.id)
      const sliceTo =
        state.orderPage.records.length >= state.orderPage.pageSize
          ? -1
          : state.orderPage.records.length

      if (idx >= 0) {
        state.orderPage.records[idx] = action.payload
      } else if (
        !state.orderPage.records.length ||
        action.payload.id >
          state.orderPage.records[state.orderPage.records.length - 1]?.id
      ) {
        const nextIndex = Math.max(
          state.orderPage.records.findIndex((order) => order.id < action.payload.id),
          0,
        )
        state.orderPage.records = [
          ...state.orderPage.records.slice(0, nextIndex),
          action.payload,
          ...state.orderPage.records.slice(nextIndex, sliceTo),
        ]
      }
    },

    remove(state, action: PayloadAction<Order>) {
      state.orderPage.records = state.orderPage.records.filter(
        (order) => order.id !== action.payload.id,
      )
    },

    updateCurrentlyViewed(state, action: PayloadAction<Order>) {
      state.currentlyViewed = {
        ...action.payload,
        workflow: action.payload.workflow.length
          ? action.payload.workflow
          : state.currentlyViewed?.workflow || [],
      }
    },

    actionUpdateResolve(state, action: PayloadAction<Order>) {
      state.orderPage.records = state.orderPage.records.map((order) =>
        order.id === action.payload.id ? { ...order, ...action.payload } : order,
      )
    },

    setSelected(
      state,
      action: PayloadAction<Array<{ order: Order; isSelected: boolean }>>,
    ) {
      let data = state.selectedOrders.slice()
      action.payload.forEach((r) => {
        data = r.isSelected
          ? data.filter((rr) => rr.id !== r.order.id)
          : data.concat(r.order)
      })
      state.selectedOrders = data
    },

    clearSelected(state) {
      state.selectedOrders = []
    },

    setTableDisplayMode(state, action: PayloadAction<DisplayMode>) {
      state.displayMode = action.payload
    },

    setFilter(state, action: PayloadAction<Partial<OrderFilter>>) {
      state.filter = {
        ...state.filter,
        ...action.payload,
      }
    },

    clearFilter(state) {
      state.filter = initialFilter
    },

    clear(state) {
      return initialState
    },
  },
})

export default orderSlice
