import React from 'react'

import { Flexbox, Text, TextField } from '@stage-ui/core'
import { Info } from '@stage-ui/icons'
import { StaffInput } from '@foods-n-goods/server/generated/schema'

type StaffCredentialsUpdateProps = {
  login?: string | null
  password?: string | null
  onChange: KeyValueChangeFunction<StaffInput>
}

export function StaffCredentialsUpdate(props: StaffCredentialsUpdateProps) {
  const { login, password, onChange } = props

  return (
    <Flexbox column>
      <Flexbox
        p="m"
        borderStyle="solid"
        borderWidth="1px"
        borderColor="gray200"
        borderRadius="m"
        backgroundColor="gray100"
        alignItems="center"
      >
        <Info color="orange500" mr="m" size="1.125rem" />
        <Text size="s" color="orange500">
          После изменения пользователь не сможет зайти в свой профиль под старым логином,
          старый логин будет заблокирован!
        </Text>
      </Flexbox>
      <TextField
        autoFocus
        mt="m"
        defaultValue={login || ''}
        label="Логин"
        placeholder="Логин персонала"
        onChange={(e) => onChange('login', e.target.value)}
      />
      <TextField
        mt="m"
        defaultValue={password || ''}
        label="Пароль"
        placeholder="Пароль персонала"
        onChange={(e) => onChange('pwd', e.target.value)}
      />
    </Flexbox>
  )
}
