import ClientSystem from '@foods-n-goods/client/system/types'
import { OrderQualityItem } from '@foods-n-goods/server/generated/schema'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type OrderQualityFilter = {
  section?: string
  positionId?: string
}
export interface OrderQualityState {
  loading: ClientSystem.Loading
  data: OrderQualityItem[]
  filter: OrderQualityFilter
}

export const initialState: OrderQualityState = {
  loading: 'idle',
  data: [],
  filter: {},
}

const orderQualitySlice = createSlice({
  name: 'orderQuality',
  initialState,
  reducers: {
    loading(state) {
      state.loading = 'pending'
    },
    resolve(state, action: PayloadAction<OrderQualityState['data']>) {
      state.data = action.payload
      state.loading = 'resolved'
    },
    append(state, action: PayloadAction<OrderQualityState['data']>) {
      state.data = action.payload.concat(state.data)
    },
    setFilter(state, action: PayloadAction<Partial<OrderQualityFilter>>) {
      state.filter = {
        ...state.filter,
        ...action.payload,
      }
    },
    clearFilter(state) {
      state.filter = {}
    },
    clear(state) {
      return initialState
    },
  },
})

export default orderQualitySlice
