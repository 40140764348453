import { Staff } from '@foods-n-goods/server/generated/schema'
import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'

export const useStaff = (id?: Staff['id']) => {
  const staffData = useSelector((state) => state.staff.data, objectEqual)
  const equalStaff = staffData.find((staff) => staff.id === id)

  return equalStaff
}
