import React from 'react'

import { Divider, Flexbox } from '@stage-ui/core'

import { PurchasesProgressBar } from './ProgressBar'

export function PurchasesHeader() {
  return (
    <Flexbox alignItems="flex-end" h="100%">
      <Divider color="gray200" mx="2rem" h="100%" w="0.0625rem" />
      <PurchasesProgressBar />
    </Flexbox>
  )
}
