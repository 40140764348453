import createGQLCient from '@foods-n-goods/client/utils/createGQLClient'
import { GRAPHQL_HOST } from 'env'
import requests, { Requests } from 'requests'
import localStorage from 'utils/localStorage'

export default createGQLCient<Requests>(
  requests,
  GRAPHQL_HOST,
  async () => {
    return {
      token: localStorage.getPreference('token') || '',
    }
  },
  true,
)
