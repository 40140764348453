import { useEffect, useRef, useState } from 'react'

import { OrderAddProductCustom } from '.'

import { Order, Product } from '@foods-n-goods/server/generated/schema'
import {
  Block,
  Drop,
  Flexbox,
  Grid,
  ScrollView,
  Spinner,
  Text,
  TextField,
  useTheme,
} from '@stage-ui/core'
import TextFieldTypes from '@stage-ui/core/control/TextField/types'
import DropTypes from '@stage-ui/core/layout/Drop/types'
import { ArrowIosLeft, Done, Search } from '@stage-ui/icons'
import request from 'requests/request'
import { rub } from 'utils/rub'

type OrderAddPositionsSearchProps = {
  order: Order
  addProducts: OrderAddProductCustom[]
  onProductSelect: (p: Product) => void
}

export const OrderAddPositionsSearch: React.FC<OrderAddPositionsSearchProps> = (
  props,
) => {
  const { order, addProducts, onProductSelect } = props

  const theme = useTheme()

  const fieldRef = useRef<TextFieldTypes.Ref>(null)
  const dropRef = useRef<DropTypes.Ref>(null)

  const [isOpen, setIsOpen] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [searching, setSearching] = useState(false)

  const [products, setProducts] = useState<Product[]>([])

  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)
  const fetchProducts = async (search: string) => {
    if (search.length < 3) return
    setSearching(true)
    try {
      const products = await request('products', {
        search,
        clientId: order.client.id,
        marketId: order.marketId,
      })
      setProducts(products.records)
    } catch (error) {
    } finally {
      setSearching(false)
    }
  }

  const performSearchProducts = () => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(() => fetchProducts(searchText), 300)
  }

  useEffect(() => {
    performSearchProducts()
    if (!searchText) {
      setProducts([])
      // setIsOpen(false)
    }
  }, [searchText])

  useEffect(() => {
    dropRef.current?.updatePosition()
  }, [addProducts])

  return (
    <Flexbox column>
      <TextField
        ref={fieldRef}
        label="Наименование продукта"
        placeholder="Продукт"
        leftChild={<Search ml="0" size="l" />}
        rightChild={
          searching ? (
            <Spinner />
          ) : (
            <ArrowIosLeft
              color="primary"
              style={{
                transition: 'all 0.25s ease-in-out',
                transform: `rotate(${isOpen && !!products.length ? '-90deg' : '0'})`,
              }}
            />
          )
        }
        onChange={(e) => {
          setSearchText(e.target.value)
        }}
        onFocus={() => setIsOpen(true)}
      />
      {fieldRef.current?.container && (
        <Drop
          visible={isOpen && !!products.length}
          ref={dropRef}
          target={{
            current: fieldRef.current?.container,
          }}
          animation={{
            type: 'slide',
            duration: 100,
            reverse: true,
          }}
          onClickOutside={(e, outTarget) => {
            if (outTarget) {
              setIsOpen(false)
            }
          }}
          stretchWidth
        >
          <Block
            style={{
              position: 'relative',
              background: theme.color.surface.rgb().string(),
              borderRadius: theme.radius.s,
              boxShadow: theme.assets.shadow.l,
              boxSizing: 'border-box',
              overflow: 'hidden',
              top: '0.25rem',
            }}
          >
            <ScrollView
              preventStageEvents
              size="xs"
              xBarPosition="none"
              overrides={{
                content: {
                  maxHeight: '16rem',
                },
              }}
            >
              <Flexbox column backgroundColor="gray50">
                {products.map((product) => {
                  const isActive =
                    addProducts.findIndex((ap) => ap.id === product.id) >= 0
                  const disabled =
                    (order.positions &&
                      order.positions.findIndex((p) => p.id === product.id) >= 0) ||
                    !parseFloat(product.price)
                  return (
                    <Grid
                      alignItems="end"
                      gap="0.5rem"
                      p="s"
                      templateColumns="1fr 6rem 1.5rem"
                      key={product.id}
                      borderWidth="0 0 1px 0"
                      borderStyle="solid"
                      borderColor="gray300"
                      style={[
                        [
                          !disabled && {
                            ':hover': {
                              backgroundColor: theme.color.gray[100].hex(),
                            },
                          },
                          isActive && {
                            backgroundColor: `${theme.color.green[100].hex()} !important`,
                          },
                          disabled && {
                            cursor: 'not-allowed',
                          },
                        ],
                      ]}
                      onClick={() => {
                        if (disabled) return
                        setIsOpen(false)
                        setTimeout(() => onProductSelect(product), 150)
                      }}
                    >
                      <Flexbox gridColumn="1" column>
                        <Text size="xs" color={disabled ? 'gray300' : 'gray400'}>
                          #{product.article}
                        </Text>
                        <Text size="s" color={disabled ? 'gray300' : 'gray900'} ellipsis>
                          {product.name}
                        </Text>
                      </Flexbox>
                      <Text
                        gridColumn="2"
                        size="s"
                        color={disabled ? 'gray300' : 'gray900'}
                        textAlign="right"
                        weight={500}
                      >{`${rub(Number(product.price))} / ${product.unit.text}`}</Text>
                      <Done
                        gridColumn="3"
                        display={isActive ? 'block' : 'none'}
                        color="primary"
                        size="1.25rem"
                        mb="xs"
                      />
                    </Grid>
                  )
                })}
              </Flexbox>
            </ScrollView>
          </Block>
        </Drop>
      )}
    </Flexbox>
  )
}
