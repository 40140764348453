export default `
    query reportAvailable {
      reportAvailable {
        id
        name
        description
        params {
          id
          label
          type
          placeholder
          required
          value
          hint
        }
      }
    }
`
