import { GraphQLClient } from 'graphql-request'

import Queue from './queue'

// eslint-disable-next-line import/no-mutable-exports
export let host = ''

export type GQLRequest<A, R> = {
  args: A
  resp: R
}

export type ClientRequests = Record<string, GQLRequest<any, any>>

export const setEndpoint = (endpoint: string) => {
  host = endpoint
}

export type LocalGQLClient<CR extends ClientRequests> = <N extends keyof CR>(
  name: N,
  args?: CR[N]['args'],
  headers?: Record<string, string | undefined>,
  allowBatch?: boolean,
) => Promise<CR[N]['resp']>

function createClient<CR extends ClientRequests>(
  requests: Record<keyof CR, string>,
  endpoint: string,
  getLocalHeaders?: () => Promise<Record<string, string | undefined>>,
  devTools?: boolean,
) {
  host = endpoint

  const request: LocalGQLClient<CR> = async <N extends keyof CR>(
    name: N,
    args?: CR[N]['args'],
    headers: Record<string, string | undefined> = {},
    allowBatch = false,
  ) => {
    const requestId = Queue.add(name)

    try {
      const request = requests[name]
      const localHeaders = (await getLocalHeaders?.()) || {}

      if (!request) {
        throw {
          code: 666,
          message: `Request doesn't exist`,
        }
      }

      const client = new GraphQLClient(`${host}/graphql`, {
        headers: {
          ...localHeaders,
          ...headers,
        } as Record<string, string>,
      })

      const response = await client.request<CR[N]['resp']>(request, args)

      if (typeof response[name] === 'undefined') {
        throw {
          code: 666,
          message: `Response has no data`,
        }
      }

      if (!Queue.isExist(requestId) && !allowBatch) {
        return await new Promise(() => null)
      }

      return response[name]
    } catch (error) {
      const message =
        error?.response?.errors?.[0]?.message || error.message || JSON.stringify(error)

      devTools && console.warn(`📡 GraphQL say after ${name} request: ${message}`)
      throw {
        code: error.code || '-1',
        message,
      }
    } finally {
      Queue.done(requestId)
    }
  }

  return request
}

export default createClient
