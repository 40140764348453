import React from 'react'

import { Client } from '@foods-n-goods/server/generated/schema'
import { Flexbox, Text } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import { RegisterIconStatus } from 'pages/clients/components/RegisterIconStatus'
import moment from 'utils/moment'

export function RegisterCell(ctx: TableTypes.TableCellContext<Client>) {
  const { row } = ctx
  return (
    <Flexbox alignItems="center">
      <RegisterIconStatus {...row} />
      <Text size="s" color="gray900" ml="s">
        {moment(row.createDate).format('DD.MM.YYYY')}
      </Text>
    </Flexbox>
  )
}
