import React from 'react'

import { Order } from '@foods-n-goods/server/generated/schema'
import { Flexbox, useTheme } from '@stage-ui/core'
import MenuNavigation from 'components/MenuNavigation'

import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'

import { OrderActions } from 'actions'

import { OrderControls } from './OrderControls'

interface OrderDetailsToolbarProps {
  order: Order
  modal: boolean
}

export function OrderDetailsToolbar(props: OrderDetailsToolbarProps) {
  const { order, modal } = props
  const { color } = useTheme()

  const currentSection = useSelector(
    (state) => state.order.filter.detailsSection,
    objectEqual,
  )

  return (
    <Flexbox
      justifyContent="space-between"
      pb="m"
      style={
        !modal && {
          position: 'sticky',
          top: '6rem',
          zIndex: 7,
          backgroundColor: color.gray[50].hex(),
        }
      }
    >
      <MenuNavigation
        items={[
          { id: '', title: 'Основная карта' },
          { id: 'photos', title: 'Фотоотчёт' },
          { id: 'history', title: 'История' },
        ]}
        onChange={(section) => OrderActions.setFilter({ detailsSection: section })}
        currentSection={currentSection}
      />
      {!modal && <OrderControls order={order} />}
    </Flexbox>
  )
}
