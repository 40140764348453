import ClientSystem from '@foods-n-goods/client/system/types'
import { Device } from '@foods-n-goods/server/generated/schema'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface DevicesState {
  loading: ClientSystem.Loading
  data: Device[]
  fetchError: RequestError | null
  actionError: RequestError | null
}

export const initialState: DevicesState = {
  loading: 'idle',
  data: [],
  fetchError: null,
  actionError: null,
}

const devicesSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    loading(state) {
      state.loading = 'pending'
    },

    resolve(state, action: PayloadAction<DevicesState['data']>) {
      state.data = action.payload
      state.loading = 'resolved'
      state.fetchError = initialState.fetchError
    },

    reject(state, action: PayloadAction<RequestError>) {
      state.loading = 'rejected'
      state.fetchError = action.payload
    },

    updateDevices(state, action: PayloadAction<Device[]>) {
      action.payload.forEach((device) => {
        const index = state.data.findIndex((item) => item.id === device.id)
        if (index >= 0) {
          state.data[index] = device
        } else {
          state.data = state.data.concat(device)
        }
      })
    },

    update(state, action: PayloadAction<Device>) {
      const index = state.data.findIndex(({ id }) => id === action.payload.id)
      if (index >= 0) {
        state.data[index] = action.payload
        state.actionError = initialState.actionError
      }
    },

    create(state, action: PayloadAction<Device>) {
      state.data = state.data.concat(action.payload)
      state.actionError = initialState.actionError
    },

    delete(state, action: PayloadAction<Device['id']>) {
      state.data = state.data.filter((c) => c.id !== action.payload)
      state.actionError = initialState.actionError
    },

    actionReject(state, action: PayloadAction<RequestError>) {
      state.actionError = action.payload
    },

    clear(state) {
      return initialState
    },
  },
})

export default devicesSlice
