import {
  MutationOrderCourierAutoAssignArgs,
  QueryOrdersArgs,
} from '@foods-n-goods/server/generated/schema'
import { GeoData } from '@foods-n-goods/server/src/socket/geo'
import request from 'requests/request'
import store, { AppStore, DeliveryStore, StaffStore } from 'store'
import { DeliveryFilter, OrderDelivery, OrderPageDelivery } from 'store/delivery'
import actionNotify from 'utils/actionNotify'

export default {
  async fetchOrders(args: QueryOrdersArgs, print?: boolean) {
    try {
      if (print) {
        return (await request('orders', args)) as OrderPageDelivery
      }
      DeliveryStore.ordersFetch()
      const res = (await request('orders', args)) as OrderPageDelivery
      DeliveryStore.ordersResolve(res)
      return res
    } catch (error) {
      if (!print) {
        DeliveryStore.ordersReject(error)
      }
      return null
    }
  },

  updateOrders(orders: OrderDelivery[]) {
    const { date } = store.getState().delivery.filter

    const ordersToUpdate = orders.filter((order) => {
      if (
        new Date(order.preferredDeliveryDate || 0).toDateString() !==
        new Date(date).toDateString()
      ) {
        return false
      }

      const deliveryOrders = store.getState().delivery.orderPage.records

      const currentDeliveryOrder = deliveryOrders.find(
        (deliveryOrder) => deliveryOrder.id === order.id,
      )

      const shouldDeliveryUpdate =
        !currentDeliveryOrder ||
        currentDeliveryOrder.status.value !== order.status.value ||
        currentDeliveryOrder.courier?.id !== order.courier?.id ||
        currentDeliveryOrder.comment !== order.comment ||
        currentDeliveryOrder.preferDeliveryInterval !== order.preferDeliveryInterval ||
        currentDeliveryOrder.preferredDeliveryDate !== order.preferredDeliveryDate ||
        currentDeliveryOrder.sequence !== order.sequence ||
        currentDeliveryOrder.deleteDate !== order.deleteDate

      return shouldDeliveryUpdate
    })

    // spread to prevent object is not extensible error
    if (ordersToUpdate.length > 0) {
      DeliveryStore.ordersUpdate(ordersToUpdate.map((ord) => ({ ...ord })))
    }
  },

  async setOrderSequence(order: OrderDelivery, sequence: number) {
    try {
      DeliveryStore.ordersUpdate([{ ...order, sequence }])
      await request('orderUpdateDeliverySequence', {
        id: order.id,
        sequence,
      })
    } catch (error) {
      DeliveryStore.ordersUpdate([order])
      actionNotify({
        title: `Порядок доставки`,
        message: `Ошибка обновления: ${(error as Error).message}`,
        type: 'error',
      })
    }
  },

  setActiveOrders(ids: string[]) {
    DeliveryStore.setOrdersSelected(ids)
    DeliveryStore.setСurrentOrder(ids[0] || null)
  },

  setActiveCouriers(ids: string[]) {
    if (store.getState().delivery.currentCourier === ids[0]) {
      DeliveryStore.setCouriersSelected([])
      DeliveryStore.setСurrentCourier(null)
      return
    }
    DeliveryStore.setCouriersSelected(ids)
    DeliveryStore.setСurrentCourier(ids[0] || null)
  },

  async courierAutoAssign(args: MutationOrderCourierAutoAssignArgs) {
    try {
      AppStore.pending(true)
      await request('orderCourierAutoAssign', args)
      actionNotify({
        title: 'Успешное назначение!',
        message: 'На заказы успешно назначены водители!',
        type: 'success',
      })
    } catch (error) {
      actionNotify({
        title: 'Ошибка!',
        message: error.message,
        type: 'error',
      })
    } finally {
      AppStore.pending(false)
    }
  },

  updateCourierPosition(data: GeoData) {
    StaffStore.updateCourierPosition(data)
    return store.getState().staff.data.find((s) => s.id === data.user.id)
  },

  courierRouteSet(route: ymaps.Route | null) {
    if (route) {
      const paths = route.getPaths()
      DeliveryStore.courierRouteSet({
        time: route.getJamsTime(),
        length: route.getLength(),
        timeWithTrafficJam: route.getHumanJamsTime().replace(/&#160;/g, ' '),
        segments: Array(paths.getLength())
          .fill('')
          .map((_, index) => ({
            time: paths.get(index).getJamsTime(),
            length: paths.get(index).getLength(),
            timeWithTrafficJam: paths
              .get(index)
              .getHumanJamsTime()
              .replace(/&#160;/g, ' '),
          })),
      })
    } else {
      DeliveryStore.courierRouteSet(null)
    }
  },
  courierRouteRemove() {
    return DeliveryStore.courierRouteSet(null)
  },

  setFilter(filter: Partial<DeliveryFilter>) {
    DeliveryStore.setFilter(filter)
  },

  clearFilter() {
    DeliveryStore.clearFilter()
  },
}
