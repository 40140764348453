import React, { useEffect, useState } from 'react'

import { Button, Flexbox, ScrollView, Spinner } from '@stage-ui/core'
import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'

import { ReportAvailable } from '@foods-n-goods/server/generated/schema'

import ReportActions from 'actions/report'

import { ArrowRight } from '@stage-ui/icons'

import { NewReportRow } from './NewReportRow'

type NewReportSelectProps = {
  close: () => void
  onSelect: (report: ReportAvailable) => void
}

export function NewReportSelect(props: NewReportSelectProps) {
  const { close, onSelect } = props
  const [current, setCurrent] = useState<ReportAvailable | null>(null)
  const reportAvailable = useSelector(
    (state) => state.report.reportAvailable,
    objectEqual,
  )

  useEffect(() => {
    if (reportAvailable.loading === 'idle') {
      ReportActions.reportAvailableFetch()
    }
  }, [reportAvailable.loading])

  return (
    <Flexbox column>
      <Flexbox
        column
        h="40vh"
        alignItems="flex-start"
        backgroundColor="white"
        borderRadius="m"
        borderColor="gray200"
        borderWidth="0.0625rem"
        borderStyle="solid"
        style={{ overflow: 'hidden' }}
      >
        <ScrollView mode="scroll" xBarPosition="none" w="100%" barOffset={4}>
          {reportAvailable.loading !== 'resolved' && (
            <Flexbox centered p="m">
              <Spinner m="xl" />
            </Flexbox>
          )}
          {reportAvailable.data.map((report) => (
            <NewReportRow
              current={report.id === current?.id}
              key={report.id}
              report={report}
              onClick={() => setCurrent(report)}
            />
          ))}
        </ScrollView>
      </Flexbox>

      <Flexbox justifyContent="space-between" mt="l">
        <Flexbox flex={1} justifyContent="flex-end">
          <Button
            mr="m"
            label="Отмена"
            color="gray500"
            textColor="gray500"
            decoration="text"
            onClick={close}
            pr="m"
          />
          <Button
            disabled={!current}
            label="Далее"
            rightChild={<ArrowRight />}
            onClick={() => {
              if (current) {
                onSelect(current)
              }
            }}
          />
        </Flexbox>
      </Flexbox>
    </Flexbox>
  )
}
