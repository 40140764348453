import React from 'react'

import { Text } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'

import { openProductModal } from 'components/Modals/ProductView'

import { OrderedPosition } from '..'

export function OrderPositionNameCell(ctx: TableTypes.TableCellContext<OrderedPosition>) {
  const { row } = ctx
  const { name, deleteDate } = row

  const declined = !!deleteDate || row.statKey === 'posRejected'
  const halfAccepted = row.statKey === 'posDeliveredPartial'

  let color = 'gray900'

  if (declined) {
    color = 'red500'
  }
  if (halfAccepted) {
    color = 'orange500'
  }

  const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    openProductModal(row.productId, e)
  }

  return (
    <Text
      size="s"
      weight={declined || halfAccepted ? 700 : 500}
      color={color}
      decoration={declined ? 'line-through' : 'underline'}
      onClick={onClick}
    >
      {name}
    </Text>
  )
}
