import React from 'react'

import { Staff } from '@foods-n-goods/server/generated/schema'
import { Block, Flexbox, Paragraph, ScrollView, useTheme } from '@stage-ui/core'
import { DeliveryActions } from 'actions'
import useSelector from 'hooks/useSelector'
import { ym } from 'pages/logistics/MapView/hooks/useYandexMaps'

import { Car } from '@stage-ui/icons'

import { DeliveryFilter, OrderDelivery } from 'store/delivery'

import { objectEqual } from 'utils/objectEqual'

import { CourierRow } from './components/CourierRow'

export const deliveryCourierList = (
  staffs: Staff[],
  deliveryOrders: OrderDelivery[],
  tab: DeliveryFilter['couriersTab'],
) => {
  return staffs.filter((staff) => {
    if (staff.role.code !== 'COURIER') {
      return false
    }
    if (!tab || tab === 'all') {
      return true
    }
    if (tab === 'online') {
      return !!staff.online
    }
    if (
      tab === 'hasOrders' &&
      deliveryOrders.some((order) => order.courier?.id === staff.id)
    ) {
      return true
    }
    return false
  })
}

export function CourierSideView() {
  const { color } = useTheme()

  const { filter, staffs, currentId, deliveryOrders } = useSelector(
    (state) => ({
      filter: state.delivery.filter,
      staffs: state.staff.data,
      currentId: state.delivery.currentCourier,
      deliveryOrders: state.delivery.orderPage.records.map((order) => ({
        ...order,
        positions: [],
      })),
    }),
    objectEqual,
  )

  const onRowClick = (courier: Staff) => {
    DeliveryActions.setFilter({
      courierId: courier.id,
    })
    DeliveryActions.setActiveCouriers([courier.id])
    DeliveryActions.setActiveOrders([])
    ym.ref?.focus([courier.id], true)
  }

  const onMouseEnter = (courier: Staff) => {
    ym.ref?.hover([courier.id])
  }

  const onMouseLeave = (courier: Staff) => {
    ym.ref?.hover([courier.id], false)
  }

  const couriers = deliveryCourierList(staffs, deliveryOrders, filter.couriersTab)

  return (
    <Block
      h="100%"
      flexBasis="7.5rem"
      borderRadius="s"
      borderColor="gray200"
      backgroundColor="surface"
      style={{
        outline: '0.0625rem solid',
        outlineColor: color.gray[200].hex(),
        overflow: 'hidden',
      }}
    >
      <ScrollView mode="hidden" h="100%" flexBasis="7.5rem">
        {!couriers.length && (
          <Flexbox centered p="s" pt="xl" column>
            <Car size="3rem" color="gray300" mb="s" />
            <Paragraph size="s" align="center" color="gray500">
              Водители
            </Paragraph>
          </Flexbox>
        )}
        {couriers.map((courier, index) => (
          <CourierRow
            key={courier.id}
            courier={courier}
            selected={currentId === courier.id}
            divider={index + 1 !== couriers.length}
            onClick={() => onRowClick(courier)}
            onMouseEnter={() => onMouseEnter(courier)}
            onMouseLeave={() => onMouseLeave(courier)}
          />
        ))}
      </ScrollView>
    </Block>
  )
}
