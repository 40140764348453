import React from 'react'

import { Block, Flexbox, Text } from '@stage-ui/core'
import { Car, Checkmark, Close } from '@stage-ui/icons'
import { useHTMLRender } from 'pages/logistics/MapView/hooks/useHTMLRender'

export const PlacemarkTemplate = (): ReturnType<typeof useHTMLRender> => {
  const h = '1.625rem'

  const white = '#FFFFFF'
  const gray600 = '#4B5563'
  const green500 = '#10B981'
  const red = '#f00'

  const wrapperStyle = {
    'data-type': 'order',
    'data-id': '{{properties.order.id}}',
    'data-courier': '{{properties.order.courier.id}}',
    'data-hover': '{{properties.hover}}',
    'data-focus': '{{properties.focus}}',
    'aria-details': 'width: {{properties.style.containerWidth}}',
  }
  const containerStyle = {
    'aria-details': `
            width: {{properties.style.containerWidth}}; 
            background-color: {{properties.style.backgroundColor}};
        `,
  }
  const carIconStyle = {
    'aria-details': `
            color: {{properties.style.courierColor}}; 
            display: {{properties.style.carDisplay}}
        `,
  }
  const successIconStyle = {
    'aria-details': `
      display: {{properties.style.successIconDisplay}}
    `,
  }
  const failedIconStyle = {
    'aria-details': 'display: {{properties.style.deliveryFailed}}',
  }
  const counterStyle = {
    'aria-details': `
            color: {{properties.style.courierColor}}; 
            display: {{properties.style.sequenceOrderDisplay}}
        `,
  }
  const dotStyle = {
    'aria-details': 'background-color: {{properties.style.dotColor}}',
  }
  const dotBorderStyle = {
    'aria-details': `
      border-color: {{properties.style.dotBorderColor}}
    `,
  }
  const neighborsStyle = {
    'aria-details': `
      display: {{properties.style.neighborsDisplay}}
    `,
  }
  const { render, html } = useHTMLRender(
    <Block
      h={h}
      attributes={wrapperStyle}
      style={{
        '&[data-hover="true"], &:hover': {
          cursor: 'pointer',
          '> div:first-child': {
            filter: 'drop-shadow(0rem 0.125rem 0.25rem rgba(0, 0, 0, 0.1))',
            transform: 'scale(1.1)',
          },
          '> div:first-child:after': {
            borderColor: '#3e9eff',
          },
          '> div:last-child': {
            filter: 'drop-shadow(0rem 0.125rem 0.25rem rgba(0, 0, 0, 0.1))',
            transform: 'translateX(0.5rem)',
            background: '#3e9eff',
          },
        },
        '&[data-focus="true"]': {
          cursor: 'pointer',
          '> div:first-child:after': {
            borderColor: 'rgb(5, 150, 105)',
          },
          '> div:last-child': {
            background: 'rgb(5, 150, 105)',
          },
        },
      }}
    >
      <Flexbox
        h={h}
        px="0.25rem"
        position="absolute"
        borderRadius="2rem"
        shadow="m"
        alignItems="center"
        attributes={containerStyle}
        style={{
          right: 0,
          zIndex: 1,
          transition: 'all 0.125s',
          filter: 'none',
          ':after': {
            content: '""',
            position: 'absolute',
            borderStyle: 'solid',
            left: '-0.25rem',
            right: '-0.25rem',
            top: '-0.25rem',
            bottom: '-0.25rem',
            borderRadius: '10rem',
            borderColor: 'transparent',
            borderWidth: '0.25rem',
            transition: 'all 0.125s',
          },
        }}
      >
        <Flexbox
          flexShrink={0}
          centered
          w="1.25rem"
          h="1.25rem"
          borderRadius="10rem"
          attributes={dotStyle}
        >
          <Block
            w="0.875rem"
            h="0.875rem"
            borderRadius="10rem"
            style={{ border: `0.125rem solid` }}
            inlineStyle={{ borderColor: gray600 }}
            attributes={dotBorderStyle}
          >
            <Checkmark color="surface" m="-0.0625rem" attributes={successIconStyle} />
            <Close
              color="surface"
              type="filled"
              m="-0.0625rem"
              attributes={failedIconStyle}
            />
          </Block>
        </Flexbox>
        <Car
          color={white}
          type="filled"
          size="1.25rem"
          mx="xs"
          attributes={carIconStyle}
        />
        <Text
          flex={1}
          size="xs"
          weight={600}
          children="{{properties.order.sequenceOrder}}"
          attributes={counterStyle}
        />
      </Flexbox>
      <Flexbox
        h={h}
        centered
        attributes={neighborsStyle}
        p="0rem 0.375rem 0rem 1.5rem"
        backgroundColor="gray400"
        borderRadius="10rem"
        style={{
          position: 'relative',
          transition: 'all 0.125s',
          left: '1.5rem',
        }}
      >
        <Text
          fontSize="0.75rem"
          lineHeight={1}
          color="surface"
        >{`x{{properties.count}}`}</Text>
      </Flexbox>
    </Block>,
  )

  return { render, html }
}
