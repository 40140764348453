import { configureStore, EnhancedStore } from '@reduxjs/toolkit'
import { bindActionCreators } from 'redux'

import app, { AppState } from './app'
import category, { CategoryState } from './category'
import chat, { ChatState } from './chat'
import client, { ClientState } from './client'
import delivery, { DeliveryState } from './delivery'
import journal, { JournalState } from './journal'
import order, { OrderState } from './order'
import product, { ProductState } from './product'
import promotion, { PromotionState } from './promotion'
import review, { ReviewState } from './review'
import staff, { StaffState } from './staff'
import variables, { VariablesState } from './variables'
import dictionaries, { DictionariesState } from './dictionaries'
import purchases, { PurchasesState } from './purchases'
import devices, { DevicesState } from './devices'
import orderQuality, { OrderQualityState } from './orderQuality'
import report, { ReportState } from './report'

const store: EnhancedStore<State> = configureStore({
  reducer: {
    app: app.reducer,
    order: order.reducer,
    product: product.reducer,
    category: category.reducer,
    review: review.reducer,
    client: client.reducer,
    delivery: delivery.reducer,
    staff: staff.reducer,
    chat: chat.reducer,
    journal: journal.reducer,
    variables: variables.reducer,
    promotion: promotion.reducer,
    dictionaries: dictionaries.reducer,
    purchases: purchases.reducer,
    devices: devices.reducer,
    orderQuality: orderQuality.reducer,
    report: report.reducer,
  },
  devTools: !window.location.protocol.match('s') && {
    name: 'Foods-n-Goods (Console)',
    actionCreators: {
      ...app.actions,
      ...order.actions,
      ...product.actions,
      ...category.actions,
      ...review.actions,
      ...client.actions,
      ...delivery.actions,
      ...staff.actions,
      ...chat.actions,
      ...journal.actions,
      ...variables.actions,
      ...promotion.actions,
      ...dictionaries.actions,
      ...purchases.actions,
      ...devices.actions,
      ...orderQuality.actions,
      ...report.actions,
    },
    trace: true,
  },
})

export interface State {
  app: AppState
  order: OrderState
  product: ProductState
  category: CategoryState
  review: ReviewState
  client: ClientState
  delivery: DeliveryState
  staff: StaffState
  chat: ChatState
  journal: JournalState
  variables: VariablesState
  promotion: PromotionState
  dictionaries: DictionariesState
  purchases: PurchasesState
  devices: DevicesState
  orderQuality: OrderQualityState
  report: ReportState
}

export const AppStore = bindActionCreators(app.actions, store.dispatch)
export const OrderStore = bindActionCreators(order.actions, store.dispatch)
export const ProductStore = bindActionCreators(product.actions, store.dispatch)
export const CategoryStore = bindActionCreators(category.actions, store.dispatch)
export const ReviewStore = bindActionCreators(review.actions, store.dispatch)
export const ClientStore = bindActionCreators(client.actions, store.dispatch)
export const DeliveryStore = bindActionCreators(delivery.actions, store.dispatch)
export const StaffStore = bindActionCreators(staff.actions, store.dispatch)
export const ChatStore = bindActionCreators(chat.actions, store.dispatch)
export const JournalStore = bindActionCreators(journal.actions, store.dispatch)
export const VariablesStore = bindActionCreators(variables.actions, store.dispatch)
export const PromotionStore = bindActionCreators(promotion.actions, store.dispatch)
export const DictionariesStore = bindActionCreators(dictionaries.actions, store.dispatch)
export const PurchasesStore = bindActionCreators(purchases.actions, store.dispatch)
export const DevicesStore = bindActionCreators(devices.actions, store.dispatch)
export const OrderQualityStore = bindActionCreators(orderQuality.actions, store.dispatch)
export const ReportStore = bindActionCreators(report.actions, store.dispatch)

export default store
