import {
  Container,
  DeliveryIntervalData,
  MutationContainerCreateArgs,
  MutationContainerUpdateArgs,
  MutationDeliveryIntervalCreateArgs,
  MutationDeliveryIntervalUpdateArgs,
} from '@foods-n-goods/server/generated/schema'
import request from 'requests/request'
import { AppStore, DictionariesStore } from 'store'
import actionNotify from 'utils/actionNotify'

export default {
  async getDeliveryIntervals() {
    try {
      DictionariesStore.deliveryIntervalsLoading()
      const deliveryIntervals = await request('deliveryIntervals')
      DictionariesStore.deliveryIntervalsResolve(deliveryIntervals)
    } catch (error) {
      DictionariesStore.deliveryIntervalsReject(error)
    }
  },

  async updateDeliveryInterval(
    deliveryInterval: MutationDeliveryIntervalUpdateArgs,
    _cb: () => void,
  ) {
    try {
      AppStore.pending(true)
      const response = await request('deliveryIntervalUpdate', deliveryInterval)
      DictionariesStore.deliveryIntervalsActionUpdateResolve(response)

      actionNotify({
        title: `«${response.interval}»`,
        message: `Интервал доставки успешно обновлен!`,
        type: 'success',
      })

      _cb()
    } catch (error) {
      actionNotify({
        title: `Ошибка обновления интервала!`,
        message: error.message || error,
        type: 'error',
      })
    } finally {
      AppStore.pending(false)
    }
  },

  async createDeliveryInterval(
    deliveryInterval: MutationDeliveryIntervalCreateArgs,
    _cb: () => void,
  ) {
    try {
      AppStore.pending(true)
      const response = await request('deliveryIntervalCreate', deliveryInterval)
      DictionariesStore.deliveryIntervalsActionCreateResolve(response)

      actionNotify({
        title: `«${response.interval}»`,
        message: `Интервал доставки успешно добавлен!`,
        type: 'success',
      })
      _cb()
    } catch (error) {
      actionNotify({
        title: `Ошибка добавления интервала!`,
        message: error.message || error,
        type: 'error',
      })
    } finally {
      AppStore.pending(false)
    }
  },

  async deleteDeliveryInterval(item: DeliveryIntervalData, _cb: () => void) {
    try {
      AppStore.pending(true)
      await request('deliveryIntervalDelete', { id: item.id })
      DictionariesStore.deliveryIntervalsDeleteResolve(item.id)
      actionNotify({
        title: 'Удаления интервала',
        message: `Интервал успешно удален!`,
        type: 'success',
      })
      _cb()
    } catch (error) {
      actionNotify({
        title: `Ошибка удаления интервала!`,
        message: error.message || error,
        type: 'error',
      })
    } finally {
      AppStore.pending(false)
    }
  },

  async getContainers() {
    try {
      DictionariesStore.containersLoading()
      const containers = await request('containers')
      DictionariesStore.containersResolve(containers)
    } catch (error) {
      DictionariesStore.containersReject(error)
    }
  },

  async updateContainer(container: MutationContainerUpdateArgs, _cb: () => void) {
    try {
      AppStore.pending(true)
      const response = await request('containerUpdate', container)
      DictionariesStore.containersActionUpdateResolve(response)

      actionNotify({
        title: `«${response.name}»`,
        message: `Конфигурация контейнера успешно обновлена!`,
        type: 'success',
      })

      _cb()
    } catch (error) {
      actionNotify({
        title: `Ошибка обновления контейнера!`,
        message: error.message || error,
        type: 'error',
      })
    } finally {
      AppStore.pending(false)
    }
  },

  async createContainer(container: MutationContainerCreateArgs, _cb: () => void) {
    try {
      AppStore.pending(true)
      const response = await request('containerCreate', container)
      DictionariesStore.containersActionCreateResolve(response)

      actionNotify({
        title: `«${response.name}»`,
        message: `Контейнер успешно добавлен!`,
        type: 'success',
      })

      _cb()
    } catch (error) {
      actionNotify({
        title: `Ошибка добавления контейнера!`,
        message: error.message || error,
        type: 'error',
      })
    } finally {
      AppStore.pending(false)
    }
  },

  async deleteContainer(container: Container, _cb: () => void) {
    try {
      AppStore.pending(true)
      await request('containerDelete', { id: container.id })
      DictionariesStore.containersActionDeleteResolve(container.id)

      actionNotify({
        title: `«${container.name}»`,
        message: `Контейнер успешно удален!`,
        type: 'success',
      })

      _cb()
    } catch (error) {
      actionNotify({
        title: `Ошибка удаления контейнера!`,
        message: error.message || error,
        type: 'error',
      })
    } finally {
      AppStore.pending(false)
    }
  },
}
