import React from 'react'

import { Flexbox, Grid, Text } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'

import { rub } from 'utils/rub'

import { OrderedPosition } from '..'

export function OrderPositionQuantityCell(
  ctx: TableTypes.TableCellContext<OrderedPosition>,
) {
  const { row } = ctx
  const { quantity, amount, wishfulUnitName, wishfulUnitQuantity, unit } = row

  const quantityValue = `${quantity.toLocaleString()} ${unit.text}`
  const amountValue = rub(amount || 0)

  return (
    <Grid autoFlow="row" alignItems="start" gap="0.125rem">
      <Flexbox alignItems="center">
        <Text size="s" color="gray500" style={{ whiteSpace: 'nowrap' }}>
          {quantityValue}
        </Text>
        {wishfulUnitQuantity && (
          <Text size="s" color="gray500" style={{ whiteSpace: 'nowrap' }}>
            ({wishfulUnitQuantity} {wishfulUnitName})
          </Text>
        )}
      </Flexbox>
      <Flexbox alignItems="center">
        <Text size="s" color="gray500" style={{ whiteSpace: 'nowrap' }}>
          {amountValue}
        </Text>
      </Flexbox>
    </Grid>
  )
}
