import React from 'react'

import { Product } from '@foods-n-goods/server/generated/schema'
import { Block, Button, Flexbox, Paragraph } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import { PageSwitch } from 'components/PageSwitch'
import { PageTable } from 'components/PageTable'
import useSelector from 'hooks/useSelector'
import { useHistory, useLocation } from 'react-router-dom'
import { PageLoading } from 'components/PageLoading'
import { objectEqual } from 'utils/objectEqual'
import { useMouseDropMenu } from 'components/MouseMenuDrop'
import { openDevicePrinterModal } from 'components/Modals/DevicePrinterSelect'
import { OrderActions, ProductActions } from 'actions'
import { MoreVertical } from '@stage-ui/icons'

function NameCell(ctx: TableTypes.TableCellContext<Product>) {
  const { row } = ctx
  return (
    <Block>
      <Paragraph m={0} weight={700} children={row.name} />
      <Paragraph m={0} color="light" size="s" children={`#${row.article}`} />
    </Block>
  )
}

function PriceCell(ctx: TableTypes.TableCellContext<Product>) {
  const { row, value } = ctx
  const product = row
  if (!row.price || row.price === '0') {
    return ''
  }
  return (
    <Flexbox column>
      <Paragraph m={0} children={`${row.price} РУБ`} />
      <Paragraph m={0} size="xs" children={`за 1 ${product.unit.text}`} />
    </Flexbox>
  )
}

function ActionCell(ctx: TableTypes.TableCellContext<Product>) {
  const history = useHistory()
  const location = useLocation()
  const params = location.search

  const { row } = ctx

  const contextMenu = useMouseDropMenu<HTMLButtonElement>(
    [
      { text: 'Открыть продукт', icon: 'ExternalLink', value: 'OPEN' },
      { text: 'Печать EAC', icon: 'Printer', value: 'PRINT_EAC' },
    ],
    ({ value }) => {
      if (value === 'OPEN') {
        history.push(`/products/${row.id}${params}`)
      }
      if (value === 'PRINT_EAC') {
        openDevicePrinterModal((deviceId) => {
          OrderActions.printInfo({
            deviceId,
            productIds: [row.id],
          })
        })
      }
    },
  )

  return (
    <Button
      id={`product_row_action_${row.id}`}
      decoration="text"
      color="gray500"
      p={0}
      w="2rem"
      h="2rem"
      {...contextMenu}
    >
      <MoreVertical size="1.5rem" />
    </Button>
  )
}

function NomenclatureTable() {
  const history = useHistory()
  const location = useLocation()

  const { filter, data, loading, total } = useSelector(
    (state) => ({
      filter: state.product.filter,
      data: state.product.data.records,
      loading: state.product.loading === 'pending',
      total: state.product.data.total,
    }),
    objectEqual,
  )

  return (
    <PageLoading loading={loading}>
      <PageTable
        noDataText={
          loading
            ? 'Данные загружаются'
            : 'Не найдено категорий, удовлетворяющих условиям поиска.'
        }
        data={data}
        columns={[
          { key: 'name', title: 'Наименование', render: NameCell },
          { key: 'price', title: 'Цена', render: PriceCell },
          { key: '___actionCell', title: '', render: ActionCell, width: 64 },
        ]}
        onRowClick={(ctx) => {
          history.push(`/products/${ctx.row.id}${location.search}`)
        }}
      />
      {data.length > 0 && (
        <PageSwitch
          total={total}
          pageSize={filter.nomenclaturePageSize}
          page={filter.nomenclaturePage}
          onChange={({ page, pageSize }) =>
            ProductActions.setFilter({
              nomenclaturePage: page,
              nomenclaturePageSize: pageSize,
            })
          }
        />
      )}
    </PageLoading>
  )
}

export default NomenclatureTable
