import orderFields from '../fragments/orderFields.gql'

export default `
    mutation orderSetCourier($ids: [String!]!, $staffId: String) {
        orderSetCourier(ids: $ids, staffId: $staffId) {
            ...orderFields
        }
    }
    ${orderFields}
`
