import React, { useEffect } from 'react'

import { Block, Flexbox, Paragraph, ScrollView } from '@stage-ui/core'
import { List } from '@stage-ui/icons'
import useSelector from 'hooks/useSelector'
import { ym } from 'pages/logistics/MapView/hooks/useYandexMaps'
import { makeRoute } from 'pages/logistics/utils/makeRoute'

import { objectEqual } from 'utils/objectEqual'

import { OrderDelivery } from 'store/delivery'

import { deliveryCourierList } from '../CourierSideView'

import { CourierHeaderView } from './components/CourierHeaderView'
import { DragableOrder } from './components/DragableOrder'

export function CourierOrdersView() {
  const { filter, staffs, courierId, hideSimilarClientOrders, deliveryOrders } =
    useSelector(
      (state) => ({
        filter: state.delivery.filter,
        staffs: state.staff.data,
        courierId: state.delivery.currentCourier,
        hideSimilarClientOrders:
          state.app.marketConfig?.logisticsCourierHideSimilarClientOrders,
        deliveryOrders: state.delivery.orderPage.records.map((order) => ({
          ...order,
          positions: [],
        })),
      }),
      objectEqual,
    )

  const couriers = deliveryCourierList(staffs, deliveryOrders, filter.couriersTab)

  const courier = staffs.find((staff) => staff.id === courierId)

  const allowDisplay = courier && couriers.some((c) => c.id === courier?.id)

  const orders = deliveryOrders
    .slice()
    .reduce((res, item) => {
      if (item.courier?.id === courierId) {
        if (Number(hideSimilarClientOrders || '1')) {
          if (!res.some((o) => o.client.id === item.client.id)) {
            res.push(item)
          }
        } else {
          res.push(item)
        }
      }
      return res
    }, [] as OrderDelivery[])
    .sort((a, b) => ((a.sequenceOrder || 0) > (b.sequenceOrder || 0) ? 1 : -1))

  /**
   * Update courier route if we have route on map
   */
  useEffect(() => {
    if (allowDisplay && courier && ym.ref?.courierRouteCanUpdate(courier.id)) {
      makeRoute(courier, orders, false)
    }
  }, [allowDisplay, courierId, orders.map(({ id }) => id).join()])

  return (
    <Block
      ml="s"
      h="100%"
      flex={1}
      decoration="surface"
      borderRadius="s"
      borderColor="gray200"
      style={{
        overflow: 'hidden',
      }}
    >
      {!allowDisplay && (
        <Flexbox centered m="xl" column>
          <List size="3rem" color="gray300" mb="s" />
          <Paragraph size="s" align="center" color="gray500">
            Заказы водителя
          </Paragraph>
        </Flexbox>
      )}
      {!!allowDisplay && (
        <ScrollView mode="hidden">
          <CourierHeaderView />
          {!orders.length && (
            <Flexbox centered p="xl" column>
              <List size="3rem" color="gray300" mb="s" />
              <Paragraph size="s" align="center" color="gray500">
                Нет назначенных заказов
              </Paragraph>
            </Flexbox>
          )}
          {orders.map((order, index) => (
            <DragableOrder
              key={order.id}
              order={order}
              orders={orders}
              index={index}
              last={index + 1 === orders.length}
            />
          ))}
        </ScrollView>
      )}
    </Block>
  )
}
