export default `
    query reports {
      reports {
        id
        name
        createDate
        finishDate
        payload
        result
      }
    }
`
