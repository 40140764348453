import React, { useState } from 'react'

import { OrderedProduct } from '@foods-n-goods/server/generated/schema'
import { Button, Flexbox } from '@stage-ui/core'
import { OrderActions } from 'actions'
import ViewInputField from 'components/Fields/ViewInputField'

type OrderPositionChangeQuantityModalProps = {
  close: () => void
  id: OrderedProduct['id']
  unit: OrderedProduct['unit']
  quantityAfter: OrderedProduct['quantityAfter']
  disabled: boolean
}

export function OrderPositionChangeQuantityModal(
  props: OrderPositionChangeQuantityModalProps,
) {
  const { close, id, quantityAfter, disabled, unit } = props

  const [quantity, setQuantity] = useState(String(quantityAfter || ''))

  const editOrder = () => {
    if (disabled || !quantity) return
    OrderActions.orderUpdateQuantity({
      positionId: id,
      quantity: Number(quantity),
    })
  }

  const handleConfirm = () => {
    editOrder()
    close()
  }

  return (
    <Flexbox column pt="m">
      <ViewInputField
        autoFocus
        label="К отгрузке"
        value={quantity}
        onChange={(v) => setQuantity(v)}
        onEnter={handleConfirm}
        type={unit.value === 1 ? 'digits' : 'integer'}
        digits={3}
        rightChild={unit.text}
      />
      <Flexbox justifyContent="flex-end" mt="xl">
        <Button
          mr="s"
          label="Отмена"
          color="gray500"
          textColor="gray900"
          decoration="text"
          onClick={close}
        />
        <Button disabled={!quantity} label="Подтвердить" onClick={handleConfirm} />
      </Flexbox>
    </Flexbox>
  )
}
