import { MutationReportProcessArgs } from '@foods-n-goods/server/generated/schema'
import { SocketType } from '@foods-n-goods/server/src/socket/types'
import request from 'requests/request'
import { io } from 'requests/socket'
import { ReportStore } from 'store'
import { ReportFilter } from 'store/report'

export default {
  async reportAvailableFetch() {
    try {
      ReportStore.reportAvailableFetch()
      const res = await request('reportAvailable')
      ReportStore.reportAvailableResolve(res)
    } catch (error) {
      ReportStore.reportAvailableReject(error)
    }
  },

  async reportsFetch() {
    try {
      ReportStore.reportsFetch()
      const res = await request('reports')
      ReportStore.reportsResolve(res)
    } catch (error) {
      ReportStore.reportsReject(error)
    }
  },

  async reportProcess(args: MutationReportProcessArgs) {
    return request('reportProcess', args)
  },

  setFilter(filter: Partial<ReportFilter>) {
    ReportStore.setFilter(filter)
  },

  clearFilter() {
    ReportStore.clearFilter()
  },
}

io.on(SocketType.Broadcast.ReportUpdate, ReportStore.reportsUpsert)
