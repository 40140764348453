import React from 'react'

import { Order } from '@foods-n-goods/server/generated/schema'
import { Button, Flexbox, modal, Text, useTheme } from '@stage-ui/core'
import { MessageCircle } from '@stage-ui/icons'
import { openClientModal } from 'components/Modals/ClientView'
import { useMouseDropMenu } from 'components/MouseMenuDrop'
import TwoDots from 'components/Icons/TwoDots'
import { useMouseDrop } from 'components/MouseDrop'

import { openOrderCommentChangeModal } from 'components/Modals/OrderCommentChange'

import { OrderDeliveryDateChangeModal } from '../components/DeliveryDateChangeModal/OrderDeliveryDateChangeModal'

type OrderClientDetailsProps = {
  order: Order
}

export function OrderClientDetails(props: OrderClientDetailsProps) {
  const { order } = props
  const { color } = useTheme()

  const handleDeliveryDateChange = () => {
    modal({
      title: 'Дата доставки и интервал',
      overlayClose: false,
      subtitle: `Заказ [#${order.id}] ${order.client.name ?? '-'}`,
      w: '26rem',
      overrides: {
        header: { marginBottom: '1rem' },
      },
      render: (close) => <OrderDeliveryDateChangeModal onClose={close} order={order} />,
    })
  }
  const handleCommentChange = () => {
    openOrderCommentChangeModal(order)
  }

  const commentHint = useMouseDrop(order.comment || 'Добавить комментарий', {
    drop: {
      align: 'bottom',
    },
    popover: {
      align: 'top',
      style: {
        maxWidth: '35vw',
      },
    },
  })

  const contextMenu = useMouseDropMenu<HTMLButtonElement>(
    [
      { text: 'Карточка клиента', value: 'CLIENT', icon: 'Person' },
      { text: 'Дата/интервал доставки', value: 'DATE_INTERVAL', icon: 'Car2' },
      { text: 'Комментарий к заказу', value: 'COMMENT', icon: 'MessageCircle' },
    ],
    ({ value }) => {
      if (value === 'CLIENT') {
        return openClientModal(order.client.id)
      }
      if (value === 'DATE_INTERVAL') {
        return handleDeliveryDateChange()
      }
      if (value === 'COMMENT') {
        return handleCommentChange()
      }
    },
  )

  return (
    <Flexbox alignItems="center">
      <Text
        size="l"
        color="gray900"
        lineHeight={1.5}
        weight={500}
        style={{
          wordBreak: 'break-word',
        }}
      >
        {order.client.markets
          .find((m) => m.id === order.marketId && m.alias)
          ?.alias?.trim() ??
          order.client.name?.trim() ??
          order.client.identifier}
      </Text>
      <Flexbox alignItems="center" h="100%" flex={1} px="m">
        <MessageCircle
          color={order.comment ? 'blue500' : 'gray200'}
          size="1.5rem"
          onClick={handleCommentChange}
          {...commentHint}
        />
      </Flexbox>
      <Text
        weight={400}
        color="gray600"
        lineHeight={2}
        align="right"
        style={{
          wordBreak: 'break-word',
        }}
      >
        {order.deliveryAddress.address}
        {!order.deliveryAddress.address && (
          <Text size="s" color="red500" decoration="underline">
            Адрес не заполнен!
          </Text>
        )}
      </Text>
      <Button ml="xl" w="2rem" h="2rem" decoration="text" p={0} {...contextMenu}>
        <TwoDots w="1.5rem" h="1.5rem" fillColor={color.gray[900].hex()} />
      </Button>
    </Flexbox>
  )
}
