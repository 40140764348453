import purchaseFields from '../fragments/purchaseFields.gql'

export default `
    query purchases($page: Float, $pageSize: Float, $search: String, $filter: PurchaseFilter!) {
        purchases(page: $page, pageSize: $pageSize, search: $search, filter: $filter) {
            total
            records {
                ...purchaseFields
            }
        }
    }
    ${purchaseFields}
`
