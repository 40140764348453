import { DeliveryIntervalData } from '@foods-n-goods/server/generated/schema'
import { Button, Flexbox } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import { Edit } from '@stage-ui/icons'

import { updateDeliveryIntervalDataDialog } from './form'

export const columns: TableTypes.TableColumn<DeliveryIntervalData>[] = [
  { key: 'id', title: 'ID', width: '4rem' },
  { key: 'interval', width: 200, title: 'Интервал' },
  {
    key: '_',
    title: '',
    render: (ctx) => (
      <Flexbox justifyContent="flex-end">
        <Button
          ml="s"
          label="Изменить"
          decoration="plain"
          size="s"
          onClick={() => {
            updateDeliveryIntervalDataDialog(ctx.row)
          }}
          rightChild={<Edit />}
        />
      </Flexbox>
    ),
  },
]
