import orderFields from '../fragments/orderFields.gql'

export default `
    mutation orderRefund($id: String!, $amount: String!) {
        orderRefund(id: $id, amount: $amount) {
            ...orderFields
        }
    }
    ${orderFields}
`
