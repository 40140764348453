import 'types.d'
import React from 'react'

import { Viewport } from '@stage-ui/core'
import { AppActions } from 'actions'
import ReactDOM from 'react-dom'
import { Provider as ReduxProvider } from 'react-redux'
import AppRouter from 'Router'
import store from 'store'
import theme from '@foods-n-goods/client/theme'
import LoadingOverlay from 'pages/loading/Overlay'
import { BrowserRouter } from 'react-router-dom'
import requestPushPermission from 'utils/requestPushPermission'
import MouseDrop from 'components/MouseDrop'
import MouseMenuDrop from 'components/MouseMenuDrop'
import moment from 'moment'
import { globalStyles } from 'styles'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
)

AppActions.init()

// require('moment/locale/ru.js')

moment.locale('ru')

requestPushPermission()

function Application() {
  return (
    <ReduxProvider store={store}>
      <style>{`
        @keyframes rotate-center {
            0% {
                transform: rotate(0);
            }
            100% {
                transform: rotate(360deg);
            }
          }
        `}</style>
      <style media="print">{`
        @page {
          size: portrait;
          margin: 10%
        }
      `}</style>
      <BrowserRouter>
        <Viewport theme={theme} global={globalStyles}>
          <LoadingOverlay />
          <AppRouter />
          <MouseDrop />
          <MouseMenuDrop />
        </Viewport>
      </BrowserRouter>
    </ReduxProvider>
  )
}

ReactDOM.render(<Application />, document.getElementById('app'))
