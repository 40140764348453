import React from 'react'

import { Block, Flexbox, Text } from '@stage-ui/core'

import { AvatarUploadController } from './UploadController'

type ShortUser = {
  id: string
  name: string
  color: string
  online: boolean
  photo?: string | null
  updateDate: Date
}

interface AvatarProps {
  size?: string
  user: ShortUser
  label?: string | number
  onUpload?: (id: string, payload: string, _cb?: () => void) => Promise<void>
  onMouseOver?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  onMouseLeave?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const Avatar: React.ForwardRefRenderFunction<HTMLDivElement, AvatarProps> = (
  props,
  ref,
) => {
  const {
    user,
    size = '3rem',
    label,
    onClick,
    onMouseOver,
    onMouseLeave,
    onUpload,
  } = props

  if (!user) {
    return null
  }

  const userName = user.name || user.id
  const phonetic = (userName[0] || '') + (userName[1] || '')

  return (
    <Flexbox
      ref={ref}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      centered
      flexShrink={0}
      style={{
        width: size,
        height: size,
        borderRadius: `calc(${size} * 2)`,
        background: user.color || '#333',
        border: '0.0625rem solid #fff',
        cursor: onClick ? 'pointer' : 'default',
        boxSizing: 'border-box',
        ':hover': {
          '[id^=avatar_upload_controller]': {
            bottom: 0,
          },
        },
      }}
    >
      <Flexbox
        w="100%"
        h="100%"
        centered
        style={{
          overflow: 'hidden',
          borderRadius: '100%',
        }}
      >
        <Text size={`calc(${size} / 3)`} color="surface">
          {label || phonetic.toUpperCase()}
        </Text>
        {user.photo && !label && (
          <Block
            style={{
              position: 'absolute',
              left: 0,
              right: 0,
              width: '100%',
              height: '100%',
              background: `url(${user.photo}?${new Date(user.updateDate).getTime()})`,
              borderRadius: '100%',
              backgroundSize: 'contain',
            }}
          />
        )}
        <AvatarUploadController
          photo={!!user.photo}
          userId={user.id}
          onUpload={onUpload}
        />
      </Flexbox>
      {user.online && (
        <Flexbox
          w={`calc(${size} / 6)`}
          h={`calc(${size} / 6)`}
          position="absolute"
          borderRadius="1rem"
          borderColor="white"
          borderStyle="solid"
          borderWidth={`calc(${size} / 24)`}
          backgroundColor="green500"
          style={{
            left: '1%',
            top: '1%',
          }}
        />
      )}
    </Flexbox>
  )
}

export default React.forwardRef(Avatar)
