import orderFields from '../fragments/orderFields.gql'

export default `
    mutation orderPriceActualization($ids: [String!]!) {
        orderPriceActualization(ids: $ids) {
            ...orderFields
        }
    }
    ${orderFields}
`
