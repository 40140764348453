import { ReactNode } from 'react'

import { Block, Paragraph } from '@stage-ui/core'
import BlockTypes from '@stage-ui/core/layout/Block/types'

type Props = {
  text?: string
  value?: ReactNode
  color?: Stage.ColorProp
} & BlockTypes.Props
function ViewField(props: Props) {
  const { text, value, color, ...blockProps } = props
  return (
    <Block {...blockProps}>
      <Paragraph size="s" children={text} color="hard" pb="0.125rem" />
      {typeof value === 'string' ? (
        <Paragraph color={color} weight={500} children={value || '–'} />
      ) : (
        value
      )}
    </Block>
  )
}

export default ViewField
