export default `mutation clientDeliveryAddressAdd($clientId: String, $payload: ClientDeliveryAddressInput!, $shouldBeDefault: Boolean) {
    clientDeliveryAddressAdd(clientId: $clientId, payload: $payload, shouldBeDefault: $shouldBeDefault) {
        id
        name
        address
        entrance
        floor
        apartment
        intercom
        lat
        lng
        isDefault
    }
}`
