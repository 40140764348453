import React from 'react'

import { Block, Grid, Text, useTheme } from '@stage-ui/core'
import { Menu } from '@stage-ui/icons'
import { Warehouse } from '@foods-n-goods/server/generated/schema'

type DnDObject = {
  source: string
  target: string
}

const dndObject: DnDObject = {
  source: '',
  target: '',
}

type DragableWarehouseProps = {
  warehouse: Warehouse
  warehouses: Warehouse[]
  last: boolean
  onChange: (id: number, priority: number) => void
}

export function DragableWarehouse(props: DragableWarehouseProps) {
  const { warehouse, warehouses, last, onChange } = props
  const theme = useTheme()

  return (
    <>
      <Grid
        templateColumns="1rem 1fr 0.75rem"
        gap="0.25rem"
        px="s"
        py="0.75rem"
        alignItems="center"
        draggable
        onDragStart={() => {
          dndObject.source = String(warehouse.warehouseId)
          dndObject.target = ''
        }}
        onDragOver={(e) => {
          e.preventDefault()
        }}
        onDragEnter={(e) => {
          const target = e.target as HTMLDivElement
          if (String(warehouse.warehouseId) !== dndObject.source) {
            target.style.setProperty('border-top-color', theme.color.blue[500].hex())
            dndObject.target = String(warehouse.warehouseId)
          }
        }}
        onDragLeave={(e) => {
          const target = e.target as HTMLDivElement
          target.style.setProperty('border-top-color', 'rgba(255,255,255,0)')
          dndObject.target = ''
        }}
        onDrop={(e) => {
          const target = e.target as HTMLDivElement
          target.style.setProperty('border-top-color', 'rgba(255,255,255,0)')

          if (String(warehouse.warehouseId) !== dndObject.source) {
            dndObject.target = String(warehouse.warehouseId)

            const source = warehouses.find(
              (w) => dndObject.source === String(w.warehouseId),
            )
            const idx = warehouses.findIndex(
              (w) => dndObject.target === String(w.warehouseId),
            )
            const t1 = warehouses[idx]
            const t2 = warehouses[idx - 1]

            if (source && t1) {
              if (t1.priority && t1.priority !== t2?.priority) {
                if (t2?.priority) {
                  onChange(source.warehouseId, (t1.priority + t2.priority) / 2)
                } else {
                  onChange(source.warehouseId, t1.priority / 2)
                }
              } else {
                onChange(source.warehouseId, (t1.priority || 0) + 1)
              }
            }
          }
        }}
        style={{
          cursor: 'grab',
          borderBottom: '0.0625rem',
          borderBottomStyle: 'solid',
          borderBottomColor: theme.color.gray[200].rgb().string(),
          borderTopColor: 'rgba(255,255,255,0)',
          borderTopStyle: 'dashed',
          borderTopWidth: '0.125rem',
        }}
      >
        <Text size="s" color="gray400">
          {warehouse.priority}
        </Text>
        <Text size="s" color="gray900">
          {warehouse.name}
        </Text>
        <Menu color="gray300" size="0.75rem" />
      </Grid>
      {last && (
        <Block
          h="1rem"
          style={{
            borderTopColor: 'rgba(255,255,255,0)',
            borderTopStyle: 'dashed',
            borderTopWidth: '0.125rem',
          }}
          onDragEnter={(e) => {
            const target = e.target as HTMLDivElement
            target.style.setProperty('border-top-color', theme.color.blue[500].hex())
            dndObject.target = ''
          }}
          onDragLeave={(e) => {
            const target = e.target as HTMLDivElement
            target.style.setProperty('border-top-color', 'rgba(255,255,255,0)')
          }}
          onDragOver={(e) => {
            e.preventDefault()
          }}
          onDrop={(e) => {
            const target = e.target as HTMLDivElement
            target.style.setProperty('border-top-color', 'rgba(255,255,255,0)')

            const warehouse = warehouses.find(
              (w) => String(w.warehouseId) === dndObject.source,
            )
            const priority = Math.ceil(warehouses[warehouses.length - 1].priority) + 1

            if (warehouse && priority) {
              onChange(warehouse.warehouseId, priority)
            }
          }}
        />
      )}
    </>
  )
}
