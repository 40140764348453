import React, { useState } from 'react'

import { Button, Flexbox, modal } from '@stage-ui/core'
import ButtonTypes from '@stage-ui/core/control/Button/types'
import { ChatActions } from 'actions'
import ViewInputField from 'components/Fields/ViewInputField'
import { Staff } from '@foods-n-goods/server/generated/schema'
import useSelector from 'hooks/useSelector'
import { useHistory } from 'react-router-dom'
import { objectEqual } from 'utils/objectEqual'

interface StaffSendPushMessageProps {
  staff: Staff
  close: () => void
}

function StaffPushMessageModal(props: ButtonTypes.Props & StaffSendPushMessageProps) {
  const { staff, close } = props
  const history = useHistory()

  const [title, setTitle] = useState('')
  const [message, setMessage] = useState('')

  const { user, rooms } = useSelector(
    (state) => ({
      user: state.app.user,
      rooms: state.chat.rooms,
    }),
    objectEqual,
  )

  const availableRoom = rooms.find(
    (r) =>
      r.users.length === 2 &&
      r.users.some((u) => u.type === 'STAFF' && u.id === staff.id),
  )

  const handleSendMessage = async () => {
    if (!user) return

    if (availableRoom) {
      ChatActions.sendMessage(availableRoom.roomId, message)
      history.push(`/dialogs?id=${availableRoom.roomId}`)
    } else {
      const res = await ChatActions.createRoom(title, [user.id, staff.id], [])
      ChatActions.sendMessage(res.roomId, message)
      history.push(`/dialogs?id=${res.roomId}`)
    }
    close()
  }

  return (
    <Flexbox column>
      {!availableRoom && (
        <ViewInputField
          autoFocus
          label="Наименование чата"
          labelType="outside"
          value={title}
          mb="s"
          onChange={(value) => setTitle(value)}
        />
      )}
      <ViewInputField
        label="Сообщение"
        labelType="outside"
        multiline
        rows={3}
        value={message}
        onChange={(value) => setMessage(value)}
      />
      <Flexbox justifyContent="space-between" mt="3rem">
        <Flexbox flex={1} justifyContent="flex-end">
          <Button decoration="text" onClick={close} color="gray500">
            Отмена
          </Button>
          <Button ml="s" onClick={handleSendMessage}>
            Отправить
          </Button>
        </Flexbox>
      </Flexbox>
    </Flexbox>
  )
}

export const openStaffSendChatMessageModal = (
  staff: Staff,
  e?: React.MouseEvent<HTMLDivElement, MouseEvent>,
) => {
  e?.stopPropagation()
  e?.preventDefault()

  const subtitle = staff?.name || staff?.login || undefined

  modal({
    title: `Отправить сообщение ${staff ? 'сотруднику' : 'сотрудникам'}`,
    subtitle,
    w: '30rem',
    render: (close) => <StaffPushMessageModal close={close} staff={staff} />,
  })
}
