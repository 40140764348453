import React from 'react'

import BreadCrumbs from 'components/BreadCrumbs/BreadCrumbs'
import { useLocation } from 'react-router-dom'

export function SectionHeader() {
  const location = useLocation()
  const sectionPath = location.pathname.split('/')[1]

  return (
    <BreadCrumbs path="" selectedItem="" sectionName="Отчеты" sectionPath={sectionPath} />
  )
}
