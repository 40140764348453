import ClientSystem from '@foods-n-goods/client/system/types'
import { Container, DeliveryIntervalData } from '@foods-n-goods/server/generated/schema'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface DictionariesState {
  // DELIVERY INTERVALS
  deliveryIntervalsLoading: ClientSystem.Loading
  deliveryIntervalsData: DeliveryIntervalData[]
  deliveryIntervalsFetchError: RequestError | null

  // CONTAINERS
  containersLoading: ClientSystem.Loading
  containersData: Container[]
  containersFetchError: RequestError | null
}

export const initialState: DictionariesState = {
  deliveryIntervalsLoading: 'idle',
  deliveryIntervalsData: [],
  deliveryIntervalsFetchError: null,

  containersLoading: 'idle',
  containersData: [],
  containersFetchError: null,
}

const dictionariesSlice = createSlice({
  name: 'dictionaries',
  initialState,
  reducers: {
    deliveryIntervalsLoading(state) {
      state.deliveryIntervalsLoading = 'pending'
    },
    deliveryIntervalsResolve(
      state,
      action: PayloadAction<DictionariesState['deliveryIntervalsData']>,
    ) {
      state.deliveryIntervalsData = action.payload
      state.deliveryIntervalsLoading = 'resolved'
      state.deliveryIntervalsFetchError = initialState.deliveryIntervalsFetchError
    },
    deliveryIntervalsReject(
      state,
      action: PayloadAction<DictionariesState['deliveryIntervalsFetchError']>,
    ) {
      state.deliveryIntervalsLoading = 'rejected'
      state.deliveryIntervalsFetchError = action.payload
    },
    deliveryIntervalsActionUpdateResolve(
      state,
      action: PayloadAction<DeliveryIntervalData>,
    ) {
      state.deliveryIntervalsData = state.deliveryIntervalsData.map((di) =>
        di.id === action.payload.id ? { ...di, ...action.payload } : di,
      )
    },
    deliveryIntervalsActionCreateResolve(
      state,
      action: PayloadAction<DeliveryIntervalData>,
    ) {
      state.deliveryIntervalsData = [action.payload].concat(state.deliveryIntervalsData)
    },
    deliveryIntervalsDeleteResolve(
      state,
      action: PayloadAction<DeliveryIntervalData['id']>,
    ) {
      state.deliveryIntervalsData = state.deliveryIntervalsData.filter(
        (c) => c.id !== action.payload,
      )
    },

    // CONTAINERS
    containersLoading(state) {
      state.containersLoading = 'pending'
    },
    containersResolve(state, action: PayloadAction<DictionariesState['containersData']>) {
      state.containersData = action.payload
      state.containersLoading = 'resolved'
      state.containersFetchError = initialState.containersFetchError
    },
    containersReject(
      state,
      action: PayloadAction<DictionariesState['containersFetchError']>,
    ) {
      state.containersLoading = 'rejected'
      state.containersFetchError = action.payload
    },
    containersActionUpdateResolve(state, action: PayloadAction<Container>) {
      state.containersData = state.containersData.map((di) =>
        di.id === action.payload.id ? { ...di, ...action.payload } : di,
      )
    },
    containersActionCreateResolve(state, action: PayloadAction<Container>) {
      state.containersData = [action.payload].concat(state.containersData)
    },
    containersActionDeleteResolve(state, action: PayloadAction<Container['id']>) {
      state.containersData = state.containersData.filter((c) => c.id !== action.payload)
    },
    clear(state) {
      return initialState
    },
  },
})

export default dictionariesSlice
