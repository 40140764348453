import React, { useEffect, useState } from 'react'

import {
  Block,
  Button,
  Divider,
  Flexbox,
  modal,
  ScrollView,
  Spinner,
  Text,
} from '@stage-ui/core'
import { Done } from '@stage-ui/icons'
import useSelector from 'hooks/useSelector'

import { objectEqual } from 'utils/objectEqual'
import { DevicesActions } from 'actions'

type DevicePrinterModalProps = {
  close: () => void
  onPrint: (deviceId: string) => void
}

export const DevicePrinterModal: React.FC<DevicePrinterModalProps> = (props) => {
  const { close, onPrint } = props

  const { devices, loading } = useSelector(
    (state) => ({
      devices: state.devices.data.filter(
        (device) => device.active && device.type === 'PRINTER',
      ),
      loading: state.devices.loading,
    }),
    objectEqual,
  )
  const [deviceId, setDeviceId] = useState(localStorage.getItem('defaultPrinterId'))

  useEffect(() => {
    if (!devices.length) {
      DevicesActions.fetch()
    }
  }, [devices.length])

  const handlePrint = () => {
    if (deviceId) {
      onPrint(deviceId)
    }
  }

  return (
    <Flexbox column>
      <Block
        decoration="surface"
        borderRadius="m"
        borderColor="gray200"
        h="30vh"
        style={{
          overflow: 'hidden',
        }}
      >
        <ScrollView xBarPosition="none" size="xs" h="100%" barOffset={4}>
          {devices.map((device, index) => (
            <React.Fragment key={device.id}>
              <Flexbox
                p="s"
                backgroundColor={device.id === deviceId ? 'green50' : 'transparent'}
                onClick={() => {
                  setDeviceId(device.id)
                  localStorage.setItem('defaultPrinterId', device.id)
                }}
                alignItems="center"
                justifyContent="space-between"
              >
                <Flexbox alignItems="center">
                  <Text mr="s" size="s" color="gray900" weight={600}>
                    {device.name}
                  </Text>
                </Flexbox>
                {device.id === deviceId && <Done color="primary" size="1.25rem" />}
              </Flexbox>
              {devices.length !== index && <Divider color="gray200" />}
            </React.Fragment>
          ))}
          {loading !== 'resolved' && (
            <Flexbox centered p="m">
              <Spinner />
            </Flexbox>
          )}
        </ScrollView>
      </Block>
      <Flexbox mt="l" justifyContent="flex-end">
        <Button decoration="text" color="gray500" label="Отмена" onClick={close} mr="m" />
        <Button
          disabled={devices.findIndex(({ id }) => id === deviceId) < 0}
          label="Подтвердить"
          onClick={handlePrint}
        />
      </Flexbox>
    </Flexbox>
  )
}

export const openDevicePrinterModal = (
  onConfirm: (deviceId: string) => void,
  e?: React.MouseEvent<HTMLDivElement, MouseEvent>,
) => {
  e?.stopPropagation()
  e?.preventDefault()

  modal({
    title: 'Выбор принтера',
    subtitle: 'Для печати этикеток выберите принтер',
    w: '30rem',
    overlayClose: false,
    render: (close) => (
      <DevicePrinterModal
        close={close}
        onPrint={(deviceId) => {
          close()
          onConfirm(deviceId)
        }}
      />
    ),
  })
}
