import React, { useState } from 'react'

import { Button, useTheme } from '@stage-ui/core'
import moment from 'moment'
import { OrderPageDelivery } from 'store/delivery'
import { DeliveryActions } from 'actions'
import { printElement } from 'utils/printElement'

import { OrderFilter } from '@foods-n-goods/server/generated/schema'

import useSelector from 'hooks/useSelector'

import { useMouseDropMenu } from 'components/MouseMenuDrop'
import TwoDots from 'components/Icons/TwoDots'

import { objectEqual } from 'utils/objectEqual'

import { filterWithStatus } from 'pages/logistics/misc'

import { DeliveryStore } from 'store'

import { openOrderCourierChangeModal } from 'components/Modals/OrderCourierChange'

import PrintTable, { printTableId } from './PrintTable'

export function LogisticsMenuContextMenu() {
  const { color } = useTheme()

  const { filter, coordinatorCheckedOrders, loading } = useSelector(
    (state) => ({
      filter: state.delivery.filter,
      coordinatorCheckedOrders: state.delivery.orderPage.records.filter((order) => {
        return state.delivery.coordinatorCheckedOrders.includes(order.id)
      }),
      loading: state.delivery.loading === 'idle' || state.delivery.loading === 'pending',
    }),
    objectEqual,
  )

  const [dataForPrint, setDataForPrint] = useState<OrderPageDelivery | null>(null)

  const printOrderList = async () => {
    if (!filter.date || loading) return

    const filterParams: OrderFilter = {
      onlyActive: true,
      includeContainers: false,
      includePositions: false,
      includePositionStat: false,
      ...filterWithStatus[filter.ordersTab],
    }

    filterParams.preferredDeliveryDateFrom = moment(filter.date).startOf('day').toDate()
    filterParams.preferredDeliveryDateTo = moment(filter.date).endOf('day').toDate()

    setDataForPrint(
      await DeliveryActions.fetchOrders(
        { filter: filterParams, page: 1, pageSize: 10000 },
        true,
      ),
    )
    printElement(printTableId)
  }

  const contextMenu = useMouseDropMenu<HTMLButtonElement>(
    [
      {
        text: 'Печать',
        value: 'print',
        icon: 'Printer',
        hidden: filter.section === 'couriers',
      },
      {
        text: 'Автоназначение водителей',
        value: 'courierAutoAssign',
        icon: 'Sync',
        hidden: filter.section === 'orders' || filter.section === 'couriers',
      },
      {
        text: 'Назначить водителя',
        value: 'courierAssign',
        icon: 'Car2',
        hidden: filter.section === 'orders' || filter.section === 'couriers',
        disabled: coordinatorCheckedOrders.length === 0,
      },
      {
        text: 'Показывать завершенные',
        value: 'coordinatorShowAll',
        icon: filter.coordinatorShowAll ? 'CheckmarkSquare' : 'Square',
        color: filter.coordinatorShowAll ? 'green500' : 'surface',
        hidden: filter.section !== 'coordinator',
      },
      {
        text: 'Учитывать порядок доставки',
        value: 'sortBySequance',
        icon: filter.sortBySequance ? 'CheckmarkSquare' : 'Square',
        color: filter.sortBySequance ? 'green500' : 'surface',
        hidden: filter.section !== 'coordinator',
      },
    ],
    ({ value }) => {
      if (value === 'print') {
        if (filter.section === 'orders') printOrderList()
        if (filter.section === 'coordinator') {
          printElement()
        }
      }
      if (value === 'courierAutoAssign') {
        if (!filter.date) return
        DeliveryActions.courierAutoAssign({
          preferredDeliveryDateFrom: moment(filter.date).startOf('day').toDate(),
          preferredDeliveryDateTo: moment(filter.date).endOf('day').toDate(),
        })
      }
      if (value === 'courierAssign') {
        openOrderCourierChangeModal(coordinatorCheckedOrders)
      }
      if (value === 'coordinatorShowAll') {
        DeliveryActions.setFilter({
          coordinatorShowAll: !filter.coordinatorShowAll,
        })
      }
      if (value === 'sortBySequance') {
        DeliveryStore.setFilter({
          sortBySequance: !filter.sortBySequance,
        })
      }
    },
  )

  return (
    <>
      {dataForPrint && <PrintTable data={dataForPrint} header={filter.date} />}
      <Button
        ml="xs"
        w="2rem"
        h="2rem"
        decoration="text"
        p={0}
        color="gray900"
        {...contextMenu}
      >
        <TwoDots w="1.5rem" h="1.5rem" fillColor={color.gray[900].hex()} />
      </Button>
    </>
  )
}
