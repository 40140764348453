import React, { useEffect, useMemo } from 'react'

import { Block, Flexbox, Paragraph, ScrollView, Table } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import { List } from '@stage-ui/icons'
import { DeliveryActions } from 'actions'
import { PageSwitch } from 'components/PageSwitch'
import useSelector from 'hooks/useSelector'
import { ym } from 'pages/logistics/MapView/hooks/useYandexMaps'
import { OrderDelivery } from 'store/delivery'

import { PageLoading } from 'components/PageLoading'

import { objectEqual } from 'utils/objectEqual'

import { sortByCourierId } from '../../Menu/PrintTable'

import { OrderCell } from './misc/OrderCell'

export function LogisticsOrders() {
  const { filter, ordersSelected, loading, total, records } = useSelector(
    (state) => ({
      filter: state.delivery.filter,
      ordersSelected: state.delivery.ordersSelected,
      loading: state.delivery.loading === 'idle' || state.delivery.loading === 'pending',
      total: state.delivery.orderPage.total,
      records: state.delivery.orderPage.records.map((order) => ({
        ...order,
        positions: [],
      })),
    }),
    objectEqual,
  )

  const sortedOrders = useMemo(() => sortByCourierId(records), [records])

  const getAssociatedOrderIds = (od: OrderDelivery) =>
    records
      .filter(
        (o) =>
          od.deliveryAddress.lat === o.deliveryAddress.lat &&
          od.deliveryAddress.lng === o.deliveryAddress.lng,
      )
      .map(({ id }) => id)

  const columns: Array<TableTypes.TableColumn<OrderDelivery>> = [
    {
      key: 'id',
      primary: true,
      render: OrderCell,
    },
  ]

  const onRowClick = (ctx: TableTypes.TableRowContext<OrderDelivery>) => {
    DeliveryActions.setActiveOrders(getAssociatedOrderIds(ctx.row))
    DeliveryActions.setActiveCouriers([])
    ym.ref?.focus(getAssociatedOrderIds(ctx.row), true)
    ym.ref?.trackClear()
  }

  const onRowEnter = (ctx: TableTypes.TableRowContext<OrderDelivery>) => {
    ym.ref?.hover(getAssociatedOrderIds(ctx.row))
  }

  const onRowLeave = (ctx: TableTypes.TableRowContext<OrderDelivery>) => {
    ym.ref?.hover(getAssociatedOrderIds(ctx.row), false)
  }

  useEffect(() => {
    return () => {
      DeliveryActions.setFilter({
        ordersTab: 'all',
        ordersPage: 1,
        ordersPageSize: 40,
      })
    }
  }, [])

  return (
    <PageLoading loading={loading}>
      <ScrollView
        mode="hidden"
        style={{
          height: 'calc(100vh - 11.5rem)',
        }}
      >
        <Table
          data={loading ? [] : sortedOrders}
          columns={columns}
          onRowClick={onRowClick}
          selected={sortedOrders.filter((e) => ordersSelected.includes(e.id))}
          onRowMouseEnter={onRowEnter}
          onRowMouseLeave={onRowLeave}
          overrides={(t) => ({
            headCell: {
              padding: 0,
            },
            row: (props) => [
              props.selected && {
                backgroundColor: `${t.color.green[50].hex()} !important`,
              },
            ],
            rowCell: {
              padding: '0 !important',
            },
          })}
        />
        {!total && (
          <Flexbox
            column
            centered
            p="m"
            decoration="surface"
            borderRadius="s"
            borderColor="gray200"
          >
            <List size="1.5rem" color="gray300" />
            <Paragraph size="s" color="gray400">
              {loading ? 'Загрузка...' : 'Нет заказов'}
            </Paragraph>
          </Flexbox>
        )}
        <PageSwitch
          pageSize={filter.ordersPageSize}
          page={filter.ordersPage}
          total={total}
          onChange={({ page, pageSize }) =>
            DeliveryActions.setFilter({
              ordersPage: page,
              ordersPageSize: pageSize,
            })
          }
        />
        {total <= filter.ordersPageSize && <Block h="1rem" />}
      </ScrollView>
    </PageLoading>
  )
}
