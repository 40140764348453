import React from 'react'

import { Order } from '@foods-n-goods/server/generated/schema'
import { Divider, Flexbox } from '@stage-ui/core'

import { OrderPositions } from './Positions'
import { OrderClientDetails } from './OrderClientDetails'
import { OrderCardDetails } from './OrderCardDetails'
import { OrderStatusDetails } from './OrderStatusDetails'

type OrderViewGeneralInformationProps = {
  order: Order
}

export function OrderGeneralInformation(props: OrderViewGeneralInformationProps) {
  const { order } = props

  return (
    <Flexbox column>
      <Flexbox>
        <Flexbox
          column
          decoration="surface"
          p="1.25rem"
          borderRadius="s"
          borderColor="gray200"
          borderWidth="1px"
          borderStyle="solid"
          flex={1}
        >
          <OrderClientDetails order={order} />
          <Divider my="l" />
          <OrderStatusDetails order={order} />
        </Flexbox>
        <OrderCardDetails order={order} />
      </Flexbox>
      <OrderPositions order={order} />
    </Flexbox>
  )
}
