import React, { useState } from 'react'

import { modal } from '@stage-ui/core'

import { ReportAvailable } from '@foods-n-goods/server/generated/schema'

import { NewReportSelect } from './NewReportSelect'
import { NewReportConfigure } from './NewReportConfigure'

type NewReportProps = {
  close: () => void
}

function NewReport(props: NewReportProps) {
  const [report, setReport] = useState<ReportAvailable | null>(null)
  const { close } = props

  if (report) {
    return <NewReportConfigure close={close} report={report} />
  }
  return <NewReportSelect close={close} onSelect={setReport} />
}

export const newReportDisplay = () => {
  modal({
    overlayClose: false,
    title: 'Новый отчёт',
    w: '30rem',
    render: (close) => <NewReport close={close} />,
  })
}
