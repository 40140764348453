import React from 'react'

import TableTypes from '@stage-ui/core/data/Table/types'
import { StatusIndicator } from 'components/StatusIndicator'
import { Order } from '@foods-n-goods/server/generated/schema'

export function OrderStatusLabelCell(ctx: TableTypes.TableCellContext<Order>) {
  const { row } = ctx
  const { status } = row
  return <StatusIndicator status={status} w="fit-content" />
}
