import React, { ChangeEvent, useState } from 'react'

import {
  Block,
  Flexbox,
  Spinner,
  Text,
  TextField,
  useTheme,
  ScrollView,
} from '@stage-ui/core'
import BlockTypes from '@stage-ui/core/layout/Block/types'

import { Data } from '..'

interface AddressModalFormProps extends BlockTypes.Props {
  setAddressManual: (address: string) => Promise<void>
  setAddressOptions: (e: ChangeEvent<HTMLInputElement>) => Promise<void>
  data: Data
  suggestions: string[]
  disabled: boolean
}

function AddressModalForm({
  setAddressManual,
  setAddressOptions,
  data,
  suggestions,
  disabled,
}: AddressModalFormProps) {
  const { address, busy } = data
  const { color } = useTheme()
  const [openedDropdown, setOpenedDropdown] = useState(Boolean(suggestions.length))

  return (
    <>
      <TextField
        disabled={disabled}
        placeholder="Адрес"
        value={address}
        onChange={setAddressOptions}
        onClick={() => setOpenedDropdown(true)}
        rightChild={busy && <Spinner />}
      />
      {suggestions.length > 0 && (
        <Block
          decoration="surface"
          shadow="m"
          style={{
            height:
              suggestions.length === 0 ? '5rem' : `calc(${suggestions.length} * 3.5rem)`,
            maxHeight: '15rem',
            borderRadius: '0.25rem',
            position: 'absolute',
            top: '3rem',
            left: 0,
            right: 0,
            zIndex: 250,
            display: openedDropdown ? 'visible' : 'none',
          }}
        >
          <ScrollView size="s" barOffset={4}>
            {suggestions.length > 0 &&
              suggestions.map((sug, index) => (
                <Flexbox
                  key={`${sug}_${index.toString()}`}
                  alignItems="center"
                  p="s m"
                  style={{
                    borderBottomWidth: index + 1 === suggestions.length ? 0 : '1px',
                    borderBottomStyle: 'solid',
                    borderBottomColor: color.gray[300].hex(),
                    cursor: 'pointer',
                    ':hover': {
                      backgroundColor: color.green[50].hex(),
                    },
                  }}
                  onClick={() => {
                    setAddressManual(sug)
                    setOpenedDropdown(false)
                  }}
                >
                  <Text size="s" color="gray900">
                    {sug}
                  </Text>
                </Flexbox>
              ))}
          </ScrollView>
        </Block>
      )}
    </>
  )
}

export default AddressModalForm
