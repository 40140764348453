import React from 'react'

import TableTypes from '@stage-ui/core/data/Table/types'
import { rub } from 'utils/rub'
import { Flexbox, Text } from '@stage-ui/core'
import { Order } from '@foods-n-goods/server/generated/schema'

export function OrderTotalCell(ctx: TableTypes.TableCellContext<Order>) {
  const { row } = ctx
  const { amount, amountAfter } = row
  const [priceRub, priceKop] = rub(amountAfter || amount).split(',')

  return (
    <Flexbox alignItems="center">
      <Text size="s" color="gray900">
        {priceRub}.{priceKop.split(/\s+/)[0]}
      </Text>
    </Flexbox>
  )
}
