import React from 'react'

import { DatePicker, Select } from '@stage-ui/core'
import useSelector from 'hooks/useSelector'
import moment from 'moment'
import { objectEqual } from 'utils/objectEqual'
import { ClientActions } from 'actions'
import { OrderStatusCode } from '@foods-n-goods/client/system/types'

export function ClientDetailsFilter() {
  const { filter, statuses } = useSelector(
    (state) => ({
      filter: state.client.filter,
      statuses: state.app.meta?.statuses,
    }),
    objectEqual,
  )

  if (!statuses) return null

  const selectedStatuses = statuses.filter((s) => s.value === filter.detailsOrderStatus)

  return (
    <>
      <DatePicker
        range
        clearable
        size="s"
        type="day"
        format="DD MMM"
        placeholder="Период"
        defaultValue={
          filter.detailsOrderCreateDateStart && filter.detailsOrderCreateDateEnd
            ? [
                new Date(filter.detailsOrderCreateDateStart),
                new Date(filter.detailsOrderCreateDateEnd),
              ]
            : undefined
        }
        onChangeRange={([from, to]) => {
          if (from && to) {
            ClientActions.setFilter({
              detailsOrderPage: 1,
              detailsOrderCreateDateStart: moment(from).format('YYYY-MM-DD'),
              detailsOrderCreateDateEnd: moment(to).format('YYYY-MM-DD'),
            })
          }
        }}
        onClear={() => {
          ClientActions.setFilter({
            detailsOrderPage: 1,
            detailsOrderCreateDateStart: undefined,
            detailsOrderCreateDateEnd: undefined,
          })
        }}
        overrides={{
          field: {
            transition: 'background .2s ease-in-out',
            background:
              filter.detailsOrderCreateDateStart && filter.detailsOrderCreateDateEnd
                ? 'white'
                : 'transparent',
            ':focus-within': {
              background: 'white',
            },
            minWidth: '13.25rem',
          },
        }}
      />

      <Select
        size="s"
        clearable
        maxScrollHeight="20rem"
        placeholder="Статус заказа"
        options={statuses}
        values={selectedStatuses}
        onChange={(options) => {
          if (!options[0]) return
          ClientActions.setFilter({
            detailsOrderPage: 1,
            detailsOrderStatus: options[0]?.value as OrderStatusCode,
          })
        }}
        onClear={() => {
          ClientActions.setFilter({
            detailsOrderPage: 1,
            detailsOrderStatus: undefined,
          })
        }}
        overrides={{
          field: {
            transition: 'background .2s ease-in-out',
            background: filter.detailsOrderStatus ? 'white' : 'transparent',
            ':focus-within': {
              background: 'white',
            },
            minWidth: '13.25rem',
          },
        }}
      />
    </>
  )
}
