import React from 'react'

import { Text } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import { PurchaseExtended } from 'pages/purchases/data'

export function NameCell(ctx: TableTypes.TableCellContext<PurchaseExtended>) {
  const { row } = ctx
  const { product } = row

  return (
    <Text
      size="s"
      color={row._isExpandable ? 'gray900' : 'gray600'}
      weight={row._isExpandable ? 700 : 500}
    >
      {product.name}
    </Text>
  )
}
