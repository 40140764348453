import React, { useEffect } from 'react'

import { Text, Flexbox } from '@stage-ui/core'
import TypographyTypes from '@stage-ui/core/basic/Typography/types'
import FlexboxTypes from '@stage-ui/core/layout/Flexbox/types'

import { useMouseDrop } from './MouseDrop'

type ColorTextTypes = FlexboxTypes.Props & {
  size?: TypographyTypes.Props['size']
  color?: TypographyTypes.Props['color']
  weight?: TypographyTypes.Props['weight']
  background?: string
  hint?: string
}

export const ColorText: React.FC<ColorTextTypes> = (props) => {
  const {
    children,
    backgroundColor = 'gray200',
    size = 'm',
    color = 'gray900',
    weight = 500,
    background,
    hint,
    ...flexProps
  } = props

  const hintProps = useMouseDrop(hint)
  useEffect(() => {
    if (!hintProps.onMouseLeave) {
      return
    }

    return () => {
      hintProps.onMouseLeave?.()
    }
  }, [hintProps.onMouseLeave])

  return (
    <Flexbox
      centered
      borderRadius="s"
      backgroundColor={backgroundColor}
      style={[
        [
          {
            boxSizing: 'border-box',
            whiteSpace: 'nowrap',
            background: `${background as string} !important`,
          },
          flexProps.borderColor && {
            borderStyle: 'solid',
            borderWidth: '1px',
          },
        ],
      ]}
      {...hintProps}
      {...flexProps}
    >
      <Text size={size} color={color} weight={weight} lineHeight={1.2}>
        {children}
      </Text>
    </Flexbox>
  )
}
