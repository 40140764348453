import React from 'react'

import { Text } from '@stage-ui/core'
import TextTypes from '@stage-ui/core/content/Text/types'

type QualityClientNameProps = {
  name: string
} & TextTypes.Props

const QualityClientName: React.FC<QualityClientNameProps> = (props) => {
  const { name, ...textProps } = props

  return (
    <Text size="xs" color="gray500" {...textProps}>
      {name}
    </Text>
  )
}

export default QualityClientName
