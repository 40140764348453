import React from 'react'

import { Block, Flexbox } from '@stage-ui/core'
import BlockTypes from '@stage-ui/core/layout/Block/types'

import { PageHeader } from './common/PageHeader'

type SectionProps = BlockTypes.Props & {
  title?: string
  titleComponent?: React.ReactNode
  actions?: React.ReactNode
  divider?: boolean
}

export const Section: React.FC<SectionProps> = (props) => {
  const {
    title,
    titleComponent,
    actions,
    divider = true,
    children,
    ...containerProps
  } = props

  return (
    <>
      <PageHeader title={title} titleComponent={titleComponent} divider={divider} />
      <Block p="0 l l l" {...containerProps}>
        {actions && (
          <Flexbox p="0 l" mb="m">
            {actions}
          </Flexbox>
        )}
        {children}
      </Block>
    </>
  )
}
