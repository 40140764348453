import React from 'react'

import { OrderActions } from 'actions'
import { PageSwitch } from 'components/PageSwitch'
import useSelector from 'hooks/useSelector'

import { objectEqual } from 'utils/objectEqual'

export function OrderListTablePagination() {
  const { pageSize, page, total } = useSelector(
    (state) => ({
      pageSize: state.order.filter.pageSize,
      page: state.order.filter.page,
      total: state.order.orderPage.total,
    }),
    objectEqual,
  )

  return (
    <PageSwitch
      total={total}
      pageSize={pageSize}
      page={page}
      onChange={(args) => OrderActions.setFilter(args)}
    />
  )
}
