import React, { useEffect, useState } from 'react'

import { Order, SelectItem } from '@foods-n-goods/server/generated/schema'
import { Button, Flexbox, modal, Text, useTheme } from '@stage-ui/core'
import { Done, Info } from '@stage-ui/icons'
import { OrderActions } from 'actions'
import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'

type OrderStatusModalProps = {
  close: () => void
  orders: Array<Order>
}

export function OrderStatusModal(props: OrderStatusModalProps) {
  const { close, orders } = props

  const [status, setStatus] = useState<SelectItem>()
  const theme = useTheme()

  useEffect(() => {
    if (orders.length === 1 && orders[0].status) {
      setStatus(orders[0].status)
    }
  }, [])

  const statuses = useSelector((state) => state.app.meta?.statuses || [], objectEqual)

  const handleConfirm = () => {
    if (!status) return

    OrderActions.setStatus({
      ids: orders.map((order) => order.id),
      status: status.value,
    })
    close()
  }

  return (
    <Flexbox column>
      <Flexbox
        backgroundColor="gray100"
        p="m"
        mb="l"
        justifyContent="flex-start"
        alignItems="center"
        borderRadius="m"
        borderStyle="solid"
        borderColor="gray300"
        borderWidth="1px"
      >
        <Info size="1.25rem" color="orange500" mr="m" />
        <Text size="s" weight={300} color="orange500">
          При сохранении статус будет обновлён!
        </Text>
      </Flexbox>
      <Flexbox wrap="wrap" style={{ gap: 8 }}>
        {statuses.map((s) => (
          <Flexbox
            key={s.value}
            backgroundColor={s.code === status?.code ? 'green50' : 'gray200'}
            onClick={() => {
              setStatus(s)
            }}
            p="0.375rem s"
            borderRadius={theme.radius.s}
          >
            <Text size="s" color={theme.color.gray[900].hex()} mr="s">
              {s.text}
            </Text>
            <Flexbox
              column
              style={{ height: 20, width: 14 }}
              alignItems="center"
              justifyContent="flex-start"
            >
              {s.code === status?.code && <Done color="primary" size="1.25rem" />}
              {orders.length === 1 &&
                orders[0].status.code === s.code &&
                orders[0].status.code !== status?.code && (
                  <Done color="gray400" size="1.25rem" />
                )}
            </Flexbox>
          </Flexbox>
        ))}
      </Flexbox>
      <Flexbox justifyContent="flex-end" mt="1.5rem">
        <Button
          color="gray500"
          textColor="gray500"
          decoration="text"
          label="Отмена"
          mr="m"
          onClick={close}
        />
        <Button label="Сохранить" onClick={handleConfirm} />
      </Flexbox>
    </Flexbox>
  )
}

export const openOrderStatusChangeModal = (
  orders: Order[],
  e?: React.MouseEvent<HTMLDivElement, MouseEvent>,
) => {
  e?.stopPropagation()
  e?.preventDefault()

  modal({
    title: `Смена статус${orders.length > 1 ? 'ов' : 'а'}`,
    subtitle:
      orders.length === 1
        ? `Заказ [#${orders[0].id}] ${orders[0].client.name ?? '-'}`
        : '',
    w: '30rem',
    backgroundColor: 'gray50',
    overrides: { header: { marginBottom: '1rem' } },
    overlayClose: false,
    render: (close) => <OrderStatusModal close={close} orders={orders} />,
  })
}
