import { Block } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import { Table } from 'components/Table'

import Empty from './Empty'

type PageTableProps<T> = TableTypes.Props<T> & {
  filter?: React.ReactNode
  noDataText?: string
  pages?: React.ReactNode
  overrides?: TableTypes.Props<any>['overrides']
}

export function PageTable<T extends {}>(props: PageTableProps<T>) {
  const { filter, noDataText, pages, ...rest } = props

  return (
    <Block>
      {filter}
      {rest.data.length ? <Table {...rest} /> : <Empty text={noDataText} />}
      {pages}
    </Block>
  )
}
