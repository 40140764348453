/* eslint-disable @typescript-eslint/no-unsafe-call */
import React from 'react'

import { Block } from '@stage-ui/core'
import { DeliveryActions } from 'actions'

import { CourierRenderHelper } from './helpers/CourierRenderHelper'
import { OrderRenderHelper } from './helpers/OrderRenderHelper'
import { RouteRemoveHelper } from './helpers/RouteRemoveHelper'
import { useYandexMap, ym } from './hooks/useYandexMaps'
import { MapPannel } from './MapPannel'
import { RouteBanner } from './RouteBanner'

export function Mapview() {
  const [map, ref] = useYandexMap({
    width: '100%',
    height: '100%',
    onMapClick: () => {
      DeliveryActions.setActiveOrders([])
      ym.ref?.focus([], false)
    },
    onClusterSelect: (orders, placemarks, e) => {
      e.stopPropagation()
      DeliveryActions.setActiveOrders(orders.map(({ id }) => id))
    },
    onCourierClusterSelect: (couriers, placemarks, e) => {
      e.stopPropagation()
      DeliveryActions.setActiveCouriers(couriers.map(({ id }) => id))
    },
    onPlacemarkSelect: (orders, placemark, e) => {
      e.stopPropagation()
      DeliveryActions.setActiveOrders(orders.map(({ id }) => id))
    },
    onCourierSelect: ({ id }, placemark, e) => {
      e.stopPropagation()
      DeliveryActions.setActiveCouriers([id])
    },
    onClusterZoom: (e) => {
      e.stopPropagation()
      DeliveryActions.setActiveOrders([])
      ym.ref?.focus([], false)
    },
    onRouteSet: (route) => {
      DeliveryActions.courierRouteSet(route)
    },
    onRouteRemove: () => {
      DeliveryActions.courierRouteRemove()
    },
  })

  return (
    <Block
      flex={1}
      borderRadius="0.25rem 0 0 0"
      w="calc(100vw - 50rem)"
      style={{
        overflow: 'hidden',
        '@media print': {
          display: 'none',
        },
      }}
    >
      {map}
      {!!ref && (
        <>
          <CourierRenderHelper />
          <OrderRenderHelper />
          <RouteRemoveHelper />
          <MapPannel />
          <RouteBanner
            onClick={() => {
              ref?.courierRouteFocus()
            }}
            onClose={() => {
              DeliveryActions.courierRouteSet(null)
              ref?.courierRouteRemove()
            }}
          />
        </>
      )}
    </Block>
  )
}
