import React from 'react'

import { Staff, StaffInput } from '@foods-n-goods/server/generated/schema'
import { Block, Divider, Flexbox, ScrollView, Text } from '@stage-ui/core'
import useSelector from 'hooks/useSelector'
import { Done } from '@stage-ui/icons'
import { objectEqual } from 'utils/objectEqual'

type StaffColorUpdateProps = {
  value?: Staff['role']['value'] | null
  onChange: KeyValueChangeFunction<StaffInput>
}

export function StaffColorUpdate(props: StaffColorUpdateProps) {
  const { value, onChange } = props

  const colorsData = useSelector((state) => state.app.meta?.colors || [], objectEqual)
  const colors = colorsData
    .slice()
    .sort((a, b) => (a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1))

  return (
    <Block
      decoration="surface"
      borderRadius="m"
      borderColor="gray200"
      h="30vh"
      style={{
        overflow: 'hidden',
      }}
    >
      <ScrollView xBarPosition="none" size="xs" barOffset={4}>
        {colors.map((color, index) => (
          <React.Fragment key={color.value}>
            <Flexbox
              p="s m"
              backgroundColor={color.value === value ? 'green50' : 'transparent'}
              onClick={() => {
                onChange('color', color.value)
              }}
              alignItems="center"
              justifyContent="space-between"
            >
              <Flexbox alignItems="center" flex={1}>
                <Flexbox
                  w="0.875rem"
                  h="0.875rem"
                  borderRadius="50%"
                  borderColor={color.code || 'gray900'}
                  borderWidth="0.125rem"
                  borderStyle="solid"
                  backgroundColor="white"
                  mr="s"
                  centered
                >
                  <Block
                    w="0.625rem"
                    h="0.625rem"
                    borderRadius="50%"
                    backgroundColor={color.code || 'gray900'}
                  />
                </Flexbox>
                <Text color={color.code || 'gray900'}>{color.text}</Text>
              </Flexbox>
              {color.value === value && <Done color="primary" size="1.25rem" />}
            </Flexbox>
            {colors.length !== index + 1 && <Divider h="0.0625rem" color="gray200" />}
          </React.Fragment>
        ))}
      </ScrollView>
    </Block>
  )
}
