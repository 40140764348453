import ClientSystem from '@foods-n-goods/client/system/types'
import { ProductReview } from '@foods-n-goods/server/generated/schema'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ReviewState {
  loading: ClientSystem.Loading
  data: ProductReview[]
  error: RequestError | null
  page: number

  actionError: RequestError | null
}

export const initialState: ReviewState = {
  loading: 'idle',
  data: [],
  error: null,
  page: 1,

  actionError: null,
}

const reviewSlice = createSlice({
  name: 'reviews',
  initialState,
  reducers: {
    loading(state) {
      state.loading = 'pending'
    },
    resolve(state, action: PayloadAction<ReviewState['data']>) {
      state.loading = 'resolved'
      state.error = initialState.error
      state.data = action.payload
    },
    reject(state, action: PayloadAction<ReviewState['error']>) {
      state.loading = 'rejected'
      state.error = action.payload
    },

    actionUpdateResolve(state, action: PayloadAction<ProductReview>) {
      state.data = state.data.map((review) =>
        review.id === action.payload.id ? { ...review, ...action.payload } : review,
      )
      state.actionError = initialState.actionError
    },
    actionReject(state, action: PayloadAction<ReviewState['actionError']>) {
      state.actionError = action.payload
    },
    clear(state) {
      return initialState
    },
    resolvePage(
      state,
      action: PayloadAction<{
        data: ReviewState['data']
        page: ReviewState['page']
      }>,
    ) {
      state.data = action.payload.data
      state.page = action.payload.page
    },
  },
})

export default reviewSlice
