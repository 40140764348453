/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react'

import { Block, Flexbox, Paragraph, Select, TextField } from '@stage-ui/core'
import { AppActions } from 'actions'
import useSelector from 'hooks/useSelector'

import { objectEqual } from 'utils/objectEqual'

import { onOffLabels } from '../misc'

export function GeneralAutomation() {
  const { marketConfig } = useSelector(
    (state) => ({
      marketConfig: state.app.marketConfig,
    }),
    objectEqual,
  )

  return (
    <Block decoration="surface" borderColor="gray100" p="l">
      <Paragraph weight={500} size="l" color="gray900" mb="m">
        Автоматизация
      </Paragraph>
      <Flexbox
        alignItems="center"
        mb="m"
        style={(t) => [{ borderBottom: `0.0625rem solid ${t.color.gray[200].hex()}` }]}
      >
        <Paragraph color="gray500" size="s" flex={0.3} mr="m">
          Автоназначение водителя при отправке заказа в сборку
        </Paragraph>
        <Select
          decoration="none"
          options={onOffLabels}
          defaultValues={[
            onOffLabels.find(
              (opt) => opt.value === (marketConfig?.orderCourierAutoAssignOnSort || '1'),
            )!,
          ]}
          onChange={(_, value) => {
            AppActions.marketConfigSet({
              orderCourierAutoAssignOnSort: value?.value as string,
            })
          }}
        />
      </Flexbox>
      <Flexbox
        alignItems="center"
        mb="m"
        style={(t) => [{ borderBottom: `0.0625rem solid ${t.color.gray[200].hex()}` }]}
      >
        <Paragraph color="gray500" size="s" flex={0.3} mr="m">
          Схлопывать накладные по клиенту во вкладке «Водители» раздела «Логистика»
        </Paragraph>
        <Select
          decoration="none"
          options={onOffLabels}
          defaultValues={[
            onOffLabels.find(
              (opt) =>
                opt.value ===
                (marketConfig?.logisticsCourierHideSimilarClientOrders || '1'),
            )!,
          ]}
          onChange={(_, value) => {
            AppActions.marketConfigSet({
              logisticsCourierHideSimilarClientOrders: value?.value as string,
            })
          }}
        />
      </Flexbox>
      <Flexbox
        alignItems="center"
        mb="m"
        style={(t) => [{ borderBottom: `0.0625rem solid ${t.color.gray[200].hex()}` }]}
      >
        <Paragraph color="gray500" size="s" flex={0.3} mr="m">
          Назначать паллеты из справочника
        </Paragraph>
        <Select
          decoration="none"
          options={onOffLabels}
          defaultValues={[
            onOffLabels.find(
              (opt) => opt.value === (marketConfig?.palletsAutomation || '1'),
            )!,
          ]}
          onChange={(_, value) => {
            AppActions.marketConfigSet({
              palletsAutomation: value?.value as string,
            })
          }}
        />
      </Flexbox>
      <Flexbox
        alignItems="center"
        mb="m"
        style={(t) => [{ borderBottom: `0.0625rem solid ${t.color.gray[200].hex()}` }]}
      >
        <Paragraph color="gray500" size="s" flex={0.3} mr="m">
          Умная докупка
        </Paragraph>
        <Select
          decoration="none"
          options={onOffLabels}
          defaultValues={[
            onOffLabels.find(
              (opt) => opt.value === (marketConfig?.autoPurchases || '0'),
            )!,
          ]}
          onChange={(_, value) => {
            AppActions.marketConfigSet({
              autoPurchases: value?.value as string,
            })
          }}
        />
      </Flexbox>
      <Flexbox
        alignItems="center"
        mb="m"
        style={(t) => [{ borderBottom: `0.0625rem solid ${t.color.gray[200].hex()}` }]}
      >
        <Paragraph color="gray500" size="s" flex={0.3} mr="m">
          Отправлять недостающие позиции из других заказов в докупку через (секунд)
        </Paragraph>
        <TextField
          decoration="none"
          p={0}
          placeholder="60"
          defaultValue={marketConfig?.autoPurchasesAwaitTime || 60}
          onChange={(e) => {
            AppActions.marketConfigSet({
              autoPurchasesAwaitTime: e.target.value || '60',
            })
          }}
        />
      </Flexbox>
    </Block>
  )
}
