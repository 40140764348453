import React from 'react'

import { Staff, StaffInput } from '@foods-n-goods/server/generated/schema'
import { Block, Divider, Flexbox, ScrollView, Text } from '@stage-ui/core'
import useSelector from 'hooks/useSelector'
import { Done } from '@stage-ui/icons'
import { objectEqual } from 'utils/objectEqual'

type StaffRoleUpdateProps = {
  value?: Staff['role']['value'] | null
  onChange: KeyValueChangeFunction<StaffInput>
}

export function StaffRoleUpdate(props: StaffRoleUpdateProps) {
  const { value, onChange } = props

  const rolesData = useSelector((state) => state.app.meta?.roles || [], objectEqual)
  const roles = rolesData
    .slice()
    .sort((a, b) => (a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1))

  return (
    <Block
      decoration="surface"
      borderRadius="m"
      borderColor="gray200"
      h="30vh"
      style={{
        overflow: 'hidden',
      }}
    >
      <ScrollView xBarPosition="none" size="xs" barOffset={4}>
        {roles.map((role, index) => (
          <React.Fragment key={role.value}>
            <Flexbox
              p="s m"
              backgroundColor={role.value === value ? 'green50' : 'transparent'}
              onClick={() => {
                onChange('role', role.value)
              }}
              alignItems="center"
              justifyContent="space-between"
            >
              <Flexbox alignItems="center">
                <Text mr="s" size="s" color="gray900" weight={600}>
                  {role.text}
                </Text>
              </Flexbox>
              {role.value === value && <Done color="primary" size="1.25rem" />}
            </Flexbox>
            {roles.length !== index + 1 && <Divider h="0.0625rem" color="gray200" />}
          </React.Fragment>
        ))}
      </ScrollView>
    </Block>
  )
}
