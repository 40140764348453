/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react'

import { Block, Flexbox, Paragraph, Select, TextField } from '@stage-ui/core'
import { AppActions } from 'actions'
import useSelector from 'hooks/useSelector'

import { objectEqual } from 'utils/objectEqual'

import { onOffLabels } from '../misc'

export function GeneralExternalSystem() {
  const { marketConfig } = useSelector(
    (state) => ({
      marketConfig: state.app.marketConfig,
    }),
    objectEqual,
  )

  return (
    <Block decoration="surface" borderColor="gray100" p="l">
      <Paragraph weight={500} size="l" color="gray900" mb="m">
        Настройка 1C
      </Paragraph>
      <Flexbox
        alignItems="center"
        mb="m"
        style={(t) => [{ borderBottom: `0.0625rem solid ${t.color.gray[200].hex()}` }]}
      >
        <Paragraph color="gray500" size="s" flex={0.3} mr="m">
          Синхронизация
        </Paragraph>
        <Select
          decoration="none"
          options={onOffLabels}
          defaultValues={[
            onOffLabels.find(
              (opt) => opt.value === (marketConfig?.sync1cEnabled || '0'),
            )!,
          ]}
          onChange={(_, value) => {
            AppActions.marketConfigSet({
              sync1cEnabled: value?.value as string,
            })
          }}
        />
      </Flexbox>
      <Flexbox
        alignItems="center"
        mb="m"
        style={(t) => [{ borderBottom: `0.0625rem solid ${t.color.gray[200].hex()}` }]}
      >
        <Paragraph color="gray500" size="s" flex={0.3} mr="m">
          IP Whitelist
        </Paragraph>
        <TextField
          decoration="none"
          p={0}
          defaultValue={marketConfig?.sync1cWhitelist || ''}
          onChange={(e) => {
            AppActions.marketConfigSet({
              sync1cWhitelist: e.target.value || '',
            })
          }}
        />
      </Flexbox>
      <Flexbox
        alignItems="center"
        mb="m"
        style={(t) => [{ borderBottom: `0.0625rem solid ${t.color.gray[200].hex()}` }]}
      >
        <Paragraph color="gray500" size="s" flex={0.3} mr="m">
          Фильтр слов в номенклатуре
        </Paragraph>
        <TextField
          decoration="none"
          p={0}
          defaultValue={marketConfig?.filterExchangeProductName || ''}
          onChange={(e) => {
            AppActions.marketConfigSet({
              filterExchangeProductName: e.target.value || '',
            })
          }}
        />
      </Flexbox>
    </Block>
  )
}
