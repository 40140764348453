/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react'

import { Block, Flexbox, Paragraph, Select } from '@stage-ui/core'
import { AppActions } from 'actions'
import useSelector from 'hooks/useSelector'

import { objectEqual } from 'utils/objectEqual'

import { onOffLabels } from '../misc'

export function GeneralCourier() {
  const { marketConfig } = useSelector(
    (state) => ({
      marketConfig: state.app.marketConfig,
    }),
    objectEqual,
  )

  return (
    <Block decoration="surface" borderColor="gray100" p="l">
      <Paragraph weight={500} size="l" color="gray900" mb="m">
        Водитель
      </Paragraph>
      <Flexbox
        alignItems="center"
        mb="m"
        style={(t) => [{ borderBottom: `0.0625rem solid ${t.color.gray[200].hex()}` }]}
      >
        <Paragraph color="gray500" size="s" flex={0.3} mr="m">
          Возможность забрать со склада без проверки
        </Paragraph>
        <Select
          decoration="none"
          options={onOffLabels}
          defaultValues={[
            onOffLabels.find(
              (opt) =>
                opt.value === (marketConfig?.orderCourierPickUpWithoutChecking || '1'),
            )!,
          ]}
          onChange={(_, value) => {
            AppActions.marketConfigSet({
              orderCourierPickUpWithoutChecking: value?.value as string,
            })
          }}
        />
      </Flexbox>
      <Flexbox
        alignItems="center"
        mb="m"
        style={(t) => [{ borderBottom: `0.0625rem solid ${t.color.gray[200].hex()}` }]}
      >
        <Paragraph color="gray500" size="s" flex={0.3} mr="m">
          Возможность самостоятельно снять с себя заказ
        </Paragraph>
        <Select
          decoration="none"
          options={onOffLabels}
          defaultValues={[
            onOffLabels.find(
              (opt) => opt.value === (marketConfig?.orderCourierSelfTakeOff || '0'),
            )!,
          ]}
          onChange={(_, value) => {
            AppActions.marketConfigSet({
              orderCourierSelfTakeOff: value?.value as string,
            })
          }}
        />
      </Flexbox>
    </Block>
  )
}
