import { Client } from 'shared/client'
import { Staff } from 'shared/staff'

export namespace SocketType {
  export enum Events {
    Connect = 'connect',
    Disconnect = 'disconnect',
    ConnectError = 'connect_error',
    /**
     * Hello package
     */
    Hello = 'ev_hello',
    /**
     * Login user
     */
    Login = 'ev_login',
    /**
     * Logout user
     */
    Logout = 'ev_logout',
    /**
     * Online/offline event
     */
    Online = 'ev_online',
    /**
     * Keep alive event
     */
    KeepAlive = 'ev_keep_alive',
    /**
     * Location data event
     */
    GeoData = 'ev_geo_data',
    GetGeoTrackData = 'ev_geo_track_data',
    SubscribeGeoData = 'ev_geo_data_subscripbe',
    UnsubscribeGeoData = 'ev_get_data_unsubscribe',
    /**
     * emit all data for warehouse workers on login
     */
    WarehouseEmit = 'ev_warehouse_emit',
    /**
     * emit all data for couriers on login
     */
    CourierEmit = 'ev_courier_emit',
    /**
     * emit all data for purchases on login
     */
    PurchaseEmit = 'ev_purchase_emit',
    /**
     * Save product weight/quantity
     * + take photo
     */
    WarehouseControl = 'ev_warehouse_control',
    /**
     * scale data
     */
    WarehouseScale = 'ev_warehouse_scale_data',
    /**
     * get one more position to sort
     */
    WarehouseGetPosition = 'ev_warehouse_get_position',
    /**
     * clear all positions for current staff
     */
    WarehouseEndShift = 'ev_warehouse_end_shift',
    /**
     * Add container for order
     */
    WarehouseContainerAdd = 'ev_warehouse_container_add',
  }

  export enum Broadcast {
    /**
     * Order update broadcase
     */
    OrdersUpdate = 'bc_orders_update',
    /**
     * orders/positions stat counters
     */
    OrdersStat = 'bc_orders_stat',
    /**
     * Devices update event
     */
    DevicesUpdate = 'bc_devices_update',
    /**
     * lineProducts when order was updated
     */
    LineProductsUpdate = 'bc_line_products_update',
    /**
     * End shift for warehouse workers
     */
    WarehouseEndShift = 'bc_warehouse_end_shift',
    /**
     * lineProducts id to remove
     */
    OrderRemove = 'bc_order_remove',
    /**
     * CourierOrder when order was updated
     */
    CourierOrderUpdate = 'bc_courier_order_update',
    /**
     * Purchase when order was updated
     */
    PurchaseUpdate = 'bc_purchase_update',
    /**
     * Purchase id to remove
     */
    PurchaseRemove = 'bc_purchase_remove',
    /**
     * Purchase id to remove
     */
    PurchaseRemoveByPosition = 'bc_purchase_remove_by_position',
    /**
     * Devices update event
     */
    MarketConfigUpdate = 'bc_market_config_update',
    /**
     * Purchases stat counters
     */
    PurchasesStat = 'bc_purchase_stat',
    /**
     * Staff update
     */
    StaffUpdate = 'bc_staff_update',
    /**
     * Report update
     */
    ReportUpdate = 'bc_report_update',
  }

  export type OrdersStatWarehouseMetrics = {
    done: number
    purchase: number
    progress: number
    total: number
  }

  export type OrdersStat = {
    positionsTotal: number
    positionsNew: number
    positionsForSorting: number
    positionsSorting: number
    positionsPurchase: number
    positionsDelivery: number
    ordersTotal: number
    ordersNew: number
    ordersWarehouse: number
    ordersDelivery: number
    ordersDelivered: number
    ordersNotDelivered: number
    ordersNotAssigned: number
    ordersAssigned: number
    byWarehouse: Record<number, OrdersStatWarehouseMetrics>
  }

  export type PurchasesStat = {
    all: number
    inRepurchase: number
    confirmed: number
    failed: number
  }

  export type SocketLoginObject = {
    ok: boolean
    message: string
    user: Staff | null
    client: Client | null
  }

  export type WarehouseProductControlData = {
    orderId: string
    orderProductId: string
    productId: string
    scaleId: string | null
    weight: number
    tareWeight: number
    purchaseReq: boolean
    weightControl: number
    manualSubmit: boolean
    takePhoto: boolean
    overwrite: boolean
    autoTare: boolean
  }

  export type WarehouseContainerAddData = {
    orderId: string
  }

  export type Scale = {
    id: string
    name: string
    value: number
    stable: boolean
    online: boolean
  }
}
