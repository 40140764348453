import React from 'react'

import { Order } from '@foods-n-goods/server/generated/schema'
import { Block } from '@stage-ui/core'
import { MessageCircle } from '@stage-ui/icons'
import { useMouseDrop } from 'components/MouseDrop'
import TableTypes from '@stage-ui/core/data/Table/types'
import { openOrderCommentChangeModal } from 'components/Modals/OrderCommentChange'

export function OrderListTableCommentCell(ctx: TableTypes.TableCellContext<Order>) {
  const { row } = ctx
  const { comment } = row

  const hintProps = useMouseDrop(comment || 'Добавить комментарий', {
    drop: {
      align: 'top',
    },
    popover: {
      align: 'bottom',
      style: {
        maxWidth: '35vw',
      },
    },
  })

  const handleChange = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    openOrderCommentChangeModal(row, e)
  }

  return (
    <Block w="1.5rem" h="1.5rem" {...hintProps} onClick={handleChange}>
      <MessageCircle color={comment ? 'blue400' : 'gray300'} size="1.5rem" />
    </Block>
  )
}
