import React from 'react'

import { Flexbox, Grid, ScrollView } from '@stage-ui/core'
import { usePrivateRoutes } from 'Router/MainView'

import { MenuItem } from './components/MenuItem'

const Menu: React.FC<{ compact: boolean }> = (props) => {
  const { compact } = props

  const privateRoutes = usePrivateRoutes()

  return (
    <Flexbox flexGrow={1} style={{ minHeight: compact ? 'auto' : '5rem' }}>
      <ScrollView xBarPosition="none" w="100%" size="s">
        <Grid autoFlow="row" gap="0.125rem">
          {privateRoutes.map(({ value, title, icon }) => (
            <MenuItem
              key={value}
              value={value}
              title={title}
              icon={icon}
              compact={compact}
            />
          ))}
        </Grid>
      </ScrollView>
    </Flexbox>
  )
}

export default Menu
