import React from 'react'

import { Flexbox } from '@stage-ui/core'
import { Camera, Person } from '@stage-ui/icons'

type ImageWrapperProps = {
  image?: string
  size?: string
  round?: boolean
  person?: boolean
  wrapperCss?: React.CSSProperties
}

function ImageWrapper(props: ImageWrapperProps) {
  const { size, round, image, person, wrapperCss = {} } = props
  const Icon = person ? Person : Camera
  return (
    <Flexbox
      borderColor="surface"
      backgroundColor="gray50"
      style={{
        overflow: 'hidden',
        width: size,
        height: size,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: round ? '50%' : '0.5rem',
        borderWidth: round ? '2px' : 0,
        borderStyle: 'solid',
        boxSizing: 'border-box',
        ...wrapperCss,
      }}
    >
      {image ? (
        <div
          css={{
            width: size,
            height: size,
            background: image,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '50% 50%',
          }}
        />
      ) : (
        <Icon
          type={round ? 'filled' : 'outline'}
          size={round ? `calc(${size} / 2 )` : `calc(${size} * 0.65)`}
          color="gray400"
        />
      )}
    </Flexbox>
  )
}

export default ImageWrapper
