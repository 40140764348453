import React from 'react'

import { Device } from '@foods-n-goods/server/generated/schema'
import { Button, Flexbox, Text } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import { Edit, Link2 } from '@stage-ui/icons'

import { updateDeviceDialog } from '../shared/form'

function ConnectionCell(props: { ctx: TableTypes.TableCellContext<Device> }) {
  const { ctx } = props
  if (!ctx.row.active) {
    return (
      <Text size="s" ml="xs" weight={600} color="light">
        Выключены
      </Text>
    )
  }
  return (
    <Flexbox alignItems="center">
      <Link2 color={ctx.row.scale?.online ? 'success' : 'error'} />
      <Text
        size="s"
        ml="xs"
        weight={600}
        color={ctx.row.scale?.online ? 'success' : 'error'}
      >
        {ctx.row.protocol}
      </Text>
    </Flexbox>
  )
}

function ValueCell(props: { ctx: TableTypes.TableCellContext<Device> }) {
  const { ctx } = props
  if (!ctx.row.scale?.online) {
    return (
      <Text weight="bold" color="light">
        -
      </Text>
    )
  }
  return (
    <Text weight="bold" color={ctx.row.scale?.stable ? 'primary' : 'light'}>
      {ctx.row.scale?.value.toFixed(3)} кг
    </Text>
  )
}

export const columns: TableTypes.TableColumn<Device>[] = [
  { key: 'id', title: 'ID', width: '4rem', sort: 'ASC' },
  { key: 'name', width: 200, title: 'Наименование', sort: 'ASC' },
  {
    key: 'online',
    width: 200,
    title: 'Подключение',
    render: (ctx) => <ConnectionCell ctx={ctx} />,
  },
  { key: 'valve', title: 'Текущий вес', render: (ctx) => <ValueCell ctx={ctx} /> },
  {
    key: '_',
    title: '',
    render: (ctx) => (
      <Flexbox justifyContent="flex-end">
        <Button
          ml="s"
          label="Изменить"
          decoration="plain"
          size="s"
          onClick={() => {
            updateDeviceDialog(ctx.row)
          }}
          rightChild={<Edit />}
        />
      </Flexbox>
    ),
  },
]
