import React from 'react'

import { Grid } from '@stage-ui/core'

import { Client } from '@foods-n-goods/server/generated/schema'

import { ClientRating } from './Rating'
import { ClientAverageCheck } from './AverageCheck'
import { ClientCreditLimit } from './CreditLimit'
import { ClientPriority } from './Priority'

type ClientAdditionalProps = {
  client: Client
  isModal: boolean
}

export function ClientAdditional(props: ClientAdditionalProps) {
  const { client, isModal } = props

  return (
    <Grid
      w="22rem"
      gap="1rem 0"
      ml="m"
      h="100%"
      style={{
        position: isModal ? 'relative' : 'sticky',
        top: isModal ? '0' : '10.5rem',
      }}
    >
      <ClientPriority client={client} />
      <ClientAverageCheck client={client} />
      <ClientRating client={client} />
      <ClientCreditLimit client={client} />
    </Grid>
  )
}
