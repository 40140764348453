import React, { useEffect, useState } from 'react'

import { ProductGroup, ProductGroupInput } from '@foods-n-goods/server/generated/schema'
import useSelector from 'hooks/useSelector'
import { useHistory, useParams } from 'react-router-dom'

import { objectEqual } from 'utils/objectEqual'

import { Spinner } from '@stage-ui/core'

import CategoryViewControllers from './Controllers'
import CategoryViewGeneral from './General'

interface CategoryViewProps {
  modal?: boolean
  close?: () => void
  category?: ProductGroup
}

function CategoryView(props: CategoryViewProps) {
  const { close, category } = props
  const { id }: { id: string } = useParams()
  const history = useHistory()

  const { storedCategory, productGroupType } = useSelector(
    ({ app, category, product }) => ({
      productGroupType: app.meta?.productGroupType,
      storedCategory: category.data.records?.find((c) => c.id === id),
    }),
    objectEqual,
  )

  const data = category || storedCategory

  const getInitialInputData = () => ({
    name: data?.name || '',
    type: data?.type || productGroupType![0],
    sortWeight: data?.sortWeight || 0,
    searchWords: data?.searchWords || '',
    published: data?.published || false,
    parentId: data?.parentId || null,
    promoSize: data?.promoSize || false,
    isBundle: data?.isBundle || false,
  })
  const [input, setInput] = useState<ProductGroupInput>(getInitialInputData())

  const onChange: KeyValueChangeFunction<ProductGroupInput> = (key, value) => {
    setInput({ ...input, [key]: value })
  }

  const goBack = close || (() => history.push(`/products`))

  useEffect(() => {
    setInput(getInitialInputData())
  }, [storedCategory])

  if (id && (!storedCategory || !productGroupType)) {
    return <Spinner />
  }

  return (
    <>
      <CategoryViewGeneral
        id={data?.id}
        image={data?.image}
        productGroupType={productGroupType!}
        onChange={onChange}
        {...input}
      />
      <CategoryViewControllers input={input} goBack={goBack} category={data} />
    </>
  )
}

export default CategoryView
