import { Block, useTheme } from '@stage-ui/core'
import ImageController from 'components/ImageController'

type ImageProps = {
  url: string
  published?: boolean
}

export const Image: React.FC<ImageProps> = (props) => {
  const { color } = useTheme()
  const { url, published = false } = props

  return (
    <Block>
      {published && (
        <Block
          w="0.625rem"
          h="0.625rem"
          backgroundColor={published ? 'success' : 'light'}
          borderRadius="50%"
          style={{
            position: 'absolute',
            top: '-0.125rem',
            right: '-0.125rem',
            zIndex: 1,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: color.gray[200].hex(),
          }}
        />
      )}
      <ImageController size="3rem" url={url} readonly hideTitle />
    </Block>
  )
}
