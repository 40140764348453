import React from 'react'

import { Flexbox, Grid } from '@stage-ui/core'

import { Client } from '@foods-n-goods/server/generated/schema'

import { ClientInfo } from './Card'
import { ClientHeader } from './Header'
import { ClientAdditional } from './Additional'

type ClientGeneralInformationProps = {
  client: Client
  isModal: boolean
}

export function ClientGeneralInformation(props: ClientGeneralInformationProps) {
  const { client, isModal } = props

  return (
    <Flexbox alignItems="flex-start">
      <Grid
        flex={1}
        autoFlow="row"
        gap="1rem"
        // style={{ marginTop: isModal ? '-4.25rem' : 'unset' }}
      >
        <ClientHeader client={client} isModal={isModal} />
        <ClientInfo client={client} />
      </Grid>
      <ClientAdditional client={client} isModal={isModal} />
    </Flexbox>
  )
}
