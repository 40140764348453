import React, { useState } from 'react'

import { Client } from '@foods-n-goods/server/generated/schema'
import { Button, Flexbox, modal, Text, TextField } from '@stage-ui/core'
import { Info } from '@stage-ui/icons'
import { ClientActions } from 'actions'

type ClientCredentialsChangeModalProps = {
  client: Client
  close: () => void
}

function ClientCredentialsChangeModal(props: ClientCredentialsChangeModalProps) {
  const { client, close } = props
  const { id } = client

  const [identifier, setIdentifier] = useState(client.identifier)
  const [password, setPassword] = useState('')

  const handleChangeLogin = async () => {
    if (!client || !identifier || !password || password.length <= 3) return

    await ClientActions.update({
      id,
      payload: {
        identifier,
        password,
      },
    })
    close()
  }

  return (
    <Flexbox column>
      <Flexbox
        p="m"
        borderStyle="solid"
        borderWidth="1px"
        borderColor="gray200"
        borderRadius="m"
        backgroundColor="gray100"
        alignItems="center"
      >
        <Info color="orange500" mr="m" size="1.125rem" />
        <Text size="s" color="orange500">
          После изменения клиент не сможет зайти в свой профиль под старым логином, старый
          логин будет заблокирован!
        </Text>
      </Flexbox>
      <TextField
        mt="m"
        value={identifier}
        label="Новый логин"
        placeholder="Логин клиента"
        onChange={(e) => setIdentifier(e.target.value)}
      />
      <TextField
        mt="m"
        value={password}
        label="Новый пароль"
        placeholder="Пароль клиента"
        onChange={(e) => setPassword(e.target.value)}
      />
      <Flexbox mt="xl" alignItems="center" justifyContent="flex-end">
        <Button decoration="text" label="Отмена" onClick={close} color="gray500" />
        <Button label="Подтвердить" onClick={handleChangeLogin} ml="m" />
      </Flexbox>
    </Flexbox>
  )
}

export const openClientCredentialsChangeModal = (
  client: Client,
  e?: React.MouseEvent<HTMLDivElement, MouseEvent>,
) => {
  e?.stopPropagation()
  e?.preventDefault()

  modal({
    w: '35rem',
    title: 'Изменение логина',
    overlayClose: false,
    render: (close) => <ClientCredentialsChangeModal client={client} close={close} />,
  })
}
