import React, { useEffect, useState } from 'react'

import { Promotion, PromotionInput } from '@foods-n-goods/server/generated/schema'
import useSelector from 'hooks/useSelector'
import { useHistory, useParams } from 'react-router-dom'

import { objectEqual } from 'utils/objectEqual'

import PromotionViewControllers from './Controllers'
import PromotionViewGeneral from './General'

interface PromotionViewProps {
  modal?: boolean
  close?: () => void
  promotion?: Promotion
}

function PromotionView(props: PromotionViewProps) {
  const { modal } = props
  const { id }: { id: string } = useParams()
  const history = useHistory()

  const storedPromotion = useSelector(
    ({ promotion }) => promotion.data?.find((c) => c.id === id),
    objectEqual,
  )

  const promotion = props.promotion || storedPromotion

  useEffect(() => {
    if (!modal && !promotion) history.push('/promotions')
  }, [promotion])

  const [input, setInput] = useState<PromotionInput>({
    name: promotion?.name || '',
    published: promotion?.published || false,
    searchWords: promotion?.searchWords || '',
    url: promotion?.url || '',
  })

  const onChange: KeyValueChangeFunction<PromotionInput> = (key, value) => {
    setInput({ ...input, [key]: value })
  }

  const goBack = props.close || (() => history.push('/promotions'))

  return (
    <>
      <PromotionViewGeneral
        modal={modal}
        id={promotion?.id}
        image={promotion?.image}
        onChange={onChange}
        {...input}
      />

      <PromotionViewControllers input={input} goBack={goBack} promotion={promotion} />
    </>
  )
}

export default PromotionView
