import React from 'react'

import { Divider, Flexbox } from '@stage-ui/core'
import BreadCrumbs from 'components/BreadCrumbs/BreadCrumbs'
import { useLocation } from 'react-router-dom'

import { OrdersProgressBar } from './ProgressBar'

export function OrdersSectionHeader() {
  const location = useLocation()
  const orderId = location.pathname.split('/')[2]
  const sectionPath = location.pathname.split('/')[1]

  return (
    <Flexbox justifyContent="space-between" alignItems="flex-end" h="100%" flex={1}>
      <BreadCrumbs
        path={orderId}
        selectedItem={`Заказ #${orderId}`}
        sectionName="Все заказы"
        sectionPath={sectionPath}
      />
      <Divider w="0.0625rem" h="100%" color="gray200" mx="2rem" />
      <OrdersProgressBar />
    </Flexbox>
  )
}
