import React from 'react'

import { Flexbox } from '@stage-ui/core'

import Animation from 'components/Animation'

type PageLoadingProps = {
  loading: boolean
  children: React.ReactNode
}

export function PageLoading(props: PageLoadingProps) {
  const { loading, children } = props
  // loading = true
  return (
    <>
      {children}
      <Flexbox
        style={(t) => ({
          zIndex: 10,
          opacity: loading ? 1 : 0,
          pointerEvents: 'none',
          visibility: loading ? 'visible' : 'hidden',
          transition: 'opacity .25s, transform .25s',
          transform: loading ? 'scale(1.2)' : 'scale(1)',
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          top: 0,
          alignItems: 'center',
          justifyContent: 'center',
          background: `linear-gradient(0, ${t.color.background.hex()}, ${t.color.background.hex()}, transparent)`,
        })}
      >
        <Animation type="loading" w={128} h={128} />
      </Flexbox>
    </>
  )
}
