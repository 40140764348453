import React, { useEffect } from 'react'

import { Order } from '@foods-n-goods/server/generated/schema'
import { Block, Flexbox, Spinner, Text } from '@stage-ui/core'
import { OrderActions } from 'actions'
import useSelector from 'hooks/useSelector'
import { useParams } from 'react-router-dom'

import { Close } from '@stage-ui/icons'

import { objectEqual } from 'utils/objectEqual'

import { OrderDetailsToolbar } from './components/Toolbar'
import { OrderGeneralInformation } from './GeneralInformation'
import { OrderHistory } from './History'
import { OrderPhotoReport } from './PhotoReport'

interface OrderViewProps {
  id?: Order['id']
  onClose?: () => void
}

export function OrderDetails(props: OrderViewProps) {
  const { onClose = false, id } = props
  const params: { id: string } = useParams()
  const section = useSelector((state) => state.order.filter.detailsSection)

  const currentlyViewedOrder = useSelector(
    (state) => state.order.currentlyViewed,
    objectEqual,
  )

  const orderId = id || params.id

  const prefetch = () => {
    if (!orderId || !!currentlyViewedOrder) return
    OrderActions.fetchById(orderId)
  }

  useEffect(() => {
    prefetch()

    return () => {
      OrderActions.clearCurrent()
      OrderActions.setFilter({
        detailsSection: '',
      })
    }
  }, [])

  if (!currentlyViewedOrder) {
    return (
      <Flexbox centered p="xl">
        <Spinner mr="s" />
        <Text color="gray500" size="s">
          Загрузка заказа
        </Text>
      </Flexbox>
    )
  }

  return (
    <Block pb="xl">
      {onClose && (
        <Close
          size="1.5rem"
          color="gray500"
          style={{
            position: 'absolute',
            right: '0',
            top: '0',
            zIndex: 1,
          }}
          onClick={onClose}
        />
      )}
      <OrderDetailsToolbar modal={!!onClose} order={currentlyViewedOrder} />
      {!section && <OrderGeneralInformation order={currentlyViewedOrder} />}
      {section === 'photos' && (
        <OrderPhotoReport
          order={currentlyViewedOrder}
          orderedProducts={currentlyViewedOrder.positions ?? []}
        />
      )}
      {section === 'history' && <OrderHistory order={currentlyViewedOrder} />}
    </Block>
  )
}
