/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react'

import { Block, Grid, Paragraph } from '@stage-ui/core'

import ContainersClear from './actions/ContainersClear'
import { GeneralAutomation } from './components/Automation'
import { GeneralSorting } from './components/Sorting'
import { GeneralExternalSystem } from './components/ExternalSystem'
import { GeneralCourier } from './components/Courier'
import Warehouse from './components/Warehouse'
import { ClientGeneral } from './components/Client'

export function GeneralSettings() {
  return (
    <Grid autoFlow="row" gap="1rem">
      <GeneralAutomation />
      <GeneralSorting />
      <GeneralCourier />
      <Warehouse />
      <GeneralExternalSystem />
      <ClientGeneral />
      <Block decoration="surface" borderColor="gray100" p="l" mb="m">
        <Paragraph weight={500} size="l" color="gray900" mb="m">
          Сервисные действия
        </Paragraph>
        <ContainersClear mr="s" />
      </Block>
    </Grid>
  )
}
