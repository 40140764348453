import React, { useEffect } from 'react'

import { VariablesActions } from 'actions'
import LoadingData from 'components/LoadingData'
import { Section } from 'components/Section'
import useSelector from 'hooks/useSelector'
import { Route } from 'react-router-dom'

import { objectEqual } from 'utils/objectEqual'

import VariablesTable from './Table'

export function Variables() {
  const dataResolved = useSelector(
    ({ variables: { loading } }) => loading === 'resolved',
    objectEqual,
  )

  useEffect(() => {
    if (!dataResolved) {
      VariablesActions.getAll()
    }
  }, [])

  return (
    <Section title="Конфигурации">
      <LoadingData storeKey="variables">
        <Route exact path="/variables" component={VariablesTable} />
      </LoadingData>
    </Section>
  )
}
