import React, { useRef, useState } from 'react'

import { Block, Button, Flexbox, Modal } from '@stage-ui/core'
import ButtonTypes from '@stage-ui/core/control/Button/types'
import ModalTypes from '@stage-ui/core/layout/Modal/types'
import ViewInputField from 'components/Fields/ViewInputField'

interface ReviewAnswerProps {
  close?: () => void
  action: (answer: string) => void
}

function ReviewAnswer(props: ButtonTypes.Props & ReviewAnswerProps) {
  const { id, close, action, ...buttonProps } = props

  const [answer, setAnswer] = useState<string>('')
  const modalRef = useRef<ModalTypes.Ref>(null)

  const openModal = () => {
    close?.()
    modalRef.current?.open()
  }
  const closeModal = () => modalRef.current?.close()

  const performAnswer = () => action(answer)

  return (
    <>
      <Button
        color="primary"
        children="Ответить на отзыв"
        onClick={openModal}
        {...buttonProps}
      />
      <Modal ref={modalRef} title="Ответ" subtitle="на отзыв" w="30rem">
        <Block>
          <ViewInputField
            mt="1rem"
            label="Текст ответа"
            labelType="outside"
            value={answer}
            onChange={(value) => setAnswer(value)}
          />
          <Flexbox justifyContent="flex-end" mt="3rem">
            <Button children="Отмена" decoration="text" onClick={closeModal} />
            <Button
              ml="0.5rem"
              onClick={performAnswer}
              color={props.color}
              children="Ответить"
            />
          </Flexbox>
        </Block>
      </Modal>
    </>
  )
}

export default ReviewAnswer
