import React from 'react'

import { Table } from '@stage-ui/core'
import useSelector from 'hooks/useSelector'

import { FullScreenVideo } from 'pages/settings/CameraDevices/columns'

import { Device } from '@foods-n-goods/server/generated/schema'

import { objectEqual } from 'utils/objectEqual'

import { qualityOrderPositions } from '../Table/QualityTableMisc'

type QualityCamerasViewProps = {
  device: Device
}

export function QualityCamerasView(props: QualityCamerasViewProps) {
  const { device } = props

  const data = useSelector((state) => state.orderQuality.data, objectEqual)

  const filteredData = data.filter((d) => String(d.packParams?.cameraId) === device.id)

  return (
    <>
      <FullScreenVideo
        device={device}
        width="50%"
        style={{
          maxWidth: '100%',
          minWidth: '35rem',
          borderRadius: '0.25rem',
        }}
      />
      <Table
        mt="xl"
        columns={qualityOrderPositions}
        data={filteredData}
        overrides={{
          rowCell: {
            padding: '0.5rem 0.5rem',
          },
          container: {
            thead: {
              th: {
                ':nth-of-type(n+6):nth-child(-n+9)': {
                  padding: 0,
                },
              },
            },
            tbody: {
              td: {
                height: '2.125rem',
                ':nth-of-type(n+6):nth-child(-n+9)': {
                  padding: 0,
                },
              },
            },
          },
        }}
      />
    </>
  )
}
