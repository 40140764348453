import React from 'react'

import { Order, OrderedProduct } from '@foods-n-goods/server/generated/schema'
import { Flexbox, Grid, Text } from '@stage-ui/core'
import { PhotoReport } from 'pages/quality/components/PhotoReport'
import { makeOrderQualityItem } from 'utils/makeOrderQualityItem'

interface OrderPhotoReportProps {
  orderedProducts: OrderedProduct[]
  order: Order
}

export function OrderPhotoReport(props: OrderPhotoReportProps) {
  const { orderedProducts, order } = props

  const filteredPositions = orderedProducts.filter((p) => !!p.photo)

  if (!filteredPositions.length) {
    return (
      <Flexbox column centered p="xl">
        <Text color="gray900">Фотографий нет</Text>
      </Flexbox>
    )
  }

  const allReports = filteredPositions.map((p) => makeOrderQualityItem(order, p))

  return (
    <Grid templateColumns={['repeat(auto-fill, 22.25rem)', '1fr 1fr']} gap="1.5rem">
      {allReports.map((rep) => (
        <PhotoReport w="100%" key={rep.id} item={rep} allReports={allReports} />
      ))}
    </Grid>
  )
}
