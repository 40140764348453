import React, { Fragment, useState } from 'react'

import {
  Block,
  Divider,
  Flexbox,
  ScrollView,
  Text,
  TextField,
  useTheme,
} from '@stage-ui/core'
import { ChatNew, Person, Search } from '@stage-ui/icons'
import MenuNavigation from 'components/MenuNavigation'
import { useMouseDrop } from 'components/MouseDrop'
import useSelector from 'hooks/useSelector'
import { ChatStore } from 'store'

import { objectEqual } from 'utils/objectEqual'

import { ChatActions } from 'actions'

import RoomCell from './components/RoomCell'
import { showRoomEditForm } from './Configurate'

function RoomsView() {
  const theme = useTheme()

  const [search, setSearch] = useState('')

  const newChatHint = useMouseDrop('Создать чат')

  const { filter, userId, rooms, activeRoomId, roomActivity } = useSelector(
    (state) => ({
      filter: state.chat.filter,
      userId: state.app.user?.id,
      roomActivity: state.chat.roomActivity,
      activeRoomId: state.chat.activeRoomId,
      rooms: state.chat.rooms
        .filter((room) => {
          if (!search) {
            return true
          }
          const userNames = room.users.map(({ name }) => name).join()
          return `${room.name}${userNames}`.toLowerCase().match(search.toLowerCase())
        })
        .sort((a, b) => (a.time < b.time ? 1 : -1)),
    }),
    objectEqual,
  )

  const filtered = {
    all: rooms,
    onlyNew: [],
    withClient: rooms.filter((r) => r.users.some((u) => u.type === 'CLIENT')),
    needAnswer: rooms.filter(
      (r) => r.messages.length && r.messages[r.messages.length - 1].user?.id !== userId,
    ),
  }

  // @ts-expect-error
  const filtredRooms: Room[] = filtered[filter.section || 'all'] || rooms

  return (
    <Block
      px="0.875rem"
      w="26rem"
      style={{
        minWidth: '18rem',
      }}
    >
      <ScrollView mode="hidden" pb="m">
        <Block position="sticky" backgroundColor="gray50" style={{ top: 0, zIndex: 1 }}>
          <Flexbox mb="m" pt="0.875rem">
            <TextField
              flex={1}
              size="m"
              mr="s"
              w="100%"
              value={search}
              leftChild={<Search size="1.5rem" color="gray500" />}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Поиск"
              borderColor="gray200"
              clearable
              overrides={{
                field: {
                  paddingLeft: '0.5rem',
                  transition: 'width 300ms',
                  boxShadow: 'none',
                  backgroundColor: theme.color.gray[50].hex(),
                  '&#focused': {
                    backgroundColor: theme.color.white.hex(),
                  },
                },
                input: () => [
                  {
                    '::placeholder': {
                      color: theme.color.gray[500].hex(),
                      fontSize: '0.875rem',
                      fontWeight: 500,
                    },
                  },
                ],
              }}
            />
            <ChatNew
              {...newChatHint}
              p="0.5rem"
              size="1.5rem"
              color="gray500"
              onClick={() => showRoomEditForm()}
            />
          </Flexbox>
          <MenuNavigation
            items={[
              { id: 'all', title: 'Все', count: filtered.all.length },
              {
                id: 'withClient',
                title: 'С клиентом',
                count: filtered.withClient.length,
              },
              {
                id: 'needAnswer',
                title: 'Без ответа',
                count: filtered.needAnswer.length,
              },
              {
                id: 'onlyNew',
                title: 'Новые',
                count: filtered.onlyNew.length,
              },
            ]}
            currentSection={filter.section}
            onChange={(section) => ChatActions.setFilter({ section })}
          />
          <Divider color="gray200" mt="-0.0625rem" mb="m" />
        </Block>
        {filtredRooms.length === 0 && (
          <Flexbox h="60vh" column alignItems="center" justifyContent="center">
            <Person color="gray300" size="2rem" my="s" />
            <Text color="gray400" size="s" weight={500}>
              Нет чатов
            </Text>
          </Flexbox>
        )}
        {filtredRooms.length > 0 && (
          <Block
            decoration="surface"
            borderRadius="m"
            mt="m"
            borderColor="gray200"
            overflow="hidden"
          >
            {filtredRooms.map((room, index) => (
              <Fragment key={room.roomId}>
                <RoomCell
                  key={room.roomId}
                  userId={userId!}
                  isActive={activeRoomId === room.roomId}
                  isTyping={roomActivity[room.roomId].isTyping}
                  room={room}
                  onClick={() => {
                    ChatStore.roomActivate(room.roomId)
                  }}
                />
                {filtredRooms.length - 1 !== index && <Divider />}
              </Fragment>
            ))}
          </Block>
        )}
      </ScrollView>
    </Block>
  )
}

export default RoomsView
