import React, { useEffect, useState } from 'react'

import { Block, Button, Flexbox, modal, Spinner, Text } from '@stage-ui/core'
import FlexboxTypes from '@stage-ui/core/layout/Flexbox/types'

import useSelector from 'hooks/useSelector'

import { prettyPackDate } from 'utils/prettyDate'

import { ArrowIosLeft, ArrowIosRight } from '@stage-ui/icons'

import { FullScreenVideo } from 'pages/settings/CameraDevices/columns'

import { Device, OrderQualityItem } from '@foods-n-goods/server/generated/schema'

import QualityOrder from 'pages/quality/components/QualityOrder'

import QualityPackDate from 'pages/quality/components/QualityPackDate'
import QualityScale from 'pages/quality/components/QualityScale'
import WarehouseWorker from 'pages/quality/components/QualityWarehouseWorker'
import QualityProductName from 'pages/quality/components/QualityProductName'
import Quantity from 'pages/quality/components/Quantity'
import Pallets from 'pages/quality/components/Pallets'
import QualityClientName from 'pages/quality/components/QualityClientName'
import { objectEqual } from 'utils/objectEqual'
import { OrderQualityActions } from 'actions'

type PhotoReportModalProps = FlexboxTypes.Props & {
  close: () => void
  orders: OrderQualityItem[]
}

export function PhotoReportModal(props: PhotoReportModalProps) {
  const { close, orders } = props
  const filter = useSelector((state) => state.orderQuality.filter, objectEqual)

  const [positionId, setPositionId] = useState(0)
  const [scaleName, setScaleName] = useState('')
  const [camera, setCamera] = useState<Device | undefined>()
  const [cameraOn, setCameraOn] = useState(false)

  const devices = useSelector((state) => state.devices.data, objectEqual)

  useEffect(() => {
    const posId = orders.findIndex((order) => order.id === filter.positionId)

    const scaleName =
      devices.find((device) => Number(device.id) === orders[posId]?.packParams?.scaleId)
        ?.name || ''

    const camera =
      devices.find(
        (device) => Number(device.id) === orders[posId]?.packParams?.cameraId,
      ) || undefined

    setPositionId(posId >= 0 ? posId : 0)
    setScaleName(scaleName)
    setCamera(camera)
  }, [orders, devices, filter.positionId])

  const order = orders[positionId]

  if (!order) return <Spinner />

  const packDate = prettyPackDate(order.packDate)

  return (
    <Flexbox column backgroundColor="white" borderRadius="0.25rem" h="100%">
      <Flexbox
        flex={1}
        onClick={close}
        justifyContent="space-between"
        backgroundColor="gray200"
        style={{
          background: `url("${order.photo}")`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'auto 100%',
          backgroundPosition: 'center',
          borderTopLeftRadius: '0.25rem',
          borderTopRightRadius: '0.25rem',
        }}
      >
        {cameraOn && camera && <FullScreenVideo device={camera} width="100%" />}
        {camera && (
          <Flexbox
            position="absolute"
            mt="xl"
            ml="xl"
            backgroundColor="white"
            p="xs"
            borderRadius="m"
            h="fit-content"
            onClick={(event) => event.stopPropagation()}
          >
            <Button
              w="10rem"
              py="0.625rem"
              borderRadius="m"
              onClick={() => setCameraOn(false)}
              decoration={cameraOn ? 'text' : 'filled'}
            >
              <Text size="xs" color={cameraOn ? 'gray400' : 'white'}>
                Фотоотчет
              </Text>
            </Button>
            <Button
              w="10rem"
              py="0.625rem"
              borderRadius="m"
              onClick={() => setCameraOn(true)}
              decoration={cameraOn ? 'filled' : 'text'}
            >
              <Text size="xs" color={cameraOn ? 'white' : 'gray400'}>
                {camera?.name || ''}
              </Text>
            </Button>
          </Flexbox>
        )}
        {!cameraOn && orders.length > 1 && (
          <>
            <Flexbox
              position="absolute"
              onClick={(event) => event.stopPropagation()}
              flexBasis="content"
              h="fit-content"
              borderRadius="100%"
              style={{ top: '50%', left: 0 }}
            >
              <Button
                w="5.25rem"
                h="5.25rem"
                borderRadius="100%"
                decoration="text"
                disabled={positionId <= 0}
                onClick={(event) => {
                  event.stopPropagation()
                  if (positionId <= 0) {
                    return
                  }
                  const newId = positionId - 1

                  OrderQualityActions.setFilter({ positionId: orders[newId].id })
                  setPositionId(newId)
                }}
              >
                <ArrowIosLeft
                  size="2.5rem"
                  color={positionId > 0 ? 'white' : 'gray400'}
                />
              </Button>
            </Flexbox>
            <Flexbox
              position="absolute"
              onClick={(event) => event.stopPropagation()}
              flexBasis="content"
              style={{ right: 0, top: '50%' }}
              h="fit-content"
              borderRadius="100%"
            >
              <Button
                w="5.25rem"
                h="5.25rem"
                borderRadius="100%"
                decoration="text"
                disabled={positionId >= orders.length - 1}
                onClick={(event) => {
                  event.stopPropagation()
                  if (positionId >= orders.length - 1) {
                    return
                  }
                  const newId = positionId + 1

                  OrderQualityActions.setFilter({ positionId: orders[newId].id })
                  setPositionId(newId)
                }}
              >
                <ArrowIosRight
                  size="2.5rem"
                  color={positionId < orders.length - 1 ? 'white' : 'gray400'}
                />
              </Button>
            </Flexbox>
          </>
        )}
      </Flexbox>
      <Flexbox flexBasis="5.25rem" p="1.375rem" justifyContent="space-between">
        <Block>
          <Flexbox justifyContent="flex-start" alignItems="center">
            <QualityOrder id={order.orderId} mr="1.25rem" close={close} />
            <QualityPackDate flex={0} date={packDate} mr="1.25rem" />
            <QualityScale flexGrow={0} name={scaleName} mr="1.25rem" />
            <WarehouseWorker>{order.warehouseWorkerName}</WarehouseWorker>
          </Flexbox>
          <Flexbox column mt="m">
            <QualityProductName>{order.productName}</QualityProductName>
            <QualityClientName mt="xs" name={order.clientName} />
          </Flexbox>
        </Block>
        <Flexbox alignItems="baseline">
          <Pallets containers={order.containers} mr="1.25rem" />
          <Quantity
            quantityAfter={order.quantityAfter}
            quantityBefore={order.quantityBefore}
            quantitySupposed={order.quantitySupposed}
            tareWeight={order.tareWeight}
          />
        </Flexbox>
      </Flexbox>
    </Flexbox>
  )
}

export const openPhotoReportModal = (
  orders: OrderQualityItem[],
  onClose?: () => void,
  e?: React.MouseEvent<HTMLDivElement, MouseEvent>,
) => {
  e?.stopPropagation()
  e?.preventDefault()

  modal({
    backgroundColor: 'transparent',
    w: '75vw',
    h: '100vh',

    overrides: {
      window: {
        boxShadow: 'none',
        backgroundColor: 'transparent',
        margin: 0,
        filter: 'drop-shadow(4px 4px 50px rgba(0, 0, 0, 0.28))',
      },
      header: {
        position: 'absolute',
        right: 0,
      },
      cross: {
        display: 'none',
      },
    },
    onClose,
    render: (close) => <PhotoReportModal close={close} orders={orders} />,
  })
}
