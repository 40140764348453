import React from 'react'

import { modal } from '@stage-ui/core'
import { Product } from '@foods-n-goods/server/generated/schema'
import ProductView from 'pages/products/View/ProductsView'
import { ProductActions } from 'actions'

export const openProductModal = (
  id: Product['id'],
  e?: React.MouseEvent<HTMLDivElement, MouseEvent>,
) => {
  e?.stopPropagation()
  e?.preventDefault()

  const open = () =>
    modal({
      decoration: 'panel',
      hideHeader: true,
      overrides: (t) => ({
        window: () => [
          {
            background: t.color.background.hex(),
            minWidth: '90vw',
            minHeight: '90vh',
          },
        ],
      }),
      render: (close) => <ProductView id={id} close={close} />,
    })

  ProductActions.getById(id, open)
}
