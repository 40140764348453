import React from 'react'

import { Report } from '@foods-n-goods/server/generated/schema'
import TableTypes from '@stage-ui/core/data/Table/types'
import { CheckmarkCircle, Sync } from '@stage-ui/icons'

export function CellStatus(ctx: TableTypes.TableCellContext<Report>) {
  const { row } = ctx

  if (row.finishDate) {
    return <CheckmarkCircle size="1.5rem" color="primary" />
  }
  return (
    <Sync
      size="1.5rem"
      color="orange500"
      style={{
        animation: 'rotate-center 3s linear infinite',
      }}
    />
  )
}
