import React from 'react'

import { Client } from '@foods-n-goods/server/generated/schema'
import { Text } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'

export function NameCell(ctx: TableTypes.TableCellContext<Client>) {
  const { row } = ctx
  const { markets, name, identifier } = row

  const clientName = markets[0]?.alias || name || identifier || 'Неопознанный енот'

  return (
    <Text size="s" color="gray900" weight={600}>
      {clientName}
    </Text>
  )
}
