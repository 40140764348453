import React from 'react'

import { Flexbox, Text } from '@stage-ui/core'
import { ArrowIosLeft } from '@stage-ui/icons'
import { useHistory } from 'react-router-dom'

type BreadCrumbsProps = {
  path: string
  selectedItem: string
  sectionName: string
  sectionPath: string
}

function BreadCrumbs(props: BreadCrumbsProps) {
  const { path, selectedItem, sectionName, sectionPath } = props
  const history = useHistory()
  return (
    <Flexbox justifyContent="flex-start" flex={1}>
      {path ? (
        <Flexbox alignItems="flex-end">
          <ArrowIosLeft
            mr="m"
            size="l"
            color="gray400"
            onClick={() => history.goBack()}
            alignSelf="center"
          />
          <Flexbox column>
            <Text size="xs" weight={500} color="gray400" mt="0.3125rem">
              {sectionName}
            </Text>
            <Flexbox>
              <Text weight={700} color="gray900" size="1.125rem" mt="0.2125rem">
                {selectedItem}
              </Text>
            </Flexbox>
          </Flexbox>
        </Flexbox>
      ) : (
        <Text
          weight={700}
          color="gray900"
          size="1.125rem"
          onClick={() => history.push(`/${sectionPath}`)}
        >
          {sectionName}
        </Text>
      )}
    </Flexbox>
  )
}
export default BreadCrumbs
