import React, { useState } from 'react'

import { Button, Flexbox, modal } from '@stage-ui/core'
import ButtonTypes from '@stage-ui/core/control/Button/types'
import { StaffActions } from 'actions'
import ViewInputField from 'components/Fields/ViewInputField'
import { Staff } from '@foods-n-goods/server/generated/schema'

interface StaffSendPushMessageProps {
  staff?: Staff
  close: () => void
}

function StaffPushMessageModal(props: ButtonTypes.Props & StaffSendPushMessageProps) {
  const { staff, close } = props

  const [message, setMessage] = useState('')
  const [title, setTitle] = useState('')

  const handlePushMessage = () => {
    StaffActions.sendPushMessage({
      message,
      title,
      ...(staff && {
        staffId: staff.id,
      }),
    })
    close()
  }

  return (
    <Flexbox column>
      <ViewInputField
        autoFocus
        label="Заголовок"
        labelType="outside"
        value={title}
        onChange={(value) => setTitle(value)}
      />
      <ViewInputField
        label="Сообщение"
        labelType="outside"
        mt="s"
        multiline
        rows={3}
        value={message}
        onChange={(value) => setMessage(value)}
      />
      <Flexbox justifyContent="space-between" mt="3rem">
        <Flexbox flex={1} justifyContent="flex-end">
          <Button decoration="text" onClick={close} color="gray500">
            Отмена
          </Button>
          <Button ml="s" onClick={handlePushMessage}>
            Отправить
          </Button>
        </Flexbox>
      </Flexbox>
    </Flexbox>
  )
}

export const openStaffPushMessageModal = (
  staff?: Staff,
  e?: React.MouseEvent<HTMLDivElement, MouseEvent>,
) => {
  e?.stopPropagation()
  e?.preventDefault()

  const subtitle = staff?.name || staff?.login || undefined

  modal({
    title: `Отправить уведомление ${staff ? 'сотруднику' : 'сотрудникам'}`,
    subtitle,
    w: '30rem',
    render: (close) => <StaffPushMessageModal close={close} staff={staff} />,
  })
}
