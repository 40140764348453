import React from 'react'

import { Order } from '@foods-n-goods/server/generated/schema'
import { Flexbox, Grid, Text } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'

export function OrderListTableContainerCell(ctx: TableTypes.TableCellContext<Order>) {
  const { row } = ctx
  const { containers } = row

  return (
    <Grid autoFlow="column" gap="0.125rem" alignItems="center">
      {containers.map((container) => (
        <Flexbox
          centered
          key={container.id}
          borderColor="gray300"
          borderRadius="s"
          mr="xs"
          w="1.5rem"
          h="1.5rem"
          style={{ border: '1px solid' }}
        >
          <Text color="gray900" size="s" weight={600}>
            {container.name}
          </Text>
        </Flexbox>
      ))}
    </Grid>
  )
}
