import React, { Fragment } from 'react'

import { ReportAvailable } from '@foods-n-goods/server/generated/schema'
import { Divider, Flexbox, Grid, Text } from '@stage-ui/core'
import { Checkmark } from '@stage-ui/icons'

type NewReportRowProps = {
  report: ReportAvailable
  current: boolean
  onClick: () => void
}

export function NewReportRow(props: NewReportRowProps) {
  const { report, current, onClick } = props

  return (
    <>
      <Grid
        templateColumns="1fr 1.25rem"
        gap="0.5rem"
        p="s m"
        backgroundColor={current ? 'green50' : 'transparent'}
        onClick={onClick}
        alignItems="center"
        justifyContent="space-between"
      >
        <Flexbox alignItems="center">
          <Flexbox column>
            <Text mr="s" size="s" color="gray900" weight={600} ellipsis>
              {report.name}
            </Text>
          </Flexbox>
        </Flexbox>

        {current && <Checkmark color="green500" />}
      </Grid>
      <Divider color="gray200" />
    </>
  )
}
