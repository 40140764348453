import React from 'react'

import { Text } from '@stage-ui/core'
import TextTypes from '@stage-ui/core/content/Text/types'

type QualityWarehouseWorkerProps = { children: string } & Omit<
  TextTypes.Props,
  'children'
>

const QualityWarehouseWorker: React.FC<QualityWarehouseWorkerProps> = (props) => {
  const { children, ...textProps } = props
  return (
    <Text
      size="xs"
      color="gray500"
      weight={400}
      style={{
        whiteSpace: 'nowrap',
        maxWidth: '60%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
      {...textProps}
    >
      Сборщик: {children}
    </Text>
  )
}

export default QualityWarehouseWorker
