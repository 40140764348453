/* eslint-disable no-param-reassign */
import React, { useEffect, useMemo } from 'react'

import { Flexbox, Paragraph, ScrollView, Table, Text } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import { List } from '@stage-ui/icons'
import useSelector from 'hooks/useSelector'
import { ym } from 'pages/logistics/MapView/hooks/useYandexMaps'
import { OrderDelivery, OrderDeliveryCoordinator } from 'store/delivery'

import { PageLoading } from 'components/PageLoading'
import { DeliveryActions } from 'actions'

import { objectEqual } from 'utils/objectEqual'

import wordForNum from '@foods-n-goods/client/utils/wordForNum'

import moment from 'moment'

import { LogisticsCoordinatorIndexCell } from './misc/IndexCell'
import { LogisticsCoordinatorClientCell } from './misc/СlientCell'
import { LogisticsCoordinatorCourierCell } from './misc/CourierCell'

export const coordinatorColumns: Array<TableTypes.TableColumn<OrderDeliveryCoordinator>> =
  [
    {
      key: 'id',
      width: 28,
      render: LogisticsCoordinatorIndexCell,
    },
    {
      key: 'clientName',
      title: 'Контрагент',
      sort: 'DEFAULT',
      primary: true,
      width: 288,
      render: LogisticsCoordinatorClientCell,
    },
    {
      key: 'index',
      title: 'Водитель',
      sort: 'ASC',
      width: 130,
      render: LogisticsCoordinatorCourierCell,
    },
  ]

export function LogisticsCoordinator() {
  const { filter, ordersSelected, loading, deliveryRecords, coordinatorData } =
    useSelector(
      ({ delivery }) => ({
        filter: delivery.filter,
        ordersSelected: delivery.ordersSelected,
        loading: delivery.loading === 'idle' || delivery.loading === 'pending',
        coordinatorData: delivery.coordinatorData,
        deliveryRecords: delivery.orderPage.records.map((order) => ({
          id: order.id,
          deliveryAddress: order.deliveryAddress,
        })),
      }),
      objectEqual,
    )

  useEffect(() => {
    return () => {
      DeliveryActions.setFilter({
        coordinatorShowAll: false,
      })
    }
  }, [])

  const preparedCoordinatorData = useMemo(() => {
    const result: (OrderDeliveryCoordinator & {
      index: number
      filled: boolean
    })[] = coordinatorData
      .slice()
      .sort((a, b) => a.clientName.localeCompare(b.clientName) || 0)
      .sort((a, b) => a.courierName?.localeCompare(b.courierName || '') || 0)
      .sort((a, b) => {
        if (filter.sortBySequance && a.courierName === b.courierName) {
          return Math.min(...a.orders.map((o) => o.sequence || 0)) >
            Math.min(...b.orders.map((o) => o.sequence || 0))
            ? 1
            : -1
        }
        return 0
      })
      .map((p, i) => ({
        ...p,
        index: i + 1,
        filled: false,
      }))

    result.forEach((p, i) => {
      if (i) {
        if (result[i - 1]?.courierName !== result[i].courierName) {
          p.filled = !result[i - 1].filled
        } else {
          p.filled = result[i - 1].filled
        }
      }
    })
    return result
  }, [coordinatorData, filter.sortBySequance])

  const getAssociatedOrderIds = (od: OrderDelivery) =>
    deliveryRecords
      .filter(
        (o) =>
          od.deliveryAddress.lat === o.deliveryAddress.lat &&
          od.deliveryAddress.lng === o.deliveryAddress.lng,
      )
      .map(({ id }) => id)

  const onRowClick = (ctx: TableTypes.TableRowContext<OrderDeliveryCoordinator>) => {
    if (!ctx.row.orders.length) return

    const ids = ctx.row.orders.map((o) => o.id)
    DeliveryActions.setActiveOrders(ids)
    DeliveryActions.setActiveCouriers([])
    ym.ref?.focus(getAssociatedOrderIds(ctx.row.orders[0]), true)
    ym.ref?.trackClear()
  }

  const onRowEnter = (ctx: TableTypes.TableRowContext<OrderDeliveryCoordinator>) => {
    if (!ctx.row.orders.length) return
    ym.ref?.hover(getAssociatedOrderIds(ctx.row.orders[0]))
  }

  const onRowLeave = (ctx: TableTypes.TableRowContext<OrderDeliveryCoordinator>) => {
    if (!ctx.row.orders.length) return
    ym.ref?.hover(getAssociatedOrderIds(ctx.row.orders[0]), false)
  }

  return (
    <PageLoading loading={loading}>
      {coordinatorData.length === 0 && (
        <Flexbox
          column
          centered
          p="m"
          decoration="surface"
          borderRadius="s"
          borderColor="gray200"
        >
          <List size="1.5rem" color="gray300" />
          <Paragraph size="s" color="gray400">
            {loading ? 'Загрузка..' : 'Нет заказов'}
          </Paragraph>
        </Flexbox>
      )}
      {coordinatorData.length > 0 && (
        <ScrollView
          mode="hidden"
          style={{
            height: 'calc(100vh - 11.5rem)',
            '@media print': {
              height: 'auto',
            },
          }}
        >
          <Paragraph
            weight="bold"
            style={{
              display: 'none',
              '@media print': {
                display: 'block',
              },
            }}
          >
            Доставка на {moment(filter.date).format('DD.MM.YYYY')}
          </Paragraph>
          <Table
            data={preparedCoordinatorData}
            columns={coordinatorColumns}
            onRowClick={onRowClick}
            selected={coordinatorData.filter((cd) =>
              cd.orders.some((o) => ordersSelected.includes(o.id)),
            )}
            onRowMouseEnter={onRowEnter}
            onRowMouseLeave={onRowLeave}
            style={{
              tr: {
                '[role=checkbox]': {
                  display: 'none',
                },
                ':hover': {
                  '[role=checkbox]': {
                    display: 'block',
                  },
                },
              },
              '@media print': {
                '*': {
                  fontSize: '14px !important',
                  color: 'black !important',
                  borderRadius: '0 !important',
                  padding: 0,
                },
                td: {
                  width: 'auto !important',
                  '> div': {},
                  '> span': {
                    height: '1rem !important',
                  },
                },
                'th > div': {
                  padding: '0.5rem 0 !important',
                },
                'tr td:first-child div': {
                  fontWeight: 'bold !important',
                },
                ' svg': {
                  display: 'none',
                },
              },
            }}
            overrides={(t) => ({
              headCell: {
                padding: 0,
              },
              row: (props) => [
                props.selected && {
                  backgroundColor: `${t.color.green[50].hex()} !important`,
                },
              ],
              rowCell: {
                padding: '0 !important',
                margin: '0 !important',
              },
            })}
          />
          <Paragraph
            color="gray500"
            textAlign="center"
            size="s"
            pt="s"
            style={{
              '@media print': {
                display: 'none',
              },
            }}
          >
            Всего{' '}
            <Text size="s" weight={700} color="gray900">
              {deliveryRecords.length}
            </Text>{' '}
            {wordForNum(deliveryRecords.length, ['заказ', 'заказа', 'заказов'])} у{' '}
            <Text size="s" weight={700} color="gray900">
              {coordinatorData.length}
            </Text>{' '}
            {wordForNum(coordinatorData.length, ['клиента', 'клиентов', 'клиентов'])}
          </Paragraph>
        </ScrollView>
      )}
    </PageLoading>
  )
}
