import React, { Fragment, useCallback, useMemo } from 'react'

import { Block, Flexbox, Meter, Text } from '@stage-ui/core'

export type ProgressBarEntity = {
  title: string
  dotColor: string
  background?: string
  amount: number
  loading?: boolean
}

type ProgressBarProps = {
  total: number
  entities: ProgressBarEntity[]
  noDataText: string
}

const calculatePercent = (total: number) => (value: number) =>
  total !== 0 ? (100 * value) / total : 0

export const ProgressBar: React.FC<ProgressBarProps> = (props) => {
  const { total, entities, noDataText } = props

  const percentOfTotal = useCallback(calculatePercent(total), [total])

  const meterPrepend = useMemo(
    () => (
      <>
        {total === 0 ? (
          <Text
            size="xs"
            align="center"
            color="gray400"
            weight="200"
            w="100%"
            position="absolute"
            textAlign="center"
            style={{
              top: -2,
              zIndex: 10,
              background: 'transparent !important',
            }}
          >
            {noDataText}
          </Text>
        ) : (
          <Block display="none" />
        )}
        <Meter.Thumb
          value={100}
          color="gray200"
          overrides={{
            container: {
              borderRadius: 0,
            },
          }}
        />
      </>
    ),
    [total],
  )

  const thumbPercents = useMemo(
    () =>
      [meterPrepend].concat(
        entities
          .reduce<Array<{ percent: number; background: string; loading: boolean }>>(
            (acc, entity, id) =>
              acc.concat({
                percent: percentOfTotal(entity.amount) + (acc[id - 1]?.percent ?? 0),
                background: entity?.background ?? '',
                loading: entity?.loading ?? false,
              }),
            [],
          )
          .reverse()
          .map(({ percent, background, loading }, index) => (
            <Meter.Thumb
              key={index}
              loading={loading}
              value={percent}
              overrides={{
                container: {
                  borderRadius: 0,
                  background,
                },
              }}
            />
          )),
      ),
    [meterPrepend, percentOfTotal, entities],
  )

  return (
    <Flexbox column flexShrink={0} justifyContent="center">
      <Flexbox
        alignSelf="flex-end"
        w="35rem"
        mb="s"
        justifyContent="space-between"
        alignItems="center"
      >
        <Flexbox alignItems="center">
          {entities.map(({ title, dotColor, amount }, index) => (
            <Fragment key={index.toString()}>
              <Block
                mr="s"
                w="0.5rem"
                h="0.5rem"
                backgroundColor={dotColor}
                borderRadius="1rem"
              />
              <Text size="xs" color="gray500" weight={500} children={title} />
              <Text
                size="xs"
                ml="xs"
                mr="m"
                color="gray500"
                weight={500}
                children={amount}
              />
            </Fragment>
          ))}
        </Flexbox>
        <Text size="xs" ml="s" color="gray500" weight={500}>
          Всего {total}
        </Text>
      </Flexbox>
      <Meter
        size="m"
        alignSelf="flex-end"
        w="35rem"
        flex="0 1 auto"
        borderWidth={0}
        backgroundColor="gray300"
        children={thumbPercents}
      />
    </Flexbox>
  )
}
