import moment from 'moment'

// idk what is this ?
const definition: moment.LocaleSpecification = {
  calendar: {
    lastDay: '[Вчера] HH:mm',
    sameDay: '[Сегодня] HH:mm',
    nextDay: '[Завтра] HH:mm',
    lastWeek: '[last] dddd [at] LT',
    nextWeek: 'dddd [at] LT',
    sameElse: 'L',
  },
}

moment.locale('ru')
export default moment
