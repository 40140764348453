import React from 'react'

import { Divider, Flexbox, Text } from '@stage-ui/core'

const QualityQuantityCell: React.FC<{ quantity: string }> = (props) => {
  const { quantity } = props
  return (
    <Flexbox alignItems="center" mr="s" h="100%">
      <Divider h="2.5rem" w="1px" vertical mr="m" color="gray200" />
      <Text size="s">{quantity || '—'}</Text>
    </Flexbox>
  )
}

export default QualityQuantityCell
