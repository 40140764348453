import React from 'react'

import { Divider, Flexbox, Header, Text, useTheme, ArcProgress } from '@stage-ui/core'
import { QuestionMarkCircle, Star } from '@stage-ui/icons'
import { useMouseDrop } from 'components/MouseDrop'

import { Client } from '@foods-n-goods/server/generated/schema'

import { RatingExplanation } from './RatingExplanation'

const creditMaxLimit = 1000000

type ClientRatingProps = {
  client: Client
}

export function ClientRating(props: ClientRatingProps) {
  const { client } = props

  const { color } = useTheme()

  const ratingQuestion = useMouseDrop(
    `Рейтинг клиента формируется по формуле:
        "Количественная мера принятых позиций заказов" + "Количественная мера частично принятых позиций заказов"
        / "Количественная мера всех позиций заказов"`,
    {
      drop: {
        w: '25rem',
        align: 'bottom',
      },
      popover: {
        align: 'top',
      },
    },
  )

  const ratingString = (client.rating * 10).toFixed(1)
  const rating =
    ratingString.split('.')[1] === '0' ? ratingString.split('.')[0] : ratingString

  return (
    <Flexbox
      column
      decoration="surface"
      borderRadius="s"
      p="1.25rem"
      pt="2.5rem"
      borderColor="gray200"
    >
      <Header size="s">Рейтинг</Header>
      <Divider color="gray200" my="0.75rem" />
      <Flexbox mt="m" column alignItems="center" justifyContent="center">
        <ArcProgress
          strokeWidth="1rem"
          className="green500"
          value={client.rating * 100}
          size="14rem"
        />
        <Flexbox
          alignItems="center"
          justifyContent="center"
          w="5rem"
          style={{
            top: `calc(50%)`,
            left: `calc(50% - 3rem)`,
            position: 'absolute',
          }}
        >
          <Star size="1.25rem" mr="xs" color={color.yellow[400].hex()} type="filled" />
          <Header size="l">{rating}</Header>
        </Flexbox>
      </Flexbox>

      <Flexbox mt="m" alignItems="center">
        <QuestionMarkCircle type="filled" color="gray400" mr="s" {...ratingQuestion} />
        <Text size="s" color="gray500">
          Как формируется рейтинг клиента?
        </Text>
      </Flexbox>
      <Divider my="m" color="gray100" />
      <RatingExplanation rating={client.rating} />
    </Flexbox>
  )
}
