import React from 'react'

import { Block, Flexbox, Grid, useTheme } from '@stage-ui/core'
import { AlertTriangle } from '@stage-ui/icons'
import MenuNavigation from 'components/MenuNavigation'
import { useMouseDrop } from 'components/MouseDrop'
import useSelector from 'hooks/useSelector'

import { objectEqual } from 'utils/objectEqual'

import { OrderActions } from 'actions'

import { OrderListFilter } from './components/Filter'
import { OrderListGroupSelect } from './components/OrderGroupSelect'
import { OrderListToolbarRowModeSwitch } from './components/RowModeSwitch'
import { orderMenuItems } from './misc'

export function OrderListToolbar() {
  const theme = useTheme()

  const { selectedOrders, filter } = useSelector(
    (state) => ({
      selectedOrders: state.order.selectedOrders,
      filter: state.order.filter,
    }),
    objectEqual,
  )

  const ordersUpdatingHint = useMouseDrop(
    'Обновление в режиме реального времени не работает при установленном фильтре «Поиск»!',
    {
      drop: {
        w: '25rem',
        align: 'bottom',
      },
      popover: {
        align: 'top',
      },
    },
  )

  return (
    <Block
      pb="m"
      style={{
        position: 'sticky',
        top: '6rem',
        zIndex: 7,
        backgroundColor: theme.color.gray[50].hex(),
      }}
    >
      <Grid templateColumns="26.625rem 2.5rem 2.5rem 1fr" gap="0.5rem">
        <MenuNavigation
          items={orderMenuItems}
          currentSection={filter.section}
          onChange={(section) => {
            OrderActions.setFilter({ section })
          }}
        />
        <OrderListToolbarRowModeSwitch />
        <Flexbox centered w="2.5rem" h="2.5rem">
          {filter.search && (
            <AlertTriangle color="orange500" size="1.5rem" {...ordersUpdatingHint} />
          )}
        </Flexbox>
        {!selectedOrders.length ? <OrderListFilter /> : <OrderListGroupSelect />}
      </Grid>
    </Block>
  )
}
