import orderFields from '../fragments/orderFields.gql'

export default `
    mutation orderAddProduct($id: String!, $products: [OrderAddProduct!]!) {
        orderAddProduct(id: $id, products: $products) {
            ...orderFields
        }
    }
    ${orderFields}
`
