import React, { useEffect, useRef, useState } from 'react'

import ChatTypes from '@foods-n-goods/server/src/socket/chat/types'
import {
  Block,
  Button,
  Checkbox,
  Divider,
  Flexbox,
  modal,
  Paragraph,
  ScrollView,
  Spinner,
  TextField,
} from '@stage-ui/core'
import { Search, Trash2 } from '@stage-ui/icons'
import { ChatActions, ClientActions, StaffActions } from 'actions'
import Avatar from 'components/Avatar'
import useSelector from 'hooks/useSelector'

import MenuNavigation from 'components/MenuNavigation'

import { PageSwitch } from 'components/PageSwitch'

import ScrollViewTypes from '@stage-ui/core/layout/ScrollView/types'

import { objectEqual } from 'utils/objectEqual'

import AvatarControl from './components/AvatarControl'

type RoomEditFormProps = {
  room?: ChatTypes.Room
  onClose: () => void
  find?: string
}

export function RoomEditForm(props: RoomEditFormProps) {
  const { room, onClose, find = '' } = props
  const [pending, setPending] = useState(false)
  const [search, setSearch] = useState<string>(find)
  const [roomName, setRoomName] = useState<string>(room?.name || '')

  const scrollRef = useRef<ScrollViewTypes.Ref>(null)

  const { filter, staffs, userId, staffLoading, clientPage, clientLoading } = useSelector(
    (state) => ({
      filter: state.chat.filter,
      userId: state.app.user?.id,
      staffs: state.staff.data,
      staffLoading: state.staff.loading,
      clientPage: state.client.clientPage,
      clientLoading: state.client.loading,
    }),
    objectEqual,
  )

  const [selectedStaff, setSelectedStaff] = useState<string[]>(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    room?.users.filter((u) => u.type === 'STAFF').map(({ id }) => id) || [userId!],
  )
  const [selectedClients, setSelectedClients] = useState<string[]>(
    room?.users.filter((u) => u.type === 'CLIENT').map(({ id }) => id) || [],
  )

  const allUsers: (ChatTypes.User & { search: string; subtitle: string })[] = []

  useEffect(() => {
    ChatActions.setFilter({
      createPage: 1,
    })
  }, [filter.createSection])

  useEffect(() => {
    scrollRef?.current?.scrollTop()
  }, [filter.createSection, filter.createPage])

  useEffect(() => {
    if (staffLoading === 'idle') {
      StaffActions.fetch()
    }
  }, [staffLoading])

  useEffect(() => {
    ClientActions.fetch({
      page: filter.createPage,
      search,
    })
  }, [filter.createPage, search])

  useEffect(() => {
    if (find) {
      ChatActions.setFilter({
        createSection: 'client',
      })
      setSearch(find)
    }

    return () => {
      ChatActions.clearFilter()
    }
  }, [])

  const onSubmit = async () => {
    if (pending) {
      return
    }
    try {
      setPending(true)
      if (room) {
        await ChatActions.updateRoom(
          room.roomId,
          roomName,
          selectedStaff,
          selectedClients,
        )
      } else {
        await ChatActions.createRoom(roomName, selectedStaff, selectedClients)
      }
      onClose()
    } catch (error) {
      setPending(false)
    }
    onClose()
  }

  const onDelete = () => {
    if (pending || !room) return
    ChatActions.deleteRoom(room.roomId)
    onClose()
  }

  if (!filter.createSection) {
    staffs.forEach((u) => {
      allUsers.push({
        id: u.id,
        color: u.color?.code || '#555',
        name: u.name || u.login || '',
        type: 'STAFF',
        photo: u.photo || '',
        subtitle: u.role.text,
        updateDate: u.updateDate,
        search: `${u.name || ''}${u.login || ''}`.toLowerCase(),
      })
    })
  }

  if (filter.createSection === 'client') {
    clientPage.records.forEach((u) => {
      const name =
        u.markets[0]?.alias || u.defaultCompany?.shortName || u.name || u.identifier
      allUsers.push({
        id: u.id,
        color: '#BBB',
        name,
        type: 'CLIENT',
        photo: u.photo || '',
        updateDate: u.updateDate,
        subtitle: `ИНН ${u.defaultCompany?.inn || '-'}`,
        search: `${name}${u.defaultCompany?.ceo || ''}${u.defaultCompany?.inn || ''}${
          u.defaultCompany?.kpp || ''
        }${u.defaultCompany?.fullName || ''}`.toLowerCase(),
      })
    })
  }

  const usersToDisplay = allUsers.filter((u) => {
    if (!search) return true
    return !!u.search.match(search.toLocaleLowerCase())
  })

  const avatarUsers: ChatTypes.User[] = staffs
    .filter(({ id }) => selectedStaff.includes(id))
    .map((u) => ({
      id: u.id,
      color: u.color?.code || '#555',
      name: u.name || u.login || '',
      type: 'STAFF',
      updateDate: u.updateDate,
      photo: u.photo || '',
    }))

  return (
    <Flexbox column w="40rem">
      <Flexbox mb="m" alignItems="center">
        <TextField
          mr="m"
          value={roomName}
          maxLength={1024}
          placeholder="Наименование"
          onChange={(e) => setRoomName(e.target.value)}
          overrides={{
            field: {
              boxShadow: 'none',
              background: roomName ? 'surface' : 'transparent',
            },
          }}
        />
        <AvatarControl hint users={avatarUsers} />
        <Paragraph align="right" m={0} flex={1} color="gray500">
          Выбрано: {selectedStaff.length + selectedClients.length} из{' '}
          {staffs.length + clientPage.total}
        </Paragraph>
      </Flexbox>
      <TextField
        clearable
        onClear={() => setSearch('')}
        mb="m"
        value={search}
        maxLength={1024}
        placeholder="Поиск"
        leftChild={<Search />}
        onChange={(e) => setSearch(e.target.value)}
        overrides={{
          field: {
            boxShadow: 'none',
            background: search ? 'surface' : 'transparent',
          },
        }}
      />
      <MenuNavigation
        items={[
          { id: '', title: 'Персонал' },
          { id: 'client', title: 'Клиенты' },
        ]}
        currentSection={filter.createSection}
        onChange={(createSection) => ChatActions.setFilter({ createSection })}
      />
      <Block
        mt="m"
        decoration="surface"
        borderRadius="m"
        borderColor="gray200"
        h="50vh"
        style={{
          overflow: ' hidden',
        }}
      >
        <ScrollView ref={scrollRef} h="100%" barOffset={4}>
          {usersToDisplay.map((user, index) => (
            <Flexbox py="s" column key={user.id}>
              <Flexbox
                onClick={() => {
                  if (userId === user.id) return
                  if (user.type === 'STAFF') {
                    setSelectedStaff((prev) =>
                      prev.includes(user.id)
                        ? prev.filter((x) => x !== user.id)
                        : prev.concat(user.id),
                    )
                  }
                  if (user.type === 'CLIENT') {
                    setSelectedClients((prev) =>
                      prev.includes(user.id)
                        ? prev.filter((x) => x !== user.id)
                        : prev.concat(user.id),
                    )
                  }
                }}
              >
                <Checkbox
                  mx="m"
                  size="l"
                  disabled={userId === user.id}
                  checked={
                    userId === user.id ||
                    selectedStaff.includes(user.id) ||
                    selectedClients.includes(user.id)
                  }
                />
                <Avatar user={{ ...user, online: false }} />
                <Flexbox column ml="m">
                  <Paragraph m={0} size="m" color="gray900" weight={600}>
                    {user.name}
                  </Paragraph>
                  <Paragraph m={0} size="s" color="gray500">
                    {user.subtitle}
                  </Paragraph>
                </Flexbox>
              </Flexbox>
              {usersToDisplay.length !== index + 1 && <Divider color="gray200" mt="m" />}
            </Flexbox>
          ))}
        </ScrollView>
        {((filter.createSection === 'client' && clientLoading !== 'resolved') ||
          (!filter.createSection && staffLoading !== 'resolved')) && (
          <Flexbox
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(255,255,255,0.5)',
            }}
            centered
            p="m"
          >
            <Spinner />
          </Flexbox>
        )}
      </Block>
      {filter.createSection === 'client' && (
        <PageSwitch
          page={filter.createPage}
          pageSize={filter.createPageSize}
          total={clientPage.total}
          onChange={({ page, pageSize }) =>
            ChatActions.setFilter({ createPage: page, createPageSize: pageSize })
          }
        />
      )}
      <Flexbox justifyContent="space-between" mt="m">
        <Button
          color="red500"
          decoration="text"
          leftChild={<Trash2 />}
          onClick={onDelete}
          disabled={!room}
        >
          {pending ? <Spinner /> : 'Удалить группу'}
        </Button>
        <Button
          onClick={onSubmit}
          disabled={selectedStaff.length + selectedClients.length < 2}
        >
          {pending && <Spinner />}
          {room ? 'Сохранить группу' : 'Создать группу'}
        </Button>
      </Flexbox>
    </Flexbox>
  )
}

export const showRoomEditForm = (room?: ChatTypes.Room) => {
  modal({
    title: 'Создание группового чата',
    overlayClose: false,
    overrides: (t) => ({
      window: () => [
        {
          padding: '1.5rem',
          background: t.color.gray[50].hex(),
        },
      ],
    }),
    render: (close) => <RoomEditForm room={room} onClose={close} />,
  })
}
