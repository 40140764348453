import React, { useState } from 'react'

import { Client } from '@foods-n-goods/server/generated/schema'
import { Button, Flexbox, Header, modal, TextField } from '@stage-ui/core'
import { ClientActions } from 'actions'

type ClientDeclineModalProps = {
  client: Client
  close: () => void
}

function ClientDeclineModal(props: ClientDeclineModalProps) {
  const { client, close } = props
  const { id, markets, name, identifier } = client

  const clientName = markets[0]?.alias || name || identifier || 'Неопознанный енот'

  const [reasonText, setReasonText] = useState('')

  const handleDecline = async () => {
    await ClientActions.marketUpdate({
      id,
      payload: {
        comment: reasonText,
        status: 'DECLINED',
      },
    })
    close()
  }

  return (
    <Flexbox column flex={1}>
      <Header size="xs" mb="m" color="gray900">
        {clientName}
      </Header>
      <TextField
        autoFocus
        mt="m"
        value={reasonText}
        placeholder="Укажите причину отказа"
        onChange={(e) => setReasonText(e.target.value)}
      />
      <Flexbox mt="xl" alignItems="center" justifyContent="flex-end">
        <Button decoration="text" label="Отмена" onClick={close} color="gray500" />
        <Button label="Отказать" color="red500" onClick={handleDecline} ml="m" />
      </Flexbox>
    </Flexbox>
  )
}

export const openClientDeclineModal = (
  client: Client,
  e?: React.MouseEvent<HTMLDivElement, MouseEvent>,
) => {
  e?.stopPropagation()
  e?.preventDefault()

  modal({
    title: 'Отказать клиенту',
    w: '35rem',
    overlayClose: false,
    overrides: {
      header: () => [
        {
          marginBottom: 0,
        },
      ],
    },
    render: (close) => <ClientDeclineModal client={client} close={close} />,
  })
}
