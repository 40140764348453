import { Queries, Mutations } from 'requests'
import { YMapsApi } from 'react-yandex-maps'

declare global {
  let ymaps: YMapsApi
  const CFG_MODE: string

  interface RequestError {
    code: number
    message: string
  }

  type AppQueries = Queries
  type AppMutations = Mutations
  type AppRequests = Queries & Mutations
  type AppRequestsNames = keyof AppRequests
  type AppRequestHeader = {
    token?: string
    code?: string
  }
  type AppRequestParams = {
    name: AppRequestsNames
    variables?: AppRequests[AppRequestsNames]['params']
    headers?: AppRequestHeader
  }
  type AppRequestResponse<N = AppRequestsNames> = AppRequests[N]['response']
  type AppRequest = <N extends AppRequestsNames>(
    name: N,
    variables?: AppRequests[N]['params'],
    headers?: AppRequestHeader,
  ) => Promise<Exclude<AppRequestResponse<N>, void>>

  type KeyValueChangeFunction<T> = <K extends keyof T>(key: K, value: T[K]) => void
}
export default global
