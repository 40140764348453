import React from 'react'

import { Text } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import moment from 'moment'
import { PurchaseExtended } from 'pages/purchases/data'

export function PurchaseListTableCreateDateCell(
  ctx: TableTypes.TableCellContext<PurchaseExtended>,
) {
  const { row } = ctx
  const { createDate } = row

  return (
    <Text color="gray500" size="s">
      {moment(createDate).format('DD MMMM')}
    </Text>
  )
}
