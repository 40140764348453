import React, { useEffect } from 'react'

import { Flexbox, Spinner } from '@stage-ui/core'
import orderQuality from 'actions/orderQuality'
import Empty from 'components/Empty'
import MenuNavigation from 'components/MenuNavigation'
import { Section } from 'components/Section'
import useSelector from 'hooks/useSelector'

import { Device } from '@foods-n-goods/server/generated/schema'
import { DevicesStore } from 'store'

import { SocketType } from '@foods-n-goods/server/src/socket/types'
import { DevicesActions, OrderQualityActions } from 'actions'
import { io } from 'requests/socket'

import genCameraName from 'utils/genCameraName'

import { objectEqual } from 'utils/objectEqual'

import CamerasNavigation from './components/CamerasNavigation'
import { QualityReportView } from './components/ReportView'
import { QualityCamerasView } from './components/CamerasView'

let _timer: any

export function Quality() {
  const { filter, loading, data, devices } = useSelector(
    (state) => ({
      filter: state.orderQuality.filter,
      loading: state.orderQuality.loading,
      data: state.orderQuality.data,
      devices: state.devices,
    }),
    objectEqual,
  )

  const append = () => {
    if (_timer) {
      orderQuality.append()
      _timer = setTimeout(append, 5000)
    }
  }

  useEffect(() => {
    const updateDevices = (data: Device[]) => {
      DevicesStore.updateDevices(data)
    }

    DevicesActions.fetch()
    OrderQualityActions.fetch()

    io.on(SocketType.Broadcast.DevicesUpdate, updateDevices)

    _timer = setTimeout(append, 5000)

    return () => {
      io.off(SocketType.Broadcast.DevicesUpdate, updateDevices)
      clearTimeout(_timer)
      _timer = null

      OrderQualityActions.clearFilter()
    }
  }, [])

  const selectedDevice = devices.data.find((c) => genCameraName(c.id) === filter.section)

  return (
    <Section title="Контроль качества" divider={false}>
      <Flexbox justifyContent="space-between" mb="2.5rem">
        <MenuNavigation
          items={[{ id: 'photo', title: 'Фотоотчет' }]}
          currentSection={filter.section}
          onChange={(section) => OrderQualityActions.setFilter({ section })}
        />
        <CamerasNavigation />
      </Flexbox>
      {!data.length && loading === 'pending' && <Spinner p="m" />}
      {loading === 'resolved' && data.length === 0 && (
        <Empty text="Здесь будут появляться фото собранных продуктов в реальном времени" />
      )}
      {!filter.section && <QualityReportView />}
      {selectedDevice && <QualityCamerasView device={selectedDevice} />}
    </Section>
  )
}
