import React from 'react'

import { ProductInput } from '@foods-n-goods/server/generated/schema'
import { Select } from '@stage-ui/core'
import Content from 'components/Content'
import ViewInputField from 'components/Fields/ViewInputField'
import ViewGridBlock from 'components/ViewGridBlock'
import useSelector from 'hooks/useSelector'

import { objectEqual } from 'utils/objectEqual'

import ProductViewHeader from './Header'

interface ProductViewGeneralProps {
  modal?: boolean
  input: ProductInput
  onChange: KeyValueChangeFunction<ProductInput>
}

function ProductViewGeneral(props: ProductViewGeneralProps) {
  const { modal, onChange, input } = props

  const { storeCategories, loading } = useSelector(
    ({ category }) => ({
      loading: category.loading !== 'resolved',
      storeCategories: category.data.records,
    }),
    objectEqual,
  )

  const categories = storeCategories.map((c) => ({
    text: `${c.name} (${c.type.text})`,
    value: c.id,
  }))

  return (
    <Content>
      {!modal && <ProductViewHeader published={input.published} onChange={onChange} />}
      <ViewGridBlock>
        <ViewInputField
          label="Артикул"
          value={input.article}
          onChange={(value) => onChange('article', value)}
          type="integer"
        />
        <ViewInputField
          label="Наименование"
          value={input.name}
          onChange={(value) => onChange('name', value)}
          gridColumnEnd="span 2"
        />
        <ViewInputField
          label="Наименование для клиентов"
          value={input.alias}
          onChange={(value) => onChange('alias', value)}
          gridColumnEnd="span 2"
        />
        <ViewInputField
          label="Поисковые слова"
          value={input.searchWords || ''}
          onChange={(value) => onChange('searchWords', value)}
          gridColumnEnd="span 2"
        />
        <ViewInputField
          label="Сортировка"
          value={(input.sortWeight || 0).toString()}
          onChange={(value) => onChange('sortWeight', Number(value))}
        />
        <Select
          placeholder={
            loading ? 'Загрузка родительских групп...' : 'Выбрать родительскую группу'
          }
          disabled={!!loading}
          label="Размещение в группах"
          labelType="outside"
          options={categories}
          values={categories.filter((c) =>
            input.productGroups.some((pg) => c.value === pg),
          )}
          onChange={(options) =>
            onChange(
              'productGroups',
              options.map((o) => String(o.value)),
            )
          }
          clearable
          multiselect
          gridColumnEnd="span 3"
        />
      </ViewGridBlock>
    </Content>
  )
}

export default ProductViewGeneral
