import React, { useEffect, useState } from 'react'

import { Flexbox, Text } from '@stage-ui/core'
import { openClientAliasChangeModal } from 'components/Modals/ClientAliasChange'
import { openClientCredentialsChangeModal } from 'components/Modals/ClientCredentialsChange'

import { openClientExternalIdChangeModal } from 'components/Modals/ClientExIdChange'

import { Client } from '@foods-n-goods/server/generated/schema'

import { openClientAddressChangeModal } from 'components/Modals/ClientAddressChange'

import { Star } from '@stage-ui/icons'

import request from 'requests/request'

import SelectTypes from '@stage-ui/core/control/Select/types'

import { ClientDetailsInfoBlock } from '../components/InfoBlock'

import { ClientDetailsInfoRowProps } from '../components/InfoRow'

type ClientCardProps = {
  client: Client
}

export function ClientInfo(props: ClientCardProps) {
  const { client } = props

  const [marketUsersOptions, setMarketUsersOptions] = useState<
    SelectTypes.Option<string>[]
  >([])

  const getMarketUsers = async () => {
    const inn = client?.defaultCompany?.inn
    if (!inn) return
    const clients = await request('marketUsers', { inn })
    if (clients) {
      const options = clients.map((c) => ({
        text: `${c.name} - ${c.exId}`,
        value: c.exId,
      }))

      setMarketUsersOptions(options)
    }
  }

  useEffect(() => {
    getMarketUsers()
  }, [])

  const accountRows: ClientDetailsInfoRowProps[] = [
    {
      param: 'Логин',
      value: client.identifier,
      onChange: () => openClientCredentialsChangeModal(client),
    },
    {
      param: 'Код внешней системы',
      value:
        marketUsersOptions.find((mu) => mu.value === client.markets[0]?.exId)?.text ||
        client.markets[0]?.exId,
      onChange: () => openClientExternalIdChangeModal(client),
    },
    {
      param: 'Алиас',
      value: client.markets[0]?.alias,
      onChange: () => openClientAliasChangeModal(client),
    },
  ]

  const requisitesRows: ClientDetailsInfoRowProps[] = [
    {
      param: 'Кор.счёт',
      value: undefined,
    },
    {
      param: 'ОГРН',
      value: client?.defaultCompany?.ogrn,
    },
    {
      param: 'БИК',
      value: undefined,
    },
    {
      param: 'ИНН',
      value: client?.defaultCompany?.inn,
    },
    {
      param: 'Юридический адрес',
      value: undefined,
    },
  ]

  const addressesRows: ClientDetailsInfoRowProps[] = client.deliveryAddresses.map(
    (adr, idx) => {
      const param = adr.name || `Ресторан ${idx + 1}`
      const address = `${adr.address} ${adr.apartment || ''} ${adr.floor || ''}`
      return {
        param,
        value: (
          <Flexbox flex={1} alignItems="center">
            <Text color="gray900">{address}</Text>
            {adr.isDefault && (
              <Star color="yellow500" type="filled" ml="s" size="1.125rem" />
            )}
          </Flexbox>
        ),
        onChange: () => openClientAddressChangeModal(client.id, adr),
      }
    },
  )

  return (
    <>
      <ClientDetailsInfoBlock
        clientId={client.id}
        block="account"
        title="Аккаунт"
        rows={accountRows}
      />
      <ClientDetailsInfoBlock
        clientId={client.id}
        block="requisites"
        title="Реквизиты организации"
        rows={requisitesRows}
      />
      <ClientDetailsInfoBlock
        clientId={client.id}
        block="address"
        title="Адреса организации"
        rows={addressesRows}
        noDataComponent={
          <Flexbox flex={1} p="m" pb={0} centered column>
            <Text size="s" color="gray500">
              Нет адресов доставки
            </Text>
          </Flexbox>
        }
      />
    </>
  )
}
