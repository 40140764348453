import { Block, Text } from '@stage-ui/core'

interface EmptyDataProps {
  text?: string
}

function NoData(props: EmptyDataProps) {
  return (
    <Block decoration="surface" py="1.25rem" textAlign="center">
      <Text children={props.text || 'Нет данных для отображения'} />
    </Block>
  )
}

export default NoData
