import { FC, ReactNode } from 'react'

import { Block, Flexbox, Paragraph } from '@stage-ui/core'
import BlockTypes from '@stage-ui/core/layout/Block/types'

interface ContentProps {
  title?: string
  subtitle?: string
  actions?: ReactNode
  icon?: ReactNode
  decoration?: BlockTypes.Props['decoration']
}

const Content: FC<ContentProps & BlockTypes.Props> = (props) => {
  const { title, subtitle, actions, icon, decoration, children, ...blockProps } = props

  return (
    <Block
      style={{ transition: 'all .15s ease-in-out' }}
      decoration="surface"
      borderRadius="m"
      p="l"
      mt="m"
      borderColor="gray200"
      {...blockProps}
    >
      {title && (
        <Flexbox pb="m" justifyContent="space-between" alignItems="center">
          <Flexbox alignItems="center">
            {icon && <Block mr="1.25rem" children={icon} />}
            <Block>
              <Paragraph
                m={0}
                children={title}
                transform="uppercase"
                color="gray400"
                weight={700}
                size="1.125rem"
              />
              {subtitle && <Paragraph m={0} size="s" children={subtitle} />}
            </Block>
          </Flexbox>
          {actions}
        </Flexbox>
      )}
      {children}
    </Block>
  )
}

export default Content
