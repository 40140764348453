import { Journal } from '@foods-n-goods/server/generated/schema'
import { Flexbox, Paragraph, Text } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import { DateTime } from 'components/DateTime'

function Action(ctx: TableTypes.TableCellContext) {
  const { action, isSystem } = ctx.row as Journal
  return (
    <Flexbox column>
      {isSystem && (
        <Paragraph m={0} size="s" color="warning" children="Системное событие" />
      )}
      <Paragraph m={0} children={action} />
    </Flexbox>
  )
}

export const columns: TableTypes.TableColumn[] = [
  {
    key: 'createDate',
    title: 'Дата',
    render: (ctx) => <DateTime date={ctx.row.createDate} />,
    width: '10rem',
  },
  {
    key: 'staff',
    title: 'Пользователь',
    render: (ctx) => (
      <Text size="s" color="gray900">
        {ctx.row.staff.name || ctx.row.staff.login}
      </Text>
    ),
    width: '12rem',
  },
  { key: 'action', title: 'Событие', render: Action },
]
