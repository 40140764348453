import React from 'react'

import { Client } from '@foods-n-goods/server/generated/schema'
import { Flexbox, Text, useTheme } from '@stage-ui/core'
import TableTypes from '@stage-ui/core/data/Table/types'
import { Star } from '@stage-ui/icons'

export function RatingCell(ctx: TableTypes.TableCellContext<Client>) {
  const { row } = ctx
  const { color } = useTheme()

  const ratingString = (row.rating * 10 || 0).toFixed(1)
  const rating =
    ratingString.split('.')[1] === '0' ? ratingString.split('.')[0] : ratingString

  return (
    <Flexbox alignItems="center">
      <Star size="m" mr="s" color={color.yellow[400].hex()} type="filled" />
      <Text size="s">{rating} / 10</Text>
    </Flexbox>
  )
}
