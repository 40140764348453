import React, { useState } from 'react'

import { Client } from '@foods-n-goods/server/generated/schema'
import { Flexbox, modal, Text, Button, TextField } from '@stage-ui/core'
import { Info } from '@stage-ui/icons'
import { ClientActions } from 'actions'
import useSelector from 'hooks/useSelector'
import { objectEqual } from 'utils/objectEqual'

type ClientPushMessageModalProps = {
  client?: Client
  close: () => void
}

function ClientPushMessageModal(props: ClientPushMessageModalProps) {
  const { close, client } = props
  const [message, setMessage] = useState('')

  const user = useSelector(({ app }) => app.user, objectEqual)

  const name = user?.name || user?.login || 'Безымянный пользователь'

  const handleSendMessage = () => {
    if (client) {
      ClientActions.sendPushMessage({
        message,
        silent: false,
        title: `Сообщение от ${name}`,
        id: client.id,
        cb: close,
      })
      return
    }
    ClientActions.broadcastPushMessage({
      message,
      silent: false,
      title: `Сообщение от ${name} `,
      product_id: '',
      cb: close,
    })
  }

  return (
    <Flexbox column w="30rem">
      <Flexbox
        p="m"
        borderStyle="solid"
        borderWidth="1px"
        borderColor="gray200"
        borderRadius="m"
        backgroundColor="gray100"
        alignItems="center"
      >
        <Info color="orange500" mr="m" size="1.125rem" />
        <Flexbox column>
          <Text size="s" color="orange500">
            {client
              ? 'Сообщение будет отправлено выбранному клиенту.'
              : 'Сообщение будет отправлено всем клиентам.'}
          </Text>
          <Text size="s" color="orange500">
            Отправлять сообщение можно 1 раз в 15 минут.
          </Text>
        </Flexbox>
      </Flexbox>
      <TextField
        placeholder="Текст сообщения"
        multiline
        rows={4}
        value={message}
        onChange={(v) => setMessage(v.target.value)}
        mt="l"
      />
      <Flexbox mt="xl" alignItems="center" justifyContent="flex-end">
        <Button decoration="text" label="Отмена" onClick={close} color="gray500" />
        <Button label="Отправить" onClick={handleSendMessage} ml="m" />
      </Flexbox>
    </Flexbox>
  )
}

export const openClientPushMessageModal = (
  client?: Client,
  e?: React.MouseEvent<HTMLDivElement, MouseEvent>,
) => {
  e?.stopPropagation()
  e?.preventDefault()

  modal({
    title: `Отправить уведомление ${client ? 'клиенту' : 'клиентам'}`,
    overlayClose: false,
    render: (close) => <ClientPushMessageModal close={close} client={client} />,
  })
}
