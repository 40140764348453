import type {
  ClientProduct,
  ClientDeliveryAddress,
  AvailableDate,
  AvailableDateInterval,
  ClientPaymentMethod,
} from '@foods-n-goods/server/generated/schema'

import type { LocalGQLClient } from '../utils/createGQLClient'

import type { EnhancedStore } from '@reduxjs/toolkit'

import type { ClientRequests } from './requests'
import type { AppState } from './store/app'
import type { UserState } from './store/user'
import type { ShopState } from './store/shop'
import type { ChatState } from './store/chat'
import type { TemplatesState } from './store/templates'

export enum OrderStatusCode {
  NEW = 1,
  PAYED = 2,
  SORTING = 3,
  AWAIT_COURIER = 4,
  PICKED_UP = 5,
  DELIVERY = 6,
  DELIVERED = 7,
  NOT_DELIVERED = 8,
  REJECTED = 9,
  OUT_OF_STOCK = 10,
  PURCHASE_PENDING = 11,
  PURCHASE_CONFIRMED = 12,
  PURCHASE_FAILED = 13,
  CANCELED = 14,
}

declare namespace ClientSystem {
  interface State {
    app: AppState
    user: UserState
    shop: ShopState
    chat: ChatState
    templates: TemplatesState
  }

  type AddressFormData = {
    address: string
    floor: string
    apartment: string
    intercom: string
  }

  interface CartItem {
    quantity: number
    unitName: string
    product: ClientProduct
    comment?: string
    wishfulUnitId?: string
    amount: number
    weight?: number
    checked?: boolean
  }

  interface Options {
    endpoint: string
    staticEndpoint: string
    name?: string
    devTools?: boolean
    localStorage: {
      setItem: SetLocalVariable
      getItem: GetLocalVariable
      removeItem: RemoveLocalVariable
    }
    callbacks?: {
      onUserLoaded?: () => void
    }
  }

  interface ActionsOptions extends Options {
    store: EnhancedStore<State>
    request: LocalGQLClient<ClientRequests>
  }

  type Error = {
    code: number
    message: string
  }
  type Loading = 'idle' | 'pending' | 'resolved' | 'rejected'
  type StoreData<T> = {
    loading: Loading
    data: T
    error?: Error | null
    pending?: boolean
  }

  type SetLocalVariable = (key: string, value: any) => Promise<void>
  type GetLocalVariable = (key: string) => Promise<any>
  type RemoveLocalVariable = (key: string) => Promise<any>
}

export type CheckOject = {
  value?: boolean
  hint: string
  async?: boolean
}

export type SharedValidation = { summary?: boolean }
export type InnValidation = SharedValidation & {
  length: CheckOject
  isNumber: CheckOject
}
export type PasswordValidation = SharedValidation & { length: CheckOject }
export type NameValidation = SharedValidation & { unique: CheckOject; length: CheckOject }
export type PhoneValidation = SharedValidation & { length: CheckOject }
export type EmailValidation = SharedValidation & { form: CheckOject }
export type Validations =
  | InnValidation
  | PasswordValidation
  | NameValidation
  | PhoneValidation
  | EmailValidation
  | SharedValidation

export type FormItem<
  T extends
    | string
    | ClientDeliveryAddress
    | AvailableDate
    | AvailableDateInterval
    | ClientPaymentMethod
    | undefined,
> = {
  name: FormNames
  value: T
  keyboardType?: 'default' | 'number-pad' | 'email-address' | 'phone-pad' | 'numeric'
  secure?: boolean
  hint?: string
  validation: Validations
}

export enum OrderPropertyNames {
  ADDRESS = 'Адрес',
  DELIVERY_DATE = 'Дата доставки',
  DELIVERY_INTERVAL = 'Интервал',
  CONTACT_PERSON = 'Контактное лицо',
  PHONE = 'Телефон',
  PAYMENT_METHOD = 'Способ оплаты',
  COMMENT = 'Комментарий',
}

export enum FormNames {
  INN = 'ИНН',
  PHONE = 'Телефон',
  EMAIL = 'E-mail',
  USER_NAME = 'Имя пользователя',
  PASSWORD = 'Пароль',

  ADDRESS = 'Адрес',
  DELIVERY_DATE = 'Дата доставки',
  DELIVERY_INTERVAL = 'Интервал',
  CONTACT_PERSON = 'Контактное лицо',
  // PHONE = 'Телефон',
  PAYMENT_METHOD = 'Способ оплаты',
  COMMENT = 'Комментарий',
}

export type OrderInfoItem = {
  title: OrderPropertyNames
  address?: ClientDeliveryAddress
  deliveryDate?: AvailableDate
  deliveryInterval?: AvailableDateInterval
  contactPerson?: string
  phone?: string
  paymentMethod?: ClientPaymentMethod
}

export default ClientSystem
