import React, { useEffect } from 'react'

import { Section } from 'components/Section'
import { Route } from 'react-router-dom'

import { ClientActions } from 'actions'

import ClientList from './ClientList'
import { ClientsSectionHeader } from './components/SectionHeader'
import { ClientDetails } from './ClientDetails'

export function Clients() {
  useEffect(() => {
    return () => {
      ClientActions.clearFilter()
    }
  }, [])

  return (
    <Section titleComponent={<ClientsSectionHeader />} p="0 l">
      <Route exact path="/clients" component={ClientList} />
      <Route exact path="/clients/:id" component={ClientDetails} />
    </Section>
  )
}
