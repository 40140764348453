import React from 'react'

import { PageTable } from 'components/PageTable'
import useSelector from 'hooks/useSelector'

import { objectEqual } from 'utils/objectEqual'

import { columns } from './misc'

function VariablesTable() {
  const data = useSelector(({ variables }) => variables.data, objectEqual)

  return (
    <PageTable
      noDataText="Не найдено конфигураций."
      data={data}
      columns={columns}
      pagination={{
        pageSize: 40,
      }}
    />
  )
}

export default VariablesTable
