import React from 'react'

import { modal } from '@stage-ui/core'
import { StaffDetails } from 'pages/staff/Details'
import { Staff } from '@foods-n-goods/server/generated/schema'

export const openStaffModal = (
  id: Staff['id'],
  e?: React.MouseEvent<HTMLDivElement, MouseEvent>,
) => {
  e?.stopPropagation()
  e?.preventDefault()

  modal({
    decoration: 'panel',
    hideHeader: true,
    overrides: (t) => ({
      window: () => [
        {
          background: t.color.background.hex(),
          minWidth: '90vw',
          minHeight: '90vh',
        },
      ],
    }),
    render: (close) => <StaffDetails id={id} onClose={close} />,
  })
}
