import React, { useCallback, useEffect, useState } from 'react'

import { TextField } from '@stage-ui/core'
import { Search } from '@stage-ui/icons'

import { debounce } from '@foods-n-goods/client/utils/debounce'

type Props = {
  onSearch: (search: string) => void
  initial?: string
  overrides?: {}
}

const FilterCommon: React.FC<Props> = ({ onSearch, initial, overrides }) => {
  const [search, setSearch] = useState('')
  const refetchAfterDebounce = useCallback(debounce(onSearch, 500), [initial])

  useEffect(() => {
    setSearch(initial ?? '')
  }, [initial])

  return (
    <TextField
      size="s"
      clearable
      placeholder="Поиск"
      leftChild={<Search size="l" />}
      value={search}
      onChange={(e) => {
        setSearch(e.target.value)
        refetchAfterDebounce(e.target.value)
      }}
      overrides={{
        container: {
          maxWidth: '20rem',
        },
        field: {
          height: '2.5rem',
          padding: '0.625rem',
          transition: 'background .2s ease-in-out',
          boxShadow: 'none',
          background: search ? 'surface' : 'transparent',
          ':focus-within': {
            background: 'white',
          },
          ...overrides,
        },
      }}
    />
  )
}

export default FilterCommon
