import { Paragraph } from '@stage-ui/core'

interface ReviewViewHeaderProps {
  modal?: boolean
}

function ReviewViewHeader(props: ReviewViewHeaderProps) {
  if (props.modal) return null

  return (
    <Paragraph
      m={0}
      children="Детали отзыва"
      transform="uppercase"
      color="gray400"
      weight={700}
      size="1.125rem"
    />
  )
}

export default ReviewViewHeader
