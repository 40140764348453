import moment from 'moment'

interface QueueObject {
  id: string
  name: string | number | symbol
  startTime: number
}

namespace Queue {
  let queue: Array<QueueObject> = new Array<QueueObject>()
  const MAXQUEUE_SIZE = 20

  /* Уникальные идентификаторы */
  function generateId() {
    return Math.random().toString(16).slice(2)
  }

  /* Подчистка мусора */
  function gc() {
    if (queue.length && queue.length > MAXQUEUE_SIZE) {
      queue = queue.slice(1)
    }
  }

  /* Очищаем запросы */
  export function dropAll() {
    queue = []
  }

  /* Добавление запроса в очередь */
  export function add(name: QueueObject['name']) {
    const id = generateId()
    gc()

    queue = queue.filter((req) => req.name !== name)
    queue.push({ id, name, startTime: moment().valueOf() })

    return id
  }

  /* Запись результата запроса */
  export function done(id: string) {
    queue = queue.filter((req) => req.id !== id)
  }

  /* Проверяем необходим ли запрос */
  export function isExist(id: string) {
    return !!queue.find((q) => q.id == id)
  }
}

export default Queue
